import React, { useCallback } from 'react';
import lodash from 'lodash';
import { useRefresh, usePermissions, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import styles from './UserPhotoInput.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import { Action, Section } from '../../permissions';

const UserPhotoInput = ({ label, record, userIdSource }) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const refresh = useRefresh();
  const handleChange = useCallback((event) => {
    const userId = lodash.get(record, userIdSource);
    const file = event.target.files[0];
    service.uploadUserPhoto(userId, file).then((response) => {
      refresh();
    }).catch((error) => {
      console.log(error);
    });
  }, [record, refresh, service, userIdSource]);
  const { loaded, permissions } = usePermissions();
  const showUploadButton = loaded && permissions.check(Section.USERS, Action.UPDATE);
  if (!showUploadButton) {
    return null;
  }
  return (
    <label className={styles.buttonContainer}>
      <input
        accept="image/*"
        className={styles.hidden}
        onChange={handleChange}
        type="file"
      />
      <Button
        variant="contained"
        color="primary"
        component="span"
        startIcon={<CloudUploadIcon />}
      >
        {translate(label)}
      </Button>
    </label>
  );
};

export default UserPhotoInput;
