import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import styles from './MedicEfficiencyForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import {getAuthToken} from '../../storages/auth';
import {formatApiDate} from '../../utils/formatters';

class MedicEfficiencyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dealers: [],
      submitProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());

    const params = this.props.params;
    const periodEnd = moment();
    const periodStart = moment().subtract(1, 'days');
    this.formInitialValues = (params) ? params : {
      dateFrom: formatApiDate(periodStart),
      dateTo: formatApiDate(periodEnd),
      dealerId: null
    };

    this.requiredFields = ['dateFrom', 'dateTo'];
  }

  componentDidMount() {
    this.service.getMedicEfficiencyDealers().then((response) => {
      this.setState({dealers: response.json.data});
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {dealers, submitProgress} = this.state;
    return (
      <Paper>
        <Form
          initialValues={this.formInitialValues}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateInput
              className={styles.formControl}
              source="dateFrom"
              label="telemedic.medicEfficiency.form.dateFrom"
              required/>
            <DateInput
              className={styles.formControl}
              source="dateTo"
              label="telemedic.medicEfficiency.form.dateTo"
              required/>
            <AutocompleteInput
              className={styles.formControl}
              source="dealerId"
              label="telemedic.medicEfficiency.form.dealer"
              choices={dealers}
              choiceLabelField="name"
              choiceValueField="id"/>
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }

  validate = (values) => {
    const errors = {};
    for (const fieldName of this.requiredFields) {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    }
    return errors;
  };

  onSubmit = (data) => {
    this.props.paramsCallback(data);
    this.setState({submitProgress: true});
    const {dateFrom, dateTo, dealerId} = data;
    const promise = this.service.getMedicEfficiencyReport(dateFrom, dateTo, dealerId);
    promise.then((response) => {
      this.setState({submitProgress: false});
      if (response.json.checkups.length > 0) {
        this.props.dataCallback(response.json);
      }
      else {
        this.props.showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({submitProgress: false});
      this.props.showNotification('telemedic.errors.submitError');
    });
  };
}

export default connect(null, {showNotification})(withTranslate(MedicEfficiencyForm));
