import React from 'react';
import {BooleanInput} from 'react-admin';

import FileInput from './FileInput';


const DeleteableFileInput = ({
    accept,
    basePath,
    deleteCheckboxLabel,
    fileInputLabel,
    id,
    record,
    resource,
    source,
    ...rest}) => {
  const showDeleteCheckbox = !!(record && record[source])
  return (
    <React.Fragment>
      <FileInput
        accept={accept}
        basePath={basePath}
        id={id + '_file'}
        label={fileInputLabel}
        record={record}
        resource={resource}
        source={source + '_file'}/>
      {showDeleteCheckbox &&
        <BooleanInput
          basePath={basePath}
          id={id + '_delete'}
          label={deleteCheckboxLabel}
          record={record}
          resource={resource}
          source={source + '_delete'}/>
      }
    </React.Fragment>
  );
}

export default DeleteableFileInput;
