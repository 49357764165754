import React, { useCallback, useMemo } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { FixedSizeList as List } from 'react-window';

import MultiDownshift from '../components/inputs/MultiDownshift';

const wrapperStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
};

const topDropdownStyle = {
  position: 'absolute',
  zIndex: 10,
  left: 0,
  bottom: 48,
  minWidth: 256,
  maxWidth: 400,
};

const bottomDropdownStyle = {
  position: 'absolute',
  zIndex: 10,
  left: 0,
  minWidth: 256,
  maxWidth: 400,
};

const hiddenStyle = {
  visibility: 'hidden',
};

const chipStyle = {
  marginRight: 5,
  marginTop: 5,
};

const createMultiAutocomplete = (WrappedInput) => ({
  choices,
  choiceLabelField = 'label',
  choiceValueField = 'value',
  onChange,
  suggestionRows = 8,
  value,
  ...rest
}) => {
  const labelMap = useMemo(() => {
    const labelMap = {};
    choices.forEach((choice) => {
      labelMap[choice[choiceValueField]] = choice[choiceLabelField];
    });
    return labelMap;
  }, [choices, choiceLabelField, choiceValueField]);
  const itemToString = useCallback((item) => {
    const label = labelMap[item];
    return typeof (label) !== 'undefined' ? label : '';
  }, [labelMap]);
  return (
    <MultiDownshift
      onChange={onChange}
      itemToString={itemToString}
      initialValue={value}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        getButtonProps,
        getRemoveButtonProps,
        selectedItems,
        onDelete,
      }) => (
        <MultiAutocomplete
          WrappedInput={WrappedInput}
          choices={choices}
          choiceLabelField={choiceLabelField}
          choiceValueField={choiceValueField}
          clearSelection={clearSelection}
          getInputProps={getInputProps}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          inputValue={inputValue}
          selectedItems={selectedItems}
          isOpen={isOpen}
          openMenu={openMenu}
          rootProps={getRootProps({ refKey: 'innerRef' }, { suppressRefError: true })}
          suggestionRows={suggestionRows}
          getButtonProps={getButtonProps}
          getRemoveButtonProps={getRemoveButtonProps}
          onDelete={onDelete}
          {...rest}
        />
      )}
    </MultiDownshift>
  );
};

const MultiAutocomplete = ({
  WrappedInput,
  choices,
  choiceLabelField,
  choiceValueField,
  clearSelection,
  dropdownAboveInput,
  getInputProps,
  getItemProps,
  getMenuProps,
  getRootProps,
  highlightedIndex,
  inputValue,
  selectedItems,
  isOpen,
  openMenu,
  resettable,
  rootProps,
  suggestionRows,
  getButtonProps,
  getRemoveButtonProps,
  onDelete,
  ...rest
}) => {
  const suggestions = useMemo(() => {
    const trimmedValue = inputValue.trim();
    if (choices && choices.length) {
      if (trimmedValue.length) {
        const valueRegExp = new RegExp(
          trimmedValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').replace(' ', '.*'), 'i',
        );
        return choices.filter((item) => valueRegExp.test(item[choiceLabelField]));
      }
      return choices;
    }
    return [];
  }, [choices, choiceLabelField, inputValue]);
  const { innerRef, ...restRootProps } = rootProps;
  const suggestionsHeight = suggestions.length > suggestionRows
    ? suggestionRows * 40
    : suggestions.length * 40;
  return (
    <div style={wrapperStyle} ref={innerRef} {...restRootProps}>
      <WrappedInput
        autoComplete="off"
        endAdornment={
          resettable ? (
            <IconButton
              size="small"
              style={inputValue ? null : hiddenStyle}
              onClick={clearSelection}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : null
        }
        {...getInputProps({ ...rest, onFocus: openMenu })}
      />
      <div {...getMenuProps()}>
        {isOpen && (
          <Paper style={dropdownAboveInput ? topDropdownStyle : bottomDropdownStyle} square>
            <List
              width={256}
              height={suggestionsHeight}
              itemCount={suggestions.length}
              itemSize={40}
              value={inputValue}
            >
              {({ index, style }) => {
                const suggestion = suggestions[index];
                return (
                  <MenuItem
                    {...getItemProps({
                      key: index,
                      component: 'div',
                      index: index,
                      item: suggestion[choiceValueField],
                      selected: (highlightedIndex === index || selectedItems.includes(suggestion.name)),
                      style: style,
                      title: suggestion[choiceLabelField],
                    })}
                  >
                    {suggestion[choiceLabelField]}
                  </MenuItem>
                );
              }}
            </List>
          </Paper>
        )}
      </div>
      <div display="flex">
        {selectedItems.length > 0 && selectedItems.map((item) => (
          <Chip label={item} style={chipStyle} onDelete={() => { onDelete(item); }} />
        ))}
      </div>
    </div>
  );
};

export default createMultiAutocomplete;
