import React, { createContext, useState, useRef, useEffect } from 'react'
import { WS_ROOT, WS_SECRET_KEY } from '../config';
import { getAuthToken } from '../storages/auth';

const WS_RECONNECT_PERIOD = 5000;

export const WebsocketContext = createContext(false, null, () => {});

export const WebsocketProvider = ({ children }) => {
    const [isReady, setIsReady] = useState(false);
    const [val, setVal] = useState(null);

    const ws = useRef(null);

    const connect = () => {
        const socket = new WebSocket(WS_ROOT);

        socket.onopen = () => {
            if (getAuthToken() === null) {
                socket.close();
                return;
            }
            ws.current.send(JSON.stringify({
                type: 'auth_user',
                data: {
                    server_key: WS_SECRET_KEY,
                    auth_token: getAuthToken()
                }
            }));
            setIsReady(true);
            window.dispatchEvent(new Event('websocketsAuthenitcated'));
        }
        socket.onclose = () => {
            setIsReady(false);
            setTimeout(() => connect(), WS_RECONNECT_PERIOD);
        }
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'observe_checkup') {
                localStorage.setItem('observedCheckupIds', data['observed_checkup_ids']);
                window.dispatchEvent(new Event('observedCheckupsUpdate'));
            }
            setVal(data);
        }

        ws.current = socket;

        return () => socket.close();
    }


    useEffect(connect, []);

    const ret = [isReady, val, ws.current?.send.bind(ws.current)];

    return (
        <WebsocketContext.Provider value={ret}>
            {children}
        </WebsocketContext.Provider>
    );
};
