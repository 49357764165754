import download from 'downloadjs';

import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import {
  RadioButtonGroupInput, Title, useTranslate, usePermissions,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import AttachFile from '@material-ui/icons/AttachFile';
import Lock from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormDiv from '../../components/FormDiv';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import TZDateInput from '../../components/inputs/TZDateInput';
import styles from './CheckupJournalsPage.module.css';
import useAsyncValue from '../../hooks/useAsyncValue';
import useTelemedicService from '../../hooks/useTelemedicService';
import { getRutokenServiceInstance } from '../../services/RutokenService';
import { Section, Action } from '../../permissions';
import { downloadDocx, downloadExcel, downloadPdf } from '../../utils/download';
import { formatDisplayDate } from '../../utils/formatters';

const DOCX = 'docx';
const PDF = 'pdf';
const XLSX = 'xlsx';
const JOURNAL_FORMATS = [
  { id: DOCX, name: 'telemedic.checkupJournals.form.getDOCX' },
  { id: PDF, name: 'telemedic.checkupJournals.form.getPDF' },
  { id: XLSX, name: 'telemedic.checkupJournals.form.getXLSX' },
];
const REQUIRED_FIELDS = ['periodFrom', 'periodTo', 'organizationId', 'journalFormat'];

const CheckupJournalsPage = () => {
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  const service = useTelemedicService();
  const [organizations] = useAsyncValue(
    () => service.getCheckupJournalFormOrganizationsData(), null, [],
  );
  const initialValues = useMemo(() => {
    const monthAgo = moment().subtract(1, 'months');
    const periodFrom = monthAgo.clone().startOf('month');
    const periodTo = monthAgo.clone().endOf('month');
    const journalFormat = DOCX;
    return { periodFrom, periodTo, journalFormat };
  }, []);
  const validate = useCallback((formData) => {
    const errors = {};
    REQUIRED_FIELDS.forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  }, []);
  const [checkupJournal, setCheckupJournal] = useState(false);
  const [checkupJournalType, setCheckupJournalType] = useState(false);
  const [userSignature, setSignature] = useState(false);
  const [submissionProgress, setSubmissionProgress] = useState(false);
  const [submittedPeriodFrom, setSubmittedPeriodFrom] = useState(false);
  const [submittedPeriodTo, setSubmittedPeriodTo] = useState(false);
  const [shouldSign, setShouldSign] = useState(false);
  const [userHasSignature, setUserHasSignature] = useState(false);
  if (shouldSign === undefined) {
    setShouldSign(false);
  }
  const rutokenService = useMemo(() => getRutokenServiceInstance(), []);
  const downloadJournal = (journal, journalType, periodFrom, periodTo) => {
    const filename = `Журнал медосмотров ${formatDisplayDate(periodFrom)} - ${formatDisplayDate(periodTo)}`;
    if (journalType === DOCX) {
      downloadDocx(journal, filename);
    } else if (journalType === PDF) {
      downloadPdf(journal, filename);
    } else if (journalType === XLSX) {
      downloadExcel(journal, filename);
    }
  };

  const checkSignature = useCallback(() => {
    rutokenService.selectDevice().then((deviceId) => {
      setUserHasSignature(!!deviceId);
    }).catch((e) => {
      setUserHasSignature(false);
    });
  }, [rutokenService]);

  const onClickDownloadJournal = () => (
    downloadJournal(checkupJournal, checkupJournalType, submittedPeriodFrom, submittedPeriodTo)
  );
  const onClickDownloadSignature = () => {
    const formattedDate = `${formatDisplayDate(submittedPeriodFrom)} - ${formatDisplayDate(submittedPeriodTo)}`;
    download(
      userSignature,
      `Подпись журнала медосмотров ${formattedDate}.sig`,
    );
  };

  const userHasSignPermission = loaded && userHasSignature && permissions.check(Section.CHECKUP_JOURNALS, Action.SIGN);
  if (!loaded) {
    checkSignature();
  }

  const sign = (journal) => {
    rutokenService.getDeviceAndCertificateId().then(
      (result) => rutokenService.sign(result.deviceId, result.certificateId, journal),
    ).then((signature) => {
      setSignature(signature);
      setSubmissionProgress(false);
    }).catch((e) => {
      console.log(e);
    });
  };

  const submit = (formData) => {
    let journalPromise;
    if (formData.journalFormat === DOCX) {
      journalPromise = service.getCheckupJournal(formData);
    } else if (formData.journalFormat === PDF) {
      journalPromise = service.getCheckupJournalPdf(formData);
    } else if (formData.journalFormat === XLSX) {
      journalPromise = service.getCheckupJournalXlsx(formData);
    }
    setSubmissionProgress(true);
    journalPromise.then(({ blob }) => {
      setCheckupJournal(blob);
      setCheckupJournalType(formData.journalFormat);
      setSubmittedPeriodFrom(formData.periodFrom);
      setSubmittedPeriodTo(formData.periodTo);
      if (shouldSign) {
        sign(blob);
      } else {
        downloadJournal(blob, formData.journalFormat, formData.periodFrom, formData.periodTo);
        setSubmissionProgress(false);
      }
    }).catch((e) => {
      console.log(e);
      setSubmissionProgress(false);
    });
  };

  return (
    <div>
      <Title title="telemedic.checkupJournals.label" />
      <Paper>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          <FormDiv className={styles.container}>
            <FormDiv className={styles.firstColumn}>
              <FormContent>
                <TZDateInput
                  className={styles.formControl}
                  label="telemedic.checkupJournals.form.periodFrom"
                  required
                  source="periodFrom"
                />
                <TZDateInput
                  className={styles.formControl}
                  isPeriodEnd
                  label="telemedic.checkupJournals.form.periodTo"
                  required
                  source="periodTo"
                />
                <AutocompleteInput
                  choices={organizations}
                  choiceLabelField="name"
                  choiceValueField="id"
                  className={styles.formControl}
                  label="telemedic.checkupJournals.form.organization"
                  required
                  source="organizationId"
                />
                <RadioButtonGroupInput
                  className={styles.formControl}
                  source="journalFormat"
                  label="telemedic.checkupJournals.form.journalFormat"
                  required
                  choices={JOURNAL_FORMATS}
                />
                {
                  userHasSignPermission && (
                    <FormControlLabel
                      control={<Checkbox checked={shouldSign} onChange={() => setShouldSign(!shouldSign)} />}
                      label={translate('telemedic.checkupJournals.signJournal')}
                    />
                  )
                }
                {
                  userHasSignPermission && (
                    <FormLabel className={styles.hint}>
                      {translate('telemedic.checkupJournals.signatureHint')}
                    </FormLabel>
                  )
                }
              </FormContent>
              {
                (checkupJournal || userSignature) && userHasSignPermission && (
                  <div className={styles.downloadButtonContainer}>
                    <FormLabel>{translate('telemedic.checkupJournals.filesAreReady')}</FormLabel>
                    {
                      checkupJournal && (
                        <Button
                          className={styles.downloadButton}
                          variant="outlined"
                          onClick={onClickDownloadJournal}
                          startIcon={<AttachFile />}
                        >
                          {translate('telemedic.checkupJournals.downloadJournal')}
                        </Button>
                      )
                    }
                    {
                      userSignature && (
                        <Button
                          className={styles.downloadButton}
                          variant="outlined"
                          startIcon={<Lock />}
                          onClick={onClickDownloadSignature}
                        >
                          {translate('telemedic.checkupJournals.downloadSignature')}
                        </Button>
                      )
                    }
                  </div>
                )
              }
            </FormDiv>
            <FormDiv className={styles.secondColumn}>
              {
                !userHasSignPermission && (
                  <div className={styles.warningContainer}>
                    <Typography className={styles.warning}>
                      {translate('telemedic.checkupJournals.requiresECP')}
                    </Typography>
                  </div>
                )
              }
              <div className={styles.warningContainer}>
                <Typography className={styles.warning}>
                  {translate('telemedic.checkupJournals.signaturePDFOnly')}
                </Typography>
              </div>
            </FormDiv>
          </FormDiv>
          <FormFooter>
            <ProgressButton
              color="primary"
              label="telemedic.actions.send"
              progress={submissionProgress}
              type="submit"
              variant="contained"
            />
          </FormFooter>
        </Form>
      </Paper>
    </div>
  );
};

export default CheckupJournalsPage;
