import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './CheckupsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import { formatDisplayDate } from '../../utils/formatters';
import { RISK_GROUP_PRESSURE_AND_PULSE, RISK_GROUP_ALCOHOL, RISK_GROUP_TEMPERATURE } from './constants';

const formatPercent = (count, total) => `${Math.round((count * 100) / total)}%`;

class RiskGroupDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statisticsData: null,
      exportProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onExport = () => {
    this.setState({ exportProgress: true });
    const { params, showNotification } = this.props;
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId, riskGroupParams,
    } = params;
    const promise = this.service.exportRiskGroup(
      dateFrom, dateTo, checkupType, organizationId, terminalId, riskGroupParams
    );
    promise.then(() => {
      this.setState({ exportProgress: false });
    }).catch((e) => {
      console.log(e);
      this.setState({ exportProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  }

  renderTable(data) {
    const { translate, params } = this.props;
    let columnReasonColSpan = 1;
    params.riskGroupParams.forEach((riskGroupParam) => {
      if (riskGroupParam === RISK_GROUP_PRESSURE_AND_PULSE) {
        columnReasonColSpan += 2;
      } else if (riskGroupParam === RISK_GROUP_TEMPERATURE) {
        columnReasonColSpan += 1;
      } else if (riskGroupParam === RISK_GROUP_ALCOHOL) {
        columnReasonColSpan += 1;
      }
    });
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>{translate('telemedic.statistics.riskGroup.columnIndex')}</TableCell>
              <TableCell rowSpan={2}>{translate('telemedic.statistics.riskGroup.columnFullName')}</TableCell>
              <TableCell colSpan={columnReasonColSpan}>{translate('telemedic.statistics.riskGroup.columnReason')}</TableCell>
              <TableCell rowSpan={2}>{translate('telemedic.statistics.riskGroup.columnStatistics')}</TableCell>
              <TableCell rowSpan={2}>{translate('telemedic.statistics.riskGroup.columnDisapprovals')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('telemedic.statistics.riskGroup.columnCheckupDate')}</TableCell>
              {
                params.riskGroupParams.includes(RISK_GROUP_PRESSURE_AND_PULSE) && (
                  <TableCell>{translate('telemedic.statistics.riskGroup.columnBloodPressure')}</TableCell>
                )
              }
              {
                params.riskGroupParams.includes(RISK_GROUP_PRESSURE_AND_PULSE) && (
                  <TableCell>{translate('telemedic.statistics.riskGroup.columnPulse')}</TableCell>
                )
              }
              {
                params.riskGroupParams.includes(RISK_GROUP_TEMPERATURE) && (
                  <TableCell>{translate('telemedic.statistics.riskGroup.columnTemperature')}</TableCell>
                )
              }
              {
                params.riskGroupParams.includes(RISK_GROUP_ALCOHOL) && (
                  <TableCell>{translate('telemedic.statistics.riskGroup.columnAlcohol')}</TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderRows(data.users)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderRows = (users) => {
    const rows = [];
    users.forEach((user, i) => {
      const nonAdmissions = user.non_admissions;
      const nonAdmissionsCount = nonAdmissions.length;
      nonAdmissions.forEach((nonAdmission, j) => {
        rows.push(
          this.renderRow(i, user, nonAdmissionsCount, j, nonAdmissions[j]),
        );
      });
    });
    return rows;
  };

  renderRow = (userIndex, user, nonAdmissionsCount, nonAdmissionIndex, nonAdmission) => {
    const { params } = this.props;
    const columns = [];
    if (nonAdmissionIndex === 0) {
      columns.push(
        <TableCell key={0} rowSpan={nonAdmissionsCount}>{userIndex + 1}</TableCell>,
      );
      columns.push(
        <TableCell key={1} rowSpan={nonAdmissionsCount}>
          {`${user.last_name} ${user.first_name} ${user.middle_name}`}
          <br />
          {formatDisplayDate(user.birthdate)}
        </TableCell>,
      );
    }
    columns.push(
      <TableCell key={2}>{formatDisplayDate(nonAdmission.date)}</TableCell>,
    );
    if (nonAdmission.blood_pressure_abnormal || nonAdmission.pulse_abnormal || nonAdmission.temperature_abnormal
      || nonAdmission.alcohol_abnormal) {
      let bloodPressure;
      if (nonAdmission.blood_pressure_abnormal) {
        bloodPressure = `${nonAdmission.arterial_pressure}/${nonAdmission.venous_pressure}`;
      } else {
        bloodPressure = '';
      }
      if (params.riskGroupParams.includes(RISK_GROUP_PRESSURE_AND_PULSE)) {
        columns.push(
          <TableCell key={3}>{bloodPressure}</TableCell>,
        );
        columns.push(
          <TableCell key={4}>{nonAdmission.pulse_abnormal && nonAdmission.pulse}</TableCell>,
        );
      }
      if (params.riskGroupParams.includes(RISK_GROUP_TEMPERATURE)) {
        columns.push(
          <TableCell key={5}>{nonAdmission.temperature_abnormal && nonAdmission.temperature}</TableCell>,
        );
      }
      if (params.riskGroupParams.includes(RISK_GROUP_ALCOHOL)) {
        columns.push(
          <TableCell key={6}>{nonAdmission.alcohol_abnormal && nonAdmission.alcohol}</TableCell>,
        );
      }
    } else {
      columns.push(
        <TableCell key={7} colSpan={4}>{nonAdmission.non_admission_reasons_text}</TableCell>,
      );
    }
    if (nonAdmissionIndex === 0) {
      columns.push(
        <TableCell key={8} rowSpan={nonAdmissionsCount}>
          {`${nonAdmissionsCount}/${user.checkups}`}
        </TableCell>,
      );
      columns.push(
        <TableCell key={9} rowSpan={nonAdmissionsCount}>
          {formatPercent(nonAdmissionsCount, user.checkups)}
        </TableCell>,
      );
    }
    return (
      <TableRow key={`${user.id}_${nonAdmissionIndex}`}>
        {columns}
      </TableRow>
    );
  };

  render() {
    const { statisticsData, onBackClick, translate } = this.props;
    const { exportProgress } = this.state;

    const pageTitle = translate('telemedic.statistics.riskGroup.title');

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData} />
        {this.renderTable(statisticsData)}
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(RiskGroupDisplay));
