import React from 'react';
import Downshift from 'downshift';

class MultiDownshift extends React.Component {
  constructor(props) {
    super(props);
    const { initialValue } = props;
    this.state = { selectedItems: initialValue };
  }

  onDelete = (item) => {
    const { onChange } = this.props;
    const callOnChange = () => {
      const { selectedItems } = this.state;
      if (onChange) {
        onChange(
          selectedItems,
          this.getStateAndHelpers({}),
        );
      }
    };
    this.removeItem(item, callOnChange);
  }

  getStateAndHelpers(downshift) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, onDelete } = this;
    return {
      getRemoveButtonProps,
      selectedItems,
      onDelete,
      ...downshift,
    };
  }

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: true,
        };
      default:
        return changes;
    }
  }

  handleSelection = (selectedItem, downshift) => {
    const { onSelect, onChange } = this.props;
    const { selectedItems } = this.state;
    const callOnChange = () => {
      const { selectedItems } = this.state;
      if (onSelect) {
        onSelect(
          selectedItems,
          this.getStateAndHelpers(downshift),
        );
      }
      if (onChange) {
        onChange(
          selectedItems,
          this.getStateAndHelpers(downshift),
        );
      }
    };
    if (selectedItems.includes(selectedItem)) {
      this.removeItem(selectedItem, callOnChange);
    } else {
      this.addSelectedItem(selectedItem, callOnChange);
    }
  }

  getRemoveButtonProps = ({ onClick, item, ...props } = {}) => ({
    onClick: (e) => {
      if (onClick) {
        onClick(e);
      }
      e.stopPropagation();
      this.removeItem(item);
    },
    ...props,
  })

  removeItem(item, cb) {
    this.setState(({ selectedItems }) => ({
      selectedItems: selectedItems.filter((i) => i !== item),
    }), cb);
  }

  addSelectedItem(item, cb) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item],
      }),
      cb,
    );
  }

  render() {
    const { render, children = render, ...props } = this.props;
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {(downshift) => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default MultiDownshift;
