import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import {
  SimpleShowLayout, TextField, showNotification, useNotify, useTranslate,
} from 'react-admin';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';

import DropDownMenu from '../../components/DropDownMenu';
import ErrorField from '../../components/fields/ErrorField';
import FieldLink from '../../components/FieldLink';
import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ImageField from '../../components/fields/ImageField';
import ImageListField from '../../components/fields/ImageListField';
import ProgressButton from '../../components/ProgressButton';
import RestrictedShow from '../../components/admin/RestrictedShow';
import WaybillTitle from './WaybillTitle';
import useTelemedicService from '../../hooks/useTelemedicService';
import styles from './WaybillShow.module.css';

import { Role } from '../../constants';
import { RoutePath, getUrl } from '../../routes';
import { getRole } from '../../storages/auth';
import {
  formatDisplayDateTime, formatPersonName, formatCheckupResult,
  formatTechInspectionType, formatTechInspectionResult,
} from '../../utils/formatters';
import { printHtml } from '../../utils/print';

function getCheckupUrl(record) {
  return record ? getUrl(RoutePath.CHECKUP_SHOW, { id: record.checkup_id }) : '';
}

function getTechInspectionUrl(record) {
  return record ? getUrl(RoutePath.TECH_INSPECTION_SHOW, { id: record.tech_inspection_id }) : '';
}

const WaybillShow = (props) => {
  const formatVehicle = (vehicle) => (
    `${vehicle.manufacturer} ${vehicle.model} (${vehicle.car_license_plate})`
  );
  const formatCheckupDatetime = (checkup) => {
    if (checkup) {
      return `${formatDisplayDateTime(checkup.datetime_start)} - ${formatDisplayDateTime(checkup.datetime_end)}`;
    }
    return '-';
  };
  const translate = useTranslate();
  const formatCheckupLinkText = (checkupId) => {
    const title = translate('telemedic.waybills.show.checkup');
    return `${title} #${checkupId}`;
  };
  const formatTechInspectionLinkText = (techInspectionId) => {
    const title = translate('telemedic.waybills.show.techInspection');
    return `${title} #${techInspectionId}`;
  };
  const userIsAdmin = (getRole() === Role.ADMIN);
  const { showNotification } = props;
  return (
    <RestrictedShow
      title={<WaybillTitleWithControls showNotification={showNotification} />}
      {...props}
    >
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <TextField label="telemedic.common.kisArtId" source="kis_art_id" />
            {userIsAdmin && (
              <ErrorField label="telemedic.waybills.show.kisArtError" source="error" />
            )}
            <TextField
              label="telemedic.waybills.show.organization"
              source="organization.name"
            />
            <FormatValueField
              label="telemedic.waybills.show.driver"
              source="driver"
              formatter={formatPersonName}
            />
            <FormatValueField
              label="telemedic.waybills.show.vehicle"
              source="vehicle"
              formatter={formatVehicle}
            />
            <TextField
              label="telemedic.waybills.show.inspectionPoint"
              source="inspection_point.name"
            />
            <FormatValueField
              label="telemedic.waybills.show.datetime"
              source="datetime"
              formatter={formatDisplayDateTime}
            />
            <FormatValueField
              label="telemedic.waybills.show.type"
              source="type"
              formatter={formatTechInspectionType}
            />
            <TextField
              label="telemedic.waybills.show.mileage"
              source="mileage"
            />
            <FieldLink
              label="telemedic.waybills.show.checkup"
              getUrl={getCheckupUrl}
            >
              <FormatValueField
                source="checkup_id"
                formatter={formatCheckupLinkText}
              />
            </FieldLink>
            <FormatValueField
              label="telemedic.waybills.show.checkupDatetime"
              source="checkup"
              formatter={formatCheckupDatetime}
            />
            <FormatValueField
              label="telemedic.waybills.show.medic"
              source="checkup.medic"
              formatter={formatPersonName}
            />
            <FormatValueField
              label="telemedic.waybills.show.checkupResult"
              source="checkup.result_medic"
              formatter={formatCheckupResult}
            />
            <FormatValueField
              label="telemedic.waybills.show.checkupResultDatetime"
              source="checkup.result_datetime"
              formatter={formatDisplayDateTime}
            />
            <FieldLink
              label="telemedic.waybills.show.techInspection"
              getUrl={getTechInspectionUrl}
            >
              <FormatValueField
                source="tech_inspection_id"
                formatter={formatTechInspectionLinkText}
              />
            </FieldLink>
            <FormatValueField
              label="telemedic.waybills.show.techInspectionDatetime"
              source="tech_inspection.date"
              formatter={formatDisplayDateTime}
            />
            <FormatValueField
              label="telemedic.waybills.show.mechanic"
              source="tech_inspection.mechanic"
              formatter={formatPersonName}
            />
            <FormatValueField
              label="telemedic.waybills.show.techInspectionResult"
              source="tech_inspection.result"
              formatter={formatTechInspectionResult}
            />
            <FormatValueField
              label="telemedic.waybills.show.techInspectionResultDatetime"
              source="tech_inspection.result_datetime"
              formatter={formatDisplayDateTime}
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <ImageField
            label="telemedic.waybills.show.driverPhoto"
            source="driver_photo"
          />
          <ImageListField
            label="telemedic.waybills.show.vehiclePhotos"
            source="vehicle_photo"
          />
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

const WAYBILL_COMMON = 1;
const WAYBILL_KIS_ART = 2;
const WAYBILL_TAXI = 3;
const WAYBILL_FREIGHT_VEHICLE = 4;

const WaybillTitleWithControls = ({ record, showNotification }) => {
  const translate = useTranslate();
  const iframeRef = useRef(null);
  const service = useTelemedicService();

  const [printProgress, setPrintProgress] = useState();
  const printOptions = useMemo(() => {
    const printWaybill = (promise) => {
      setPrintProgress(true);
      promise.then((response) => {
        printHtml(iframeRef, response.text);
        setPrintProgress(false);
      }).catch((e) => {
        console.log(e);
        setPrintProgress(false);
        showNotification(e.errorDescription || translate('telemedic.errors.submitError'));
      });
    };
    return [
      {
        action: () => printWaybill(service.getWaybillPrintPage(record.id, WAYBILL_COMMON)),
        id: 'id_print_common_waybill',
        labelId: 'telemedic.waybills.show.waybillCommon'
      },
      {
        action: () => printWaybill(service.getWaybillPrintPage(record.id, WAYBILL_TAXI)),
        id: 'id_print_taxi_waybill',
        labelId: 'telemedic.waybills.show.waybillTaxi',
      },
    ]
  }, [record, service, showNotification, translate]);

  const [downloadProgress, setDownloadProgress] = useState();
  const downloadOptions = useMemo(() => {
    const downloadWaybill = (promise) => {
      setDownloadProgress(true);
      promise.then(() => {
        setDownloadProgress(false);
      }).catch((e) => {
        console.log(e);
        setDownloadProgress(false);
        showNotification(e.errorDescription || translate('telemedic.errors.submitError'));
      });
    };
    return [
      {
        action: () => downloadWaybill(service.getWaybillPdf(record.id, WAYBILL_COMMON)),
        id: 'id_download_common_waybill',
        labelId: 'telemedic.waybills.show.waybillCommon',
      },
      {
        action: () => downloadWaybill(service.getWaybillPdf(record.id, WAYBILL_KIS_ART)),
        id: 'id_download_kis_art_waybill',
        labelId: 'telemedic.waybills.show.waybillKisArt',
      },
      {
        action: () => downloadWaybill(service.getWaybillPdf(record.id, WAYBILL_TAXI)),
        id: 'id_print_taxi_waybill',
        labelId: 'telemedic.waybills.show.waybillTaxi',
      },
      {
        action: () => downloadWaybill(service.getWaybillExcel(record.id, WAYBILL_FREIGHT_VEHICLE)),
        id: 'id_print_freight_vehicle_waybill',
        labelId: 'telemedic.waybills.show.waybillFreightVehicle',
      },
    ]
  }, [record, service, showNotification, translate]);

  const [sendProgress, setSendProgress] = useState();
  const notify = useNotify();
  const sendWaybill = useCallback(() => {
    setSendProgress(true);
    service.sendWaybill(record.id).then(() => {
      setSendProgress(false);
      notify(translate('telemedic.waybills.show.kisArtSendSuccess'), 'info');
    }).catch((error) => {
      console.log(error);
      setSendProgress(false);
      notify(translate('telemedic.waybills.show.kisArtSendError'), 'warning');
    });
  }, [notify, record, service, setSendProgress, translate]);
  return (
    <>
      <WaybillTitle record={record} />
      <DropDownMenu
        choices={printOptions}
        icon={<PrintIcon />}
        labelId="telemedic.actions.print"
        progress={printProgress}
      />
      <DropDownMenu
        choices={downloadOptions}
        icon={<CloudDownloadIcon />}
        labelId="telemedic.actions.download"
        progress={downloadProgress}
      />
      <ProgressButton
        className={styles.printButton}
        variant="contained"
        color="primary"
        icon={<CloudUploadIcon />}
        label="telemedic.waybills.show.kisArtSend"
        onClick={sendWaybill}
        progress={sendProgress}
      />
      <iframe
        className={styles.iframe}
        ref={iframeRef}
        title="print"
      />
    </>
  );
};

export default connect(null, { showNotification })(WaybillShow);
