import React, { useCallback, useEffect, useState } from 'react';
import { TextInput, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';

import Form from '../../components/Form';
import Info from '../../components/Info';
import LoginBox from './LoginBox';
import ProgressButton from '../../components/ProgressButton';
import useRutokenService from '../../hooks/useRutokenService';
import useTelemedicService from '../../hooks/useTelemedicService';
import { formatApiDate } from '../../utils/formatters';

import styles from './LoginStyles.module.css';

const AttachTokenPage = () => {
  const translate = useTranslate();
  const [certificateData, setCertificateData] = useState(null);
  const rutokenService = useRutokenService();
  useEffect(() => {
    rutokenService.selectDevice().then((deviceId) => (
      rutokenService.login(deviceId)
        .then(() => rutokenService.selectCertificate(deviceId))
        .then((certificate) => (
          rutokenService.getCertificatePublicKey(deviceId, certificate.id).then((publicKey) => (
            rutokenService.getCertificate(deviceId, certificate.id).then((pem) => (
              setCertificateData({
                id: certificate.id,
                content: pem,
                validityFrom: formatApiDate(certificate.data.validNotBefore),
                validityTo: formatApiDate(certificate.data.validNotAfter),
                publicKey,
              })
            ))
          ))
        ))
    )).catch(() => setCertificateData(false));
  }, [rutokenService]);
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'telemedic.validation.required';
    }
    if (!values.password) {
      errors.password = 'telemedic.validation.required';
    }
    return errors;
  }, []);
  const [attachmentSuccess, setAttachmentSuccess] = useState(null);
  const [attachmentError, setAttachmentError] = useState(null);
  const telemedicService = useTelemedicService();
  const attachToken = useCallback((data) => (
    telemedicService.attachToken(
      data.username, data.password, certificateData.id, certificateData.content,
      certificateData.validityFrom, certificateData.validityTo, certificateData.publicKey,
    ).then(() => {
      setAttachmentSuccess(translate('telemedic.login.tokenSuccessfullyAttached'));
      setAttachmentError(null);
    }).catch((e) => {
      setAttachmentSuccess(null);
      setAttachmentError(e.errorDescription || translate('telemedic.errors.submitError'));
    })
  ), [certificateData, telemedicService, translate]);
  const cancel = useCallback(() => window.history.back(), []);
  return (
    <LoginBox>
      <Form
        className={styles.form}
        onSubmit={attachToken}
        validate={validate}
      >
        <TextInput
          className={styles.largeInput}
          fullWidth
          helperText={false}
          label="telemedic.login.fields.login"
          margin="normal"
          name="username"
          variant="outlined"
        />
        <TextInput
          className={styles.largeInput}
          fullWidth
          helperText={false}
          label="telemedic.login.fields.password"
          margin="normal"
          name="password"
          type="password"
          variant="outlined"
        />
        <div className={styles.buttons}>
          <ProgressButton
            type="submit"
            variant="contained"
            color="primary"
            label="telemedic.actions.attachToken"
            progress={certificateData === null}
            disabled={certificateData === false}
          />
          <Button
            type="button"
            color="primary"
            onClick={cancel}
          >
            {translate('telemedic.actions.goBack')}
          </Button>
        </div>
        {!!attachmentError && (
          <Info level="error" variant="caption">{attachmentError}</Info>
        )}
        {!!attachmentSuccess && (
          <Info level="info" variant="caption">{attachmentSuccess}</Info>
        )}
      </Form>
    </LoginBox>
  );
};

export default AttachTokenPage;
