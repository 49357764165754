import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './CheckupsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService'
import {getAuthToken} from '../../storages/auth';
import {formatDisplayDate} from '../../utils/formatters';

class AgeRiskGroupDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statisticsData: null,
      exportProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {statisticsData, onBackClick, translate} = this.props;
    const exportProgress = this.state.exportProgress;

    const pageTitle = translate('telemedic.statistics.ageRiskGroup.title');

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon/>}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData}/>
        {this.renderTable(statisticsData)}
      </Card>
    );
  }

  renderTable(data) {
    const translate = this.props.translate;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('telemedic.statistics.ageRiskGroup.columnIndex')}</TableCell>
              <TableCell>{translate('telemedic.statistics.ageRiskGroup.columnFullName')}</TableCell>
              <TableCell>{translate('telemedic.statistics.ageRiskGroup.columnOrganization')}</TableCell>
              <TableCell>{translate('telemedic.statistics.ageRiskGroup.columnBirthday')}</TableCell>
              <TableCell>{translate('telemedic.statistics.ageRiskGroup.columnAge')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.users.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{index + 1}</TableCell>
                <TableCell>{row.last_name} {row.first_name} {row.middle_name}</TableCell>
                <TableCell>{row.organization_name}</TableCell>
                <TableCell>{formatDisplayDate(row.birthday)}</TableCell>
                <TableCell>{row.age}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  onExport = () => {
    this.setState({exportProgress: true});
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId
    } = this.props.params;
    const promise = this.service.exportAgeRiskGroup(
      dateFrom, dateTo, checkupType, organizationId, terminalId
    );
    promise.then(() => {
      this.setState({exportProgress: false});
    }).catch((e) => {
      console.log(e);
      this.setState({exportProgress: false});
      this.props.showNotification('telemedic.errors.submitError');
    });
  }
}

export default connect(null, {showNotification})(withTranslate(AgeRiskGroupDisplay));
