import React from 'react';
import {Create} from 'react-admin';

import TerminalForm from './TerminalForm';
import TerminalTitle from './TerminalTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const TerminalCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={TerminalForm}
    routePath={RoutePath.TERMINAL_SHOW}
    title={<TerminalTitle/>}
    {...props}/>
);
