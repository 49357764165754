import React from 'react';
import { Edit } from 'react-admin';

import OrganizationForm from './OrganizationForm';
import OrganizationTitle from './OrganizationTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const OrganizationEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={OrganizationForm}
    routePath={RoutePath.ORGANIZATION_SHOW}
    title={<OrganizationTitle />}
    {...props}
  />
);

export default OrganizationEdit;
