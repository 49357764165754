import React from 'react';
import lodash from 'lodash';
import {useTranslate} from 'react-admin';

import QrCode from '../media/QrCode';

const ImageField = ({label, optional, record, source}) => {
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const src = record ? lodash.get(record, source) : null;
  return (
    (!optional || src)
    ? <QrCode title={labelText} src={src}/>
    : null
  );
}

export default ImageField;
