import {ErrorCode} from '../constants';
import {serializeQueryParams} from '../utils/urls';

class HttpService {

  constructor(apiRoot, authToken) {
    this.apiRoot = apiRoot;
    this.authToken = authToken;
    this.controller = new AbortController();
  }

  getBlob(path, queryParams) {
    return this.get(path, null, queryParams).then(readBlobResponse);
  }

  getJson(path, queryParams) {
    return this.get(path, null, queryParams).then(parseJsonResponse);
  }

  getJsonData(path, queryParams) {
    return this.get(path, null, queryParams)
      .then(parseJsonResponse)
      .then(getResponseJsonData);
  }

  getText(path, headers, queryParams) {
    return this.get(path, null, queryParams).then(parseTextResponse);
  }

  get(path, headers, queryParams) {
    return this.request('GET', path, headers, queryParams, null);
  }

  postJsonGetBlob(path, data) {
    return this.postJson(path, data).then(readBlobResponse);
  }

  postJsonGetJson(path, data) {
    return this.postJson(path, data).then(parseJsonResponse);
  }

  postJson(path, data) {
    const headers = {'Content-Type': 'application/json'};
    const body = JSON.stringify(data);
    return this.post(path, headers, null, body);
  }

  postMultipartGetJson(path, data, files) {
    return this.postMultipart(path, data, files).then(parseJsonResponse);
  }

  postMultipart(path, data, files) {
    const formData = new FormData();
    if (data) {
      const dataJson = JSON.stringify(data);
      formData.append('body', dataJson);
    }
    for (let key in files) {
      formData.append(key, files[key]);
    }
    return this.post(path, null, null, formData);
  }

  post(path, headers, queryParams, body) {
    return this.request('POST', path, headers, queryParams, body);
  }

  patchJsonGetJson(path, data) {
    return this.patchJson(path, data).then(parseJsonResponse);
  }

  patchJson(path, data) {
    const headers = {'Content-Type': 'application/json'};
    const body = JSON.stringify(data);
    return this.patch(path, headers, null, body);
  }

  patch(path, headers, queryParams, body) {
    return this.request('PATCH', path, headers, queryParams, body);
  }

  deleteGetJson(path, queryParams) {
    return this.delete(path, null, queryParams).then(parseJsonResponse);
  }

  delete(path, headers, queryParams) {
    return this.request('DELETE', path, headers, queryParams);
  }

  request(method, path, customHeaders, queryParams, body) {
    let url = this.apiRoot + path;
    if (queryParams) {
      url += '?' + serializeQueryParams(queryParams);
    }
    const headers = {
      'Cache-Control': 'no-cache'
    }
    if (this.authToken) {
      headers['Authorization'] = `Bearer ${this.authToken}`;
    }
    if (customHeaders) {
      Object.assign(headers, customHeaders);
    }
    const request = new Request(url, {
      method: method,
      headers: new Headers(headers),
      body: body
    });
    const options = {
      credentials: 'same-origin',
      signal: this.controller.signal
    };
    return fetch(request, options).catch(e => {
      return Promise.reject({
        status: 0,
        error: e
      })
    }).then(response => {
      const status = response.status;
      if (status === 401) {
        localStorage.removeItem('token');
      }
      if (200 <= status && status < 300) {
        return response;
      }
      else {
        return response.json().catch(e => {
          return Promise.reject({
            status: status,
            error: e,
            errorCode: ErrorCode.RESPONSE_PARSE_ERROR
          });
        }).then(responseJson => {
          return Promise.reject({
            status: status,
            errorCode: responseJson.error_code,
            errorDescription: responseJson.error_description,
            errorData: responseJson.error_data
          });
        });
      }
    });
  }

  abort() {
    this.controller.abort();
  }
}

function parseJsonResponse(response) {
  return response.json().then(json => {
    return Promise.resolve({
      status: response.status,
      json: json
    });
  }).catch(e => {
    return Promise.reject({
      status: response.status,
      error: e,
      errorCode: ErrorCode.RESPONSE_PARSE_ERROR
    });
  })
}

function getResponseJsonData(response) {
  return response.json.data;
}

function parseTextResponse(response) {
  return response.text().then(text => {
    return Promise.resolve({
      status: response.status,
      text: text
    });
  }).catch(e => {
    return Promise.reject({
      status: response.status,
      error: e,
      errorCode: ErrorCode.RESPONSE_PARSE_ERROR
    });
  })
}

function readBlobResponse(response) {
  return response.blob().then(blob => {
    return Promise.resolve({
      status: response.status,
      blob: blob
    });
  }).catch(e => {
    return Promise.reject({
      status: response.status,
      error: e,
      errorCode: ErrorCode.RESPONSE_READ_ERROR
    });
  })
}

export default HttpService;
