import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {Title, showNotification, useTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import ToolBar from '../../components/ToolBar';
import styles from './TerminalVerificationDisplay.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import {RoutePath, getUrl} from '../../routes';
import {formatDisplayDate} from '../../utils/formatters';

const _TerminalVerificationDisplay = ({showNotification}) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [exportProgress, setExportProgress] = useState(false);
  const handleExport = useCallback(() => {
    setExportProgress(true);
    service.exportTerminalVerificationReport().then(() => {
      setExportProgress(false);
    }).catch((e) => {
      console.log(e);
      setExportProgress(false);
      showNotification('telemedic.errors.submitError');
    });
  }, [service, setExportProgress, showNotification]);
  const pageTitle = translate('telemedic.control.terminalVerification.label');
  return (
    <Card className={styles.card}>
      <Title title="telemedic.control.label"/>
      <ToolBar
        title={pageTitle}
      >
        <ProgressButton
          className={styles.floatRight}
          variant="contained"
          color="primary"
          icon={<SaveIcon/>}
          label="telemedic.actions.export"
          onClick={handleExport}
          progress={exportProgress}
        />
      </ToolBar>
      <TerminalsTable/>
    </Card>
  );
}

const TerminalsTable = () => {
  const translate = useTranslate();
  const [terminalsError, setTerminalsError] = useState(false);
  const service = useTelemedicService();
  const terminals = useTelemedicServiceJsonValue(() => {
    return service.getTerminalVerificationReport();
  }, null, null, (e) => {
    console.log(e);
    setTerminalsError(true);
  });
  return (
    <TableContainer className={styles.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnName')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnBuildDate')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnVerificationDate')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnPlannedVerificationDate')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnPlannedVerificationDaysLeft')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnLastActivityTime')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnDealer')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnTonometerModel')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnTonometerSerial')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnBreathalyzerModel')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnBreathalyzerSerial')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnPyrometerModel')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalVerification.columnPyrometerSerial')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={14}
            data={terminals}
            error={terminalsError}
            emptyTextId="telemedic.common.noData"
            errorTextId="telemedic.errors.fetchError"
            placeholderRows={5}
            Row={TerminalsTableRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TerminalsTableRow = ({item}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">{item.id}</TableCell>
      <TableCell>
        <Link href={getUrl(RoutePath.TERMINAL_SHOW, {id: item.id})}>
          {item.name}
        </Link>
      </TableCell>
      <TableCell>{formatDisplayDate(item.build_date)}</TableCell>
      <TableCell>{formatDisplayDate(item.verification_date)}</TableCell>
      <TableCell>{formatDisplayDate(item.planned_verification_date)}</TableCell>
      <TableCell>{item.planned_verification_days_left}</TableCell>
      <TableCell>{formatDisplayDate(item.last_activity_time)}</TableCell>
      <TableCell>{item.dealers.join(', ')}</TableCell>
      <TableCell>{item.tonometer_model}</TableCell>
      <TableCell>{item.tonometer_serial}</TableCell>
      <TableCell>{item.breathalyzer_model}</TableCell>
      <TableCell>{item.breathalyzer_serial}</TableCell>
      <TableCell>{item.pyrometer_model}</TableCell>
      <TableCell>{item.pyrometer_serial}</TableCell>
    </TableRow>
  );
}

export const TerminalVerificationDisplay = connect(null, {showNotification})(_TerminalVerificationDisplay);
