import * as React from 'react';

const PulseOximeter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 512.5 512.5"
    {...props}
  >
    <path d="m412.636 115.611c-6.476-6.353-14.914-9.771-23.858-9.586-9.395.18-17.88 4.304-23.877 10.761v-44.812c0-9.14-3.763-18.074-10.323-24.51-6.476-6.353-14.938-9.742-23.859-9.587-9.807.188-18.624 4.674-24.652 11.625-3.3-15.046-16.733-26.342-32.755-26.342-5.008 0-9.758 1.111-14.029 3.088l.332 208.559h-30.664v-85.814h-37.635v150.98h-30.666v-113.519c-3.263-.854-6.679-1.323-10.206-1.323h-24.668v117.268c0 31.69 9.218 62.426 26.658 88.884l8.265 12.539c16.116 24.452 24.612 52.856 24.568 82.143l-.04 26.536h213.451v-10.094c0-30.61 4.678-60.905 13.902-90.044 6.887-21.748 10.378-48.764 10.378-80.298v-191.943c.001-9.141-3.762-18.074-10.322-24.511zm-21.572 33.937v85.259h-30.664v-85.259zm-96.9-44.268h30.664v129.527h-30.664z" />
    <path d="m229.281 118.993v-118.661h-80.148v43.271h39.918v30h-39.918v45.39z" />
    <path d="m145.214 118.993c-14.876 0-25.673-9.938-25.673-23.629v-95.364h-30v95.364c0 30.072 24.454 53.629 55.673 53.629h3.919v-30z" />
  </svg>
);

export default PulseOximeter;
