import React from 'react';
import PropTypes from 'prop-types';

const defaultInnerHTML = { __html: '&#8203;' };

const FormHelperTextSpan = ({error, helperText, touched, translate}) => {
  if (error && touched) {
    return (
      <span>{translate(error)}</span>
    );
  }
  if (helperText) {
    return (
      <span>{translate(helperText)}</span>
    );
  }
  if (helperText !== false) {
    return (<span dangerouslySetInnerHTML={defaultInnerHTML}/>);
  }
  return (<span></span>);
}

FormHelperTextSpan.propTypes = {
  error: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  touched: PropTypes.bool,
  translate: PropTypes.func
};

export default FormHelperTextSpan;
