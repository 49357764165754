import React, {useCallback, useState} from 'react';
import {useInput, useTranslate} from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperTextSpan from '../FormHelperTextSpan';
import styles from './FileListInput.module.css';

const FileListInput = (props) => {
  const {
    accept,
    className,
    helperText,
    label,
    margin = 'dense',
    required,
    variant = 'filled'
  } = props;
  const {
    meta: {touched, error},
    input: {onChange, value, ...inputRest},
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const [files, setFiles] = useState([]);
  const handleChange = useCallback(
    (event) => {
      const newFiles = [...files, event.target.files[0]];
      event.target.value = null;
      setFiles(newFiles);
      onChange(newFiles);
    },
    [files, onChange]
  );
  const handleDelete = useCallback(
    (event) => {
      const index = parseInt(event.currentTarget.getAttribute('data-index'));
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
      onChange(newFiles);
    },
    [files, onChange]
  )
  return (
    <FormControl className={className} margin={margin}>
      <div className={styles.inputBackground}>
        <InputLabel
          error={!!(touched && error)}
          required={required || isRequired}
          shrink={true}
          variant={variant}
        >
          {labelText}
        </InputLabel>
        <div>
          {files.map((file, index) => (
            <div className={styles.file}>
              <span className={styles.filename}>{file.name}</span>
              <IconButton data-index={index} onClick={handleDelete}>
                <DeleteIcon/>
              </IconButton>
            </div>
          ))}
        </div>
        <input
          type="file"
          accept={accept}
          onChange={handleChange}
          {...inputRest}/>
      </div>
      <FormHelperText
        error={!!(touched && error)}
        variant={variant}
      >
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}/>
      </FormHelperText>
    </FormControl>
  );
}

export default FileListInput;
