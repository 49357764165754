import React, { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import TextInput from '../../components/inputs/TextInput';

import Form from '../../components/Form';
import Info from '../../components/Info';
import LoginBox from './LoginBox';
import ProgressButton from '../../components/ProgressButton';
import useFormResponseHandler from '../../hooks/useFormResponseHandler';
import useTelemedicService from '../../hooks/useTelemedicService';

import styles from './LoginStyles.module.css';

const PasswordChangePage = () => {
  const translate = useTranslate();
  const validate = useCallback((values) => {
    const errors = {};
    ['oldPassword', 'newPassword', 'confirm'].forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    if (values.newPassword && values.confirm && values.newPassword !== values.confirm) {
      errors.confirm = 'telemedic.validation.passwordMismatch';
    }
    return errors;
  }, []);
  const [passwordChangeProgress, setPasswordChangeProgress] = useState(false);
  const [
    handleSuccess, handleError, success, error, getFieldProps,
  ] = useFormResponseHandler({
    defaultError: translate('telemedic.errors.submitError'),
  });
  const telemedicService = useTelemedicService();
  const changePassword = useCallback((data) => {
    setPasswordChangeProgress(true);
    telemedicService.changePassword(
      data.oldPassword, data.newPassword, data.confirm,
    ).then(() => {
      setPasswordChangeProgress(false);
      handleSuccess();
    }).catch((e) => {
      console.log(e);
      setPasswordChangeProgress(false);
      handleError(e);
    });
  }, [handleError, handleSuccess, telemedicService]);
  const cancel = useCallback(() => window.history.back(), []);
  return (
    <LoginBox>
      <Form
        className={styles.form}
        onSubmit={changePassword}
        validate={validate}
      >
        <TextInput
          className={styles.largeInput}
          fullWidth
          label="telemedic.passwordChange.oldPassword"
          margin="normal"
          name="oldPassword"
          type="password"
          variant="outlined"
          {...getFieldProps('old_password')}
        />
        <TextInput
          className={styles.largeInput}
          fullWidth
          label="telemedic.passwordChange.newPassword"
          margin="normal"
          name="newPassword"
          type="password"
          variant="outlined"
          {...getFieldProps('new_password')}
        />
        <TextInput
          className={styles.largeInput}
          fullWidth
          label="telemedic.passwordChange.confirm"
          margin="normal"
          name="confirm"
          type="password"
          variant="outlined"
          {...getFieldProps('confirm')}
        />
        <div className={styles.buttons}>
          <ProgressButton
            type="submit"
            variant="contained"
            color="primary"
            label="telemedic.actions.changePassword"
            progress={passwordChangeProgress}
          />
          <Button
            type="button"
            color="primary"
            onClick={cancel}
          >
            {translate('telemedic.actions.goBack')}
          </Button>
        </div>
        {!!error && (
          <Info className={styles.control} level="error" variant="caption">
            {error}
          </Info>
        )}
        {success && (
          <Info className={styles.control} level="info" variant="caption">
            {translate('telemedic.passwordChange.passwordSuccessfullyChanged')}
          </Info>
        )}
      </Form>
    </LoginBox>
  );
};

export default PasswordChangePage;
