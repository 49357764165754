import React from 'react';

import styles from './FormContent.module.css';

const FormContent = ({children}) => {
  return (
    <div className={styles.formContent}>
      {children}
    </div>
  );
}

export default FormContent;
