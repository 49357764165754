import React, { useState } from 'react';
import { Title } from 'react-admin';

import ReceivedCheckupsForm from './ReceivedCheckupsForm';
import ReceivedCheckupsDisplay from './ReceivedCheckupsDisplay';

const ReceivedCheckupsPage = () => {
  const [formParams, setFormParams] = useState(null);
  const [statisticsData, setStatisticsData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  };

  const dataCallback = (statisticsData) => {
    setStatisticsData(statisticsData);
  };

  const onBackClick = () => {
    setStatisticsData(null);
  };

  return (
    <div>
      <Title title="telemedic.receivedCheckups.label" />
      {
        !statisticsData && (
          <ReceivedCheckupsForm
            params={formParams}
            paramsCallback={paramsCallback}
            dataCallback={dataCallback}
          />
        )
      }
      {
        !!statisticsData && (
          <ReceivedCheckupsDisplay
            params={formParams}
            statisticsData={statisticsData}
            onBackClick={onBackClick}
          />
        )
      }
    </div>
  );
};

export default ReceivedCheckupsPage;
