import React from 'react';
import {SimpleShowLayout, TextField} from 'react-admin';

import FieldLink from '../../components/FieldLink';
import FormatChipField from '../../components/fields/FormatChipField';
import InspectionPointTitle from './InspectionPointTitle';
import ListField from '../../components/fields/ListField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import {RoutePath, getUrl} from '../../routes';

export const InspectionPointShow = (props) => {
  return (
    <RestrictedShow title={<InspectionPointTitle/>} {...props}>
      <SimpleShowLayout>
        <TextField label="telemedic.inspectionPoints.show.name" source="name"/>
        <ListField
          label="telemedic.inspectionPoints.show.organizations"
          source="organizations"
        >
          <FieldLink getUrl={getOrganizationUrl}>
            <FormatChipField formatter={organizationFormatter} clickable/>
          </FieldLink>
        </ListField>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}

const getOrganizationUrl = (record) => {
  return record ? getUrl(RoutePath.ORGANIZATION_SHOW, {id: record.id}) : '';
}

const organizationFormatter = (record) => {
  return record ? `${record.id} - ${record.name}` : '';
}
