import React from 'react';

import FormDiv from '../../../components/FormDiv';
import RestrictedShow from '../../../components/admin/RestrictedShow';
import styles from './CheckupShow.module.css';
import withCheckupActions from '../withCheckupActions';

import CheckupTitleWithControls from './CheckupTitleWithControls';
import CheckupLayout from './CheckupLayout';
import UserMedia from './UserMedia';
import { WebsocketContext } from '../../../providers/webSocketProvider';
import { WS_SECRET_KEY } from '../../../config';
import { Action, Section } from '../../../permissions';

class CheckupShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.startObserving();
    window.addEventListener('websocketsAuthenitcated',(e) => {
      this.startObserving();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);

    const [ready, val, send] = this.context;
    const { id, permissions } = this.props;

    if (permissions && permissions.check(Section.CHECKUPS, Action.REVIEW) && ready) {
      send(JSON.stringify({
        type: 'stop_observing_checkup',
        data: {
          server_key: WS_SECRET_KEY,
          checkup_id: id
        }
      }));
    };
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  startObserving() {
    const [ready, val, send] = this.context;
    const { id, permissions } = this.props;
  
    if (permissions && permissions.check(Section.CHECKUPS, Action.REVIEW) && ready) {
      send(JSON.stringify({
        type: 'observe_checkup',
        data: {
          server_key: WS_SECRET_KEY,
          checkup_id: id
        }
      }));
    }
  }

  render() {
    const { checkupActions, ...showProps } = this.props;
    const { windowWidth, showActionButtons } = this.state;


    return (
      <RestrictedShow
        title={<CheckupTitleWithControls checkupActions={checkupActions} showActionButtons={showActionButtons} />}
        {...showProps}
      >
        <FormDiv className={styles.container}>
          <FormDiv className={styles.firstColumn}>
            <CheckupLayout />
            {
              windowWidth < 1300
                && <UserMedia />
            }
          </FormDiv>
          {
            windowWidth >= 1300
              && <UserMedia />
          }
        </FormDiv>
      </RestrictedShow>
    );
  }
}

CheckupShow.contextType = WebsocketContext;

export default withCheckupActions(CheckupShow);
