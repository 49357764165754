import React, {Children, isValidElement, cloneElement} from 'react';

const FormDiv = ({
  basePath,
  children,
  record,
  resource,
  version,
  ...rest
}) => {
  return (
    <div {...rest}>
      {Children.map(children, field =>
        field && isValidElement(field) ? (
          typeof field.type === 'string'? (
            field
          ) : (
            cloneElement(field, {
              record,
              resource,
              basePath,
            })
          )
        ) : null
      )}
    </div>
  );
}

export default FormDiv;
