import React, { useCallback, useEffect, useState } from 'react';
import {
  BooleanField, SimpleShowLayout, TextField, useTranslate, useShowController
} from 'react-admin';

import FormatField from '../../components/fields/FormatField';
import FormatValueField from '../../components/fields/FormatValueField';
import OrganizationTitleWithControls from './OrganizationTitleWithControls';
import RestrictedShow from '../../components/admin/RestrictedShow';
import Changelog from '../../components/Changelog';
import styles from './OrganizationShow.module.css';

import { Role } from '../../constants';
import { getRole } from '../../storages/auth';
import useTelemedicService from '../../hooks/useTelemedicService';

const CAN_VIEW_GENERAL_DATA = [Role.ADMIN];
const CAN_VIEW_DEALER_DATA = [Role.ADMIN, Role.PARTNER, Role.SUPPORT]
const CAN_VIEW_KIS_ART_DATA = [Role.ADMIN, Role.PARTNER]
const CAN_VIEW_BLOCK_DATA = [Role.ADMIN, Role.SUPPORT];

const SERVER_NAME_TO_LABEL = {
  'affiliate_number': 'telemedic.organizations.show.affiliate_number',
  'ogrn': 'telemedic.organizations.show.ogrn',
  'inn': 'telemedic.organizations.show.inn',
  'kpp': 'telemedic.organizations.show.kpp',
  'name': 'telemedic.organizations.show.name',
  'address': 'telemedic.organizations.show.address',
  'contract_status': 'telemedic.organizations.show.contract',
  'region':'telemedic.organizations.show.region',
  'dealer.name': 'telemedic.organizations.show.dealer',
  'dealers_responsible.name': 'telemedic.organizations.show.dealersResponsible',
  'responsible_name': 'telemedic.organizations.show.responsibleName',
  'responsible_phone': 'telemedic.organizations.show.responsiblePhone',
  'responsible_emails': 'telemedic.organizations.show.responsibleEmail',
  'contacts': 'telemedic.organizations.show.contacts',
  'comments': 'telemedic.organizations.show.comments',
  'alcohol_block': 'telemedic.organizations.show.alcoholBlock',
  'duration_minutes_alcohol_block': 'telemedic.organizations.show.durationMinutes',
  'checkup_block': 'telemedic.organizations.show.checkupBlock',
  'duration_minutes_checkup_block': 'telemedic.organizations.show.durationMinutes',
  'three_checkup_block': 'telemedic.organizations.show.threeCheckupBlock',
  'duration_minutes_three_checkup_block': 'telemedic.organizations.show.durationMinutes',
  'limit_pre_trip': 'telemedic.organizations.show.limitPreTrip',
  'time_from_limit_pre_trip': 'telemedic.organizations.show.timeFrom',
  'time_to_limit_pre_trip': 'telemedic.organizations.show.timeTo',
  'limit_post_trip': 'telemedic.organizations.show.limitPostTrip',
  'time_from_limit_post_trip': 'telemedic.organizations.show.timeFrom',
  'time_to_limit_post_trip': 'telemedic.organizations.show.timeTo',
  'is_kis_art_related': 'telemedic.organizations.form.isKisArtRelated',
  'kis_art_id': 'telemedic.common.kisArtId',
  'kis_art_client_id': 'telemedic.organizations.form.kisArtClientId',
  'kis_art_client_secret': 'telemedic.organizations.form.kisArtClientSecret',
  'operator_kis_art_id': 'telemedic.organizations.show.operatorKisArtId',
  'medic_kis_art_id': 'telemedic.organizations.show.medicKisArtId',
  'mechanic_kis_art_id': 'telemedic.organizations.show.mechanicKisArtId',
  'epl_gis_epd_api': 'telemedic.organizations.show.eplGisEpdAPI',
  'sett_sms': 'telemedic.organizations.show.alcoholSMS',
  'sett_alcohol_email': 'telemedic.organizations.show.alcoholEmail',
  'sett_not_admit_notify_email': 'telemedic.organizations.show.notAdmitNotifyEmail',
  'sett_temperature_in_degrees': 'telemedic.organizations.show.temperatureInDegrees',
  'sett_autoinspect': 'telemedic.organizations.show.autoinspect',
  'sett_repeats': 'telemedic.organizations.show.repeats',
  'sett_face_id': 'telemedic.organizations.show.enableFaceId',
  'proc_pers_data': 'telemedic.organizations.show.procPersData',
  'sett_org_billing': 'telemedic.organizations.show.useOrganizationBilling',
  'sett_local_time': 'telemedic.organizations.show.useLocalTime',
  'partner': 'telemedic.organizations.show.partner',
  'price_type': 'telemedic.organizations.show.priceType',
  'price': 'telemedic.organizations.show.price',
  'tech_inspection_price': 'telemedic.organizations.show.techInspectionPrice',
  'sett_paid_pre_inspections': 'telemedic.organizations.show.paidPreInspections',
  'should_not_count_repeated_checkups': 'telemedic.organizations.show.shouldNotCountRepeatedCheckups',
  'dealer_price_type': 'telemedic.organizations.show.priceTypeDealer',
  'dealer_price': 'telemedic.organizations.show.dealerPrice',
  'tech_inspection_dealer_price': 'telemedic.organizations.show.techInspectionDealerPrice',
  'inspection_package': 'telemedic.organizations.show.inspectionPackage',
  'sett_paid_pre_inspections_for_dealer': 'telemedic.organizations.show.paidPreInspectionsForDealer',
  'should_not_count_repeated_checkups_for_dealer': 'telemedic.organizations.show.shouldNotCountRepeatedCheckupsForDealer',
  'equipment_is_rent': 'telemedic.organizations.show.EquipmentIsRent',
  'term_id': 'telemedic.organizations.show.chooseTerminals',
  'monthly_equipment_pay': 'telemedic.organizations.show.monthlyEquipmentPay',
}

const OrganizationShow = (props) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [changelog, setChangelog] = useState([]);
  const priceTypeFormatter = useCallback((record, source) => {
    if (record) {
      if (record.price_type === 1) {
        return `${translate('telemedic.organizations.show.monthly')}`;
      }
      return `${translate('telemedic.organizations.show.perCheckup')}`;
    }
    return '';
  }, [translate]);
  const priceFormatter = useCallback((record, source) => {
    if (record && record[source]) {
      const price = record[source];
      if (record.price_type === 1) {
        return `${price} ${translate('telemedic.organizations.show.monthly')}`;
      }
      return `${price} ${translate('telemedic.organizations.show.perCheckup')}`;
    }
    return '';
  }, [translate]);
  const partnerFormatter = useCallback((partner) => (
    `${partner.last_name} ${partner.first_name} ${partner.middle_name} (${partner.login})`
  ), []);
  const role = getRole();
  const { record } = useShowController(props);
  const canViewGeneralData = CAN_VIEW_GENERAL_DATA.includes(role);
  const canViewDealerData = CAN_VIEW_DEALER_DATA.includes(role);
  const canViewBlockData = CAN_VIEW_BLOCK_DATA.includes(role);
  const isRecordKisArtRelated = !!record && !!record.is_kis_art_related;
  const isRecordEplGisEpdApi = !!record && !!record.epl_gis_epd_api;
  const showKisArtCredentials = CAN_VIEW_KIS_ART_DATA.includes(role) && isRecordKisArtRelated;
  const showAlcoholBlockDuration = canViewBlockData && !!record && !!record.alcohol_block;
  const showCheckupBlockDuration = canViewBlockData && !!record && !!record.checkup_block;
  const showThreeCheckupBlockDuration = canViewBlockData && !!record && !!record.three_checkup_block;
  const showEnablePreTripDuration = canViewBlockData && !!record && !!record.limit_pre_trip;
  const showEnablePostTripDuration = canViewBlockData && !!record && !!record.limit_post_trip;
  useEffect(() => {
    if (!record) {
      return;
    }
    service.getOrganizationChangelog(record.id).then((response) => {
      if (response.status === 200) {
        setChangelog(response.json.changes);
      }
    });
  }, [record, service]);
  return (
    <RestrictedShow title={<OrganizationTitleWithControls />} {...props}>
      <div className={styles.show}>
        <div className={styles.compactShow}>
          <SimpleShowLayout className={styles.columnShow}>
            <div className={styles.formTitle}>{translate('telemedic.organizations.show.legalData')}</div>
            <TextField label="telemedic.organizations.show.affiliateNumber" source="affiliate_number" />
            <TextField label="telemedic.organizations.show.ogrn" source="ogrn" />
            <TextField label="telemedic.organizations.show.inn" source="inn" />
            <TextField label="telemedic.organizations.show.kpp" source="kpp" />
            <TextField label="telemedic.organizations.show.name" source="name" />
            <TextField label="telemedic.organizations.show.address" source="address" />
            <TextField label="telemedic.organizations.show.contract" source="contract_status" />
          </SimpleShowLayout>

          <SimpleShowLayout className={styles.columnShow}>
            <div className={styles.formTitle}>{translate('telemedic.organizations.show.orgData')}</div>
            <TextField label="telemedic.organizations.show.region" source="region" />
            {
              canViewDealerData
                && <TextField label="telemedic.organizations.show.dealer" source="dealer.name" />
            }
            {
              canViewDealerData
                && <TextField label="telemedic.organizations.show.dealersResponsible" source="dealers_responsible.name" />
            }
            <TextField label="telemedic.organizations.show.responsibleName" source="responsible_name" />
            <TextField label="telemedic.organizations.show.responsiblePhone" source="responsible_phone" />
            <TextField label="telemedic.organizations.show.responsibleEmail" source="responsible_emails" />
            <TextField label="telemedic.organizations.show.contacts" source="contacts" />
            <TextField label="telemedic.organizations.show.comments" source="comments" />
          </SimpleShowLayout>

          <SimpleShowLayout className={styles.columnShow}>
            {isRecordEplGisEpdApi &&
              <div className={styles.formTitle}>Данные о клиенте для ЭПЛ</div>
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.name" source="name" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.organization_type" source="organization_type" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.full_name" source="full_name" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.inn" source="inn" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.ogrn" source="ogrn" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.kpp" source="kpp" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_region_code" source="legal_region_code" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_city" source="legal_city" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_settlement" source="legal_settlement" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_index" source="legal_index" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_neighborhood" source="legal_neighborhood" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_street" source="legal_street" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_house" source="legal_house" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_building" source="legal_building" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.legal_apartment" source="legal_apartment" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_region_code" source="actual_region_code" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_city" source="actual_city" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_settlement" source="actual_settlement" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_index" source="actual_index" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_neighborhood" source="actual_neighborhood" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_street" source="actual_street" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_house" source="actual_house" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_building" source="actual_building" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.actual_apartment" source="actual_apartment" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.org_phone" source="org_phone" />
            }
            {isRecordEplGisEpdApi &&
              <TextField label="telemedic.organizations.show.org_email" source="org_email" />
            }
          </SimpleShowLayout>
        </div>

        <SimpleShowLayout className={styles.columnShow}>
          <div className={styles.formTitle}>{translate('telemedic.organizations.show.generalSettings')}</div>
          <BooleanField label="telemedic.organizations.form.isKisArtRelated" source="is_kis_art_related" />
          {
            showKisArtCredentials
              && <TextField label="telemedic.common.kisArtId" source="kis_art_id" />
          }
          {
            showKisArtCredentials
              && <TextField label="telemedic.organizations.form.kisArtClientId" source="kis_art_client_id" />
          }
          {
            showKisArtCredentials
              && <TextField label="telemedic.organizations.form.kisArtClientSecret" source="kis_art_client_secret" />
          }
          {
            showKisArtCredentials
              && <TextField label="telemedic.organizations.show.operatorKisArtId" source="operator_kis_art_id" />
          }
          {
            showKisArtCredentials
              && <TextField label="telemedic.organizations.show.medicKisArtId" source="medic_kis_art_id" />
          }
          {
            showKisArtCredentials
              && <TextField label="telemedic.organizations.show.mechanicKisArtId" source="mechanic_kis_art_id" />
          }
          {
            canViewGeneralData && (
              <BooleanField
                label="telemedic.organizations.show.aisErpApi"
                source="ais_erp_api"
              />
            )
          }
          {canViewGeneralData && (
          <BooleanField
            label="telemedic.organizations.show.eplGisEpdAPI"
            source="epl_gis_epd_api"
          />
          )}
          {canViewGeneralData && (
          <BooleanField
            label="telemedic.organizations.show.alcoholSMS"
            source="sett_sms"
          />
          )}
          {canViewGeneralData && (
          <BooleanField
            label="telemedic.organizations.show.alcoholEmail"
            source="sett_alcohol_email"
          />
          )}
          {canViewGeneralData && (
          <BooleanField
            label="telemedic.organizations.show.notAdmitNotifyEmail"
            source="sett_not_admit_notify_email"
          />
          )}
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.temperatureInDegrees"
              source="sett_temperature_in_degrees"
            />
          )}
          <BooleanField
            label="telemedic.organizations.show.autoinspect"
            source="sett_autoinspect"
          />
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.repeats"
              source="sett_repeats"
            />
          )}
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.enableFaceId"
              source="sett_face_id"
            />
          )}
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.procPersData"
              source="proc_pers_data"
            />
          )}
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.partnerApiEpl"
              source="partner_api_epl"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiEplName"
              source="partner_api_epl_name"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.ediwebGlnOrg"
              source="ediweb_gln_org"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiOrgId"
              source="partner_api_org_id"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiLogin"
              source="partner_api_login"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiPassword"
              source="partner_api_password"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiMedicInn"
              source="partner_api_medic_inn"
            />
          )}
          {canViewGeneralData && (
            <TextField
              label="telemedic.organizations.show.partnerApiMedicKpp"
              source="partner_api_medic_kpp"
            />
          )}
          <BooleanField label="telemedic.organizations.show.useOrganizationBilling" source="sett_org_billing" />
          {canViewGeneralData && (
            <BooleanField
              label="telemedic.organizations.show.useLocalTime"
              source="sett_local_time"
            />
          )}
          {canViewGeneralData && (
            <FormatValueField
              label="telemedic.organizations.show.partner"
              source="partner"
              formatter={partnerFormatter}
            />
          )}
        </SimpleShowLayout>

        <SimpleShowLayout className={styles.columnShow}>
          <div className={styles.formTitle}>{translate('telemedic.organizations.show.blockSettings')}</div>
          {canViewBlockData && (
            <BooleanField
              label="telemedic.organizations.show.alcoholBlock"
              source="alcohol_block"
            />
          )}
          {
            showAlcoholBlockDuration
              && <TextField label="telemedic.organizations.show.durationMinutes" source="duration_minutes_alcohol_block" />
          }
          {canViewBlockData && (
            <BooleanField
              label="telemedic.organizations.show.checkupBlock"
              source="checkup_block"
            />
          )}
          {
            showCheckupBlockDuration
              && <TextField label="telemedic.organizations.show.durationMinutes" source="duration_minutes_checkup_block" />
          }
          {canViewBlockData && (
            <BooleanField
              label="telemedic.organizations.show.threeCheckupBlock"
              source="three_checkup_block"
            />
          )}
          {
            showThreeCheckupBlockDuration
              && <TextField label="telemedic.organizations.show.durationMinutes" source="duration_minutes_three_checkup_block" />
          }
          {canViewBlockData && (
            <BooleanField
              label="telemedic.organizations.show.limitPreTrip"
              source="limit_pre_trip"
            />
          )}
          {
            showEnablePreTripDuration
              && <TextField label="telemedic.organizations.show.timeFrom" source="time_from_limit_pre_trip" />
          }
          {
            showEnablePreTripDuration
              && <TextField label="telemedic.organizations.show.timeTo" source="time_to_limit_pre_trip" />
          }
          {canViewBlockData && (
            <BooleanField
              label="telemedic.organizations.show.limitPostTrip"
              source="limit_post_trip"
            />
          )}
          {
            showEnablePostTripDuration
              && <TextField label="telemedic.organizations.show.timeFrom" source="time_from_limit_post_trip" />
          }
          {
            showEnablePostTripDuration
              && <TextField label="telemedic.organizations.show.timeTo" source="time_to_limit_post_trip" />
          }
        </SimpleShowLayout>

        <SimpleShowLayout className={styles.columnShow}>
          <div className={styles.formTitle}>{translate('telemedic.organizations.show.financialData')}</div>
          <div className={styles.financeData}>
            <SimpleShowLayout className={styles.columnShow}>
              <div className={styles.formSubTitle}>{translate('telemedic.organizations.show.checkupPayment')}</div>
              <FormatField
                label="telemedic.organizations.show.priceType"
                source="price_type"
                formatter={priceTypeFormatter}
              />
              <FormatField
                label="telemedic.organizations.show.price"
                source="price"
                formatter={priceFormatter}
              />
              <FormatField
                label="telemedic.organizations.show.techInspectionPrice"
                source="tech_inspection_price"
                formatter={priceFormatter}
              />
              <BooleanField
                label="telemedic.organizations.show.inspectionPackage"
                source="inspection_package"
              />
              <BooleanField
                label="telemedic.organizations.show.paidPreInspections"
                source="sett_paid_pre_inspections"
              />
              <BooleanField
                label="telemedic.organizations.show.shouldNotCountRepeatedCheckups"
                source="should_not_count_repeated_checkups"
              />
            </SimpleShowLayout>

            <SimpleShowLayout className={styles.columnShow}>
              <div className={styles.formSubTitle}>{translate('telemedic.organizations.show.servicePayment')}</div>
              <FormatField
                label="telemedic.organizations.show.priceTypeDealer"
                source="dealer_price_type"
                formatter={priceTypeFormatter}
              />
              <FormatField
                label="telemedic.organizations.show.dealerPrice"
                source="dealer_price"
                formatter={priceFormatter}
              />
              <FormatField
                label="telemedic.organizations.show.techInspectionDealerPrice"
                source="tech_inspection_dealer_price"
                formatter={priceFormatter}
              />
              <BooleanField
                label="telemedic.organizations.show.inspectionPackage"
                source="inspection_package"
              />
              <BooleanField
                label="telemedic.organizations.show.paidPreInspectionsForDealer"
                source="sett_paid_pre_inspections_for_dealer"
              />
              <BooleanField
                label="telemedic.organizations.show.shouldNotCountRepeatedCheckupsForDealer"
                source="should_not_count_repeated_checkups_for_dealer"
              />
            </SimpleShowLayout>

            <SimpleShowLayout className={styles.columnShow}>
              <div className={styles.formSubTitle}>{translate('telemedic.organizations.show.equipmentRent')}</div>
              <BooleanField label="telemedic.organizations.show.EquipmentIsRent" source="equipment_is_rent" />
              <TextField label="telemedic.organizations.show.chooseTerminals" source="term_id" />
              <TextField label="telemedic.organizations.show.monthlyEquipmentPay" source="monthly_equipment_pay" />
            </SimpleShowLayout>

            <SimpleShowLayout className={styles.columnShow}>
              <div className={styles.formSubTitle}>{translate('telemedic.organizations.show.softwarePayment')}</div>
            </SimpleShowLayout>
          </div>
        </SimpleShowLayout>
        <Changelog
          changelog={changelog}
          fieldsLabels={SERVER_NAME_TO_LABEL}
        />
      </div>
    </RestrictedShow>
  );
};

export default OrganizationShow;
