import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from 'react-admin';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import styles from './LargeCheckList.module.css';

import {FixedSizeList as List} from 'react-window';
import {castValue, ValueTypes} from '../utils/values';


const LargeCheckList = ({
    choiceLabelField,
    choiceValueField,
    choices,
    onChange,
    value,
    valueType = ValueTypes.STRING
  }) => {
  const translate = useTranslate();
  const [nameFilter, setNameFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(false);
  const handleNameChange = useCallback((event) => {
    setNameFilter(event.target.value);
  }, [setNameFilter]);
  const handleSelectedChange = useCallback((event) => {
    setSelectedFilter(event.target.checked);
  }, [setSelectedFilter]);
  const handleChoiceCheckboxChange = useCallback((event) => {
    const checkboxValue = castValue(event.target.value, valueType);
    if (value) {
      if (event.target.checked) {
        onChange([...value, checkboxValue]);
      }
      else {
        onChange(value.filter(v => v !== checkboxValue));
      }
    }
    else if (event.target.checked) {
      onChange([checkboxValue]);
    }
  }, [onChange, value, valueType]);
  const filteredChoices = useMemo(() => {
    return filterChoices(choices, choiceLabelField, choiceValueField, nameFilter, selectedFilter, value);
  }, [choices, choiceLabelField, choiceValueField, nameFilter, selectedFilter, value]);
  return (
    <React.Fragment>
      <Input
        type="text"
        placeholder={translate('telemedic.largeCheckList.filterByName')}
        onChange={handleNameChange}
        fullWidth/>
      <FormControlLabel
        label={translate('telemedic.largeCheckList.selectedOnly')}
        control={
          <Switch color="primary" onChange={handleSelectedChange}/>
        }/>
      <List
        className={styles.list}
        width={232}
        height={180}
        itemCount={filteredChoices.length}
        itemSize={40}
        value={value}
      >
        {({index, style}) => {
          const choice = filteredChoices[index];
          const choiceValue = choice[choiceValueField];
          return (
            <ChoiceItem
              checked={value && value.indexOf(choiceValue) >= 0}
              label={choice[choiceLabelField]}
              onChange={handleChoiceCheckboxChange}
              style={style}
              value={choiceValue}
            />
          );
        }}
      </List>
    </React.Fragment>
  );
};


const filterChoices = (choices, labelField, valueField, nameFilter, selectedFilter, selectedValues) => {
  if (!choices) {
    return [];
  }
  const nameFilterRegExp = nameFilter
    ? new RegExp(nameFilter.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').replace(' ', '.*'), 'i')
    : null;
  return choices.filter((choice) => {
    if (selectedFilter) {
      if (!selectedValues || selectedValues.indexOf(choice[valueField]) < 0) {
        return false;
      }
    }
    if (nameFilterRegExp && !nameFilterRegExp.test(choice[labelField])) {
      return false;
    }
    return true;
  });
};


const ChoiceItem = ({checked, label, onChange, style, value}) => {
  return (
    <FormControlLabel
      key={value}
      style={style}
      label={
        <Typography variant="body2">{label}</Typography>
      }
      control={
        <Checkbox
          color="primary"
          value={value}
          checked={checked}
          onChange={onChange}
          size="small"/>
      }/>
  );
};


LargeCheckList.propTypes = {
  choices: PropTypes.array,
  choiceLabelField: PropTypes.string,
  choiceValueField: PropTypes.string,
  value: PropTypes.array,
  valueType: PropTypes.oneOf(Object.values(ValueTypes)),
  onChange: PropTypes.func
};

export default LargeCheckList;
