import React, { useCallback, useEffect, useState } from 'react';
import { TextInput, useLogin, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import Form from '../../components/Form';
import Info from '../../components/Info';
import LoginBox from './LoginBox';
import ProgressButton from '../../components/ProgressButton';
import styles from './LoginStyles.module.css';
import useRutokenService from '../../hooks/useRutokenService';
import { RoutePath, getUrl } from '../../routes';
import useTelemedicService from '../../hooks/useTelemedicService';

const LoginPage = () => {
  const translate = useTranslate();
  const [tokenLoginData, setTokenLoginData] = useState(null);
  const service = useTelemedicService()
  const rutokenService = useRutokenService();
  useEffect(() => {
    rutokenService.selectDevice().then((deviceId) => (
      rutokenService.login(deviceId)
        .then(() => rutokenService.selectCertificate(deviceId))
        .then((certificate) => (
          rutokenService.getCertificatePublicKey(deviceId, certificate.id).then((publicKey) => (
            setTokenLoginData({
              certificateId: certificate.id,
              certificatePublicKey: publicKey,
            })
          ))
        ))
    )).catch(() => setTokenLoginData(false));
  }, [rutokenService]);
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'telemedic.validation.required';
    }
    if (!values.password) {
      errors.password = 'telemedic.validation.required';
    }
    return errors;
  }, []);
  
  const params_raw = document.location.hash.split('?')[1];
  const params = new URLSearchParams(params_raw);

  const login = useLogin();
  const [loginError, setLoginError] = useState(params.get('error'));
  const loginWithPassword = useCallback((data) => {
    login(data).catch((errorMessage) => setLoginError(errorMessage));
  }, [login]);
  const loginWithToken = useCallback(() => {
    login(tokenLoginData).catch((errorMessage) => setLoginError(errorMessage));
  }, [login, tokenLoginData]);
  const loginWithESIA = useCallback(async () => {
    const response = await service.getEsiaLogin();    
    window.location.href = response.json.redirect_to;
  }, [service]);

  return (
    <LoginBox>
      <Form
        className={styles.form}
        onSubmit={loginWithPassword}
        validate={validate}
      >
        <TextInput
          className={styles.largeInput}
          fullWidth
          helperText={false}
          label="telemedic.login.fields.login"
          margin="normal"
          name="username"
          variant="outlined"
        />
        <TextInput
          className={styles.largeInput}
          fullWidth
          helperText={false}
          label="telemedic.login.fields.password"
          margin="normal"
          name="password"
          type="password"
          variant="outlined"
        />
        <div className={styles.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {translate('telemedic.actions.login')}
          </Button>
          <ProgressButton
            type="button"
            variant="contained"
            color="primary"
            label="telemedic.actions.loginByToken"
            progress={tokenLoginData === null}
            disabled={tokenLoginData === false}
            onClick={loginWithToken}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={loginWithESIA}
          >
            {translate('telemedic.actions.loginByESIA')}
          </Button>
        </div>
        {!!loginError && (
          <Info className={styles.control} level="error" variant="caption">
            {loginError}
          </Info>
        )}
        <Link className={styles.control} href={getUrl(RoutePath.ATTACH_TOKEN)}>
          {translate('telemedic.login.restoreTokenAccess')}
        </Link>
        <Link className={styles.control} href={getUrl(RoutePath.PASSWORD_RESET)}>
          {translate('telemedic.login.resetPassword')}
        </Link>
      </Form>
    </LoginBox>
  );
};

export default LoginPage;
