import React from 'react';
import {Title, withTranslate} from 'react-admin';

import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {DatePicker} from '@material-ui/pickers';

import FilterFormContent from '../../components/FilterFormContent';
import Spacer from '../../components/Spacer';
import ProgressButton from '../../components/ProgressButton';
import ProgressDialog from '../../components/ProgressDialog';
import TableRowEmpty from '../../components/tables/TableRowEmpty';
import TelemedicService from '../../services/TelemedicService';
import styles from './SignedCheckupJournalsPage.module.css';

import {API_DATE_FORMAT, DISPLAY_DATE_FORMAT} from '../../constants';
import {Section, Action} from '../../permissions';
import {getRutokenServiceInstance} from '../../services/RutokenService';
import {getAuthToken, isTokenAttached} from '../../storages/auth';

class _SignedCheckupJournalsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTokenCheckRunning: false,
      isTokenAttached: false,
      isDeviceAvailable: false,
      date: moment().format('YYYY-MM-DD'),
      notFormedOnly: false,
      checkupJournals: [],
      notFormedCheckupJournals: [],
      allJournalsSelected: false,
      selectedJournals: {},
      errorText: null,
      isSignDialogOpened: false,
      isSigning: false,
      journalsSigned: 0,
      journalsFailed: 0,
      isSignFinished: false,
      isSignError: false,
      signErrorText: ''
    }
    this.telemedicService = new TelemedicService(getAuthToken());
    this.rutokenService = getRutokenServiceInstance();
    this.isComponentMounted = false;

    this.deviceTimerId = null;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.fetchCheckupJournals();
    const tokenAttached = isTokenAttached();
    if (tokenAttached) {
      this.setState({
        isTokenAttached: true,
        isTokenCheckRunning: true
      });
      this.deviceTimerId = setInterval(() => {
        this.checkTokenState();
      }, 6000);
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.telemedicService.abort();
    if (this.deviceTimerId) {
      clearTimeout(this.deviceTimerId);
      this.deviceTimerId = null;
    }
  }

  checkTokenState() {
    console.log('checkTokenState');
    this.rutokenService.selectDevice().then((deviceId) => {
      this.deviceId = deviceId;
      this.setStateSafe({
        isDeviceAvailable: true,
        isTokenCheckRunning: false
      });
    }).catch((e) => {
      console.log(e);
      this.setStateSafe({
        isDeviceAvailable: false,
        isTokenCheckRunning: false
      });
    });
  }

  setStateSafe(state) {
    if (this.isComponentMounted) {
      this.setState(state);
    }
  }

  fetchCheckupJournals() {
    this.setState({
      allJournalsSelected: false,
      selectedJournals: {}
    });
    this.telemedicService.getCheckupJournalsToSign(this.state.date).then((response) => {
      if (response.json) {
        const checkupJournals = response.json.data;
        const notFormedCheckupJournals = checkupJournals.filter(checkupJournal => !checkupJournal.journal_url);
        this.setState({
          checkupJournals: checkupJournals,
          notFormedCheckupJournals: notFormedCheckupJournals
        });
      }
      else if (response.error_description) {
        this.setState({errorText: response.error_description});
      }
      else {
        this.setState({errorText: this.props.translate('telemedic.errors.fetchError')});
      }
    }).catch((e) => {
      console.log(e);
      this.setState({errorText: this.props.translate('telemedic.errors.fetchError')});
    });
  }

  render() {
    const translate = this.props.translate;
    const {
      isSignDialogOpened,
      isSigning, selectedJournals, journalsSigned, journalsFailed,
      isSignFinished,
      isSignError, signErrorText
    } = this.state;
    const progressText = isSigning ? (
      <span>
        {translate('telemedic.signedCheckupJournals.list.totalJournals')}: {Object.keys(selectedJournals).length}<br/>
        {translate('telemedic.signedCheckupJournals.list.signedJournals')}: {journalsSigned}<br/>
        {translate('telemedic.signedCheckupJournals.list.withErrors')}: {journalsFailed}
      </span>
    ) : '';
    const successText = isSignFinished ? (
      <span>
        {translate('telemedic.signedCheckupJournals.list.journalsSignFinished')}<br/>
        {translate('telemedic.signedCheckupJournals.list.totalJournals')}: {Object.keys(selectedJournals).length}<br/>
        {translate('telemedic.signedCheckupJournals.list.signedJournals')}: {journalsSigned}<br/>
        {translate('telemedic.signedCheckupJournals.list.withErrors')}: {journalsFailed}
      </span>
    ) : '';
    return (
      <div>
        <Title title="telemedic.signedCheckupJournals.label"/>
        {this.renderFilters()}
        <Paper>
          {this.renderTable()}
        </Paper>
        <ProgressDialog
          open={isSignDialogOpened}
          isProgress={isSigning}
          progressText={progressText}
          isSuccess={isSignFinished}
          successText={successText}
          isError={isSignError}
          errorText={signErrorText}
          onRetry={this.handleSignClick}
          onCancel={this.handleOnCancel}
          onDone={this.handleOnDone}/>
      </div>
    );
  }

  renderFilters() {
    const translate = this.props.translate;
    const {
      isTokenCheckRunning, isTokenAttached, isDeviceAvailable,
      date, notFormedOnly, selectedJournals
    } = this.state;
    let tokenError = null;
    if (!isTokenAttached) {
      tokenError = translate('telemedic.signedCheckupJournals.list.tokenNotAttached');
    }
    else if (!isDeviceAvailable) {
      tokenError = translate('telemedic.signedCheckupJournals.list.deviceNotAvailable');
    }
    return (
      <Toolbar variant="regular" disableGutters={true}>
        <FilterFormContent>
          <DatePicker
            autoOk={true}
            label={translate('telemedic.signedCheckupJournals.list.date')}
            margin="dense"
            inputVariant="filled"
            format={DISPLAY_DATE_FORMAT}
            value={date}
            onChange={this.handleDateChange}
            required/>
          <Spacer/>
          <FormControlLabel
            control={
              <Switch
                checked={notFormedOnly}
                onChange={this.handleNotFormedSwitchChange}/>
            }
            label={translate('telemedic.signedCheckupJournals.list.notFormedOnly')}
          />
          <Spacer fill={true}/>
          {!isTokenCheckRunning && !tokenError &&
            <ProgressButton
              label="telemedic.signedCheckupJournals.list.signSelected"
              color="primary"
              variant="contained"
              disabled={Object.keys(selectedJournals).length === 0}
              onClick={this.handleSignClick}/>
          }
          {!isTokenCheckRunning && tokenError &&
            <Typography className={styles.tokenError}>{tokenError}</Typography>
          }
        </FilterFormContent>
      </Toolbar>
    );
  }

  handleDateChange = (date) => {
    this.setState({date: date.format(API_DATE_FORMAT)}, () => this.fetchCheckupJournals());
  }

  handleNotFormedSwitchChange = (event) => {
    this.setState({notFormedOnly: event.target.checked});
  }

  handleSignClick = () => {
    this.setState({
      isSignDialogOpened: true,
      isSigning: true,
      journalsSigned: 0,
      journalsFailed: 0,
      isSignFinished: false,
      isSignError:false,
      signErrorText: ''
    });
    let deviceId, certificateId;
    this.rutokenService.getDeviceAndCertificateId().then((result) => {
      deviceId = result.deviceId;
      certificateId = result.certificateId;
      return this.rutokenService.getCertificatePublicKey(deviceId, certificateId);
    }).then((certificatePublicKey) => {
      return this.signJournals(deviceId, certificateId, certificatePublicKey);
    }).then(() => {
      this.setState({
        isSigning: false,
        isSignFinished: true
      });
    }).catch((e) => {
      console.log(e);
      this.setState({
        isSigning: false,
        isSignError: true,
        signErrorText: this.props.translate('telemedic.signedCheckupJournals.list.publicKeyError')
      });
    });
  }

  handleOnCancel = () => {
    this.setState({isSignDialogOpened: false});
  }

  handleOnDone = () => {
    this.setState({isSignDialogOpened: false});
    this.fetchCheckupJournals();
  }

  async signJournals(deviceId, certificateId, certificatePublicKey) {
    const {date, notFormedCheckupJournals, selectedJournals} = this.state;
    for (var i = 0, length = notFormedCheckupJournals.length; i < length; ++i) {
      const journal = notFormedCheckupJournals[i];
      const organizationId = journal.organization.id;
      let encodedJournal;
      if (selectedJournals[journal.organization.id]) {
        await this.telemedicService.getEncodedCheckupJournal(date, organizationId, certificatePublicKey).then((response) => {
          encodedJournal = response.json.data;
          return this.rutokenService.sign(deviceId, certificateId, encodedJournal);
        }).then((signature) => {
          return this.telemedicService.uploadSignedCheckupJournal(date, organizationId, encodedJournal, signature);
        }).then(() => {
          this.setState((state) => {
            return {journalsSigned: state.journalsSigned + 1}
          });
        }).catch((e) => {
          console.log(e);
          this.setState((state) => {
            return {journalsFailed: state.journalsFailed + 1}
          });
        });
      }
    }
  }

  renderTable() {
    const {permissions, translate} = this.props;
    const {
      notFormedOnly, checkupJournals, notFormedCheckupJournals,
      allJournalsSelected, selectedJournals, errorText
    } = this.state;

    const displayCheckupJournals = notFormedOnly ? notFormedCheckupJournals : checkupJournals;

    const checkupJournalsEmpty = !checkupJournals || !checkupJournals.length;
    const displayCheckupJournalsEmpty = !displayCheckupJournals || !displayCheckupJournals.length;

    const userHasSignPermission = permissions.check(Section.SIGNED_CHECKUP_JOURNALS, Action.SIGN);

    const downloadLabel  = translate('telemedic.actions.download');

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{translate('telemedic.signedCheckupJournals.list.organization')}</TableCell>
              <TableCell>{translate('telemedic.signedCheckupJournals.list.journal')}</TableCell>
              <TableCell>{translate('telemedic.signedCheckupJournals.list.signature')}</TableCell>
              <TableCell>
                <Checkbox
                  checked={allJournalsSelected}
                  onChange={this.handleSelectAllChange}/>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!displayCheckupJournalsEmpty && displayCheckupJournals.map(checkupJournal => {
              return (
                <TableRow key={checkupJournal.organization.id}>
                  <TableCell>{checkupJournal.organization.id}</TableCell>
                  <TableCell>{checkupJournal.organization.name}</TableCell>
                  <TableCell>
                    {checkupJournal.journal_url
                      ? <Link color="primary" href={checkupJournal.journal_url}>{downloadLabel}</Link>
                      : '-'
                    }
                  </TableCell>
                  <TableCell>
                    {checkupJournal.signature_url
                      ? <Link color="primary" href={checkupJournal.signature_url}>{downloadLabel}</Link>
                      : '-'
                    }
                  </TableCell>
                  <TableCell>
                  {userHasSignPermission && !checkupJournal.signature_url &&
                    <Checkbox
                      checked={!!selectedJournals[checkupJournal.organization.id]}
                      value={checkupJournal.organization.id}
                      onChange={this.handleCheckboxChange}/>
                  }
                  </TableCell>
                </TableRow>
              );
            })}
            {displayCheckupJournalsEmpty && !!errorText &&
              <TableRowEmpty
                color="error"
                colSpan={5}
                textId={errorText}/>
            }
            {displayCheckupJournalsEmpty && !errorText &&
              <TableRowEmpty
                colSpan={5}
                textId={
                  checkupJournalsEmpty
                  ? 'telemedic.signedCheckupJournals.list.empty'
                  : 'telemedic.signedCheckupJournals.list.filteredEmpty'
                }/>
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  handleSelectAllChange = (event) => {
    const {notFormedCheckupJournals, allJournalsSelected} = this.state;
    if (event.target.checked) {
      const allJournals = [];
      for (var i = 0, length = notFormedCheckupJournals.length; i < length; i++) {
        const checkupJournal = notFormedCheckupJournals[i];
        if (!checkupJournal.journal_url) {
          allJournals[checkupJournal.organization.id] = true;
        }
      }
      this.setState({
        allJournalsSelected: true,
        selectedJournals: allJournals
      });
    }
    else if (allJournalsSelected) {
      this.setState({
        allJournalsSelected: false,
        selectedJournals: {}
      });
    }
    else {
      this.setState({
        allJournalsSelected: false
      });
    }
  }

  handleCheckboxChange = (event) => {
    var {allJournalsSelected, selectedJournals} = this.state;
    if (allJournalsSelected && !event.target.checked) {
      allJournalsSelected = false;
    }
    selectedJournals[event.target.value] = event.target.checked;
    this.setState({
      allJournalsSelected: allJournalsSelected,
      selectedJournals: selectedJournals
    });
  }
}

const SignedCheckupJournalsPage = withTranslate(_SignedCheckupJournalsPage);

export default SignedCheckupJournalsPage;
