import { useState } from 'react';

import { getRutokenServiceInstance } from '../services/RutokenService';

const useRutokenService = () => {
  const [service] = useState(getRutokenServiceInstance());
  return service;
};

export default useRutokenService;
