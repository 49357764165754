import React, { useCallback, useState, useEffect } from 'react';
import {
  SimpleShowLayout,
  TextField,
  useTranslate,
  usePermissions,
  BooleanField,
  useShowController,
} from 'react-admin';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import Button from '@material-ui/core/Button';

import FieldLink from '../../components/FieldLink';
import FormatChipField from '../../components/fields/FormatChipField';
import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ListField from '../../components/fields/ListField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import TerminalTitle from './TerminalTitle';

import { RoutePath, getUrl } from '../../routes';
import { formatDisplayDate, formatDisplayDateTime } from '../../utils/formatters';
import useTelemedicService from '../../hooks/useTelemedicService';
import styles from './TerminalShow.module.css';
import { Action, Section } from '../../permissions';
import Changelog from '../../components/Changelog';

const SERVER_NAME_TO_LABEL = {
  'type': 'telemedic.terminals.show.type',
  'model': 'telemedic.terminals.show.model',
  'build_date': 'telemedic.terminals.show.buildDate',
  'verification_date': 'telemedic.terminals.show.verificationDate',
  'deadline': 'telemedic.terminals.show.deadline',
  'last_activity_time': 'telemedic.terminals.show.lastActivityTime',
  'teamview_login': 'telemedic.terminals.show.teamViewerLogin',
  'teamview_password': 'telemedic.terminals.show.teamViewerPassword',
  'breathalyzer_model': 'telemedic.terminals.show.breathalyzerModel',
  'breathalyzer_serial': 'telemedic.terminals.show.breathalyzerSerial',
  'pyrometer_model': 'telemedic.terminals.show.pyrometerModel',
  'pyrometer_serial': 'telemedic.terminals.show.pyrometerSerial',
  'tonometer_model': 'telemedic.terminals.show.tonometerModel',
  'tonometer_serial': 'telemedic.terminals.show.tonometerSerial',
  'pc_model': 'telemedic.terminals.show.pcModel',
  'camera_model': 'telemedic.terminals.show.cameraModel',
  'name': 'telemedic.terminals.show.name',
  'region': 'telemedic.terminals.show.region',
  'timezone': 'telemedic.terminals.show.timezone',
  'organizations': 'telemedic.terminals.show.organizations',
  'inspection_point.name': 'telemedic.terminals.show.inspectionPoint',
  'printer_model': 'telemedic.terminals.show.printerModel',
  'ambient_temperature': 'telemedic.terminals.show.ambientTemperature',
  'illuminance': 'telemedic.terminals.show.illuminance',
  'humidity': 'telemedic.terminals.show.humidity',
  'control_hash': 'telemedic.terminals.show.controlHash',
  'current_hash': 'telemedic.terminals.show.currentHash',
  'tamper_detected': 'telemedic.terminals.show.tamperSensor',
  'comments': 'telemedic.terminals.show.comments',
  'message': 'telemedic.terminals.show.message',
  'medic_phone': 'telemedic.terminals.show.medicPhone',
  'sett_autoinspect': 'telemedic.terminals.show.autoinspect',
  'ais_erp_api': 'telemedic.terminals.show.aisErpApi',
  'terminal_ais_erp_id': 'telemedic.terminals.show.terminalAisErpId',
  'ais_erp_errors': 'telemedic.terminals.show.aisErpErrors',
};

const getOrganizationUrl = (record) => (record ? getUrl(RoutePath.ORGANIZATION_SHOW, { id: record.id}) : '');

const organizationFormatter = (record) => (record ? `${record.id} - ${record.name}` : '');

const TerminalShow = (props) => {
  const translate = useTranslate();
  const telemedicService = useTelemedicService();
  const { loaded, permissions } = usePermissions();
  const formatTamperDetected = useCallback(
    (value) => {
      if (value === true) return translate('telemedic.terminals.show.tamperDetected');
      if (value === false) return translate('telemedic.terminals.show.tamperNotDetected');
      return '';
    }, [translate],
  );
  const { record } = useShowController(props);
  const [changelog, setChangelog] = useState([]);
  useEffect(() => {
    if (!record) {
      return;
    }
    telemedicService.getTerminalChangelog(record.id).then((response) => {
      if (response.status === 200) {
        setChangelog(response.json.changes);
      }
    });
  }, [record, telemedicService]);
  const formatCoverEnerg = useCallback(
    (value) => {
      if (value === 'close') return translate('telemedic.terminals.show.coverEnergClose');
      if (value === 'open') return translate('telemedic.terminals.show.coverEnergOpen');
      if (value === 'lostpower') return translate('telemedic.terminals.show.coverEnergLostPower');
      if (value === 'lostpower_open') return translate('telemedic.terminals.show.coverEnergLostPowerOpen');
      return '';
    }, [translate],
  );
  return (
    <RestrictedShow title={<TerminalTitle />} {...props}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            {
              loaded && permissions.check(Section.TERMINALS, Action.RESTART) && (
                <Button
                  type="button"
                  color="primary"
                  onClick={() => { telemedicService.restartTerminal(props.id); }}
                >
                  {translate('telemedic.terminals.show.restart')}
                </Button>
              )
            }
            <TextField label="telemedic.terminals.show.type" source="type" />
            <TextField label="telemedic.terminals.show.model" source="model" />
            <FormatValueField
              label="telemedic.terminals.show.buildDate"
              source="build_date"
              formatter={formatDisplayDate}
            />
            <FormatValueField
              label="telemedic.terminals.show.verificationDate"
              source="verification_date"
              formatter={formatDisplayDate}
            />
            <FormatValueField
              label="telemedic.terminals.show.deadline"
              source="deadline"
              formatter={formatDisplayDate}
            />
            <FormatValueField
              label="telemedic.terminals.show.lastActivityTime"
              source="last_activity_time"
              formatter={formatDisplayDateTime}
            />
            <TextField label="telemedic.terminals.show.teamViewerLogin" source="teamview_login" />
            <TextField label="telemedic.terminals.show.teamViewerPassword" source="teamview_password" />
            <TextField label="telemedic.terminals.show.termAdminPassword" source="conf_adm_pass" />
            <TextField label="telemedic.terminals.show.breathalyzerModel" source="breathalyzer_model" />
            <TextField label="telemedic.terminals.show.breathalyzerSerial" source="breathalyzer_serial" />
            <TextField label="telemedic.terminals.show.pyrometerModel" source="pyrometer_model" />
            <TextField label="telemedic.terminals.show.pyrometerSerial" source="pyrometer_serial" />
            <TextField label="telemedic.terminals.show.tonometerModel" source="tonometer_model" />
            <TextField label="telemedic.terminals.show.tonometerSerial" source="tonometer_serial" />
            <TextField label="telemedic.terminals.show.pcModel" source="pc_model" />
            <TextField label="telemedic.terminals.show.cameraModel" source="camera_model" />
            <TextField label="telemedic.terminals.show.name" source="name" />
            <TextField label="telemedic.terminals.show.region" source="region" />
            <TextField label="telemedic.terminals.show.timezone" source="timezone" />
            <ListField
              label="telemedic.terminals.show.organizations"
              source="organizations"
            >
              <FieldLink getUrl={getOrganizationUrl}>
                <FormatChipField formatter={organizationFormatter} clickable />
              </FieldLink>
            </ListField>
            <TextField label="telemedic.terminals.show.inspectionPoint" source="inspection_point.name" />
            <TextField label="telemedic.terminals.show.printerModel" source="printer_model" />
            <TextField label="telemedic.terminals.show.decree866" source="decree_866" />
            <TextField label="telemedic.terminals.show.ambientTemperature" source="ambient_temperature" />
            <TextField label="telemedic.terminals.show.illuminance" source="illuminance" />
            <TextField label="telemedic.terminals.show.humidity" source="humidity" />
            <TextField label="telemedic.terminals.show.controlHash" source="control_hash" />
            <TextField label="telemedic.terminals.show.currentHash" source="current_hash" />
            <FormatValueField
              label="telemedic.terminals.show.tamperSensor"
              source="tamper_detected"
              formatter={formatTamperDetected}
            />
            <FormatValueField
              label="telemedic.terminals.show.coverEnerg"
              source="cover_energ"
              formatter={formatCoverEnerg}
            />
            <TextField label="telemedic.terminals.show.comments" source="comments" />
            <TextField label="telemedic.terminals.show.message" source="message" />
            <TextField label="telemedic.terminals.show.medicPhone" source="medic_phone" />
            <BooleanField label="telemedic.terminals.show.autoinspect" source="sett_autoinspect" />
            <BooleanField label="telemedic.terminals.show.aisErpApi" source="ais_erp_api" />
            <BooleanField label="telemedic.terminals.show.addDataToAisErpApi" source="add_data_to_ais_erp_api" />
            <TextField label="telemedic.terminals.show.terminalAisErpId" source="terminal_ais_erp_id" />
            <TextField label="telemedic.terminals.show.aisErpErrors" source="ais_erp_errors" />
            <TextField label="telemedic.terminals.show.arshinCertNumber" source="arshin_cert_number" />
            <Changelog
              changelog={changelog}
              fieldsLabels={SERVER_NAME_TO_LABEL}
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <TerminalMap />
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

const TerminalMap = ({record}) => {
  if (record && record.latitude && record.longitude) {
    const terminalPosition = [record.latitude, record.longitude];
    return (
      <div className={styles.mapContainer}>
        <YMaps>
          <Map width={400} height={400} defaultState={{ center: terminalPosition, zoom: 16 }}>
            <Placemark geometry={terminalPosition} />
          </Map>
        </YMaps>
      </div>
    );
  }
  return (<div />);
};

export default TerminalShow;
