import { useEffect } from 'react';

import useTelemedicService from '../hooks/useTelemedicService';

const IDLE_LOGOUT_PERIOD = 180000;

const IdleLogoutTracker = () => {
  const shouldTriggerLogoutTimer = JSON.parse(localStorage.getItem('did_login_with_esia'));
  const service = useTelemedicService();

  const logoutTimer = () => {
    if (!shouldTriggerLogoutTimer) {
      return;
    }

    const esiaLogout = async () => {
      const response = await service.getEsiaLogout();
      window.location.href = response.json.redirect_to;
    };
    let t;
    const resetTimer = () => {
      clearTimeout(t);
      t = setTimeout(esiaLogout, IDLE_LOGOUT_PERIOD);
    };

    window.addEventListener('load', resetTimer, true);
    window.addEventListener('mousemove', resetTimer, true);
    window.addEventListener('mousedown', resetTimer, true);
    window.addEventListener('touchstart', resetTimer, true);
    window.addEventListener('touchmove', resetTimer, true);
    window.addEventListener('click', resetTimer, true);
    window.addEventListener('keydown', resetTimer, true);
    window.addEventListener('scroll', resetTimer, true);
  };

  useEffect(logoutTimer, []);

  return null;
};

export default IdleLogoutTracker;
