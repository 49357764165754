import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInput, useTranslate } from 'react-admin';

import TextField from '@material-ui/core/TextField'

import FormHelperTextSpan from '../FormHelperTextSpan';

const TextInput = (props) => {
  const {
    className,
    error: propsError,
    fullWidth,
    helperText,
    label,
    margin,
    variant,
  } = props;
  const {
    input: { name, onChange, ...rest },
    meta: { error, required, touched },
    isRequired,
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const InputLabelProps = useMemo(() => ({
    required: required || isRequired,
  }), [isRequired, required]);
  return (
    <TextField
      className={className}
      error={!!(touched && (error || propsError))}
      fullWidth={fullWidth}
      helperText={(
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}
        />
      )}
      InputLabelProps={InputLabelProps}
      label={labelText}
      margin={margin}
      name={name}
      onChange={onChange}
      variant={variant}
      {...rest}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  margin: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.string,
};

TextInput.defaultProps = {
  className: undefined,
  error: false,
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  margin: 'dense',
  required: false,
  variant: 'filled',
};

export default TextInput;
