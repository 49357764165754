import React from 'react';
import { withTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import CheckList from '../../components/CheckList';
import ProgressButton from '../../components/ProgressButton';

import { CheckupResult, NonAdmissionReason } from '../../constants';
import { formatNonAdmissionReason } from '../../utils/formatters';

import styles from './NonAdmissionDialog.module.css';

class _NonAdmissionDialog extends React.Component {
  NON_ADMISSION_CHOICES = [
    [
      'Видеозапись осмотра:', [
        {
          value: NonAdmissionReason.ALCOHOL_NOT_ON_VIDEO,
          label: formatNonAdmissionReason(NonAdmissionReason.ALCOHOL_NOT_ON_VIDEO),
        },
        {
          value: NonAdmissionReason.BLOOD_PRESSURE_NOT_ON_VIDEO,
          label: formatNonAdmissionReason(NonAdmissionReason.BLOOD_PRESSURE_NOT_ON_VIDEO),
        },
        {
          value: NonAdmissionReason.TEMPERATURE_NOT_ON_VIDEO,
          label: formatNonAdmissionReason(NonAdmissionReason.TEMPERATURE_NOT_ON_VIDEO),
        },
        {
          value: NonAdmissionReason.INSUFFICIENT_LIGHTING,
          label: formatNonAdmissionReason(NonAdmissionReason.INSUFFICIENT_LIGHTING),
        },
        {
          value: NonAdmissionReason.WRONG_DRIVER,
          label: formatNonAdmissionReason(NonAdmissionReason.WRONG_DRIVER),
        },
        {
          value: NonAdmissionReason.BAD_PICTURE_QUALITY,
          label: formatNonAdmissionReason(NonAdmissionReason.BAD_PICTURE_QUALITY),
        },
      ],
    ],
    [
      'Результаты измерений:', [
        {
          value: NonAdmissionReason.ALCOHOL_ABNORMAL,
          label: formatNonAdmissionReason(NonAdmissionReason.ALCOHOL_ABNORMAL),
        },
        {
          value: NonAdmissionReason.BLOOD_PRESSURE_ABNORMAL,
          label: formatNonAdmissionReason(NonAdmissionReason.BLOOD_PRESSURE_ABNORMAL),
        },
        {
          value: NonAdmissionReason.PULSE_ABNORMAL,
          label: formatNonAdmissionReason(NonAdmissionReason.PULSE_ABNORMAL),
        },
        {
          value: NonAdmissionReason.TEMPERATURE_ABNORMAL,
          label: formatNonAdmissionReason(NonAdmissionReason.TEMPERATURE_ABNORMAL),
        },
      ],
    ],
    [
      'Прочее:', [
        {
          value: NonAdmissionReason.CUFF_WORN_NOT_CORRECTLY,
          label: formatNonAdmissionReason(NonAdmissionReason.CUFF_WORN_NOT_CORRECTLY),
        },
        {
          value: NonAdmissionReason.COMPLAINTS,
          label: formatNonAdmissionReason(NonAdmissionReason.COMPLAINTS),
        },
        {
          value: NonAdmissionReason.TAKE_OFF_OUTERWEAR,
          label: formatNonAdmissionReason(NonAdmissionReason.TAKE_OFF_OUTERWEAR),
        },
      ],
    ],
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedReasons: [],
      comment: '',
      submitProgress: false,
      errorText: '',
    };
  }

  onSelectedReasonsChange = (selectedReasons) => {
    this.setState({ selectedReasons });
  }

  onCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  }

  submitNonAdmission = () => {
    const { notAdmitUser, onDone, translate } = this.props;
    const { selectedReasons, comment } = this.state;
    if (!selectedReasons.length && !comment) {
      this.setState({
        errorText: translate('telemedic.checkupDialogs.noReasonSelected'),
      });
      return;
    }
    this.setState({
      errorText: '',
      submitProgress: true,
    });
    notAdmitUser(selectedReasons, comment).then(() => {
      this.setState({
        submitProgress: false,
        errorText: '',
      });
      onDone();
    }).catch((e) => {
      let errorText;
      if (e.errorText) {
        errorText = e.errorText;
      } else if (e.errorTextId) {
        errorText = translate(e.errorTextId);
      } else {
        errorText = translate('telemedic.checkupDialogs.unknownError');
      }
      this.setState({
        submitProgress: false,
        errorText,
      });
    });
  }

  render() {
    const { resultAuto, onCancel, translate } = this.props;
    const {
      selectedReasons, comment, submitProgress, errorText,
    } = this.state;
    const warningText = (resultAuto === CheckupResult.ADMITTED)
      ? translate('telemedic.checkupDialogs.warningAdmitted')
      : '';
    return (
      <Dialog open>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {translate('telemedic.checkupDialogs.nonAdmissionReason')}
          </Typography>
          <CheckList
            choiceGroups={this.NON_ADMISSION_CHOICES}
            value={selectedReasons}
            valueType="integer"
            onChange={this.onSelectedReasonsChange}
          />
          <FormLabel>
            {translate('telemedic.checkupDialogs.comment')}
          </FormLabel>
          <textarea
            className={styles.dialogTextArea}
            value={comment}
            onChange={this.onCommentChange}
          />
          <FormHelperText error>{warningText}</FormHelperText>
          <FormHelperText error>{errorText}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <ProgressButton
            type="submit"
            variant="contained"
            color="primary"
            label="telemedic.actions.notAdmit"
            progress={submitProgress}
            onClick={this.submitNonAdmission}
          />
          <Button onClick={onCancel}>
            {translate('telemedic.dialogs.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslate(_NonAdmissionDialog);
