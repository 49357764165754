import React, { useCallback, useState } from 'react';
import { TextInput, Title, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';

import FilterFormContent from '../../components/FilterFormContent';
import Form from '../../components/Form';
import Spacer from '../../components/Spacer';
import TableRowEmpty from '../../components/tables/TableRowEmpty';
import useTelemedicService from '../../hooks/useTelemedicService';

const CheckOrgInnPage = () => {
  const [inn, setInn] = useState();
  const [organizations, setOrganizations] = useState(null);
  const service = useTelemedicService();
  const handleChange = useCallback(({ newInn }) => {
    setInn(newInn);
  }, [setInn]);
  const searchOrganizations = useCallback(() => {
    service.checkOrganizationInn(inn).then((response) => {
      setOrganizations(response.json.data);
    }).catch((error) => {
      console.log(error);
    });
  }, [inn, service]);
  const translate = useTranslate();
  return (
    <div>
      <Title title="telemedic.checkOrgInn.label" />
      <Toolbar variant="regular" disableGutters>
        <Form onChange={handleChange} onSubmit={searchOrganizations}>
          <FilterFormContent>
            <TextInput
              name="inn"
              label={translate('telemedic.checkOrgInn.list.inn')}
              margin="dense"
              size="small"
              variant="filled"
              helperText={false}
            />
            <Spacer />
            <Button
              color="primary"
              disabled={!inn}
              type="submit"
              variant="contained"
            >
              {translate('telemedic.checkOrgInn.list.search')}
            </Button>
          </FilterFormContent>
        </Form>
      </Toolbar>
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('telemedic.checkOrgInn.list.inn')}</TableCell>
                <TableCell>{translate('telemedic.checkOrgInn.list.name')}</TableCell>
                <TableCell>{translate('telemedic.checkOrgInn.list.dealer')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations && organizations.map((organization) => {
                const { id, attributes } = organization;
                return (
                  <TableRow key={id}>
                    <TableCell>{attributes.inn}</TableCell>
                    <TableCell>{attributes.name}</TableCell>
                    <TableCell>{attributes.dealer ? attributes.dealer.name : ''}</TableCell>
                  </TableRow>
                );
              })}
              {organizations == null && (
                <TableRowEmpty colSpan={3} textId="telemedic.checkOrgInn.list.prompt" />
              )}
              {organizations && !organizations.length && (
                <TableRowEmpty colSpan={3} textId="telemedic.checkOrgInn.list.noResults" />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CheckOrgInnPage;
