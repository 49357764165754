import React from 'react';
import { Create } from 'react-admin';

import TestResultForm from './TestResultForm';
import TestResultTitle from './TestResultTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const TestResultCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={TestResultForm}
    routePath={RoutePath.TEST_QUESTION_CATEGORIES_SHOW}
    title={<TestResultTitle />}
    {...props}
  />
);

export default TestResultCreate;
