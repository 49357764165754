import React from 'react';
import { connect } from 'react-redux';
import { SelectInput, showNotification, CheckboxGroupInput, withTranslate } from 'react-admin';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import styles from './StatisticsForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import { API_DATE_FORMAT, CheckupType } from '../../constants';
import {
  REPORT_TYPE_INSPECTIONS, REPORT_TYPE_INSPECTIONS_EXTENDED,
  REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS,
  REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V,
  REPORT_TYPE_RISK_GROUP, REPORT_TYPE_AGE_RISK_GROUP,
  REPORT_TYPE_WORKING_AND_REST_TIME, REPORT_TYPE_WORKING_AND_REST_TIME_COLORED,
  RISK_GROUP_PRESSURE_AND_PULSE, RISK_GROUP_ALCOHOL, RISK_GROUP_TEMPERATURE,
} from './constants';
import { formatInspectionType } from '../../utils/formatters';

class StatisticsForm extends React.Component {
  constructor(props) {
    super(props);

    const { params, translate } = this.props;

    this.state = {
      organizations: [],
      terminals: [],
      selectedOrganiztionId: null,
      orgTerminals: [],
      submitProgress: false,
      currentReportType: REPORT_TYPE_INSPECTIONS,
    };
    this.service = new TelemedicService(getAuthToken());

    this.checkupTypeChoices = Object.values(CheckupType).map((checkupType) => (
      { id: checkupType, name: formatInspectionType(checkupType).toLowerCase() }
    ));
    this.reportTypeChoices = [
      { id: REPORT_TYPE_INSPECTIONS, name: translate('telemedic.statistics.form.reportTypeCheckups') },
      { id: REPORT_TYPE_INSPECTIONS_EXTENDED, name: translate('telemedic.statistics.form.reportTypeInspectionsExtended') },
      { id: REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS, name: translate('telemedic.statistics.form.reportTypeCheckupsWithoutRepeats') },
      { id: REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V, name: translate('telemedic.statistics.form.reportTypeCheckupsWithoutRepeats2v') },
      { id: REPORT_TYPE_RISK_GROUP, name: translate('telemedic.statistics.form.reportTypeRiskGroup') },
      { id: REPORT_TYPE_AGE_RISK_GROUP, name: translate('telemedic.statistics.form.reportTypeAgeRiskGroup') },
      { id: REPORT_TYPE_WORKING_AND_REST_TIME, name: translate('telemedic.statistics.form.reportTypeWorkingAndRestTime') },
      { id: REPORT_TYPE_WORKING_AND_REST_TIME_COLORED, name: translate('telemedic.statistics.form.reportTypeWorkingAndRestTimeColored') }
    ];
    this.riskGroupParamsChoices = [
      { id: RISK_GROUP_PRESSURE_AND_PULSE, name: translate('telemedic.statistics.form.pressureAndPulse') },
      { id: RISK_GROUP_ALCOHOL, name: translate('telemedic.statistics.form.alcohol') },
      { id: RISK_GROUP_TEMPERATURE, name: translate('telemedic.statistics.form.temperature') },
    ];

    const previousMonth = moment().subtract(1, 'months');
    this.formInitialValues = (params) || {
      dateFrom: previousMonth.startOf('month').format(API_DATE_FORMAT),
      dateTo: previousMonth.endOf('month').format(API_DATE_FORMAT),
      checkupType: null,
      organizationId: null,
      terminalId: null,
      reportType: REPORT_TYPE_INSPECTIONS,
      pressureAndPulse: true,
      riskGroupParams: [RISK_GROUP_PRESSURE_AND_PULSE, RISK_GROUP_ALCOHOL, RISK_GROUP_TEMPERATURE],
    };

    this.requiredFields = [
      'dateFrom', 'dateTo', 'organizationId', 'reportType',
    ];
  }

  componentDidMount() {
    const { params } = this.props;
    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    this.service.getTerminalValues().then((response) => {
      this.setState({ terminals: response.json.data });
      if (params) {
        this.updateOrgTerminals(response.json.data, params.organizationId);
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  validate = (values) => {
    const errors = {};
    this.requiredFields.forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  }

  onChange = (data) => {
    const { terminals } = this.state;
    this.setState({currentReportType: data.reportType})
    this.updateOrgTerminals(terminals, data.organizationId);
  }

  onSubmit = (data) => {
    const { paramsCallback, dataCallback, showNotification } = this.props;
    paramsCallback(data);
    this.setState({ submitProgress: true });
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId, reportType, riskGroupParams
    } = data;
    let promise;
    switch (reportType) {
      case REPORT_TYPE_INSPECTIONS:
        promise = this.service.getCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, false, false);
        break;
      case REPORT_TYPE_INSPECTIONS_EXTENDED:
        promise = this.service.getCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, false, false);
        break;
      case REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS:
        promise = this.service.getCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, true, false);
        break;
      case REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V:
        promise = this.service.getCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, false, true);
        break;
      case REPORT_TYPE_RISK_GROUP:
        promise = this.service.getRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId, riskGroupParams);
        break;
      case REPORT_TYPE_AGE_RISK_GROUP:
        promise = this.service.getAgeRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId);
        break;
      case REPORT_TYPE_WORKING_AND_REST_TIME:
        promise = this.service.getWorkingAndRestTime(dateFrom, dateTo, checkupType, organizationId, terminalId);
        break;
      case REPORT_TYPE_WORKING_AND_REST_TIME_COLORED:
        promise = this.service.getWorkingAndRestTime(dateFrom, dateTo, checkupType, organizationId, terminalId);
        break;
      default:
        throw new Error(`Invalid report type: ${reportType}`);
    }
    promise.then((response) => {
      this.setState({ submitProgress: false });
      if (!response.json.is_empty) {
        dataCallback(response.json);
      } else {
        showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({ submitProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  };

  updateOrgTerminals(terminals, organizationId) {
    const { selectedOrganiztionId } = this.state;
    if (organizationId) {
      const orgTerminals = terminals.filter((terminal) => terminal.organizations.indexOf(organizationId) >= 0);
      this.setState({
        selectedOrganiztionId: organizationId,
        orgTerminals,
      });
    } else if (selectedOrganiztionId) {
      this.setState({
        selectedOrganiztionId: null,
        orgTerminals: [],
      });
    }
  }

  render() {
    const { organizations, orgTerminals, submitProgress, currentReportType } = this.state;
    return (
      <Paper>
        <Form
          initialValues={this.formInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateInput
              className={styles.formControl}
              source="dateFrom"
              label="telemedic.statistics.form.dateFrom"
              required
            />
            <DateInput
              className={styles.formControl}
              source="dateTo"
              label="telemedic.statistics.form.dateTo"
              required
            />
            <CheckboxGroupInput
              className={styles.formControl}
              source="checkupType"
              label="telemedic.usage.form.checkupTypes"
              choices={this.checkupTypeChoices}
              row={false}
            />
            <AutocompleteInput
              className={styles.formControl}
              source="organizationId"
              label="telemedic.statistics.form.organization"
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              required
            />
            <AutocompleteInput
              className={styles.formControl}
              source="terminalId"
              label="telemedic.statistics.form.terminal"
              choices={orgTerminals}
              choiceLabelField="name"
              choiceValueField="id"
              resettable
            />
            <SelectInput
              className={styles.formControl}
              source="reportType"
              label="telemedic.statistics.form.reportType"
              choices={this.reportTypeChoices}
              required
            />
            {
              currentReportType === REPORT_TYPE_RISK_GROUP && (
                <CheckboxGroupInput
                  source="riskGroupParams"
                  label="telemedic.statistics.form.riskGroupParams"
                  choices={this.riskGroupParamsChoices}
                />
              )
            }
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(StatisticsForm));
