import React from 'react';
import {Filter, Datagrid, TextField, TextInput} from 'react-admin';

import RestrictedList from '../../components/admin/RestrictedList';

export const DealersResponsibleList = props => (
    <RestrictedList
      title="telemedic.dealersResponsible.label"
      filters={<DealersResponsibleFilter/>}
      sort={{field: 'name', order: 'ASC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <TextField
          label="telemedic.dealersResponsible.list.name"
          source="name"
          sortable={true}/>
        <TextField
          label="telemedic.dealersResponsible.list.comment"
          source="comment"
          sortable={false}/>
      </Datagrid>
    </RestrictedList>
);

const DealersResponsibleFilter = (props) => (
    <Filter key="dealers_responsible_filter" {...props}>
      <TextInput
        label="telemedic.dealersResponsible.list.name"
        source="name"
        alwaysOn resettable/>
    </Filter>
);
