import * as React from 'react';
import { Children, cloneElement, memo } from 'react';
import { toggleSidebar, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { LoadingIndicator, UserMenu as DefaultUserMenu } from 'ra-ui-materialui';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            paddingRight: 24,
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
    { name: 'RaAppBar' }
);

const AppBar = (props) => {
    const {
        children,
        classes: classesOverride,
        className,
        color = 'secondary',
        logout,
        open,
        title,
        userMenu,
        ...rest
    } = props;
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const translate = useTranslate();

    return (
        <MuiAppBar className={className} color={color} {...rest}>
            <Toolbar
                disableGutters
                variant="dense"
                className={classes.toolbar}
            >
                <Tooltip
                    title={translate(
                        open
                            ? 'ra.action.close_menu'
                            : 'ra.action.open_menu',
                        {
                            _: 'Open/Close menu',
                        }
                    )}
                    enterDelay={500}
                >
                    <IconButton
                        color="inherit"
                        onClick={() => dispatch(toggleSidebar())}
                        className={classNames(classes.menuButton)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                {Children.count(children) === 0 ? (
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                ) : (
                    children
                )}
                <LoadingIndicator />
                {typeof userMenu === 'boolean'
                    ? userMenu === true
                        ? cloneElement(<DefaultUserMenu />, { logout })
                        : null
                    : cloneElement(<DefaultUserMenu />, { logout })}
            </Toolbar>
        </MuiAppBar>
    );
};

export default memo(AppBar);
