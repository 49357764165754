import React from 'react';
import { Edit } from 'react-admin';

import MedOrganizationForm from './MedOrganizationForm';
import MedOrganizationTitle from './MedOrganizationTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const MedOrganizationEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={MedOrganizationForm}
    routePath={RoutePath.MED_ORGANIZATION_SHOW}
    title={<MedOrganizationTitle />}
    {...props}
  />
);

export default MedOrganizationEdit;
