export const REPORT_TYPE_INSPECTIONS = '1';
export const REPORT_TYPE_INSPECTIONS_EXTENDED = '6';
export const REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS = '2';
export const REPORT_TYPE_RISK_GROUP = '3';
export const REPORT_TYPE_AGE_RISK_GROUP = '4';
export const REPORT_TYPE_WORKING_AND_REST_TIME = '5';
export const REPORT_TYPE_WORKING_AND_REST_TIME_COLORED = '7';
export const REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V = '8';

export const RISK_GROUP_PRESSURE_AND_PULSE = 'pressure_and_pulse';
export const RISK_GROUP_ALCOHOL = 'alcohol';
export const RISK_GROUP_TEMPERATURE = 'temperature';
