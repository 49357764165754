import React from 'react';
import PropTypes from 'prop-types';

import TelemedicService from '../services/TelemedicService'
import {getAuthToken} from '../storages/auth';


class TelemedicDataSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.dataDefault,
      error: null
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentDidMount() {
    this.props.dataPromise(this.service).then((response) => {
      this.setState({data: response.json.data});
    }).catch((error) => {
      this.setState({error: error.errorDescription || this.props.dataDefaultError});
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {
      dataPromise,
      dataDefault,
      dataDefaultError,
      dataAttrName = 'data',
      children,
      ...rest
    } = this.props;
    const {data, error} = this.state;
    const childProps = {
      error: error,
      ...rest
    };
    childProps[dataAttrName] = data;
    return (
      <React.Fragment>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, childProps);
        })}
      </React.Fragment>
    );
  }
}

TelemedicDataSource.propTypes = {
  dataPromise: PropTypes.func,
  dataDefault: PropTypes.any,
  dataDefaultError: PropTypes.string,
  dataAttrName: PropTypes.string
};

export default TelemedicDataSource;
