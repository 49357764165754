import React, {useState} from 'react';
import {Title} from 'react-admin';

import HealthStatusForm from './HealthStatusForm';
import HealthStatusDisplay from './HealthStatusDisplay';

const HealthStatusPage = (props) => {
  const [formParams, setFormParams] = useState(null);
  const [healthStatusData, setHealthStatusData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  }

  const dataCallback = (healthStatusData) => {
    setHealthStatusData(healthStatusData);
  }

  const onBackClick = () => {
    setHealthStatusData(null);
  }

  const userId = props.match.params.userId;

  return (
    <div>
      <Title title="telemedic.healthStatus.label"/>
      {!healthStatusData && <HealthStatusForm
        userId={userId}
        params={formParams}
        paramsCallback={paramsCallback}
        dataCallback={dataCallback}/>}
      {!!healthStatusData && <HealthStatusDisplay
        params={formParams}
        healthStatusData={healthStatusData}
        onBackClick={onBackClick}
        {...props}/>}
    </div>
  );
}

export default HealthStatusPage;
