import React, { useState, useEffect } from 'react';
import {
  FormDataConsumer, SelectInput, TextInput,
} from 'react-admin';

import MaskedDateInput from '../../components/inputs/MaskedDateInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import {ValueTypes} from '../../utils/values';
import styles from './EWaybillForm.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import { WaybillTerm, WhoCreated, postshiftCheckup, transportType, commercialTransportationType, infoAboutTypeTransportation } from '../../constants';
import { formatWaybillTerm, formatWhoCreated, formatPostshiftCheckup, formatTransportType, formatCommercialTransportationType, formatInfoAboutTypeTransportation } from '../../utils/formatters';


const EWaybillForm = ({ getInputProps, ...rest }) => {
  const service = useTelemedicService();
  const user = useTelemedicServiceJsonValue(
    () => service.getUserInfo(), null, [],
  );
  const org_val = useTelemedicServiceJsonValue(
    () => service.getOrganizationValues(), null, [],
  );
  const WaybillTermChoices = [
    {id: WaybillTerm.DAY, name: formatWaybillTerm(WaybillTerm.DAY)},
    {id: WaybillTerm.FEWDAYS, name: formatWaybillTerm(WaybillTerm.FEWDAYS)}
  ];
  const whoCreatedChoices = [
    {id: WhoCreated.OWNER, name: formatWhoCreated(WhoCreated.OWNER)},
    {id: WhoCreated.LANDLORD, name: formatWhoCreated(WhoCreated.LANDLORD)}
  ];
  const postshiftCheckupChoices = [
    {id: postshiftCheckup.YES, name: formatPostshiftCheckup(postshiftCheckup.YES)},
    {id: postshiftCheckup.NO, name: formatPostshiftCheckup(postshiftCheckup.NO)}
  ];
  const transportTypeChoices = [
    {id: transportType.commercialTransportation, name: formatTransportType(transportType.commercialTransportation)},
    {id: transportType.ownNeeds, name: formatTransportType(transportType.ownNeeds)},
    {id: transportType.specialTransport, name: formatTransportType(transportType.specialTransport)}
  ];
  const commercialTransportationTypeChoices = [
    {id: commercialTransportationType.regularTransportation, name: formatCommercialTransportationType(commercialTransportationType.regularTransportation)},
    {id: commercialTransportationType.cargoTransportation, name: formatCommercialTransportationType(commercialTransportationType.cargoTransportation)},
    {id: commercialTransportationType.requestTransportation, name: formatCommercialTransportationType(commercialTransportationType.requestTransportation)},
    {id: commercialTransportationType.taxiTransportation, name: formatCommercialTransportationType(commercialTransportationType.taxiTransportation)},
    {id: commercialTransportationType.busTransportation, name: formatCommercialTransportationType(commercialTransportationType.busTransportation)}
  ];
  const infoAboutTypeTransportationChoices = [
    {id: infoAboutTypeTransportation.urban, name: formatInfoAboutTypeTransportation(infoAboutTypeTransportation.urban)},
    {id: infoAboutTypeTransportation.commuter, name: formatInfoAboutTypeTransportation(infoAboutTypeTransportation.commuter)},
    {id: infoAboutTypeTransportation.intercity, name: formatInfoAboutTypeTransportation(infoAboutTypeTransportation.intercity)}
  ];
  let userOrganizations = [];
  let defaultOrganizationId;
  const [vehicle, setVehicle] = useState([]);
  const [userValues, setUserValues] = useState([]);
  const [workerValues, setWorkerValues] = useState([]);
  const [orgInfo, setOrgInfo] = useState([]);
  const [orgAddress, setOrgAddress] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [inspectionPoints, setInspectionPoints] = useState([]);
  const [driverInfo, setDriverInfo] = useState([]);
  const [driving_license_date, setDrivingLicenseDate] = useState("");
  const [onlyOneRefresh , setOnlyOneRefresh] = useState(true);
  let fio;
  let userId;
  console.log(user)
  if (workerValues) {
    userId = user.id;
  }
  if (user.last_name) {
    fio = user.last_name
  }
  if (user.first_name) {
    fio = fio + " " + user.first_name
  }
  if (user.middle_name) {
    fio = fio + " " + user.middle_name
  }
  if (user.organizations && user.organizations.length > 0) {
    userOrganizations = org_val.filter(org => user.organizations.includes(org.id));
  }

  const changeValuesForOrg = (selectedOrganizationId) => {
    changeVehicle(selectedOrganizationId);
    changeUserValues(selectedOrganizationId);
    changeWorkerValues(selectedOrganizationId);
    changeOrgInfo(selectedOrganizationId);
    changeInspectionPoint(selectedOrganizationId);
  }

  const changeInspectionPoint = (selectedOrganizationId) => {
    service.getTechInspectionFormInspectionPointValues(selectedOrganizationId)
    .then((inspectionPointsData) => {
      setInspectionPoints(inspectionPointsData.json.data);
    })
  };
  
  const changeVehicle = (selectedOrganizationId) => {
    service.getVehicleValues(selectedOrganizationId)
    .then((vehicleData) => {
      setVehicle(vehicleData.json.data);
    })
  };

  const changeUserValues = (selectedOrganizationId) => {
    service.getUserValues(selectedOrganizationId)
    .then((userValues) => {
      setUserValues(userValues.json.data);
    })
  };
  
  const changeWorkerValues = (selectedOrganizationId) => {
    service.getWorkerValuesByOrg(selectedOrganizationId)
    .then((workerValues) => {
      setWorkerValues(workerValues.json.data);
    }).then(console.log(workerValues))
  };

  const changeOrgInfo = (selectedOrganizationId) => {
    if (selectedOrganizationId) {
      service.getOrganization(selectedOrganizationId)
      .then((orgValues) => {
        if (orgValues.status === 200) {
          setOrgInfo(orgValues.json.data.attributes);
          const orgAttributes = orgValues.json.data.attributes;
          const orgAddressData = {
            region_code: orgAttributes.actual_region_code || orgAttributes.legal_region_code,
            city: orgAttributes.actual_city || orgAttributes.legal_city,
            settlement: orgAttributes.actual_settlement || orgAttributes.legal_settlement,
            index: orgAttributes.actual_index || orgAttributes.legal_index,
            neighborhood: orgAttributes.actual_neighborhood || orgAttributes.legal_neighborhood,
            street: orgAttributes.actual_street || orgAttributes.legal_street,
            house: orgAttributes.actual_house || orgAttributes.legal_house,
            building: orgAttributes.actual_building || orgAttributes.legal_building,
            apartment: orgAttributes.actual_apartment || orgAttributes.legal_apartment,
          };
          setOrgAddress(orgAddressData);
        }
      })
    }
  };

  const changeVehicleInfo = (vehicleId) => {
    if (vehicleId) {
      service.getVehicle(vehicleId)
      .then((vehicleData) => {
        setVehicleInfo(vehicleData.json.data.attributes);
      })
    }
  };
  
  const changeDriverInfo = (driverId) =>
    service.getUser(driverId)
    .then((driverData) => 
      setDriverInfo(driverData.json.data.attributes));

  useEffect(() => {
    if (driverInfo && Object.keys(driverInfo).length > 0) {
      console.log(driverInfo.driving_license_date);
      if (driverInfo.driving_license_date === null || driverInfo.driving_license_date === "") {
        setDrivingLicenseDate("01.01.1900");
      }
      else {
        setDrivingLicenseDate(driverInfo.driving_license_date);
      }
      
    }
  }, [driverInfo]);

  if (rest.record.organization) {
    if (rest.record.organization.id && onlyOneRefresh) {
      changeValuesForOrg(rest.record.organization.id);
      setOnlyOneRefresh(false)
    }
  }
  


  return (
    <RestrictedSimpleForm className={styles.compactForm} {...rest}>
      <SelectInput
        label="telemedic.ewaybills.form.organization"
        choices={userOrganizations}
        optionText="name"
        optionValue="id"
        source="organization_id"
        defaultValue={defaultOrganizationId}
        valueType={ValueTypes.INTEGER}
        onChange={(event) => changeValuesForOrg(event.target.value)}
        {...getInputProps('organization_id')}
      />
      <FormDataConsumer>
        {
          ({ formData }) => formData.organization_id && (
            <TextInput
              label="telemedic.ewaybills.form.inn"
              source="organization_inn"
              defaultValue={orgInfo.inn}
              value={orgInfo.inn}
              {...getInputProps('organization_inn')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.organization_id && (
            <TextInput
              label="telemedic.ewaybills.form.kpp"
              source="organization_kpp"
              defaultValue={orgInfo.kpp}
              value={orgInfo.kpp}
              {...getInputProps('organization_kpp')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.organization_id && (
            <TextInput
              label="telemedic.ewaybills.form.ogrn"
              source="organization_ogrn"
              defaultValue={orgInfo.ogrn}
              value={orgInfo.ogrn}
              {...getInputProps('organization_ogrn')}
            />
          )
        }
      </FormDataConsumer>
      <h6>Адрес организации</h6>
      <TextInput
        label="telemedic.ewaybills.form.index"
        source="address_index"
        defaultValue={orgAddress.index}
        value={orgAddress.index}
        {...getInputProps('address_index')}
      />
      <TextInput
        label="telemedic.ewaybills.form.regionCode"
        source="address_region_code"
        defaultValue={orgAddress.region_code}
        value={orgAddress.region_code}
        {...getInputProps('address_region_code')}
      />
      <TextInput
        label="telemedic.ewaybills.form.city"
        source="address_city"
        defaultValue={orgAddress.city}
        value={orgAddress.city}
        {...getInputProps('address_city')}
      />
      <TextInput
        label="telemedic.ewaybills.form.street"
        source="address_street"
        defaultValue={orgAddress.street}
        value={orgAddress.street}
        {...getInputProps('address_street')}
      />
      <TextInput
        label="telemedic.ewaybills.form.home"
        source="address_home"
        defaultValue={orgAddress.house}
        value={orgAddress.house}
        {...getInputProps('address_home')}
      />
      <SelectInput
        label="telemedic.ewaybills.form.postshiftCheckup"
        choices={postshiftCheckupChoices}
        optionText="name"
        optionValue="id"
        source="postshift_checkup"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('postshift_checkup')}
      />
      <SelectInput
        label="telemedic.ewaybills.form.transportType"
        choices={transportTypeChoices}
        optionText="name"
        optionValue="id"
        source="transport_type"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('transport_type')}
      />
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && (
            <SelectInput
              label="telemedic.ewaybills.form.commercialTransportationType"
              choices={commercialTransportationTypeChoices}
              optionText="name"
              optionValue="id"
              source="commercial_transportation_type"
              valueType={ValueTypes.INTEGER}
              {...getInputProps('commercial_transportation_type')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <h6>Место отправления (Пункт осмотра)</h6>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.index"
              source="inspection_point_index"
              {...getInputProps('inspectionPoint_index')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.regionCode"
              source="inspection_point_region_code"
              {...getInputProps('inspection_point_region_code')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.city"
              source="inspection_point_city"
              {...getInputProps('inspection_point_city')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.street"
              source="inspection_point_street"
              {...getInputProps('inspection_point_street')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.home"
              source="inspection_point_home"
              {...getInputProps('inspection_point_home')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <h6>Адрес пункта погрузки</h6>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.index"
              source="loading_inspection_address_index"
              {...getInputProps('loading_inspection_address_index')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.regionCode"
              source="loading_inspection_address_region_code"
              {...getInputProps('loading_inspection_address_region_code')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.city"
              source="loading_inspection_address_city"
              {...getInputProps('loading_inspection_address_city')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.street"
              source="loading_inspection_address_street"
              {...getInputProps('loading_inspection_address_street')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.home"
              source="loading_inspection_address_home"
              {...getInputProps('loading_inspection_address_home')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <h6>Адрес пункта выгрузки</h6>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.index"
              source="unloading_inspection_address_index"
              {...getInputProps('unloading_inspection_address_index')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.regionCode"
              source="unloading_inspection_address_region_code"
              {...getInputProps('unloading_inspection_address_region_code')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.city"
              source="unloading_inspection_address_city"
              {...getInputProps('unloading_inspection_address_city')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.street"
              source="unloading_inspection_address_street"
              {...getInputProps('unloading_inspection_address_street')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.transport_type === transportType.commercialTransportation && formData.commercial_transportation_type === commercialTransportationType.cargoTransportation && (
            <TextInput
              label="telemedic.ewaybills.form.home"
              source="unloading_inspection_address_home"
              {...getInputProps('unloading_inspection_address_home')}
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        label="telemedic.ewaybills.form.infoAboutTypeTransportation"
        choices={infoAboutTypeTransportationChoices}
        optionText="name"
        optionValue="id"
        source="infoAbout_type_transportation"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('infoAbout_type_transportation')}
      />
      <SelectInput
        label="telemedic.ewaybills.form.waybillTerm"
        choices={WaybillTermChoices}
        optionText="name"
        optionValue="id"
        source="waybill_term"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('waybill_term')}
      />
      <FormDataConsumer>
        {
          ({ formData }) => formData.waybill_term === 1 && (
            <MaskedDateInput
              label="telemedic.ewaybills.form.waybillTermDate"
              source="waybill_term_date"
              {...getInputProps('waybill_term_date')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.waybill_term === 2 && (
            <MaskedDateInput
              label="telemedic.ewaybills.form.waybillTermDateBegin"
              source="waybill_term_date_begin"
              {...getInputProps('waybill_term_date_begin')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.waybill_term === 2 && (
            <MaskedDateInput
              label="telemedic.ewaybills.form.waybillTermDateEnd"
              source="waybill_term_date_end"
              {...getInputProps('waybill_term_date_end')}
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        label="telemedic.ewaybills.form.whoCreated"
        choices={whoCreatedChoices}
        optionText="name"
        optionValue="id"
        source="who_created"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('who_created')}
      />
      <SelectInput
        label="telemedic.ewaybills.form.driver"
        choices={userValues}
        optionText="name"
        optionValue="id"
        source="driver_id"
        onChange={(event) => changeDriverInfo(event.target.value)}
        valueType={ValueTypes.INTEGER}
        {...getInputProps('driver_id')}
      />
      
      
      
      <FormDataConsumer>
        {
          ({ formData }) => formData.driver_id && driverInfo ? (
            <TextInput
              label="telemedic.ewaybills.form.drivingLicenseNumber"
              source="driving_license_number"
              defaultValue={driverInfo.driving_license_number}
              value={driverInfo.driving_license_number}
              {...getInputProps('driving_license_number')}
            />
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.driver_id && (
            <TextInput
              label="telemedic.ewaybills.form.drivingLicenseSerial"
              source="driving_license_serial"
              {...getInputProps('driving_license_serial')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.driver_id && driving_license_date ? (
            <MaskedDateInput
              label="telemedic.ewaybills.form.drivingLicenseDate"
              source="driving_license_date"
              defaultValue={driving_license_date}
              value={driving_license_date}
              {...getInputProps('driving_license_date')}
            />
          ) : null
        }
      </FormDataConsumer>
      <SelectInput
        label="telemedic.ewaybills.form.vehicle"
        choices={vehicle}
        optionText="name"
        optionValue="id"
        source="vehicle_id"
        valueType={ValueTypes.INTEGER}
        onChange={(event) => changeVehicleInfo(event.target.value)}
        {...getInputProps('vehicle_id')}
      />
      <FormDataConsumer>
        {
          ({ formData }) => formData.vehicle_id && (
            <TextInput
              label="telemedic.ewaybills.form.vehicleType"
              source="vehicle_type"
              {...getInputProps('vehicle_type')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.vehicle_id && (
            <TextInput
              label="telemedic.ewaybills.form.vehicleMark"
              source="vehicle_mark"
              defaultValue={vehicleInfo.manufacturer}
              value={vehicleInfo.manufacturer}
              {...getInputProps('vehicle_mark')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.vehicle_id && (
            <TextInput
              label="telemedic.ewaybills.form.vehicleModel"
              source="vehicle_model"
              defaultValue={vehicleInfo.model}
              value={vehicleInfo.model}
              {...getInputProps('vehicle_model')}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {
          ({ formData }) => formData.vehicle_id && (
            <TextInput
              label="telemedic.ewaybills.form.vehicleRegNumber"
              source="vehicle_reg_number"
              defaultValue={vehicleInfo.car_license_plate}
              value={vehicleInfo.car_license_plate}
              {...getInputProps('vehicle_reg_number')}
            />
          )
        }
      </FormDataConsumer>
      <LargeCheckListInput
        label="telemedic.ewaybills.form.inspectionPoint"
        choiceLabelField="name"
        choiceValueField="id"
        choices={inspectionPoints}
        source="inspection_point_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('inspection_point_ids')}
      />
      <h6>Сведения о лице кто подписывает</h6>
      <SelectInput
        label="telemedic.ewaybills.form.worker"
        choices={workerValues}
        optionText="name"
        optionValue="id"
        source="significant_id"
        defaultValue={userId}
        valueType={ValueTypes.INTEGER}
        {...getInputProps('significant_id')}
      />
      <TextInput
        label="telemedic.ewaybills.form.fio"
        source="fio"
        defaultValue={fio}
        {...getInputProps('fio')}
      />
      <TextInput
        label="telemedic.ewaybills.form.jobTitle"
        source="job_title"
        defaultValue="Диспетчер"
        {...getInputProps('job_title')}
      />
    </RestrictedSimpleForm>
  );
};

export default EWaybillForm;
