import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from 'react-admin'

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import styles from './TableRowEmpty.module.css';

const TableRowEmpty = ({color, colSpan, text, textId}) => {
  const translate = useTranslate();
  const displayText = textId ? translate(textId) : text;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="subtitle1" className={styles.empty} color={color}>
          {displayText}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

TableRowEmpty.propTypes = {
  color: PropTypes.string,
  colSpan: PropTypes.number,
  text: PropTypes.string,
  textId: PropTypes.string
};

export default TableRowEmpty;
