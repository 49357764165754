import React from 'react';
import PropTypes from 'prop-types';

import {Form as FinalForm, FormSpy as FinalFormSpy} from 'react-final-form';
import {debounce} from 'lodash';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedSubmit = debounce(this.props.onSubmit, 1000);
  }

  componentWillUnmount() {
    this.debouncedSubmit.cancel();
  }

  render() {
    const {
      className, children, initialValues, onChange, onReset, onSubmit,
      submitOnChange, validate
    } = this.props;
    const formChildren = React.Children.toArray(children);
    if (onChange || submitOnChange) {
      formChildren.push(
        <FinalFormSpy
          key="formspy"
          subscription={{values: true}}
          onChange={this.onFormChange}/>
      );
    }
    return (
      <ResettableFinalFrom
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        onReset={onReset}
        render={
          ({values, handleSubmit, handleReset}) => (
            <form
              className={className}
              onSubmit={handleSubmit}
              onReset={handleReset}
              children={formChildren}/>
          )
        }/>
    );
  }

  onFormChange = ({values}) => {
    const {onChange, submitOnChange} = this.props;
    if (onChange) setTimeout(() => onChange(values));
    if (submitOnChange) this.debouncedSubmit(values);
  }
}

Form.propTypes = {
  initialValues: PropTypes.object,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  submitOnChange: PropTypes.bool
};

class ResettableFinalFrom extends React.Component {
  constructor(props) {
    super(props);
    this.form = null;
  }

  render() {
    const {render, ...rest} = this.props;
    return (
      <FinalForm render={this.renderForm} {...rest}/>
    );
  }

  renderForm = (props) => {
    this.form = props.form;
    return this.props.render({
      handleReset: this.handleReset,
      ...props
    })
  }

  handleReset = () => {
    const onReset = this.props.onReset;
    if (onReset) onReset(this.form);
  }
}

export default Form;
