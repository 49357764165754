import React, { useEffect, useMemo, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

import CheckupTitle from '../CheckupTitle';
import DropDownMenu from '../../../components/DropDownMenu';
import UserPhotoInput from '../../users/UserPhotoInput';
import styles from './CheckupShow.module.css';

import { CheckupResult, NonAdmissionReason } from '../../../constants';
import { Section, Action } from '../../../permissions';
import { getRutokenServiceInstance } from '../../../services/RutokenService';

const CheckupTitleWithControls = ({ record, checkupActions, showActionButtons }) => {
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  const epdEnabled = record.user && record.user.organization.epl_gis_epd_api;
  const [deviceAttached, setDeviceAttached] = useState(false);
  const [badHash, setBadHash] = useState(false);

  useEffect(() => {
    if (epdEnabled === true) {
      getRutokenServiceInstance().selectDevice()
        .then(() => setDeviceAttached(true))
        .catch(() => setDeviceAttached(false));
    }
  }, [epdEnabled]);

  useEffect(() => {
    const currentHash = record.terminal?.current_hash;
    const controlHash = record.terminal?.control_hash;
    const tamperSensor = record.terminal?.tamper_detected;
    
    setBadHash(tamperSensor);

    if (
      (currentHash || controlHash) && 
      (currentHash !== controlHash)
    ) {
      setBadHash(true);
    }
  }, [record]);

  const showReviewButtons = loaded
    && permissions.check(Section.CHECKUPS, Action.REVIEW)
    && record.result_medic === CheckupResult.AWAITING
    && (epdEnabled === false || deviceAttached === true);

  const showRepeatButton = record.user && record.user.organization.sett_repeats;

  const admitUser = () => {
    checkupActions.setFlagforEpl(record.user.organization.epl_gis_epd_api, record.user.organization.id, record.user.id);
    checkupActions.setPartnerEplFile(record.partner_epl_file);
    checkupActions.admitUser(record.id, record.result_auto, {
      onDone: () => window.history.back(),
    });
  };

  const notAdmitUser = () => {
    checkupActions.notAdmitUser(record.id, record.result_auto, {
      onDone: () => window.history.back(),
    });
  };

  const requestCheckupRepeat = () => {
    checkupActions.requestCheckupRepeat(record.id, {
      onDone: () => window.history.back(),
    });
  };

  const dropDownChoices = useMemo(() => {
    const choices = [];
    if (record.result_medic === CheckupResult.ADMITTED) {
      choices.push({
        id: 1,
        labelId: 'telemedic.checkups.toolbar.doctorVerdict',
        action: () => checkupActions.getDoctorVerdict(record.id),
      });
      choices.push({
        id: 6,
        labelId: 'telemedic.checkups.toolbar.checkupReport',
        action: () => checkupActions.getCheckupReport(record.id),
      });
      choices.push({
        id: 4,
        labelId: 'telemedic.checkups.toolbar.QRCode',
        action: () => checkupActions.getQRCode(record.id),
      });
      choices.push({
        id: 5,
        labelId: 'telemedic.checkups.toolbar.taxiWaybill',
        action: () => checkupActions.getTaxiWaybill(record.id),
      });
    }
    if (record.terminal && record.result_medic === CheckupResult.NOT_ADMITTED) {
      choices.push({
        id: 1,
        labelId: 'telemedic.checkups.toolbar.doctorVerdict',
        action: () => checkupActions.getDoctorVerdict(record.id),
      });
      choices.push({
        id: 6,
        labelId: 'telemedic.checkups.toolbar.checkupReport',
        action: () => checkupActions.getCheckupReport(record.id),
      });
      choices.push({
        id: 4,
        labelId: 'telemedic.checkups.toolbar.QRCode',
        action: () => checkupActions.getQRCode(record.id),
      });
      choices.push({
        id: 2,
        labelId: 'telemedic.checkups.toolbar.nonAdmissionPaper',
        action: () => checkupActions.getNonAdmissionPaper(record.id),
      });
    }
    if (record.terminal && record.non_admission_reasons.includes(NonAdmissionReason.ALCOHOL_ABNORMAL)) {
      choices.push({
        id: 3,
        labelId: 'telemedic.checkups.toolbar.sobrietyProtocol',
        action: () => checkupActions.getSobrietyProtocol(record.id),
      });
    }
    if (record.result_medic !== CheckupResult.AWAITING &&
      record.result_medic !== CheckupResult.ADMITTED &&
      record.result_medic !== CheckupResult.NOT_ADMITTED) {
      choices.push({
        id: 4,
        labelId: 'telemedic.checkups.toolbar.QRCode',
        action: () => checkupActions.getQRCode(record.id),
      });
    }
    return choices;
  }, [checkupActions, record]);

  const showDropDownButton = loaded && permissions.check(Section.CHECKUPS, Action.READ) && dropDownChoices.length > 0;

  return (
    <>
      <CheckupTitle record={record} />
      {showReviewButtons && !badHash && (
        <>
          <Button
            className={styles.admissionButton}
            variant="contained"
            color="primary"
            onClick={admitUser}
          >
            {translate('telemedic.actions.admit')}
          </Button>
          <Button
            className={styles.nonAdmissionButton}
            variant="contained"
            onClick={notAdmitUser}
          >
            {translate('telemedic.actions.notAdmit')}
          </Button>
        </>
      )}
      {showReviewButtons && badHash && (<span style={{ color: 'red' }}>Внимание: Нарушена целостность КАП !!!</span>)}
      {showReviewButtons && showRepeatButton && !badHash && (
        <Button
          className={styles.repeatButton}
          variant="contained"
          color="primary"
          onClick={requestCheckupRepeat}
        >
          {translate('telemedic.actions.repeat')}
        </Button>
      )}
      {showDropDownButton && (
        <DropDownMenu
          choices={dropDownChoices}
          icon={<PrintIcon />}
          labelId="telemedic.actions.print"
        />
      )}
      <UserPhotoInput
        label="telemedic.actions.uploadPhoto"
        record={record}
        userIdSource="user.id"
        userPhotoSource="media.user"
      />
    </>
  );
};

export default CheckupTitleWithControls;
