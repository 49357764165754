import React from 'react';

import TextField from '@material-ui/core/TextField';

const FormTextField = ({
  meta: {touched, error} = {touched: false, error: undefined},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

export default FormTextField;
