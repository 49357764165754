import React from 'react';
import {useInput, useTranslate} from 'react-admin';

import {DatePicker} from '@material-ui/pickers';

import FormHelperTextSpan from '../FormHelperTextSpan';
import ResettableTextField from '../ResettableTextField';
import {DISPLAY_DATE_FORMAT} from '../../constants';
import {formatApiDate} from '../../utils/formatters';

const DateInput = (props) => {
  const {
    className,
    helperText,
    inputProps,
    label,
    minWidth,
    margin = 'dense',
    required,
    valueFormatter = formatApiDate,
    variant = 'filled'
  } = props;
  const {
    meta: {touched, error},
    input: {onChange, value, ...inputRest},
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  let dateInputProps;
  if (minWidth) {
    dateInputProps = {
      style: minWidthStyle,
      ...inputProps
    }
  }
  else {
    dateInputProps = inputProps;
  }
  const handleChange = (value) => {
    onChange(
      valueFormatter(value.set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}))
    );
  }
  const handleReset = (event) => {
    event.stopPropagation();
    onChange(null);
  }
  return (
    <DatePicker
      autoOk={true}
      className={className}
      error={!!(touched && error)}
      format={DISPLAY_DATE_FORMAT}
      helperText={
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}/>
      }
      InputLabelProps={{
        required: required || isRequired
      }}
      inputProps={dateInputProps}
      inputVariant={variant}
      label={labelText}
      margin={margin}
      onChange={handleChange}
      onReset={handleReset}
      required={required}
      resettable={!(required || isRequired)}
      TextFieldComponent={ResettableTextField}
      value={value || null}
      {...inputRest}/>
  );
}

const minWidthStyle = {
  width: 120
}

export default DateInput;
