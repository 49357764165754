import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { RadioButtonGroupInput, SelectInput, Title } from 'react-admin';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import TZDateInput from '../../components/inputs/TZDateInput';
import styles from './TechInspectionJournalsPage.module.css';
import useAsyncValue from '../../hooks/useAsyncValue';
import useTelemedicService from '../../hooks/useTelemedicService';

const DOCX = 'docx';
const PDF = 'pdf';
const JOURNAL_TYPES = [
  { id: 1, name: 'telemedic.techInspectionJournals.form.departureJournal' },
  { id: 2, name: 'telemedic.techInspectionJournals.form.arrivalJournal' },
];
const JOURNAL_FORMATS = [
  { id: DOCX, name: 'telemedic.techInspectionJournals.form.getDOCX' },
  { id: PDF, name: 'telemedic.techInspectionJournals.form.getPDF' },
];
const REQUIRED_FIELDS = ['periodFrom', 'periodTo', 'organizationId', 'journalFormat'];

const TechInspectionJournalsPage = () => {
  const service = useTelemedicService();
  const [organizations] = useAsyncValue(
    () => service.getTechInspectionJournalFormOrganizationsData(), null, [],
  );
  const [formValues, setFormValues] = useState({});
  const [inspectionPoints] = useAsyncValue(
    (organizationId) => (
      service.getTechInspectionJournalFormInspectionPointsData(organizationId)
    ), formValues.organizationId, [],
  );
  const initialValues = useMemo(() => {
    const monthAgo = moment().subtract(1, 'months');
    const periodFrom = monthAgo.clone().startOf('month');
    const periodTo = monthAgo.clone().endOf('month');
    const journalFormat = DOCX;
    return { periodFrom, periodTo, journalFormat };
  }, []);
  const validate = useCallback((formData) => {
    const errors = {};
    REQUIRED_FIELDS.forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  }, []);
  const [submissionProgress, setSubmissionProgress] = useState(false);
  const submit = useCallback((formData) => {
    let journalPromise;
    if (formData.journalFormat === DOCX) {
      journalPromise = service.getTechInspectionJournal(formData);
    } else if (formData.journalFormat === PDF) {
      journalPromise = service.getTechInspectionJournalPdf(formData);
    }
    setSubmissionProgress(true);
    journalPromise.then(
      () => setSubmissionProgress(false),
    ).catch(
      (e) => {
        console.log(e);
        setSubmissionProgress(false);
      },
    );
  }, [service]);
  return (
    <div>
      <Title title="telemedic.techInspectionJournals.label" />
      <Paper>
        <Form
          initialValues={initialValues}
          validate={validate}
          onChange={setFormValues}
          onSubmit={submit}
        >
          <FormContent>
            <TZDateInput
              className={styles.formControl}
              label="telemedic.techInspectionJournals.form.periodFrom"
              required
              source="periodFrom"
            />
            <TZDateInput
              className={styles.formControl}
              isPeriodEnd
              label="telemedic.techInspectionJournals.form.periodTo"
              required
              source="periodTo"
            />
            <AutocompleteInput
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              className={styles.formControl}
              label="telemedic.techInspectionJournals.form.organization"
              required
              source="organizationId"
            />
            <AutocompleteInput
              choices={inspectionPoints}
              choiceLabelField="name"
              choiceValueField="id"
              className={styles.formControl}
              label="telemedic.techInspectionJournals.form.inspectionPoint"
              source="inspectionPointId"
            />
            <SelectInput
              choices={JOURNAL_TYPES}
              className={styles.formControl}
              label="telemedic.techInspectionJournals.form.journalType"
              required
              source="journalType"
            />
            <RadioButtonGroupInput
              className={styles.formControl}
              source="journalFormat"
              label="telemedic.techInspectionJournals.form.journalFormat"
              required
              choices={JOURNAL_FORMATS}
            />
          </FormContent>
          <FormFooter>
            <ProgressButton
              color="primary"
              label="telemedic.actions.send"
              progress={submissionProgress}
              type="submit"
              variant="contained"
            />
          </FormFooter>
        </Form>
      </Paper>
    </div>
  );
};

export default TechInspectionJournalsPage;
