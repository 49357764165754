import React from 'react';
import {
  SimpleShowLayout, TextField,
} from 'react-admin';

import TestQuestionCategoryTitle from './TestQuestionCategoryTitle';
import RestrictedShow from '../../components/admin/RestrictedShow';

const TestQuestionCategoryShow = (props) => (
  <RestrictedShow title={<TestQuestionCategoryTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="telemedic.testQuestionCategory.show.category" source="category" />
    </SimpleShowLayout>
  </RestrictedShow>
);

export default TestQuestionCategoryShow;
