import React from 'react';
import {
  Filter, Datagrid, TextField, TextInput,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import { Role } from '../../constants';
import { formatPersonName } from '../../utils/formatters';

const directorsDataPromise = (service) => service.getWorkerValues(Role.MED_ORG_DIRECTOR);

const partnersDataPromise = (service) => service.getWorkerValues(Role.PARTNER);

const MedOrganizationList = (props) => (
  <RestrictedList
    title="telemedic.medOrganizations.label"
    filters={<MedOrganizationFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <TextField
        label="telemedic.medOrganizations.list.inn"
        source="inn"
        sortable
      />
      <TextField
        label="telemedic.medOrganizations.list.name"
        source="name"
        sortable
      />
      <TextField
        label="telemedic.medOrganizations.list.phone"
        source="phone"
        sortable={false}
      />
      <FormatValueField
        label="telemedic.medOrganizations.list.director"
        source="director"
        sortable
        formatter={formatPersonName}
      />
      <FormatValueField
        label="telemedic.medOrganizations.list.partner"
        source="partner"
        sortable
        formatter={formatPersonName}
      />
    </Datagrid>
  </RestrictedList>
)

const MedOrganizationFilter = (props) => (
  <Filter key="med_organization_filter" {...props}>
    <TextInput
      label="telemedic.medOrganizations.list.name"
      source="name"
      alwaysOn
      resettable
    />
    <TelemedicDataSource
      dataPromise={directorsDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.medOrganizations.list.director"
        choiceLabelField="name"
        choiceValueField="id"
        source="director_id"
      />
    </TelemedicDataSource>
    <TelemedicDataSource
      dataPromise={partnersDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.medOrganizations.list.partner"
        choiceLabelField="name"
        choiceValueField="id"
        source="partner_id"
      />
    </TelemedicDataSource>
  </Filter>
);

export default MedOrganizationList;
