import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useInput, useTranslate} from 'react-admin';

import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField'

import FormHelperTextSpan from '../FormHelperTextSpan';

const MaskedTextInput = (props) => {
  const {
    className,
    helperText,
    label,
    margin = 'dense',
    mask,
    required,
    valueFormat,
    valueParse,
    variant = 'filled'
  } = props;
  const {
    meta: {touched, error},
    input: {onBlur, onFocus, onChange, value},
    isRequired
  } = useInput(props);
  const initialDisplayValue = useMemo(() => {
    return valueFormat(value);
  }, [value, valueFormat]);
  const [displayValue, setDisplayValue] = useState(initialDisplayValue);
  const [parseError, setParseError] = useState();
  const handleChange = useCallback((event) => {
    const inputValue = event.currentTarget.value;
    setDisplayValue(inputValue);
    const {parseError, parsedValue} = valueParse(inputValue);
    onChange(parseError ? null : parsedValue);
    setParseError(parseError);
  }, [onChange, setDisplayValue, valueParse]);
  const displayError = parseError || error;
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  return (
    <InputMask
      mask={mask}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={displayValue}
    >
      <TextField
        className={className}
        error={!!(touched && displayError)}
        helperText={
          <FormHelperTextSpan
            error={displayError}
            helperText={helperText}
            touched={touched}
            translate={translate}/>
        }
        InputLabelProps={{
          required: required || isRequired
        }}
        label={labelText}
        margin={margin}
        variant={variant}/>
    </InputMask>
  );
}

MaskedTextInput.propTypes = {
  className: PropTypes.string,
  cleanValue: PropTypes.func,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  label: PropTypes.string,
  margin: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  required: PropTypes.bool,
  valueFormat: PropTypes.func,
  valueParse: PropTypes.func,
  variant: PropTypes.string
};

export default MaskedTextInput;
