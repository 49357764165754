import React from 'react';
import {
  Filter, Datagrid, TextField, SelectInput, TextInput, BooleanField, BooleanInput,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import { formatDisplayDateTime, formatRole } from '../../utils/formatters';

const WorkerList = (props) => {
  const organizationsCountFormatter = (organizations) => {
    if (organizations) return organizations.length;
    return 0;
  };

  return (
    <RestrictedList
      title="telemedic.workers.label"
      filters={<WorkerFilter />}
      sort={{ field: 'login', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <FormatValueField
          label="telemedic.workers.list.role"
          source="role"
          sortable={false}
          formatter={formatRole}
        />
        <TextField
          label="telemedic.workers.list.login"
          source="login"
          sortable
        />
        <TextField
          label="telemedic.workers.list.lastName"
          source="last_name"
          sortable
        />
        <TextField
          label="telemedic.workers.list.firstName"
          source="first_name"
          sortable
        />
        <TextField
          label="telemedic.workers.list.middleName"
          source="middle_name"
          sortable
        />
        <FormatValueField
          label="telemedic.workers.list.organizationsCount"
          source="organizations"
          sortable={false}
          formatter={organizationsCountFormatter}
        />
        <BooleanField
          label="telemedic.workers.list.isFired"
          source="is_fired"
          sortable
        />
        <FormatValueField
          label="telemedic.workers.list.createdAt"
          source="datetime_created"
          sortable
          formatter={formatDisplayDateTime}
        />
        <FormatValueField
          label="telemedic.workers.list.lastAuth"
          source="last_auth"
          sortable
          formatter={formatDisplayDateTime}
        />
      </Datagrid>
    </RestrictedList>
  );
};

const WorkerFilter = (props) => {
  const service = useTelemedicService();
  const roles = useTelemedicServiceJsonValue(
    () => service.getRoleValues(), null, [],
  );
  const organizations = useTelemedicServiceJsonValue(
    (organizationId) => service.getOrganizationValues(), null, [],
  );
  return (
    <Filter key="worker_filter" {...props}>
      <SelectInput
        label="telemedic.workers.list.role"
        choices={roles}
        source="role"
        alwaysOn
        resettable
      />
      <TextInput
        label="telemedic.workers.list.login"
        source="login"
        alwaysOn
        resettable
      />
      <TextInput
        label="telemedic.workers.list.anyName"
        source="name"
        alwaysOn
        resettable
      />
      <AutocompleteInput
        label="telemedic.workers.list.organization"
        choiceLabelField="name"
        choiceValueField="id"
        choices={organizations}
        source="organization_id"
        alwaysOn
        resettable
      />
      <BooleanInput
        label="telemedic.workers.list.showFired"
        source="is_fired"
        resettable
      />
      <BooleanInput
        label="telemedic.workers.list.showEsiaRegistered"
        source="is_esia_registered"
        resettable
      />
      <BooleanInput
        label="telemedic.workers.list.showEsiaAllowed"
        source="is_esia_allowed"
        resettable
      />
    </Filter>
  );
};

export default WorkerList;
