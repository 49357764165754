import { useEffect } from 'react';

const useInterval = (callback, period, startImmediately) => {
  useEffect(() => {
    if (startImmediately) callback();
    const timerId = setInterval(callback, period);
    return () => clearInterval(timerId);
  }, [callback, period, startImmediately]);
};

export default useInterval;
