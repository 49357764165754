import React from 'react';

import ImageField from '../../../components/fields/ImageField';
import VideoField from '../../../components/fields/VideoField';
import UserPhotoUploadInput from './UserPhotoUploadInput';
import styles from './CheckupShow.module.css';

const UserMedia = ({ record }) => {
  if (!record.media) {
    return null;
  }

  return (
    <div className={styles.mediaColumn}>
      {
        record.media.uvideo_profile && (
          <VideoField
            record={record}
            source="media.uvideo_profile"
            autoplay
            loop
            optional
          />
        )
      }
      {
        record.media.uvideo && (
          <VideoField
            label="telemedic.checkups.show.checkupVideo"
            record={record}
            source="media.uvideo"
            autoplay
            loop
            optional
          />
        )
      }
      {
        record.media.uvideo_pupill && (
          <VideoField
            record={record}
            source="media.uvideo_pupill"
            autoplay
            loop
            optional
          />
        )
      }
      <div className={styles.mediaColumnPreviews}>
        <ImageField
          label="telemedic.checkups.show.userPhoto"
          record={record}
          source="media.user"
        />
        <div className={styles.mediaContainer}>
          <ImageField
            label="telemedic.checkups.show.checkupPhoto"
            record={record}
            source="media.uphoto"
          />
          <div className={styles.mediaButtonContainer}>
            <UserPhotoUploadInput
              label="telemedic.checkups.show.addToProfile"
              record={record}
              userIdSource="user.id"
              userPhotoSource="media.uphoto"
            />
          </div>
        </div>
        <ImageField
          label="telemedic.checkups.show.medCertificate"
          record={record}
          source="media.user_med_certificate"
        />
        <ImageField
          label="telemedic.checkups.show.mouth"
          record={record}
          source="media.mouth"
          optional
        />
        <ImageField
          label="telemedic.checkups.show.eyes"
          record={record}
          source="media.eyes"
          optional
        />
        <ImageField
          label="telemedic.checkups.show.elbow"
          record={record}
          source="media.elbow"
          optional
        />
        <ImageField
          label="telemedic.checkups.show.flightsCertificateFront"
          record={record}
          source="media.flights_certificate_front"
          optional
        />
        <ImageField
          label="telemedic.checkups.show.flightsCertificateBack"
          record={record}
          source="media.flights_certificate_back"
          optional
        />
      </div>
    </div>
  );
};

export default UserMedia;
