import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextInput, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Form from '../../../components/Form';
import ProgressButton from '../../../components/ProgressButton';
import styles from '../LoginStyles.module.css';

const PasswordForm = ({ onSubmit, onCancel, submissionProgress }) => {
  const translate = useTranslate();
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'telemedic.validation.required';
    }
    if (!values.confirm) {
      errors.confirm = 'telemedic.validation.required';
    }
    if (values.password !== values.confirm) {
      errors.confirm = 'telemedic.validation.passwordMismatch';
    }
    return errors;
  }, []);
  return (
    <Form
      className={styles.form}
      onSubmit={onSubmit}
      validate={validate}
    >
      <Typography variant="h6">
        {translate('telemedic.passwordReset.newPassword')}
      </Typography>
      <TextInput
        className={styles.largeInput}
        fullWidth
        helperText={false}
        label="telemedic.passwordReset.password"
        margin="normal"
        name="password"
        type="password"
        variant="outlined"
      />
      <TextInput
        className={styles.largeInput}
        fullWidth
        helperText={false}
        label="telemedic.passwordReset.confirm"
        margin="normal"
        name="confirm"
        type="password"
        variant="outlined"
      />
      <ProgressButton
        className={styles.control}
        color="primary"
        fullWidth
        label="telemedic.passwordReset.setPassword"
        progress={submissionProgress}
        type="submit"
        variant="contained"
      />
      <Button
        className={styles.control}
        color="primary"
        fullWidth
        onClick={onCancel}
        type="button"
      >
        {translate('telemedic.actions.goBack')}
      </Button>
    </Form>
  );
};

PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submissionProgress: PropTypes.bool.isRequired,
};

export default PasswordForm;
