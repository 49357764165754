import React from 'react';
import {
  Datagrid, TextField,
} from 'react-admin';

import RestrictedList from '../../components/admin/RestrictedList';
import FormatField from '../../components/fields/FormatField';
import FormatValueField from '../../components/fields/FormatValueField';
import { formatDisplayDate } from '../../utils/formatters';

const formatPersonName = (data) => {
  const person = data.user;
  if (person) return `${person.last_name} ${person.first_name} ${person.middle_name}`;
  return '';
};

const TestResultList = (props) => (
  <RestrictedList
    title="telemedic.testResults.label"
    sort={{ field: 'created_at', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <FormatField
        label="telemedic.testResults.list.employee"
        formatter={formatPersonName}
      />
      <TextField
        label="telemedic.testResults.list.organization"
        source="user.organization.name"
        sortable
      />
      <TextField
        label="telemedic.testResults.list.numberOfQuestions"
        source="number_of_questions"
        sortable
      />
      <TextField
        label="telemedic.testResults.list.numberOfCorrectAnswers"
        source="number_of_correct_answers"
        sortable
      />
      <FormatValueField
        label="telemedic.testResults.list.createdAt"
        source="created_at"
        formatter={formatDisplayDate}
      />
    </Datagrid>
  </RestrictedList>
);

export default TestResultList;
