import React from 'react';
import {
  Filter, Datagrid, TextField, useTranslate,
} from 'react-admin';

import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Tooltip from '@material-ui/core/Tooltip';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {
  formatApiDateTime, formatCheckupResult, formatDisplayDateTime,
  formatPersonName, formatTechInspectionResult,
} from '../../utils/formatters';

const WaybillList = (props) => (
  <RestrictedList
    title="telemedic.waybills.label"
    filters={<WaybillFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <FormatValueField
        label="telemedic.waybills.list.datetime"
        source="datetime"
        sortable
        formatter={formatDisplayDateTime}
      />
      <TextField
        label="telemedic.waybills.list.vehicle"
        source="vehicle.car_license_plate"
        sortable
        sortBy="vehicle"
      />
      <TextField
        label="telemedic.waybills.list.inspectionPoint"
        source="inspection_point.name"
        sortable
        sortBy="inspection_point"
      />
      <FormatValueField
        label="telemedic.waybills.list.medic"
        source="checkup.medic"
        sortable={false}
        formatter={formatPersonName}
      />
      <FormatValueField
        label="telemedic.waybills.list.checkupResult"
        source="checkup.result_medic"
        sortable={false}
        formatter={formatCheckupResult}
      />
      <FormatValueField
        label="telemedic.waybills.list.mechanic"
        source="tech_inspection.mechanic"
        sortable={false}
        formatter={formatPersonName}
      />
      <FormatValueField
        label="telemedic.waybills.list.techInspectionResult"
        source="tech_inspection.result"
        sortable={false}
        formatter={formatTechInspectionResult}
      />
      <SyncStatusField />
    </Datagrid>
  </RestrictedList>
);

const WaybillFilter = (props) => {
  const service = useTelemedicService();
  const vehicles = useTelemedicServiceJsonValue(
    () => service.getVehicleValues(null), null, [],
  );
  const inspectionPoints = useTelemedicServiceJsonValue(
    () => service.getInspectionPointValues(null), null, [],
  );
  return (
    <Filter key="waybill_filter" {...props}>
      <DateInput
        label="telemedic.waybills.list.periodFrom"
        source="datetime_from"
        valueFormatter={formatApiDateTime}
        alwaysOn
        minWidth
        resettable
      />
      <DateInput
        label="telemedic.waybills.list.periodTo"
        source="datetime_to"
        valueFormatter={formatApiDateTime}
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.waybills.list.vehicle"
        choices={vehicles}
        choiceLabelField="name"
        choiceValueField="id"
        source="vehicle_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.waybills.list.inspectionPoint"
        choices={inspectionPoints}
        choiceLabelField="name"
        choiceValueField="id"
        source="inspection_point_id"
        alwaysOn
        minWidth
        resettable
      />
    </Filter>
  );
};

const SyncStatusField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    if (record.error) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={record.error}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    }
    if (record.kis_art_id) {
      const formattedTime = record.sent_at ? formatDisplayDateTime(record.sent_at) : '-'
      const sentAt = `${translate('telemedic.waybills.list.sentAt')}: ${formattedTime}`;
      return (
        <Tooltip disableFocusListener disableTouchListener title={sentAt}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

export default WaybillList;
