import React, {useCallback, useMemo, useState} from 'react';
import {
  BooleanInput, Notification, TextInput, Title, useTranslate
} from 'react-admin';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FilterFormContent from '../../components/FilterFormContent';
import Form from '../../components/Form';
import IndexedList from '../../utils/IndexedList';
import Spacer from '../../components/Spacer';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import TableRow from '../../components/tables/TableRow';
import useSyncInterval from '../../hooks/useSyncInterval';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import withSoundNotification from '../../hocs/withSoundNotification';

import {TechInspectionResult} from '../../constants';
import {RoutePath, navigateTo} from '../../routes';
import {
  formatApiDateTime, formatDisplayDateTime, formatPersonName, formatTechInspectionResult, formatTechInspectionType
} from '../../utils/formatters';
import {parseQueryParams, serializeQueryParams} from '../../utils/urls';

import moment from 'moment';

import styles from './TechInspectionLivePage.module.css';
import sounds from '../../sounds';

const DEFAULT_FILTERS = {
  organizationId: '',
  driverName: '',
  carLicensePlate: '',
  awaiting: false
}

const _TechInspectionLivePage = ({playSound}) => {
  const translate = useTranslate();

  const urlFilters = useMemo(() => {
    const queryParams = parseQueryParams(window.location.href.split('?')[1]);
    if (queryParams && queryParams.filters) {
      return JSON.parse(queryParams.filters)
    }
    return DEFAULT_FILTERS;
  }, []);
  const [filters, setFilters] = useState(urlFilters);
  const isFilterSet = (filters !== DEFAULT_FILTERS);
  const handleFiltersChange = useCallback((filters) => {
    setFilters(isAnyFilterSet(filters) ? filters : DEFAULT_FILTERS);
    const filtersJson = JSON.stringify(filters);
    window.location.href = window.location.href.split('?')[0] + '?' + serializeQueryParams({filters: filtersJson});
  }, []);
  const handleFiltersReset = useCallback((form) => {
    form.reset(DEFAULT_FILTERS);
  }, []);

  const [indexedTechInspections, setIndexedTechInspections] = useState(null);
  const [listError, setListError] = useState(null);
  const service = useTelemedicService();
  const initCallback = useCallback((dateCallback) => {
    const dateFrom = formatApiDateTime(moment().subtract(1, 'days'));
    return service.getTechInspections({
      dateFrom: dateFrom,
      organizationId: filters.organizationId,
      driverName: filters.driverName,
      carLicensePlate: filters.carLicensePlate,
      sort: '-created_at'
    }).then((data) => {
      setIndexedTechInspections(new IndexedList(data.json.data));
      dateCallback(dateFrom, data.json.sync_dt);
    }).catch((e) => {
      console.log(e);
      setIndexedTechInspections(null);
      let errorText = e.errorDescription || translate('telemedic.errors.fetchError');
      setListError(errorText);
    });
  }, [filters, service, translate]);
  const notificationSound = useMemo(() => {
    return new Audio(sounds.notification);
  }, []);
  const disconnectedSound = useMemo(() => {
    return new Audio(sounds.disconnected);
  }, []);
  const [updateError, setUpdateError] = useState(null);
  const updateCallback = useCallback((dateFrom, syncDate, dateCallback) => {
    return service.getTechInspectionsUpdates({
      createdFrom: dateFrom,
      updatedFrom: syncDate,
      organizationId: filters.organizationId,
      driverName: filters.driverName,
      carLicensePlate: filters.carLicensePlate
    }).then((data) => {
      const [updatedTechInspections, newTechInspections] = indexedTechInspections.getUpdated(data.json.data);
      setIndexedTechInspections(updatedTechInspections);
      setUpdateError(null);
      dateCallback(data.json.sync_dt);
      for (let newTechInspection of newTechInspections) {
        if (newTechInspection.attributes.result === TechInspectionResult.AWAITING) {
          playSound(notificationSound);
          break;
        }
      }
    }).catch((e) => {
      console.log(e);
      if (!updateError) {
        playSound(disconnectedSound);
        let errorText = e.errorDescription || translate('telemedic.errors.fetchError');
        setUpdateError(translate('telemedic.techInspectionsLive.list.listUpdateError') + '. ' + errorText);
      }
    });
  }, [disconnectedSound, indexedTechInspections, filters, notificationSound, playSound, service, translate, updateError]);
  useSyncInterval(initCallback, updateCallback, 10000);

  const techInspections = useMemo(() => {
    if (indexedTechInspections) {
      if (filters.awaiting) {
        return indexedTechInspections.getList().filter((item) => {
          return item.attributes.result === TechInspectionResult.AWAITING;
        });
      }
      return indexedTechInspections.getList();
    }
    return null;
  }, [filters.awaiting, indexedTechInspections]);
  return (
    <div>
      <Title title="telemedic.techInspectionsLive.label"/>
      <TechInspectionFilters
        filters={filters}
        initial={urlFilters}
        isFilterSet={isFilterSet}
        onChange={handleFiltersChange}
        onReset={handleFiltersReset}
        service={service}
        translate={translate}
      />
      <Paper>
        <TechInspectionTable
          techInspections={techInspections}
          error={listError}
          isFilterSet={isFilterSet}
          translate={translate}/>
      </Paper>
      <Notification
        message={updateError}
        open={!!updateError}
        type="error"
      />
    </div>
  );
}

const TechInspectionFilters = ({
  filters, initial, isFilterSet, onChange, onReset, service, translate
}) => {
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getOrganizationValues();
  }, null, []);
  return (
    <Toolbar variant="regular" disableGutters={true}>
      <Form
        initialValues={initial}
        onSubmit={onChange}
        onReset={onReset}
        submitOnChange={true}
      >
        <FilterFormContent>
          <AutocompleteInput
            name="organizationId"
            label="telemedic.techInspectionsLive.list.organization"
            choices={organizations}
            choiceLabelField="name"
            choiceValueField="id"
            helperText={false}
            resettable/>
          <Spacer/>
          <TextInput
            name="driverName"
            label="telemedic.techInspectionsLive.list.driver"
            helperText={false}
            resettable/>
          <Spacer/>
          <TextInput
            name="carLicensePlate"
            label="telemedic.techInspectionsLive.list.vehicle"
            helperText={false}
            resettable/>
          <Spacer/>
          <BooleanInput
            name="awaiting"
            label={translate('telemedic.techInspectionsLive.list.awaitingOnly')}
            helperText={false}/>
          <Spacer fill={true}/>
          {isFilterSet &&
            <Button
              type="reset"
              color="primary"
            >
              {translate('telemedic.techInspectionsLive.list.reset')}
            </Button>
          }
        </FilterFormContent>
      </Form>
    </Toolbar>
  );
}

const TechInspectionTable = ({
  techInspections, error, isFilterSet, translate
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.organization')}</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.vehicle')}</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.driver')}</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.type')}</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.datetime')}</TableCell>
            <TableCell>{translate('telemedic.techInspectionsLive.list.result')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={7}
            data={techInspections}
            emptyTextId={isFilterSet
              ? 'telemedic.techInspectionsLive.list.noResults'
              : 'telemedic.techInspectionsLive.list.noTechInspections'
            }
            error={!!error}
            errorText={error}
            placeholderRows={5}
            Row={TechInspectionRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TechInspectionRow = ({item}) => {
  const {id, attributes} = item;
  const handleRowClick = useCallback(() => {
    navigateTo(RoutePath.TECH_INSPECTION_SHOW, {id: id});
  }, [id]);
  return (
    <TableRow
      className={getRowClassName(item)}
      key={id}
      onClick={handleRowClick}
      clickable hover
    >
      <TableCell>{id}</TableCell>
      <TableCell>{attributes.organization.name}</TableCell>
      <TableCell>{attributes.vehicle.car_license_plate}</TableCell>
      <TableCell>{formatPersonName(attributes.driver)}</TableCell>
      <TableCell>{formatTechInspectionType(attributes.type_of_inspection)}</TableCell>
      <TableCell>{formatDisplayDateTime(attributes.date)}</TableCell>
      <TableCell>{formatTechInspectionResult(attributes.result)}</TableCell>
    </TableRow>
  );
}

const isAnyFilterSet = (filters) => {
  for (var key of Object.keys(filters)) {
    if (filters[key]) {
      return true;
    }
  }
  return false;
}

const getRowClassName = ({attributes}) => {
  if (attributes.result === TechInspectionResult.AWAITING) {
    return styles.awaiting;
  }
  return null;
}

export const TechInspectionLivePage = withSoundNotification(_TechInspectionLivePage);
