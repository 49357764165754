import React from 'react';
import {SimpleShowLayout, TextField} from 'react-admin';

import DealersResponsibleTitle from './DealersResponsibleTitle';
import RestrictedShow from '../../components/admin/RestrictedShow';

export const DealersResponsibleShow = (props) => {
  return (
    <RestrictedShow title=<DealersResponsibleTitle/> {...props}>
      <SimpleShowLayout>
        <TextField label="telemedic.dealersResponsible.show.name" source="name"/>
        <TextField label="telemedic.dealersResponsible.show.comment" source="comment"/>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
