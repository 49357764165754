import React from 'react';
import { useTranslate } from 'react-admin';

import { formatPersonName } from '../../utils/formatters';

const TestResultTitle = ({ record }) => {
  const label = useTranslate()('telemedic.testResults.show.label');
  return (
    <span>{(record && record.user) ? `${label} - ${formatPersonName(record.user)}` : label}</span>
  );
};

export default TestResultTitle;
