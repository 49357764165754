import * as React from "react"

function Pulse(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 40 40"
      {...props}
    >
      <path d="M11.83 1.635A11.18 11.18 0 00.65 12.815a11.18 11.18 0 00.343 2.681h9.095l.767-2.56.8.038 1.124 5.616L17.06 5.74l4.242 21.22 4.183-15.329 1.68 5.045 1.652-1.18H38.96a11.18 11.18 0 00.342-2.68 11.18 11.18 0 00-11.181-11.18 11.18 11.18 0 00-8.142 3.538 11.18 11.18 0 00-8.15-3.538zm5.03 7.316L12.58 21.797l-1.408-7.039-.47 1.564H1.227a11.18 11.18 0 002.18 3.834H3.33l8.235 8.632c4.53 4.747 8.265 8.61 8.302 8.582.037-.028 3.822-3.912 8.41-8.632l8.343-8.582h-.083a11.18 11.18 0 002.194-3.834h-9.648l-2.345 1.675-1.174-3.52-3.924 14.388-.803-.03z" />
    </svg>
  )
}

export default Pulse
