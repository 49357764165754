import React from 'react';
import {
  Filter, Datagrid, TextField, TextInput, BooleanInput, BooleanField,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import { formatDisplayDate } from '../../utils/formatters';

const UserList = (props) => (
  <RestrictedList
    title="telemedic.users.label"
    filters={<UserFilter />}
    sort={{ field: 'personnel_number', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="telemedic.users.list.personnelNumber"
        source="personnel_number"
        sortable
      />
      <TextField
        label="telemedic.users.list.lastName"
        source="last_name"
        sortable
      />
      <TextField
        label="telemedic.users.list.firstName"
        source="first_name"
        sortable
      />
      <TextField
        label="telemedic.users.list.middleName"
        source="middle_name"
        sortable
      />
      <FormatValueField
        label="telemedic.users.list.birthday"
        source="birthday"
        sortable
        formatter={formatDisplayDate}
      />
      <TextField
        label="telemedic.users.list.organization"
        source="organization.name"
        sortable
        sortBy="organization"
      />
      <BooleanField
        label="telemedic.workers.list.isFired"
        source="is_fired"
        sortable
      />
    </Datagrid>
  </RestrictedList>
);

const UserFilter = (props) => (
    <Filter key="user_filter" {...props}>
      <TextInput
        label="telemedic.users.list.personnelNumber"
        source="personnel_number"
        alwaysOn
        resettable
      />
      <TextInput
        label="telemedic.users.list.name"
        source="name"
        alwaysOn
        resettable
      />
      <TelemedicDataSource
        dataPromise={organizationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        alwaysOn
        resettable
      >
        <AutocompleteInput
          label="telemedic.users.list.organization"
          choiceLabelField="name"
          choiceValueField="id"
          source="organization_id"
        />
      </TelemedicDataSource>
      <BooleanInput
        label="telemedic.users.list.showFired"
        source="is_fired"
        resettable
      />
      <BooleanInput
        label="telemedic.users.list.showEsiaRegistered"
        source="is_esia_registered"
        resettable
      />
      <BooleanInput
        label="telemedic.users.list.showEsiaAllowed"
        source="is_esia_allowed"
        resettable
      />
    </Filter>
);

const organizationsDataPromise = (service) => service.getOrganizationValues();

export { UserList };
