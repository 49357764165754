import React, { useCallback } from 'react';
import {
  NumberInput, TextInput, BooleanInput, FormDataConsumer, required, useTranslate, useNotify,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import Button from '@material-ui/core/Button';
import styles from './TerminalForm.module.css';
import DateInput from '../../components/inputs/DateInput';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {ValueTypes} from '../../utils/values';

const TerminalForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const translate = useTranslate();
  let notify = useNotify();
  const terminal_id = rest.record.id;
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getTerminalFormOrganizationValues();
  }, null, []);
  const inspectionPoints = useTelemedicServiceJsonValue(() => {
    return service.getTerminalFormInpsectionPointValues();
  }, null, []);
  const clearCache = useCallback(async () => {
    try {
        await service.clearTerminalCache(terminal_id);
        notify('Кэш успешно почищен', 'success');
        window.location.reload();
    } catch (error) {
      notify('Ошибка при очистке: ' + error.message, 'error');
    }
}, [terminal_id, service, notify]);
  const registerAisErpIntegration = useCallback(async () => {
    try {
      await service.registerAisErpIntegration(terminal_id);
      notify('Регистрация успешно выполнена', 'success');
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      let errorMessage = 'Неизвестная ошибка';  // Значение по умолчанию
      
      // Проверяем наличие поля errorDescription
      if (error.errorDescription) {
          errorMessage = error.errorDescription;
      } else if (typeof error === 'string') {
          errorMessage = error;
      } else if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
      }
  
      notify('Ошибка при регистрации: ' + errorMessage, 'error');
    }
  
  }, [terminal_id, service, notify]);
  return (
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.terminals.form.type"
        source="type"
        validate={required()}
        {...getInputProps('type')}/>
      <TextInput
        label="telemedic.terminals.form.model"
        source="model"
        validate={required()}
        {...getInputProps('model')}/>
      <DateInput
        label="telemedic.terminals.form.buildDate"
        source="build_date"
        validate={required()}
        {...getInputProps('build_date')}/>
      <DateInput
        label="telemedic.terminals.form.verificationDate"
        source="verification_date"
        {...getInputProps('verification_date')}/>
      <DateInput
        label="telemedic.terminals.form.deadline"
        source="deadline"
        {...getInputProps('deadline')}/>
      <TextInput
        label="telemedic.terminals.form.teamViewerLogin"
        source="teamview_login"
        {...getInputProps('teamview_login')}/>
      <TextInput
        label="telemedic.terminals.form.teamViewerPassword"
        source="teamview_password"
        {...getInputProps('teamview_password')}/>
      <TextInput
        label="telemedic.terminals.form.termAdminPassword"
        source="conf_adm_pass"
        {...getInputProps('conf_adm_pass')}/>
      <TextInput
        label="telemedic.terminals.form.breathalyzerModel"
        source="breathalyzer_model"
        validate={required()}
        {...getInputProps('breathalyzer_model')}/>
      <TextInput
        label="telemedic.terminals.form.breathalyzerSerial"
        source="breathalyzer_serial"
        validate={required()}
        {...getInputProps('breathalyzer_serial')}/>
      <TextInput
        label="telemedic.terminals.form.pyrometerModel"
        source="pyrometer_model"
        validate={required()}
        {...getInputProps('pyrometer_model')}/>
      <TextInput
        label="telemedic.terminals.form.pyrometerSerial"
        source="pyrometer_serial"
        validate={required()}
        {...getInputProps('pyrometer_serial')}/>
      <TextInput
        label="telemedic.terminals.form.tonometerModel"
        source="tonometer_model"
        validate={required()}
        {...getInputProps('tonometer_model')}/>
      <TextInput
        label="telemedic.terminals.form.tonometerSerial"
        source="tonometer_serial"
        validate={required()}
        {...getInputProps('tonometer_serial')}/>
      <TextInput
        label="telemedic.terminals.form.pcModel"
        source="pc_model"
        {...getInputProps('pc_model')}/>
      <TextInput
        label="telemedic.terminals.form.cameraModel"
        source="camera_model"
        {...getInputProps('camera_model')}/>
      <TextInput
        label="telemedic.terminals.form.printerModel"
        source="printer_model"
        {...getInputProps('printer_model')}/>
      <TextInput
        label="telemedic.terminals.form.decree866"
        source="decree_866"
        {...getInputProps('decree_866')}/>
      <TextInput
        label="telemedic.terminals.form.name"
        source="name"
        validate={required()}
        {...getInputProps('name')}/>
      <TextInput
        label="telemedic.terminals.form.region"
        source="region"
        {...getInputProps('region')}/>
      <NumberInput
        label="telemedic.terminals.form.timezone"
        source="timezone"
        {...getInputProps('timezone')}/>
      <LargeCheckListInput
        label="telemedic.terminals.form.organizations"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('organization_ids')}/>
      <AutocompleteInput
        label="telemedic.terminals.form.inspectionPoint"
        choices={inspectionPoints}
        choiceLabelField="name"
        choiceValueField="id"
        source="inspection_point_id"
        {...getInputProps('inspection_point_id')}/>
      <TextInput
        label="telemedic.terminals.form.controlHash"
        source="control_hash"
        {...getInputProps('control_hash')}/>
      <TextInput
        label="telemedic.terminals.form.latitude"
        source="latitude"
        {...getInputProps('latitude')}/>
      <TextInput
        label="telemedic.terminals.form.longitude"
        source="longitude"
        {...getInputProps('longitude')}/>
      <TextInput
        label="telemedic.terminals.form.comments"
        rows={5}
        source="comments"
        multiline
        {...getInputProps('comments')}/>
      <TextInput
        label="telemedic.terminals.form.message"
        rows={5}
        source="message"
        multiline
        {...getInputProps('message')}/>
      <TextInput
        label="telemedic.terminals.form.medicPhone"
        source="medic_phone"
        multiline
        {...getInputProps('medic_phone')}/>
      <TextInput
        label="telemedic.terminals.form.arshinCertNumber"
        source="arshin_cert_number"
        {...getInputProps('arshin_cert_number')}/>
      <BooleanInput
        label="telemedic.terminals.form.autoinspect"
        source="sett_autoinspect" />
      <BooleanInput
        label="telemedic.terminals.form.aisErpApi"
        source="ais_erp_api" />
      <BooleanInput
        label="telemedic.terminals.form.addDataToAisErpApi"
        source="add_data_to_ais_erp_api" />
      <Button
        className={styles.clearCache}
        onClick={() => clearCache()} >
        {translate('telemedic.terminals.form.clearCache')}
      </Button>
      <FormDataConsumer>
        {
          ({ formData }) => formData.ais_erp_api && formData.arshin_cert_number && (formData.terminal_ais_erp_id === '' || formData.terminal_ais_erp_id === null) && (
            <Button
              className={styles.clearCache}
              onClick={() => registerAisErpIntegration()} >
              {translate('telemedic.terminals.form.registerAisErpIntegration')}
            </Button>
          )
        }
      </FormDataConsumer>
    </RestrictedSimpleForm>
  );
}

export default TerminalForm;
