import React from 'react';
import {Create} from 'react-admin';

import DealersResponsibleForm from './DealersResponsibleForm';
import DealersResponsibleTitle from './DealersResponsibleTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const DealersResponsibleCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={DealersResponsibleForm}
    routePath={RoutePath.DEALERS_RESPONSIBLE_SHOW}
    title={<DealersResponsibleTitle/>}
    {...props}/>
);
