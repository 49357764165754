import React from 'react';

import Typography from '@material-ui/core/Typography';

import styles from './UnderConstruction.module.css';

const UnderConstruction = (props) => {
  return (
    <div className={styles.container}>
      <Typography variant="h3">Страница в стадии разработки</Typography>
    </div>
  );
}

export default UnderConstruction;
