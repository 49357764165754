import React, {useState} from 'react';
import {Title} from 'react-admin';

import StatisticsForm from './StatisticsForm';
import CheckupsDisplay from './CheckupsDisplay';
import CheckupsExtendedDisplay from './CheckupsExtendedDisplay';
import RiskGroupDisplay from './RiskGroupDisplay';
import AgeRiskGroupDisplay from './AgeRiskGroupDisplay';
import WorkingAndRestTimeDisplay from './WorkingAndRestTimeDisplay';

import {
  REPORT_TYPE_INSPECTIONS,
  REPORT_TYPE_INSPECTIONS_EXTENDED,
  REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS,
  REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V,
  REPORT_TYPE_RISK_GROUP,
  REPORT_TYPE_AGE_RISK_GROUP,
  REPORT_TYPE_WORKING_AND_REST_TIME,
  REPORT_TYPE_WORKING_AND_REST_TIME_COLORED
} from './constants';

const StatisticsCheckupPage = () => {
  const [formParams, setFormParams] = useState(null);
  const [statisticsData, setStatisticsData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  }

  const dataCallback = (statisticsData) => {
    setStatisticsData(statisticsData);
  }

  const onBackClick = () => {
    setStatisticsData(null);
  }

  const reportType = statisticsData ? formParams.reportType : null;
  return (
    <div>
      <Title title="telemedic.statistics.label"/>
      {!statisticsData && <StatisticsForm
        params={formParams}
        paramsCallback={paramsCallback}
        dataCallback={dataCallback}/>}
      {reportType === REPORT_TYPE_INSPECTIONS && <CheckupsDisplay
        label="telemedic.statistics.checkups.title"
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_INSPECTIONS_EXTENDED && <CheckupsExtendedDisplay
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS && <CheckupsDisplay
        label="telemedic.statistics.checkups.titleWithoutRepeats"
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V && <CheckupsDisplay
        label="telemedic.statistics.checkups.titleWithoutRepeats"
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_RISK_GROUP && <RiskGroupDisplay
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_AGE_RISK_GROUP && <AgeRiskGroupDisplay
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}/>}
      {reportType === REPORT_TYPE_WORKING_AND_REST_TIME && <WorkingAndRestTimeDisplay
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}
        isColored={false}/>}
      {reportType === REPORT_TYPE_WORKING_AND_REST_TIME_COLORED && <WorkingAndRestTimeDisplay
        params={formParams}
        statisticsData={statisticsData}
        onBackClick={onBackClick}
        isColored/>}
    </div>
  );
};

export default StatisticsCheckupPage;
