import { API_ROOT } from '../../config';
import { getAuthToken } from '../../storages/auth';
import { serializeQueryParams } from '../../utils/urls';

import HttpError from './errors/HttpError';
import NotImplementedError from './errors/NotImplementedError';

const GET_LIST = 'GET_LIST';
const GET_ONE = 'GET_ONE';
const GET_MANY = 'GET_MANY';
const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE';
const CREATE = 'CREATE';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const DELETE_MANY = 'DELETE_MANY';

const createRequest = (method, url, headers, body) => {
  const requestHeaders = {
    ...headers,
    Authorization: `Bearer ${getAuthToken()}`,
    'Cache-Control': 'no-cache',
  };
  return new Request(url, {
    method,
    headers: requestHeaders,
    body,
  });
};

const createJsonRequest = (method, url, data) => {
  const dataJson = data ? JSON.stringify(data) : undefined;
  const headers = { 'Content-Type': 'application/json' };
  return createRequest(method, url, headers, dataJson);
};

const createMultipartRequest = (method, url, data, files) => {
  const formData = new FormData();
  if (data) {
    const dataJson = JSON.stringify(data);
    formData.append('body', dataJson);
  }
  Object.keys(files).forEach((key) => {
    formData.append(key, files[key]);
  });
  return createRequest(method, url, {}, formData);
};

const createFormRequest = (method, url, id, resourse, attributes) => {
  const files = {};
  let filesCount = 0;
  const cleanValue = (value) => {
    if (value === null) {
      return value;
    }
    if (value instanceof File) {
      const fileName = `file_${filesCount}`;
      filesCount += 1;
      files[fileName] = value;
      return `file:${fileName}`;
    }
    if (Array.isArray(value)) {
      return cleanArray(value);
    }
    if (typeof value === 'object') {
      return cleanObject(value);
    }
    return value;
  };
  const cleanArray = (array) => (
    array.map((item) => cleanValue(item))
  );
  const cleanObject = (object) => (
    Object.keys(object).reduce((acc, key) => {
      acc[key] = cleanValue(object[key]);
      return acc;
    }, {})
  );
  const cleanedAttributes = cleanObject(attributes);
  const data = {
    data: {
      id,
      type: resourse,
      attributes: cleanedAttributes,
    },
  };
  if (filesCount > 0) {
    return createMultipartRequest(method, url, data, files);
  }
  return createJsonRequest(method, url, data);
};

const dataProvider = (type, resource, params) => {
  const apiUrl = API_ROOT;

  let request;

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page[number]': page,
        'page[size]': perPage,
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        const val = params.filter[key];
        const filterKey = Array.isArray(val) ? `filter[${key}][]` : `filter[${key}]`;
        query[filterKey] = val;
      });

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      const url = `${apiUrl}/${resource}?${serializeQueryParams(query)}`;
      request = createJsonRequest('GET', url);
      break;
    }

    case GET_ONE: {
      const url = `${apiUrl}/${resource}/${params.id}`;
      request = createJsonRequest('GET', url);
      break;
    }

    case CREATE: {
      const url = `${apiUrl}/${resource}`;
      request = createFormRequest('POST', url, undefined, resource, params.data);
      break;
    }

    case UPDATE: {
      const url = `${apiUrl}/${resource}/${params.id}`;

      const attributes = params.data;
      delete attributes.id;

      request = createFormRequest('PATCH', url, params.id, resource, attributes);
      break;
    }

    case DELETE: {
      const url = `${apiUrl}/${resource}/${params.id}`;
      request = createJsonRequest('DELETE', url);
      break;
    }

    case GET_MANY: {
      const query = serializeQueryParams({
        'filter[id]': params.ids,
      });

      const url = `${apiUrl}/${resource}?${query}`;
      request = createJsonRequest('GET', url);
      break;
    }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page[number]': page,
        'page[size]': perPage,
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`filter[${key}]`] = params.filter[key];
      });

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id;

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      const url = `${apiUrl}/${resource}?${serializeQueryParams(query)}`;
      request = createJsonRequest('GET', url);
      break;
    }

    case DELETE_MANY: {
      const query = {
        'ids[]': params.ids,
      };
      const url = `${apiUrl}/${resource}?${serializeQueryParams(query)}`;
      request = createJsonRequest('DELETE', url);
      break;
    }

    default:
      throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
  }

  return fetch(request).then((response) => {
    const { status } = response;
    return response.json().then((data) => {
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(data, status),
        );
      }
      return Promise.resolve({ status, data });
    });
  }).then((response) => {
    let total;

    // For all collection requests get the total count.
    if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type)) {
      // When meta data and the 'total' setting is provided try
      // to get the total count.
      if (response.data.meta) {
        total = response.data.meta.total;
      }

      // Use the length of the data array as a fallback.
      total = total || response.data.data.length;
    }

    switch (type) {
      case GET_MANY:
      case GET_LIST: {
        return {
          data: response.data.data.map((value) => ({
            id: value.id,
            ...value.attributes,
          })),
          total,
        };
      }

      case GET_MANY_REFERENCE: {
        return {
          data: response.data.data.map((value) => ({
            id: value.id,
            ...value.attributes,
          })),
          total,
        };
      }

      case GET_ONE: {
        const { id, attributes } = response.data.data;

        return {
          data: {
            id, ...attributes,
          },
        };
      }

      case CREATE: {
        const { id, attributes } = response.data.data;

        return {
          data: {
            id, ...attributes,
          },
        };
      }

      case UPDATE: {
        const { id, attributes } = response.data.data;

        return {
          data: {
            id, ...attributes,
          },
        };
      }

      case DELETE: {
        return {
          data: { id: params.id },
        };
      }

      case DELETE_MANY: {
        return {
          data: response.data.data,
        };
      }

      default:
        throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
    }
  });
};

export default dataProvider;
