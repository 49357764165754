import * as React from "react"

function SobrietyTest(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 40 40"
      {...props}
    >
      <path d="M12.694 1.635V5.32l-2.359.013-.861.269c-.474.148-1.114.395-1.423.55-1.368.681-2.417 2.004-2.689 3.39-.095.484-.124 4.257-.1 12.93l.035 12.246.332.8c.79 1.898 2.284 3.105 4.318 3.487.59.11 2.055.137 6.926.121l6.177-.02.96-.284c1.105-.328 2.371-1.018 2.902-1.58a5.26 5.26 0 001.176-2.088c.262-.946.265-24.573.003-25.624-.359-1.44-1.39-2.765-2.65-3.456h12.405V1.635zM9.51 9.772h14.795V20.13H9.511zm2.44 11.836a2.22 2.22 0 012.223 2.216l-2.22.004h2.22a2.22 2.22 0 01-2.218 2.219 2.22 2.22 0 01-2.22-2.218 2.22 2.22 0 012.216-2.22zm10.357 0a2.22 2.22 0 012.222 2.216l-2.219.004h2.22a2.22 2.22 0 01-2.219 2.219 2.22 2.22 0 01-2.22-2.218 2.22 2.22 0 012.216-2.22z" />
    </svg>
  )
}

export default SobrietyTest
