import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {Title, showNotification, useTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import ToolBar from '../../components/ToolBar';
import styles from './TerminalVerificationDisplay.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import {RoutePath, getUrl} from '../../routes';
import {formatDisplayDate} from '../../utils/formatters';

const TerminalStatusDisplay = ({showNotification}) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [exportProgress, setExportProgress] = useState(false);
  const handleExport = useCallback(() => {
    setExportProgress(true);
    service.exportTerminalStatus().then(() => {
      setExportProgress(false);
    }).catch((e) => {
      console.log(e);
      setExportProgress(false);
      showNotification('telemedic.errors.submitError');
    });
  }, [service, setExportProgress, showNotification]);
  const pageTitle = translate('telemedic.control.terminalStatus.label');
  return (
    <Card className={styles.card}>
      <Title title="telemedic.control.label"/>
      <ToolBar
        title={pageTitle}
      >
        <ProgressButton
          className={styles.floatRight}
          variant="contained"
          color="primary"
          icon={<SaveIcon/>}
          label="telemedic.actions.export"
          onClick={handleExport}
          progress={exportProgress}
        />
      </ToolBar>
      <TerminalsTable/>
    </Card>
  );
}

const TerminalsTable = () => {
  const translate = useTranslate();
  const [terminalsError, setTerminalsError] = useState(false);
  const service = useTelemedicService();
  const terminals = useTelemedicServiceJsonValue(() => {
    return service.getTerminalStatus();
  }, null, null, (e) => {
    console.log(e);
    setTerminalsError(true);
  });
  return (
    <TableContainer className={styles.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnName')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnDeadline')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnTampering')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnHash')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnTemperature')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnHumidity')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnIlluminance')}</TableCell>
            <TableCell>{translate('telemedic.control.terminalStatus.columnGPS')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={14}
            data={terminals}
            error={terminalsError}
            emptyTextId="telemedic.common.noData"
            errorTextId="telemedic.errors.fetchError"
            placeholderRows={5}
            Row={TerminalsTableRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TerminalsTableRow = ({item}) => {
  const translate = useTranslate();
  const formatBoolValue = (val) => val ? translate('telemedic.control.terminalStatus.OK') : translate('telemedic.control.terminalStatus.attention')

  return (
    <TableRow className={!item.checks.is_everything_ok ? styles.attentionRow : null}>
      <TableCell component="th" scope="row">{item.id}</TableCell>
      <TableCell>
        <Link href={getUrl(RoutePath.TERMINAL_SHOW, {id: item.id})}>
          {item.name}
        </Link>
      </TableCell>
      <TableCell className={!item.checks.is_deadline_ok ? styles.attentionCell : null}>{item.checks.is_deadline_ok ? formatDisplayDate(item.deadline) : translate('telemedic.control.terminalStatus.attention')}</TableCell>
      <TableCell className={!item.checks.is_not_tampered ? styles.attentionCell : null}>{formatBoolValue(item.checks.is_not_tampered)}</TableCell>
      <TableCell className={!item.checks.is_hash_ok ? styles.attentionCell : null}>{formatBoolValue(item.checks.is_hash_ok)}</TableCell>
      <TableCell className={!item.checks.is_temperature_ok ? styles.attentionCell : null}>{item.ambient_temperature}</TableCell>
      <TableCell className={!item.checks.is_humidity_ok ? styles.attentionCell : null}>{item.humidity}</TableCell>
      <TableCell className={!item.checks.is_light_ok ? styles.attentionCell : null}>{item.illuminance}</TableCell>
      <TableCell className={!item.checks.is_gps_ok ? styles.attentionCell : null}>{formatBoolValue(item.checks.is_gps_ok)}</TableCell>
    </TableRow>
  );
}

export default connect(null, {showNotification})(TerminalStatusDisplay);
