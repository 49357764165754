import {useCallback, useEffect, useState} from 'react';

const useSyncInterval = (initCallback, updateCallback, period) => {
  const [startDate, setStartDate] = useState(null);
  const [syncDate, setSyncDate] = useState(null);
  const initDates = useCallback((startDate, syncDate) => {
    setStartDate(startDate);
    setSyncDate(syncDate);
  }, []);
  useEffect(() => {
    initCallback(initDates);
  }, [initCallback, initDates]);
  useEffect(() => {
    const intervalCallback = () => {
      updateCallback(startDate, syncDate || startDate, setSyncDate);
    };
    const intervalId = setInterval(intervalCallback, period);
    return () => {
      clearInterval(intervalId);
    }
  }, [period, startDate, syncDate, updateCallback]);
}

export default useSyncInterval;
