const resourcePageSize = {
  _cache: {},
  get: function(resource, defaultValue) {
    let pageSize = this._cache[resource];
    if (!pageSize) {
      pageSize = parseInt(localStorage.getItem(`pageSize.${resource}`)) || defaultValue;
      this._cache[resource] = pageSize;
    }
    return pageSize;
  },
  set: function(resource, value) {
    localStorage.setItem(`pageSize.${resource}`, value);
    this._cache[resource] = value;
  }
}

const userSettings = {
  resourcePageSize
}

export default userSettings;
