import React, { useEffect, useCallback, useState } from 'react';
import { Confirm } from 'react-admin';

import sounds from '../sounds';

function withSoundNotification(WrappedComponent) {
  return (props) => {
    const [isPermissionDialogOpened, setPermissionDialogOpened] = useState(false);
    let isSoundEnabled = sessionStorage.getItem('isSoundEnabled');
    useEffect(() => {
      let isSoundEnabled = sessionStorage.getItem('isSoundEnabled');
      if (isSoundEnabled !== null) {
        return;
      }
      const testSound = new Audio(sounds.silence);
      testSound.play().then(() => {
        isSoundEnabled = true;
        sessionStorage.setItem('isSoundEnabled', isSoundEnabled);
      }).catch((e) => {
        console.log(e);
        setPermissionDialogOpened(true);
      });
    }, []);
    const onSoundNotificationsEnabled = useCallback(() => {
      sessionStorage.setItem('isSoundEnabled', true);
      setPermissionDialogOpened(false);
    }, []);
    const onSoundNotificationsDisabled = useCallback(() => {
      sessionStorage.setItem('isSoundEnabled', false);
      setPermissionDialogOpened(false);
    }, []);
    const playSound = useCallback((sound) => {
      if (isSoundEnabled && sound) {
        sound.play();
      }
    }, [isSoundEnabled]);
    return (
      <React.Fragment>
        <WrappedComponent playSound={playSound} {...props}/>
        <Confirm
          isOpen={isPermissionDialogOpened}
          loading={false}
          title="telemedic.dialogs.soundNotification.title"
          content="telemedic.dialogs.soundNotification.content"
          onConfirm={onSoundNotificationsEnabled}
          onClose={onSoundNotificationsDisabled}/>
      </React.Fragment>
    );
  };
}

export default withSoundNotification;
