import React from 'react';
import { Create } from 'react-admin';

import WorkerForm from './WorkerForm';
import WorkerTitle from './WorkerTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const WorkerCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={WorkerForm}
    routePath={RoutePath.WORKER_SHOW}
    title={<WorkerTitle />}
    {...props}
  />
);

export default WorkerCreate;
