import React from 'react';
import {useTranslate} from 'react-admin';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import styles from './ProgressDialog.module.css';

const ProgressDialog = (props) => {
  const translate = useTranslate();
  const {
    open,
    isProgress, progressText,
    isSuccess, successText,
    isError, errorText,
    onRetry, onCancel, onDone
  } = props;
  return (
    <Dialog open={open}>
      <DialogContent>
        {isProgress &&
          <div className={styles.centerContent}>
            <CircularProgress/>
          </div>
        }
        {isProgress && !!progressText &&
          <DialogContentText>{progressText}</DialogContentText>
        }
        {isSuccess && !!successText &&
          <DialogContentText>{successText}</DialogContentText>
        }
        {isError && !!errorText &&
          <DialogContentText error={true}>{errorText}</DialogContentText>
        }
      </DialogContent>
      {isSuccess &&
        <DialogActions>
          <Button onClick={onDone}>
            {translate('telemedic.dialogs.ok')}
          </Button>
        </DialogActions>
      }
      {isError &&
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onRetry}>
            {translate('telemedic.actions.repeat')}
          </Button>
          <Button onClick={onCancel}>
            {translate('telemedic.dialogs.cancel')}
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}


export default ProgressDialog;
