import { Permissions } from '../permissions';
import { API_ROOT } from '../config';

const authProvider = {
  login: (data) => {
    let request;
    if (data.certificateId && data.certificatePublicKey) {
      request = new Request(`${API_ROOT}/login/token`, {
        method: 'POST',
        body: JSON.stringify({ cert_id: data.certificateId, public_key: data.certificatePublicKey }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
    } else {
      request = new Request(`${API_ROOT}/login`, {
        method: 'POST',
        body: JSON.stringify({ login: data.username, password: data.password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
    }
    return fetch(request)
      .then((response) => response.json())
      .catch(() => Promise.reject('Сервер недоступен'))
      .then((responseData) => {
        if (responseData.token) {
          localStorage.setItem('token', responseData.token);
          localStorage.setItem('role', responseData.role);
          localStorage.setItem('sections', JSON.stringify(responseData.sections));
          localStorage.setItem('is_token_attached', responseData.is_token_attached);
          localStorage.setItem('did_login_with_esia', false);
          localStorage.setItem('user_info', JSON.stringify(responseData.data));
        } else {
          return Promise.reject(responseData.error_description);
        }
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('sections');
    localStorage.removeItem('is_token_attached');
    localStorage.removeItem('did_login_with_esia');
    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (
    localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login/' })
  ),
  getPermissions: () => {
    const sections = JSON.parse(localStorage.getItem('sections'));
    return Promise.resolve(new Permissions(sections));
  },
};

export default authProvider;
