import React from 'react';
import {
  DeleteButton, EditButton, Show, TopToolbar, usePermissions
} from 'react-admin';

import Spacer from '../Spacer';
import { Action } from '../../permissions';

const RestrictedShow = ({ extraActions, signature, ...rest }) => {
  return (
    <Show {...rest} actions={<RestrictedActions extraActions={extraActions} signature={signature} />} />
  );
}

const RestrictedActions = ({
  basePath, data, extraActions, resource
}) => {
  const { loaded, permissions } = usePermissions();
  const children = [];
  if (loaded) {
    const section = resource ? resource.toUpperCase() : '';
    let signature = ''
    if (data) {
      signature = data.signature
    }
    if (permissions.check(section, Action.UPDATE) && signature !== 'ok') {
      children.push(
        <EditButton
          key="defaultAction1"
          basePath={basePath}
          record={data}
          resource={resource}
        />
      );
    }
    if (permissions.check(section, Action.DELETE) && signature !== 'ok') {
      children.push(
        <DeleteButton
          key="defaultAction2"
          basePath={basePath}
          mutationMode="pessimistic"
          record={data}
          resource={resource}
        />
      );
    }
    if (extraActions && extraActions.length) {
      children.push(...extraActions);
    }
  }
  if (children.length) {
    let i = 1;
    while (i < children.length) {
      const spacerKey = `spacer${i}`;
      const spacer = <Spacer key={spacerKey} />;
      children.splice(i, 0, spacer);
      i += 2;
    }
    return (
      <TopToolbar>
        { children }
      </TopToolbar>
    );
  }
  return null;
};

export default RestrictedShow;
