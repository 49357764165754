import React from 'react';
import {
  Filter, Datagrid, TextField, TextInput, SelectInput, downloadCSV, FunctionField,
  TopToolbar, useTranslate, CreateButton, sanitizeListRestProps
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import MultiAutocompleteInput from '../../components/inputs/MultiAutocompleteInput';
import { CheckupResult } from '../../constants';
import { enumChoices } from '../../utils/enums';
import {
  formatCheckupResult, formatInspectionType, formatDisplayDateTime,
  formatNonAdmissionReason, formatPersonName,
} from '../../utils/formatters';
import styles from './CheckupList.module.css';

const exporter = (checkups) => {
  const checkupsForExport = checkups.map((checkup) => ({
    ...checkup,
    non_admission_reasons: checkup.non_admission_reasons.map(
      (reasonCode) => formatNonAdmissionReason(reasonCode),
    ),
  }));
  jsonExport(checkupsForExport, {}, (err, csv) => {
    downloadCSV(csv, 'checkups');
  });
};

const ListActions = (props) => {
  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <CreateButton/>
    </TopToolbar>
  );
}


const CheckupList = (props) => (
  <RestrictedList
    exporter={exporter}
    title="telemedic.checkups.label"
    filters={<CheckupFilter />}
    actions={<ListActions />}
    sort={{ field: 'id', order: 'DESC' }}
    manualFilterSearch
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <FormatValueField
        label="telemedic.checkups.list.employee"
        source="user"
        sortable
        formatter={formatPersonName}
      />
      <TextField
        label="telemedic.checkups.list.organization"
        source="user.organization.name"
        sortable
        sortBy="organization"
      />
      <FormatValueField
        label="telemedic.checkups.list.type"
        source="type"
        sortable
        formatter={formatInspectionType}
      />
      <FormatValueField
        label="telemedic.checkups.list.datetimeStart"
        source="datetime_start"
        sortable
        formatter={formatDisplayDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.list.datetimeEnd"
        source="datetime_end"
        sortable
        formatter={formatDisplayDateTime}
      />
      <FunctionField
        label="telemedic.checkups.list.resultAuto"
        sortable={false}
        render={(record) => (
          <div className={record.result_auto === CheckupResult.NOT_ADMITTED ? styles.notAdmitted : ''}>
            {formatCheckupResult(record.result_auto)}
          </div>
        )}
      />
      <FunctionField
        label="telemedic.checkups.list.resultMedic"
        sortable={false}
        render={(record) => (
          <div className={record.result_medic === CheckupResult.NOT_ADMITTED ? styles.notAdmitted : ''}>
            {formatCheckupResult(record.result_medic)}
          </div>
        )}
      />
    </Datagrid>
  </RestrictedList>
);

const organizationsDataPromise = (service) => service.getOrganizationValues();
const dealersDataPromise = (service) => service.getDealerValues();
const terminalsDataPromise = (service) => service.getTerminalValues();

const CheckupFilter = (props) => {
  const translate = useTranslate();
  const { onSubmit } = props;

  return (
    <div className={styles.filterContainer}>
      <Filter key="checkup_filter" {...props}>
        <DateInput
          label="telemedic.checkups.list.periodFrom"
          source="date_from"
          alwaysOn
          minWidth
          resettable
        />
        <DateInput
          label="telemedic.checkups.list.periodTo"
          source="date_to"
          alwaysOn
          minWidth
          resettable
        />
        <SelectInput
          label="telemedic.checkups.list.result"
          source="result_medic"
          choices={enumChoices(CheckupResult, formatCheckupResult)}
          alwaysOn
          resettable
        />
        <TextInput
          label="telemedic.checkups.list.employee"
          source="user_name"
          alwaysOn
          resettable
        />
        <TelemedicDataSource
          dataPromise={organizationsDataPromise}
          dataDefault={[]}
          dataAttrName="choices"
          alwaysOn
          resettable
        >
          <AutocompleteInput
            label="telemedic.checkups.list.organization"
            choiceLabelField="name"
            choiceValueField="id"
            source="organization_id"
          />
        </TelemedicDataSource>
        <TelemedicDataSource
          dataPromise={dealersDataPromise}
          dataDefault={[]}
          dataAttrName="choices"
          alwaysOn
          resettable
        >
          <AutocompleteInput
            label="telemedic.checkups.list.dealer"
            choiceLabelField="name"
            choiceValueField="id"
            source="dealer_id"
          />
        </TelemedicDataSource>
        <TextInput
          label="telemedic.checkups.list.tab_num"
          source="tab_num"
          resettable
        />
        <TelemedicDataSource
          dataPromise={terminalsDataPromise}
          dataDefault={[]}
          dataAttrName="choices"
          alwaysOn
          resettable
        >
          <MultiAutocompleteInput
            name="terminal_names"
            label="telemedic.checkupsLive.list.terminal"
            choiceLabelField="name"
            choiceValueField="name"
            helperText={false}
            suggestionLimit={10}
            resettable
          />
        </TelemedicDataSource>
      </Filter>
      <Button
        variant="contained"
        color="primary"
        component="span"
        startIcon={<SearchIcon />}
        onClick={onSubmit}
        className={styles.searchButton}
      >
        {translate('telemedic.checkups.list.filterSearch')}
      </Button>
    </div>
  );
};

export default CheckupList;
