import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import {castValue, ValueTypes} from '../utils/values';

class CheckList extends React.Component {
  render() {
    const {
      className,
      label,
      choices = [],
      choiceGroups = [],
      choiceLabelField = 'label',
      choiceValueField = 'value',
      value,
      error,
      helperText
    } = this.props;
    let groups;
    if (choices && label) {
      groups = [
        [label, choices]
      ];
    }
    else {
      groups = choiceGroups;
    }
    return (
      <FormControl
        component="fieldset"
        className={className}
        error={error}
      >
        {groups.map(([groupName, groupChoices], groupIndex) => (
          <React.Fragment key={groupIndex}>
            <FormLabel component="legend">{groupName}</FormLabel>
            <FormGroup>
              {groupChoices.map((choice, choiceIndex) => (
                <FormControlLabel
                  key={choiceIndex}
                  label={choice[choiceLabelField]}
                  control={
                    <Checkbox
                      value={choice[choiceValueField]}
                      checked={value && value.indexOf(choice[choiceValueField]) >= 0}
                      onChange={this.onCheckboxChange}/>
                  }/>
              ))}
            </FormGroup>
          </React.Fragment>
        ))}
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  onCheckboxChange = (e) => {
    const {
      value,
      valueType = ValueTypes.STRING,
      onChange
    } = this.props;
    const checkboxValue = castValue(e.target.value, valueType);
    if (e.target.checked) {
      onChange([...value, checkboxValue]);
    }
    else {
      onChange(value.filter(v => v !== checkboxValue));
    }
  }
};

CheckList.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  choices: PropTypes.array,
  choiceGroups: PropTypes.array,
  choiceLabelField: PropTypes.string,
  choiceValueField: PropTypes.string,
  value: PropTypes.array,
  valueType: PropTypes.oneOf(Object.values(ValueTypes)),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default CheckList;
