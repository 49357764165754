import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';

import styles from './FormFooter.module.css';

const FormFooter = ({children}) => {
  return (
    <Toolbar className={styles.footer}>
      {children}
    </Toolbar>
  );
}

export default FormFooter;
