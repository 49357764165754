export const enumChoices = (enumObj, formatter) => {
  const choices = [];
  const values = Object.values(enumObj);
  for (var i = 0, length = values.length; i < length; ++i) {
    const value = values[i];
    choices.push({
      id: value,
      name: formatter(value)
    });
  }
  return choices;
}
