import React from 'react';
import {Create} from 'react-admin';

import CheckupForm from './CheckupForm';
import CheckupTitleWithControls from './CheckupShow/CheckupTitleWithControls';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

const postDefaultValues = () => ({datetime_start: Date.now(), datetime_end: Date.now()});

const CheckupCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={CheckupForm}
    routePath={RoutePath.CHECKUP_SHOW}
    title={<CheckupTitleWithControls/>}
    defaultValues={postDefaultValues}
    {...props}/>
);

export default CheckupCreate;
