import React from 'react';
import {useAuthState, usePermissions} from 'react-admin';

import {Action} from '../permissions';
import {RoutePath, navigateTo} from '../routes';

export default (WrappedComponent, section) => (props) => {
  const {loading: authStateLoading, authenticated } = useAuthState();
  const {loading: permissionsLoading, permissions} = usePermissions();
  if (authStateLoading || permissionsLoading) {
    return null;
  }
  if (!authenticated) {
    navigateTo(RoutePath.LOGIN);
    return null;
  }
  if (permissions) {
    if (!section || (section && permissions.check(section, Action.READ))) {
      return (
        <WrappedComponent permissions={permissions} {...props} />
      );
    }
  }
  return null;
}
