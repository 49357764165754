import React, {useCallback, useState} from 'react';
import {Title, useTranslate} from 'react-admin';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FileInput from '../../components/inputs/FileInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import Info from '../../components/Info';
import styles from './VehiclesImportPage.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

const VehiclesImportPage = (props) => {
  const STATE_NONE = 0;
  const STATE_PROGRESS = 1;
  const STATE_SUCCESS = 2;
  const STATE_ERROR = 3
  const [state, setState] = useState(STATE_NONE);
  const [logs, setLogs] = useState(null);
  const service = useTelemedicService();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getOrganizationValues();
  }, null, []);
  const translate = useTranslate();
  const validate = useCallback((data) => {
    const errors = {};
    if (!data.organizationId) {
      errors.organizationId = 'telemedic.validation.required';
    }
    if (!data.importFile) {
      errors.importFile = 'telemedic.validation.required';
    }
    return errors;
  }, []);

  const submitHandler = useCallback(({organizationId, importFile}) => {
    setState(STATE_PROGRESS);
    setLogs(null);
    service.importVehicles(organizationId, importFile).then((response) => {
      setState(response.json.success ? STATE_SUCCESS : STATE_ERROR);
      setLogs(response.json.logs);
    }).catch((e) => {
      setState(STATE_ERROR);
      setLogs(translate('telemedic.errors.unknownError'));
    });
  }, [service, translate]);
  return (
    <div>
      <Title title="telemedic.vehiclesImport.label"/>
      <Paper>
        <Form validate={validate} onSubmit={submitHandler}>
          <FormContent>
            <AutocompleteInput
              className={styles.formControl}
              source="organizationId"
              label="telemedic.vehiclesImport.form.organization"
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              required/>
            <FileInput
              className={styles.formControl}
              source="importFile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              label="telemedic.vehiclesImport.form.importFile"
              required/>
            {state === STATE_SUCCESS &&
              <Info level="info">{translate('telemedic.vehiclesImport.form.importSuccess')}</Info>
            }
            {state === STATE_ERROR &&
              <Info level="error">{translate('telemedic.vehiclesImport.form.importError')}</Info>
            }
            <ul>
              {logs && logs.map((log, index) => (
                <li key={index}>{log}</li>
              ))}
            </ul>
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={state === STATE_PROGRESS}
            />
          </FormFooter>
        </Form>
      </Paper>
    </div>
  );
}

export default VehiclesImportPage;
