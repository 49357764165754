import React from 'react';
import { useTranslate } from 'react-admin';

const TestQuestionCategoryTitle = ({ record }) => {
  const label = useTranslate()('telemedic.testQuestionCategory.show.label');
  return (
    <span>{(record && record.category) ? `${label} "${record.category}"` : label}</span>
  );
};

export default TestQuestionCategoryTitle;
