import React from 'react';
import Typography from '@material-ui/core/Typography';
import lodash from 'lodash';

const FormatValueField = ({className, source, formatter, record = {}, fallback = ''}) => {
  var formattedValue;
  if (record) {
    var value = lodash.get(record, source);
    if (!lodash.isNil(value)) formattedValue = formatter(value);
    else formattedValue = fallback;
  }
  else {
    formattedValue = fallback;
  }
  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {formattedValue}
    </Typography>
  );
};

FormatValueField.displayName = 'FormatValueField';
FormatValueField.defaultProps = {
    addLabel: true,
};

export default FormatValueField;
