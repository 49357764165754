import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Notification, Title, useTranslate } from 'react-admin';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Info from '../../components/Info'
import styles from './ActiveUsersPage.module.css';
import useInterval from '../../hooks/useInterval';
import useTelemedicService from '../../hooks/useTelemedicService';

const UserList = ({ users }) => {
  const translate = useTranslate();
  return (
    <TableContainer>
      <Table className={styles.usersTable} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{translate('telemedic.activeUsers.list.id')}</TableCell>
            <TableCell>{translate('telemedic.activeUsers.list.login')}</TableCell>
            <TableCell>{translate('telemedic.activeUsers.list.ip')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.login}</TableCell>
              <TableCell>{user.ip}</TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UserPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  login: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
});

UserList.propTypes = {
  users: PropTypes.arrayOf(UserPropTypes).isRequired,
};

const UserTypeList = ({ userTypes }) => (
  <div>
    {
      userTypes.map((userType) => (
        <Accordion key={userType.name}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{userType.name} ({userType.users.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserList users={userType.users} />
          </AccordionDetails>
        </Accordion>
      ))
    }
  </div>
);

UserTypeList.propTypes = {
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(UserPropTypes).isRequired,
  })).isRequired,
};

const ActiveUsersPage = () => {
  const translate = useTranslate();
  const [nodes, setNodes] = useState(null);
  const service = useTelemedicService();
  const [nodesError, setNodesError] = useState();
  const loadNodes = useCallback(() => {
    service.getActiveUsers().then((response) => {
      setNodes(response.json.nodes);
      setNodesError(null);
    }).catch((e) => {
      console.log(e);
      setNodesError(e.errorDescription || translate('telemedic.errors.fetchError'));
    });
  }, [service, setNodes, setNodesError, translate]);
  useInterval(loadNodes, 30000, true);
  return (
    <div>
      <Title title="telemedic.activeUsers.label" />
      <Paper className={styles.paper}>
        {nodes && nodes.length > 0 && nodes.map((node) => (
          <div key={node.name}>
            <Typography className={styles.nodeName} variant="h6">{node.name}</Typography>
            <UserTypeList userTypes={node.user_types} />
          </div>
        ))}
        {nodes && nodes.length === 0 && (
          <Typography>{translate('telemedic.activeUsers.list.noUsers')}</Typography>
        )}
        {!nodes && !!nodesError && (
          <Info level="error">{nodesError}</Info>
        )}
        {nodes && !!nodesError && (
          <Notification
            message={nodesError}
            open={!!nodesError}
            type="error"
          />
        )}
      </Paper>
    </div>
  );
};

export default ActiveUsersPage;
