import React, {useState} from 'react';
import {useTranslate} from 'react-admin';

import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import {
  ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Legend,
  Tooltip
} from 'recharts';

import ToolBar from '../../components/ToolBar';
import {CheckupShow} from '../../pages/checkups';

import styles from './HealthStatusDisplay.module.css';
import {formatDisplayDate, formatDisplayDateTime} from '../../utils/formatters';
import {replace} from '../../utils/text';

const HealthStatusDisplay = ({params, healthStatusData, onBackClick}) => {
  const [selectedCheckupId, setSelectedCheckupId] = useState();
  const onChartClick = (data) => {
    const index = data.activeTooltipIndex;
    const checkup = healthStatusData.checkups[index];
    if (checkup) {
      setSelectedCheckupId(checkup.checkup_id);
    }
  }
  const onCloseDialog = () => {
    setSelectedCheckupId(null);
  }
  const translate = useTranslate();
  const pageTitle = translate('telemedic.healthStatus.label');
  const subtitle = replace(translate('telemedic.healthStatus.display.subtitle'), {
    '{from}': formatDisplayDate(healthStatusData.date_from),
    '{to}': formatDisplayDate(healthStatusData.date_to)
  });
  const user = healthStatusData.user;
  const userName = `${user.last_name} ${user.first_name} ${user.middle_name}`;
  const birdthday = formatDisplayDate(user.birthday);

  const timestampFormatter = (value) => formatDisplayDateTime(moment(value));
  const LEGEND_LABELS = {
    'arterial_pressure': translate('telemedic.healthStatus.display.arterialPressure'),
    'venous_pressure': translate('telemedic.healthStatus.display.venousPressure'),
    'pulse': translate('telemedic.healthStatus.display.pulse'),
  }
  const legendFormatter = (value) => LEGEND_LABELS[value];
  return (
    <Card className={styles.card}>
      <ToolBar
        title={pageTitle}
        onBackClick={onBackClick}/>
      <div className={styles.userInfo}>
        <Typography component="div" variant="body1">{subtitle}</Typography>
        <Typography component="div" variant="body1">{userName}</Typography>
        <Typography component="div" variant="body1">
          {translate('telemedic.healthStatus.display.birthday')}: {birdthday}
        </Typography>
        <Typography component="div" variant="body1">
          {user.organization.name}
        </Typography>
        <Typography component="div" variant="body1">
          {translate('telemedic.healthStatus.display.personnelNumber')}: {user.personnel_number}
        </Typography>
      </div>
      <div className={styles.chartWrapper}>
        <ResponsiveContainer>
          <LineChart data={adaptDataForCharts(healthStatusData)} onClick={onChartClick}>
            <CartesianGrid strokeDasharray="3 3" with="30"/>
            <XAxis dataKey="timestamp" tickFormatter={timestampFormatter}/>
            <YAxis tickCount={16} scale="linear"/>
            <Tooltip labelFormatter={timestampFormatter}/>
            <Legend formatter={legendFormatter}/>
            <Line type="monotone" dataKey="arterial_pressure" stroke="#8884d8"/>
            <Line type="monotone" dataKey="venous_pressure" stroke="#82ca9d"/>
            <Line type="monotone" dataKey="pulse" stroke="#d88484"/>
          </LineChart>
        </ResponsiveContainer>
      </div>
      {!!selectedCheckupId && <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        onClose={onCloseDialog}
      >
        <DialogTitle className={styles.dialogTitle}>
          {translate('telemedic.healthStatus.display.checkup')}
          <IconButton onClick={onCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CheckupShow
            title={' > ' + translate('telemedic.healthStatus.display.checkup')}
            basePath="/checkups"
            resource="checkups"
             className={styles.show}
            id={selectedCheckupId}/>
        </DialogContent>
      </Dialog>}
    </Card>
  );
}

const adaptDataForCharts = (data) => {
  const checkups = data.checkups;
  const result = [];
  for (var i = 0, length = checkups.length; i < length; ++i) {
    const checkup = checkups[i];
    result.push({
      checkup_id: checkup.checkup_id,
      timestamp: moment(checkup.datetime_start).valueOf(),
      arterial_pressure: checkup.arterial_pressure,
      venous_pressure: checkup.venous_pressure,
      pulse: checkup.pulse,
    });
  }
  return result;
}

export default HealthStatusDisplay;
