import React from 'react';
import PropTypes from 'prop-types';

import Link from '@material-ui/core/Link';

const FieldLink = ({getUrl, record, children}) => {
  const child = React.Children.only(children);
  return (
    <Link href={getUrl(record)}>
      {React.cloneElement(child, {record: record})}
    </Link>
  );
}

FieldLink.propTypes = {
  getUrl: PropTypes.func,
  record: PropTypes.object
};

export default FieldLink;
