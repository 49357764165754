import React from 'react';
import { Edit } from 'react-admin';

import TestResultForm from './TestResultForm';
import TestResultTitle from './TestResultTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const TestResultEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={TestResultForm}
    routePath={RoutePath.TEST_QUESTION_CATEGORIES_SHOW}
    title={<TestResultTitle />}
    {...props}
  />
);

export default TestResultEdit;
