import React from 'react';
import {Filter, Datagrid, TextField, TextInput} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';

export const VehicleList = props => {
  return(
    <RestrictedList
      title="telemedic.vehicles.label"
      filters={<VehicleFilter/>}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <TextField
          label="telemedic.vehicles.list.manufacturer"
          source="manufacturer"
          sortable={true}/>
        <TextField
          label="telemedic.vehicles.list.model"
          source="model"
          sortable={true}/>
        <TextField
          label="telemedic.vehicles.list.carLicensePlate"
          source="car_license_plate"
          sortable={true}/>
        <TextField
          label="telemedic.vehicles.list.year"
          source="year"
          sortable={true}/>
        <TextField
          label="telemedic.vehicles.list.color"
          source="color"
          sortable={false}/>
        <TextField
          label="telemedic.vehicles.list.organization"
          source="organization.name"
          sortable={true}
          sortBy="organization"/>
      </Datagrid>
    </RestrictedList>
  );
}

const VehicleFilter = (props) => (
    <Filter key="vehicle_filter" {...props}>
      <TextInput
        label="telemedic.vehicles.list.name"
        source="name"
        alwaysOn resettable/>
      <TextInput
        label="telemedic.vehicles.list.carLicensePlate"
        source="car_license_plate"
        alwaysOn resettable/>
      <TelemedicDataSource
        dataPromise={organizationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        alwaysOn resettable>
        <AutocompleteInput
          label="telemedic.vehicles.list.organization"
          choiceLabelField="name"
          choiceValueField="id"
          source="organization_id"/>
      </TelemedicDataSource>
    </Filter>
);

const organizationsDataPromise = (service) => {
  return service.getOrganizationValues();
}
