import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './TechInspectionsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import {
  formatCheckupResult, formatInspectionType, formatInspectionsTypeGenitivePlural,
  formatDisplayDate, formatDisplayDateTime,
} from '../../utils/formatters';

const BoolDisplay = ({ value }) => (
  (value) ? <CheckIcon /> : <CloseIcon />
);

class TechInspectionsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exportProgress: false };
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onExport = () => {
    this.setState({ exportProgress: true });
    const { showNotification, params } = this.props;
    const {
      dateFrom, dateTo, inspectionType, organizationId, withoutRepeats,
    } = params;
    const promise = this.service.exportExtendedTechInspectionsStatistics(
      dateFrom, dateTo, inspectionType, organizationId, withoutRepeats,
    );
    promise.then(() => {
      this.setState({ exportProgress: false });
    }).catch((e) => {
      console.log(e);
      this.setState({ exportProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  }

  formatTotal(total) {
    const { translate } = this.props;
    const totalStringParts = [
      `${translate('telemedic.statisticsTech.techInspections.total')}: ${total.tech_inspections}. `,
      `${translate('telemedic.statisticsTech.techInspections.admissions')}: ${total.admissions}. `,
      `${translate('telemedic.statisticsTech.techInspections.nonAdmissions')}: ${total.non_admissions}. `,
    ];
    const typesStringParts = [];
    Object.entries(total.tech_inspections_by_type).forEach(([techInspectionType, count]) => {
      typesStringParts.push(
        `${formatInspectionsTypeGenitivePlural(parseInt(techInspectionType, 10))}: ${count}. `,
      );
    });
    totalStringParts.push(...typesStringParts.sort());
    return totalStringParts.join('');
  }

  renderTable(data) {
    const { translate } = this.props;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnInspectionDate')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnInspectionType')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnPersonnelNumber')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnFullName')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnBirthday')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnVehicle')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnMileage')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnLightning')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnBrakingSystem')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnSteeringWheelPlay')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnClutch')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnTirePressure')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnWipers')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnSoundSignals')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnLocks')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnFireExtinguisher')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnTowRope')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnJack')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnFirstAidKit')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnWarningTriangle')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnIndividualProtection')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnBriefingRegime')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnBriefingChildren')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnGasEquipment')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnNote')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnMechanic')}</TableCell>
              <TableCell>{translate('telemedic.statisticsTech.techInspections.columnApproval')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tech_inspections.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{formatDisplayDateTime(row.date)}</TableCell>
                <TableCell>{formatInspectionType(row.type_of_inspection)}</TableCell>
                <TableCell>{row.personnel_number}</TableCell>
                <TableCell>{`${row.last_name} ${row.first_name} ${row.middle_name}`}</TableCell>
                <TableCell>{formatDisplayDate(row.birthday)}</TableCell>
                <TableCell>
                  {`${row.vehicle_mark} ${row.vehicle_model} ${row.vehicle_year} ${row.vehicle_number}`}
                </TableCell>
                <TableCell>{row.mileage}</TableCell>
                <TableCell><BoolDisplay value={row.lightning} /></TableCell>
                <TableCell><BoolDisplay value={row.braking_system} /></TableCell>
                <TableCell><BoolDisplay value={row.steering_wheel_play} /></TableCell>
                <TableCell><BoolDisplay value={row.clutch} /></TableCell>
                <TableCell><BoolDisplay value={row.tire_pressure} /></TableCell>
                <TableCell><BoolDisplay value={row.wipers} /></TableCell>
                <TableCell><BoolDisplay value={row.sound_signals} /></TableCell>
                <TableCell><BoolDisplay value={row.locks} /></TableCell>
                <TableCell><BoolDisplay value={row.fire_extinguisher} /></TableCell>
                <TableCell><BoolDisplay value={row.tow_rope} /></TableCell>
                <TableCell><BoolDisplay value={row.jack} /></TableCell>
                <TableCell><BoolDisplay value={row.first_aid_kit} /></TableCell>
                <TableCell><BoolDisplay value={row.warning_triangle} /></TableCell>
                <TableCell><BoolDisplay value={row.individual_protection} /></TableCell>
                <TableCell><BoolDisplay value={row.briefing_regime} /></TableCell>
                <TableCell><BoolDisplay value={row.briefing_children} /></TableCell>
                <TableCell><BoolDisplay value={row.gas_equipment} /></TableCell>
                <TableCell>{row.note}</TableCell>
                <TableCell>
                  {`${row.mechanic_last_name} ${row.mechanic_first_name} ${row.mechanic_middle_name}`}
                  <br />
                  {row.digital_signature}
                </TableCell>
                <TableCell>{formatCheckupResult(row.result)}</TableCell>
                <TableCell />
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={16}>{this.formatTotal(data.total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { statisticsData, onBackClick, translate } = this.props;
    const { exportProgress } = this.state;

    const pageTitle = translate('telemedic.statisticsTech.labelExtended');

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData} />
        {this.renderTable(statisticsData)}
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(TechInspectionsDisplay));
