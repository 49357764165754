export const serializeQueryParams = (queryParams) => {
  const searchParams = new URLSearchParams();
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(queryParams)) {
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const arrayValue of value) {
        searchParams.append(key, arrayValue);
      }
    }
    else if (value != null && typeof(value) !== 'undefined') {
      searchParams.append(key, value);
    }
  }
  return searchParams;
};

export const parseQueryParams = (queryString) => {
  const queryParams = {};
  const searchParams = new URLSearchParams(queryString);
  for (let [param, value] of searchParams) {
    queryParams[param] = value;
  }
  return queryParams;
}
