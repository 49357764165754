import React, { useCallback } from 'react';
import lodash from 'lodash';
import { useRefresh, usePermissions, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import useTelemedicService from '../../../hooks/useTelemedicService';
import { Action, Section } from '../../../permissions';

const UserPhotoInput = ({
  label, record, userIdSource, userPhotoSource,
}) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const refresh = useRefresh();
  const fileURL = lodash.get(record, userPhotoSource);
  const uploadPhoto = useCallback((event) => {
    const userId = lodash.get(record, userIdSource);
    fetch(fileURL)
      .then((res) => res.blob())
      .then((file) => service.uploadUserPhoto(userId, file))
      .then((response) => {
        refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [record, refresh, service, userIdSource, fileURL]);
  const { loaded, permissions } = usePermissions();
  const showUploadButton = loaded && permissions.check(Section.USERS, Action.UPDATE) && fileURL !== null;
  if (!showUploadButton) {
    return null;
  }
  return (
    <Button
      variant="contained"
      color="primary"
      component="span"
      onClick={uploadPhoto}
      startIcon={<CloudUploadIcon />}
    >
      {translate(label)}
    </Button>
  );
};

export default UserPhotoInput;
