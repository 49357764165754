import download from 'downloadjs';

export const downloadDocx = (blob, name) => {
  download(
    blob,
    `${name}.docx`,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  );
};

export const downloadExcel = (blob, name) => {
  download(
    blob,
    `${name}.xlsx`,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
};

export const downloadPdf = (blob, name) => {
  download(
    blob,
    `${name}.pdf`,
    'application/pdf',
  );
};
