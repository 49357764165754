import React from 'react';
import {Create} from 'react-admin';

import OrgPaymentForm from './OrgPaymentForm';
import OrgPaymentTitle from './OrgPaymentTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const OrgPaymentCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={OrgPaymentForm}
    routePath={RoutePath.ORG_PAYMENT_SHOW}
    title={<OrgPaymentTitle/>}
    {...props}/>
);
