import React from 'react';
import {TextInput, required} from 'react-admin';

import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';

const DealerForm = ({getInputProps, ...rest}) => {
  return(
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.dealers.form.name"
        source="name"
        validate={required()}
        {...getInputProps('name')}/>
      <TextInput
        label="telemedic.dealers.form.responsible"
        source="responsible"
        validate={required()}
        {...getInputProps('responsible')}/>
    </RestrictedSimpleForm>
  );
}

export default DealerForm;
