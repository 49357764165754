import React from 'react';

import Paper from '@material-ui/core/Paper';

import images from '../../images';
import styles from './Video.module.css';

const Video = ({src, title, autoplay = false, loop = false}) => {
  const video = (src) ? (
    <video className={styles.video} src={src} type="video/mp4" controls autoPlay={autoplay} loop={loop} muted />
  ) : (
    <img className={styles.image} src={images.novideo} alt="" />
  );
  return (
    <Paper className={styles.container}>
      {video}
      {
        !!title
        && <div className={styles.title}>{title}</div>
      }
    </Paper>
  );
};

export default Video;
