import React from 'react';
import { connect } from 'react-redux';
import { RadioButtonGroupInput, showNotification, withTranslate } from 'react-admin';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import DateInput from '../../components/inputs/DateInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import ProgressButton from '../../components/ProgressButton';
import styles from './BillingSumForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken, getRole } from '../../storages/auth';
import { ContractStatus, Role, ReportType } from '../../constants';
import { formatApiDate } from '../../utils/formatters';
import { ValueTypes } from '../../utils/values';

class BillingSumForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      terminals: [],
      dealers: [],
      selectedReportType: ReportType.REPORT_TYPE_ORGS,
      submitProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());

    const { params, translate } = this.props;
    this.reportTypeChoices = [
      { id: ReportType.REPORT_TYPE_ORGS, name: translate('telemedic.billingSum.form.reportTypeByOrg') },
      { id: ReportType.REPORT_TYPE_TERMS, name: translate('telemedic.billingSum.form.reportTypeByTerm') },
    ];
    if (getRole() === Role.ADMIN) {
      this.reportTypeChoices.push(
        { id: ReportType.REPORT_TYPE_DEALERS, name: translate('telemedic.billingSum.form.reportTypeByDealers') },
      );
    }

    const previousMonth = moment().subtract(1, 'months');
    this.formInitialValues = (params) || {
      dateFrom: formatApiDate(previousMonth.startOf('month')),
      dateTo: formatApiDate(previousMonth.endOf('month')),
      reportType: ReportType.REPORT_TYPE_ORGS,
      organizations: [],
      terminals: [],
      dealers: [],
    };

    this.requiredFields = [
      'dateFrom', 'dateTo', 'reportType',
    ];

    this.defaultChoiceMenu = [
      {
        label: translate('telemedic.billingSum.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.billingSum.form.selectNone'),
        filter: (list) => [],
      },
    ];

    this.organizationsChoiceMenu = [
      {
        label: translate('telemedic.billingSum.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.billingSum.form.selectWithContract'),
        filter: (list) => list.filter((i) => i['contract_status'] === ContractStatus.CONCLUDED),
      },
      {
        label: translate('telemedic.billingSum.form.selectNone'),
        filter: (list) => [],
      },
    ];
  }

  componentDidMount() {
    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    this.service.getTerminalValues().then((response) => {
      this.setState({ terminals: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    this.service.getDealerValues().then((response) => {
      this.setState({ dealers: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  validate = (values) => {
    const errors = {};
    this.requiredFields.forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  }

  onChange = (data) => {
    const { selectedReportType } = this.state;
    if (data.reportType && selectedReportType !== data.reportType) {
      this.setState({ selectedReportType: data.reportType });
    }
  }

  onSubmit = (data) => {
    const { paramsCallback, dataCallback, showNotification } = this.props;
    paramsCallback(data);
    this.setState({ submitProgress: true });
    const {
      reportType, organizations, terminals, dealers, dateFrom, dateTo,
    } = data;
    let promise;
    if (reportType === ReportType.REPORT_TYPE_ORGS) {
      promise = this.service.getBillingSumByOrganizations(organizations, dateFrom, dateTo);
    } else if (reportType === ReportType.REPORT_TYPE_TERMS) {
      promise = this.service.getBillingSumByTerminals(terminals, dateFrom, dateTo);
    } else if (reportType === ReportType.REPORT_TYPE_DEALERS) {
      promise = this.service.getBillingSumByDealers(dealers, dateFrom, dateTo);
    } else {
      throw new Error('Invalid usage report type');
    }
    promise.then((response) => {
      this.setState({ submitProgress: false });
      if (!response.json.is_empty) {
        dataCallback(response.json);
      } else {
        showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({ submitProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  }

  render() {
    const {
      selectedReportType, organizations, terminals, dealers, submitProgress,
    } = this.state;

    return (
      <Paper>
        <Form
          initialValues={this.formInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateInput
              className={styles.formControl}
              source="dateFrom"
              label="telemedic.billingSum.form.dateFrom"
            />
            <DateInput
              className={styles.formControl}
              source="dateTo"
              label="telemedic.billingSum.form.dateTo"
            />
            <RadioButtonGroupInput
              className={styles.formControl}
              source="reportType"
              label="telemedic.billingSum.form.reportType"
              choices={this.reportTypeChoices}
            />
            <div className={selectedReportType === ReportType.REPORT_TYPE_ORGS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="organizations"
                label="telemedic.billingSum.form.organizations"
                choices={organizations}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.organizationsChoiceMenu}
                fixedWidth
              />
            </div>
            <div className={selectedReportType === ReportType.REPORT_TYPE_TERMS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="terminals"
                label="telemedic.billingSum.form.terminals"
                choices={terminals}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.defaultChoiceMenu}
                fixedWidth
              />
            </div>
            <div className={selectedReportType === ReportType.REPORT_TYPE_DEALERS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="dealers"
                label="telemedic.billingSum.form.dealers"
                choices={dealers}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.defaultChoiceMenu}
                fixedWidth
              />
            </div>
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(BillingSumForm));
