import React from 'react';
import { Create } from 'react-admin';

import OrganizationForm from './OrganizationForm';
import OrganizationTitle from './OrganizationTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const OrganizationCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={OrganizationForm}
    routePath={RoutePath.ORGANIZATION_SHOW}
    title={<OrganizationTitle />}
    {...props}
  />
);

export default OrganizationCreate;
