import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
  ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip,
} from 'recharts';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import ToolBar from '../../components/ToolBar';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';

import styles from './MedicWorkloadDisplay.module.css';
import { formatDisplayDate, formatDisplayDateTime, formatDisplayTime } from '../../utils/formatters';

class _MedicWorkloadDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onExport = () => {
    this.setState({ exportProgress: true });
    const { params } = this.props;
    const { dateFrom, dateTo, medicId } = params;
    const promise = this.service.exportMedicWorkloadReport(medicId, dateFrom, dateTo);
    promise.then(
      () => {
        this.setState({ exportProgress: false });
      }
    ).catch(
      (error) => {
        this.setState({ exportProgress: false });
        this.props.showNotification('telemedic.errors.submitError');
      }
    );
  }

  renderWorkloadTable(stats) {
    if (!stats.length) {
      return (<div />);
    }
    var tableData = [];
    var groupedStats = {};
    for (var i = 0, length = stats.length; i < length; ++i) {
      var periodStats = stats[i];
      var time = formatDisplayTime(periodStats.datetime_from);
      var timeStats = groupedStats[time];
      if (!timeStats) {
        timeStats = {time: time, stats: []};
        groupedStats[time] = timeStats;
        tableData.push(timeStats);
      }
      timeStats.stats.push(periodStats);
    }
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              {timeStats.stats.map((periodStats, index) => (
                <TableCell key={index}>{formatDisplayDate(periodStats.datetime_from)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((timeStats) => this.renderWorkloadTableRow(timeStats))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderTooltip = ({ payload }) => {
    if (!payload || !payload.length) {
      return (<div />);
    }
    const payloadData = payload[0].payload;
    const dtFrom = formatDisplayDateTime(payloadData.datetime_from);
    const dtTo = formatDisplayDateTime(payloadData.datetime_to);
    const checkupsTitle = this.props.translate('telemedic.medicWorkload.display.checkups');
    return (
      <div className={styles.tooltip}>
        {`${dtFrom} - ${dtTo}`}<br />
        {`${checkupsTitle}: ${payloadData.checkups_count}`}
      </div>
    );
  }

  renderWorkloadTableRow(timeStats) {
    return (
      <TableRow key={timeStats.time}>
        <TableCell component="th" scope="row">{timeStats.time}</TableCell>
        {timeStats.stats.map((periodStats, index) => (
          <TableCell key={index}>{periodStats.checkups_count}</TableCell>
        ))}
      </TableRow>
    );
  }

  render() {
    const { translate, medicWorkloadData, onBackClick } = this.props;
    const { exportProgress } = this.state;

    const pageTitle = translate('telemedic.medicWorkload.label');

    let medOrganizationTitle = '';
    let medOrganizationNames = '';
    if (medicWorkloadData.med_organization) {
      medOrganizationTitle = translate('telemedic.medicWorkload.display.medOrganization');
      medOrganizationNames = medicWorkloadData.med_organization.name;
    }

    const { medic } = medicWorkloadData;
    const medicName = `${medic.last_name} ${medic.first_name} ${medic.middle_name}`;

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <div className={styles.userInfo}>
          {!!medOrganizationTitle && (
            <Typography component="div" variant="body1">
              {`${medOrganizationTitle}: ${medOrganizationNames}`}
            </Typography>
          )}
          <Typography component="div" variant="body1">
            {`${translate('telemedic.medicWorkload.display.medic')}: ${medicName}`}
          </Typography>
          {!!medic.ecp && (
            <Typography component="div" variant="body1">
              {`${translate('telemedic.medicWorkload.display.ecp')}: ${medic.ecp}`}
            </Typography>
          )}
          {!!medic.ecp_date_to && (
            <Typography component="div" variant="body1">
              {`${translate('telemedic.medicWorkload.display.validUntil')}: ${formatDisplayDate(medic.ecp_date_to)}`}
            </Typography>
          )}
        </div>
        <div className={styles.chartWrapper}>
          <ResponsiveContainer>
            <BarChart data={medicWorkloadData.stats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="datetime_from" tickFormatter={formatDisplayDate} />
              <YAxis/>
              <Tooltip content={this.renderTooltip} />
              <Bar type="monotone" dataKey="checkups_count" fill="#6298e1" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        {this.renderWorkloadTable(medicWorkloadData.stats)}
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(_MedicWorkloadDisplay));
