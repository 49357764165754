import React, { useState } from 'react';
import { Title } from 'react-admin';

import BillingSumForm from './BillingSumForm';
import BillingSumDisplay from './BillingSumDisplay';

const BillingSumPage = () => {
  const [formParams, setFormParams] = useState(null);
  const [billingSumData, setBillingSumData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  };

  const dataCallback = (billingSumData) => {
    setBillingSumData(billingSumData);
  };

  const onBackClick = () => {
    setBillingSumData(null);
  };

  return (
    <div>
      <Title title="telemedic.billingSum.label" />
      {!billingSumData && (
        <BillingSumForm
          params={formParams}
          paramsCallback={paramsCallback}
          dataCallback={dataCallback}
        />
      )}
      {!!billingSumData && (
        <BillingSumDisplay
          params={formParams}
          billingSumData={billingSumData}
          onBackClick={onBackClick}
        />
      )}
    </div>
  );
};

export default BillingSumPage;
