import React from 'react';

import Paper from '@material-ui/core/Paper';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import styles from './ImageList.module.css';

const ImageList = ({images, title}) => {
  return (
    <Paper className={styles.container}>
      {!!images && images.map((src, index) => (
        <div key={index} className={styles.imageWrapper}>
          <Zoom>
            <img className={styles.image} src={src} alt=""/>
          </Zoom>
        </div>
      ))}
      {!!title &&
        <div className={styles.title}>{title}</div>
      }
    </Paper>
  );
}

export default ImageList;
