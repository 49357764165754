import React from 'react';
import PropTypes from 'prop-types';

import MuiTableRow from '@material-ui/core/TableRow';

import styles from './TableRow.module.css';

const TableRow = ({className, clickable, ...rest}) => {
  const classNames = [];
  if (className) {
    classNames.push(className);
  }
  if (clickable) {
    classNames.push(styles.clickable);
  }
  const resultClassName = classNames.join(' ');
  return (
    <MuiTableRow className={resultClassName} {...rest}/>
  );
}

TableRow.propTypes = {
  className: PropTypes.string,
  clickable: PropTypes.bool
};

export default TableRow;
