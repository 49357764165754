import React from 'react';
import lodash from 'lodash';
import { useTranslate } from 'react-admin';

import Video from '../media/Video';

const VideoField = ({
  label, optional, record, source, autoplay = false, loop = false
}) => {
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const src = record ? lodash.get(record, source) : null;
  return (
    (!optional || src)
      ? <Video title={labelText} src={src} autoplay={autoplay} loop={loop} />
      : null
  );
};

export default VideoField;
