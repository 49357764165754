import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import styles from './Signature.module.css';

const Signature = ({ record, source }) => {
  const signature = lodash.get(record, source);
  return signature ? (
    <img src={signature} alt="" className={styles.signature} />
  ) : null;
}

Signature.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

Signature.defaultProps = {
  record: {},
  source: null,
};

export default Signature;
