import React from 'react';
import {Fragment} from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {minWidth: theme.spacing(5)},
  sidebarIsOpen: {
    '& a, li': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a, li': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  text,
  icon,
  children,
  dense,
}) => {
  const classes = useStyles();

  let expandChildren;
  if (typeof(isOpen) !== 'undefined') {
    expandChildren = isOpen;
  }
  else {
    expandChildren = false;
    const currentPath = document.location.hash.substring(1);
    React.Children.forEach(children, (child) => {
      if (child !== null && currentPath === child.props.to) {
        expandChildren = true;
      }
    });
  }

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {expandChildren ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {text}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || expandChildren ? (
        header
      ) : (
        <Tooltip title={text} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={expandChildren} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
