import React from 'react';
import {TextInput, required} from 'react-admin';

import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';

const DealersResponsibleForm = ({getInputProps, ...rest}) => {
  return(
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.dealersResponsible.form.name"
        source="name"
        validate={required()}
        {...getInputProps('name')}/>
      <TextInput
        label="telemedic.dealersResponsible.form.comment"
        source="comment"
        {...getInputProps('comment')}/>
    </RestrictedSimpleForm>
  );
}

export default DealersResponsibleForm;
