import React from 'react';
import {useTranslate} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import {formatDisplayDate} from '../../utils/formatters';

const StatisticsHeader = ({statisticsData}) => {
  const translate = useTranslate();
  const statsPeriod = translate('telemedic.statistics.display.statsPeriod');
  const periodFrom = translate('telemedic.statistics.display.periodFrom');
  const dateFrom = formatDisplayDate(statisticsData.date_from);
  const periodTo = translate('telemedic.statistics.display.periodTo');
  const dateTo = formatDisplayDate(statisticsData.date_to);
  const statsInfoStyle = {marginBottom: 20}
  return (
    <div style={statsInfoStyle}>
      <Typography component="div" variant="body1">
        {statsPeriod}: {periodFrom} {dateFrom} {periodTo} {dateTo}
      </Typography>
      <Typography component="div" variant="body1">
        {translate('telemedic.statistics.display.organization')}: {statisticsData.organization.name}
      </Typography>
    </div>
  );
};

export default StatisticsHeader;
