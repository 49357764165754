import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ProgressButton from './ProgressButton';
import styles from './DropDownMenu.module.css';

const DropDownMenu = ({
  choices, labelId, progress, icon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleButtonClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <>
      <ProgressButton
        className={styles.dropDownButton}
        color="primary"
        icon={icon}
        label={labelId}
        onClick={handleButtonClick}
        progress={progress}
        variant="contained"
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        {
          choices.map((choice) => (
            <DropDownMenuItem
              key={choice.id}
              action={choice.action}
              labelId={choice.labelId}
              onActionSelected={handleMenuClose}
            />
          ))
        }
      </Menu>
    </>
  );
};

DropDownMenu.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func.isRequired,
    labelId: PropTypes.string.isRequired,
  })).isRequired,
  labelId: PropTypes.string.isRequired,
  progress: PropTypes.bool,
  icon: PropTypes.elementType,
};

DropDownMenu.defaultProps = {
  progress: false,
  icon: undefined,
};

const DropDownMenuItem = ({ action, labelId, onActionSelected }) => {
  const translate = useTranslate();
  const labelText = translate(labelId);
  const handleClick = useCallback(() => {
    action();
    onActionSelected();
  }, [action, onActionSelected]);
  return (
    <MenuItem onClick={handleClick}>{labelText}</MenuItem>
  );
};

DropDownMenuItem.propTypes = {
  action: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  onActionSelected: PropTypes.func.isRequired,
};

export default DropDownMenu;
