import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

const progressStyle = {
  position: 'absolute',
};

const ProgressIconButton = ({
  className,
  type,
  variant,
  color,
  icon,
  onClick,
  progress,
  size,
  disabled
}) => {
  const iconStyle = progress ? {color: 'transparent'} : null;
  return (
    <IconButton
      className={className}
      type={type}
      variant={variant}
      color={color}
      disabled={progress || disabled}
      icon={icon}
      onClick={onClick}
      size={size}
    >
      <span style={iconStyle}>{icon}</span>
      {progress && <CircularProgress size={24} style={progressStyle}/>}
    </IconButton>
  );
};

export default ProgressIconButton;
