class IndexedList {
  constructor(l) {
    this._list = l || [];
    this._index = {};
    for (let item of this._list) {
      this._index[item.id] = item;
    }
  }

  getList() {
    return this._list;
  }

  getUpdated(updates) {
    const newList = [...this._list];
    const newItems = [];
    for (let item of updates) {
      const itemId = item.id;
      const indexedItem = this._index[itemId];
      if (indexedItem) {
        // side effect
        Object.assign(indexedItem, item);
      }
      else {
        newList.unshift(item);
        newItems.unshift(item);
      }
    }
    return [new IndexedList(newList), newItems];
  }
}

export default IndexedList;
