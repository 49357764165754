import React, {useCallback} from 'react';

import MaskedTextInput from './MaskedTextInput';

const PhoneInput = (props) => {
  const valueFormat = useCallback((v) => v, []);
  const valueParse = useCallback((v) => {
    if (!v) {
      return {parsedValue: ''};
    }
    const cleaned = v.replace(/[^0-9]/g,'');
    if (cleaned.length === 11) {
      return {parsedValue: cleaned.substring(1)};
    }
    else {
      return {parseError: 'telemedic.errors.formatError'};
    }
  }, []);
  return (
    <MaskedTextInput
      mask="+7 (999) 999 99 99"
      valueFormat={valueFormat}
      valueParse={valueParse}
      {...props}/>
  );
}

export default PhoneInput;
