import React from 'react';
import {NumberInput, required} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {parseQueryParams} from '../../utils/urls';

const DealerPaymentForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const dealers = useTelemedicServiceJsonValue(() => {
    return service.getDealerPaymentFormDealerValues();
  }, null, []);
  const queryParams = parseQueryParams(window.location.href.split('?')[1]);
  const initialValues = {
    dealer_id: queryParams.dealerId
  };
  return(
    <RestrictedSimpleForm initialValues={initialValues} {...rest}>
      <AutocompleteInput
        label="telemedic.dealerPayments.form.dealer"
        choices={dealers}
        choiceLabelField="name"
        choiceValueField="id"
        source="dealer_id"
        {...getInputProps('dealer_id')}/>
      <NumberInput
        label="telemedic.dealerPayments.form.amount"
        source="amount"
        validate={required()}
        {...getInputProps('amount')}/>
    </RestrictedSimpleForm>
  );
}

export default DealerPaymentForm;
