import React, {useCallback} from 'react';

import MaskedTextInput from './MaskedTextInput';
import {formatApiDate, formatDisplayDate, parseDisplayDate} from '../../utils/formatters';

const MaskedDateInput = (props) => {
  const valueFormat = useCallback((v) => {
    return formatDisplayDate(v);
  }, []);
  const valueParse = useCallback((v) => {
    if (!v) {
      return {parsedValue: null};
    }
    const date = parseDisplayDate(v);
    if (date) {
      return {parsedValue: formatApiDate(date)};
    }
    else {
      return {parseError: 'telemedic.errors.formatError'};
    }
  }, []);
  return (
    <MaskedTextInput
      mask="99.99.9999"
      valueFormat={valueFormat}
      valueParse={valueParse}
      {...props}/>
  );
}

export default MaskedDateInput;
