import React from 'react';
import {Filter, Datagrid, TextField, TextInput} from 'react-admin';

import RestrictedList from '../../components/admin/RestrictedList';

export const DealerBalanceList = (props) => (
    <RestrictedList
      title="telemedic.dealerBalance.label"
      filters={<DealerBalanceFilter/>}
      sort={{field: 'name', order: 'ASC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <TextField
          label="telemedic.dealerBalance.list.name"
          source="name"
          sortable={true}/>
        <TextField
          label="telemedic.dealerBalance.list.balance"
          source="balance"
          sortable={true}/>
      </Datagrid>
    </RestrictedList>
);

const DealerBalanceFilter = (props) => (
    <Filter key="dealer_balance_filter" {...props}>
      <TextInput
        label="telemedic.dealerBalance.list.name"
        source="name"
        alwaysOn resettable/>
    </Filter>
);
