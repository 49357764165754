import React from 'react';
import { SimpleShowLayout, TextField, useTranslate } from 'react-admin';

import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ImageField from '../../components/fields/ImageField';
import MedOrganizationTitle from './MedOrganizationTitle';
import RestrictedShow from '../../components/admin/RestrictedShow';
import { formatPersonName, formatDisplayDate } from '../../utils/formatters';

const MedOrganizationShow = (props) => {
  const translate = useTranslate();
  const unlimitedLicense = translate('telemedic.medOrganizations.show.unlimitedLicense');

  return (
    <RestrictedShow title={<MedOrganizationTitle />} {...props}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <TextField label="telemedic.common.kisArtId" source="kis_art_id" />
            <TextField label="telemedic.medOrganizations.show.inn" source="inn" />
            <TextField label="telemedic.medOrganizations.show.kpp" source="kpp" />
            <TextField label="telemedic.medOrganizations.show.name" source="name" />
            <TextField label="telemedic.medOrganizations.show.shortName" source="short_name" />
            <TextField label="telemedic.medOrganizations.show.phone" source="phone" />
            <TextField label="telemedic.medOrganizations.show.address" source="address" />
            <TextField label="telemedic.medOrganizations.show.licenseSerial" source="license_serial" />
            <TextField label="telemedic.medOrganizations.show.licenseNumber" source="license_number" />
            <FormatValueField
              label="telemedic.medOrganizations.show.licenseDateFrom"
              source="license_date_from"
              formatter={formatDisplayDate}
              fallback={unlimitedLicense}
            />
            <FormatValueField
              label="telemedic.medOrganizations.show.licenseDateTo"
              source="license_date_to"
              formatter={formatDisplayDate}
              fallback={unlimitedLicense}
            />
            <FormatValueField
              label="telemedic.medOrganizations.show.director"
              source="director"
              formatter={formatPersonName}
            />
            <FormatValueField
              label="telemedic.medOrganizations.show.partner"
              source="partner"
              formatter={formatPersonName}
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <ImageField
            label="telemedic.medOrganizations.show.license"
            source="license"
          />
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

export default MedOrganizationShow;
