import React, { useCallback, useState } from 'react';
import {
  Filter, Datagrid, SelectInput, TextField, TextInput, FunctionField, 
} from 'react-admin';

import moment from 'moment';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import useTelemedicService from '../../hooks/useTelemedicService';
import { createTelemedicService } from '../../services/TelemedicService';
import useInterval from '../../hooks/useInterval';
import {
  formatDisplayDate, formatDisplayInterval, formatQuantity,
} from '../../utils/formatters';
import styles from './TerminalList.module.css';
import { downloadExcel } from '../../utils/download';

import * as XLSX from 'xlsx';


const OnlineIndicator = ({ show }) => (show ? <div className={styles.online} /> : null);

const exporter = async () => {
  const service = createTelemedicService();

  try {
    const response = await service.fullExportTerminal(); // Дождаться получения данных
    const terminals = response.json.data;
    console.log('Response:', response); 
    console.log('Terminals:', terminals);


    if (!terminals || terminals.length === 0) {
      console.warn('No terminals data received');
      return;
    }

    // Преобразование данных терминалов
    const terminalsForExport = terminals.map((terminal) => ({
      'ID Терминала': terminal.id,
      'Имя терминала': terminal.attributes.name,
      'Тип терминала': terminal.attributes.type,
      'Модель терминала': terminal.attributes.model,
      'Дата сборки': terminal.attributes.build_date,
      'Дата поверки': terminal.attributes.verification_date,
      'Deadline': terminal.attributes.deadline,
      'Номер AnyDesk/OpenVPN': terminal.attributes.teamview_login,
      'Пароль AnyDesk/OpenVPN': terminal.attributes.teamview_password,
      'Пароль администратора терминала': terminal.attributes.conf_adm_pass,
      'Модель алкотестера': terminal.attributes.breathalyzer_model,
      'Серийный номер алкотестера': terminal.attributes.breathalyzer_serial,
      'Модель пирометра': terminal.attributes.pyrometer_model,
      'Серийный номер пирометра': terminal.attributes.pyrometer_serial,
      'Модель тонометра': terminal.attributes.tonometer_model,
      'Серийный номер тонометра': terminal.attributes.tonometer_serial,
      'Модель ПК/моноблока': terminal.attributes.pc_model,
      'Модель камеры': terminal.attributes.camera_model,
      'Модель принтера': terminal.attributes.printer_model,
      'ПП866': terminal.attributes.decree_866,
      'Температура окр. среды': terminal.attributes.ambient_temperature,
      'Освещенность': terminal.attributes.illuminance,
      'Влажность': terminal.attributes.humidity,
      'Контрольный хэш': terminal.attributes.control_hash,
      'Текущий хэш': terminal.attributes.current_hash,
      'Датчик вскрытия': terminal.attributes.tamper_detected,
      'Энергонезависимое состояние корпуса': terminal.attributes.cover_energ,
      'Имя терминала': terminal.attributes.name,
      'Регион': terminal.attributes.region,
      'Часовой пояс': terminal.attributes.timezone,
      'Организации': terminal.attributes.organizations.map(org => org.name).join(', '), // Названия организаций
      'Пункт осмотра': terminal.attributes.inspection_point,
      'Комментарии': terminal.attributes.comments,
      'Сообщение': terminal.attributes.message,
      'Телефон врача': terminal.attributes.medic_phone,
      'Автодопуск': terminal.attributes.sett_autoinspect,
      'Последний отклик': terminal.attributes.last_activity_time,
      'Широта': terminal.attributes.latitude,
      'Долгота': terminal.attributes.longitude,
    }));

    // Создание книги (Workbook) для Excel
    const worksheet = XLSX.utils.json_to_sheet(terminalsForExport); // Преобразование JSON в лист

    // Установка ширины колонок на основе длины заголовков
    const columnWidths = Object.keys(terminalsForExport[0]).map((key) => ({ wch: key.length + 5 }));
    worksheet['!cols'] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Терминалы'); // Добавляем лист в книгу

    // Генерация файла Excel и его скачивание
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    downloadExcel(blob, 'terminals'); // Скачать файл
  } catch (error) {
    console.error('Error fetching terminal data:', error);
  }
};

const TerminalList = (props) => {
  const formatTimezone = (offsetInHours) => {
    if (offsetInHours) {
      return formatQuantity(offsetInHours, 'час', 'часа', 'часов');
    }
    return '';
  };
  const formatLastActivityTime = (timeString) => {
    if (timeString) {
      const now = moment().valueOf();
      const time = moment(timeString).valueOf();
      return `${formatDisplayInterval((now - time) / 1000)} назад`;
    }
    return '';
  };
  const organizationsCountFormatter = (organizations) => {
    if (organizations) return organizations.length;
    return 0;
  };
  const service = useTelemedicService();
  const [onlineTerminals, setOnlineTerminals] = useState([]);
  if (onlineTerminals === undefined) {
    setOnlineTerminals([]);
  }
  const intervalCallback = useCallback(() => {
    service.getActiveTerminals()
      .then((r) => {
        setOnlineTerminals(r.json.ids);
      })
      .catch((e) => console.log(e));
  }, [service]);
  useInterval(intervalCallback, 30000, true);
  return (
    <RestrictedList
      title="telemedic.terminals.label"
      filters={<TerminalFilter />}
      exporter={exporter}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.name"
          source="name"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.teamViewerLogin"
          source="teamview_login"
          sortable={false}
        />
        <FormatValueField
          label="telemedic.terminals.list.buildDate"
          source="build_date"
          sortable
          formatter={formatDisplayDate}
        />
        <FormatValueField
          label="telemedic.terminals.list.deadline"
          source="deadline"
          sortable
          formatter={formatDisplayDate}
        />
        <TextField
          label="telemedic.terminals.list.type"
          source="type"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.version"
          source="version"
          sortable
        />
        <FormatValueField
          label="telemedic.terminals.list.timezone"
          source="timezone"
          sortable
          formatter={formatTimezone}
        />
        <FunctionField
          label="telemedic.terminals.list.isOnline"
          render={
            (record) => <OnlineIndicator show={onlineTerminals.includes(record.id)} />
          }
        />
        <FormatValueField
          label="telemedic.terminals.list.lastActivityTime"
          source="last_activity_time"
          sortable
          formatter={formatLastActivityTime}
        />
        <FormatValueField
          label="telemedic.terminals.list.organizationsCount"
          source="organizations"
          sortable={false}
          formatter={organizationsCountFormatter}
        />
      </Datagrid>
    </RestrictedList>
  );
};

const dealersDataPromise = (service) => service.getDealerValues();

const organizationsDataPromise = (service) => service.getOrganizationValues();

const TerminalFilter = (props) => (
  <Filter key="terminal_filter" {...props}>
    <TextInput
      label="telemedic.terminals.list.id"
      source="id"
      resettable
    />
    <TextInput
      label="telemedic.terminals.list.name"
      source="name"
      alwaysOn
      resettable
    />
    <SelectInput
      label="telemedic.terminals.list.message"
      source="message"
      choices={[
        { id: 'true', name: 'telemedic.terminals.list.withMessage' },
        { id: 'false', name: 'telemedic.terminals.list.withoutMessage' },
      ]}
      alwaysOn
      resettable
    />
    <TelemedicDataSource
      dataPromise={dealersDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.terminals.list.dealer"
        choiceLabelField="name"
        choiceValueField="id"
        source="dealer_id"
      />
    </TelemedicDataSource>
    <TelemedicDataSource
      dataPromise={organizationsDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.terminals.list.organization"
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
      />
    </TelemedicDataSource>
  </Filter>
);

export { TerminalList };
