import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import styles from './Info.module.css';
import { mergeClassNames } from '../utils/styles';

const STYLES_MAP = {
  info: styles.info,
  warning: styles.warning,
  error: styles.error,
};

const Info = ({
  align, children, className, level, variant,
}) => {
  const mergedClassName = mergeClassNames(STYLES_MAP[level], className);
  return (
    <Typography align={align} className={mergedClassName} variant={variant}>
      {children}
    </Typography>
  );
};

Info.propTypes = {
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  level: PropTypes.string,
  variant: PropTypes.string,
};

Info.defaultProps = {
  align: undefined,
  children: undefined,
  className: undefined,
  level: 'info',
  variant: undefined,
};

export default Info;
