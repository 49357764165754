import React from 'react';
import {TextField} from 'react-admin'
import lodash from 'lodash';

const ErrorField = (props) => {
  const {record, source} = props;
  const value = lodash.get(record, source);
  if (!value) {
    return null;
  }
  return (
    <TextField color="error" {...props}/>
  );
}

export default ErrorField;
