import React from 'react';
import { Edit } from 'react-admin';

import WorkerForm from './WorkerForm';
import WorkerTitle from './WorkerTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const WorkerEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={WorkerForm}
    routePath={RoutePath.WORKER_SHOW}
    title={<WorkerTitle />}
    {...props}
  />
);

export default WorkerEdit;
