import React from 'react';
import { Create } from 'react-admin';

import EWaybillForm from './EWaybillForm';
import EWaybillTitle from './EWaybillTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const EWaybillCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={EWaybillForm}
    routePath={RoutePath.EWAYBILL_SHOW}
    title={<EWaybillTitle />}
    {...props}
  />
);

export default EWaybillCreate;
