import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import BarChartIcon from '@material-ui/icons/BarChart';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import {
  ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip,
} from 'recharts';

import ProgressButton from '../../components/ProgressButton';
import ToolBar from '../../components/ToolBar';
import styles from './UsageDisplay.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';

const TITLES = {};

class UsageDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStats: null,
      exportProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  closeStatsDialog = () => {
    this.setState({ selectedStats: null });
  };

  export = () => {
    const { showNotification, params } = this.props;
    this.setState({ exportProgress: true });
    const {
      organizations, dateFrom, dateTo, techInspectionTypes,
    } = params;
    this.service.exportUsageCheckupsByOrganizations(organizations, dateFrom, dateTo, techInspectionTypes)
      .then(() => {
        this.setState({ exportProgress: false });
      }).catch((e) => {
        console.log(e);
        this.setState({ exportProgress: false });
        showNotification('telemedic.errors.submitError');
      });
  };

  openStatsDialog(stats) {
    this.setState({ selectedStats: stats });
  }

  renderUsageChart(stats) {
    return (
      <div className={styles.chartWrapper}>
        <ResponsiveContainer>
          <BarChart data={stats.tech_inspections_by_days}>
            <XAxis dataKey="date" />
            <YAxis domain={[0, 'dataMax']} />
            <Tooltip content={this.renderTooltip} />
            <Bar type="monotone" dataKey="tech_inspections" fill="#6298e1" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderTooltip = ({ payload }) => {
    const { translate } = this.props;
    if (!payload || !payload.length) {
      return (<div />);
    }
    const payloadData = payload[0].payload;
    const dateLabel = translate('telemedic.usage.display.date');
    const techInspectionsLabel = translate('telemedic.usage.display.techInspectionsCount');
    return (
      <div className={styles.tooltip}>
        {`${dateLabel}: ${payloadData.date}`}
        <br />
        {`${techInspectionsLabel}: ${payloadData.tech_inspections}`}
      </div>
    );
  }

  renderOrganizationsTable(data) {
    const { translate } = this.props;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('telemedic.usage.display.columnOrganization')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnContract')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnRegion')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnPrice')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnCheckupsAveragePerDay')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnCheckups')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnCheckupsWithoutRepeats')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnTotalPrice')}</TableCell>
              <TableCell>{translate('telemedic.usage.display.columnTotalPriceDistinct')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.organizations.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.dogovor}</TableCell>
                <TableCell>{row.region}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.tech_inspections_average_per_day}</TableCell>
                <TableCell>{row.tech_inspections}</TableCell>
                <TableCell>{row.tech_inspections_without_repeats}</TableCell>
                <TableCell>{row.total_price}</TableCell>
                <TableCell>{row.total_price_distinct}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => this.openStatsDialog(row)}>
                    <BarChartIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4}>{translate('telemedic.usage.display.total')}</TableCell>
              <TableCell>{data.total.tech_inspections_average_per_day}</TableCell>
              <TableCell>{data.total.tech_inspections}</TableCell>
              <TableCell>{data.total.tech_inspections_without_repeats}</TableCell>
              <TableCell>{data.total.total_price}</TableCell>
              <TableCell>{data.total.total_price_distinct}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const {
      params, usageData, onBackClick, translate,
    } = this.props;
    const { selectedStats, exportProgress } = this.state;

    const pageTitle = translate(TITLES[params.reportType]);

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.export}
            progress={exportProgress}
          />
        </ToolBar>
        {this.renderUsageChart(usageData.total)}
        {!!usageData.organizations && this.renderOrganizationsTable(usageData)}
        {
          !!selectedStats && (
            <Dialog fullWidth maxWidth="md" open onClose={this.closeStatsDialog}>
              <DialogTitle className={styles.dialogTitle}>
                {selectedStats.name}
                <IconButton onClick={this.closeStatsDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {this.renderUsageChart(selectedStats)}
              </DialogContent>
            </Dialog>
          )
        }
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(UsageDisplay));
