import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const ResettableTextField = ({
  InputProps,
  onReset,
  resettable,
  value,
  ...rest
}) => {
  let resettableInputProps;
  if (resettable) {
    const endAdornment = (
      <IconButton
        size="small"
        style={value ? null : hiddenStyle}
        onClick={onReset}
      >
        <ClearIcon fontSize="small"/>
      </IconButton>
    );
    resettableInputProps = {
      endAdornment: endAdornment,
      ...InputProps
    }
  }
  else {
    resettableInputProps = InputProps;
  }
  return (
    <TextField
      InputProps={resettableInputProps}
      value={value}
      {...rest}
    />
  );
}

const hiddenStyle = {
  visibility: 'hidden'
}

ResettableTextField.propTypes = {
  onReset: PropTypes.func,
  resettable: PropTypes.bool,
};

export default ResettableTextField;
