import logo from './logo.png'
import nophoto from './nophoto.jpg'
import noqr from './noQRCode.png'
import novideo from './novideo.jpg'

export default {
  logo,
  nophoto,
  noqr,
  novideo
}
