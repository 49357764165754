import React, { useCallback, useState } from 'react';

import ProgressIconButton from './ProgressIconButton';

const PromiseIconButton = ({
  promiseFactory, onSuccess, onError, ...rest
}) => {
  const [progress, setProgress] = useState(false);
  const handleClick = useCallback(() => {
    setProgress(true);
    promiseFactory().then(() => {
      setProgress(false);
      if (onSuccess) onSuccess();
    }).catch((e) => {
      setProgress(false);
      if (onError) onError(e);
    });
  }, [promiseFactory, onSuccess, onError]);
  return (
    <ProgressIconButton
      onClick={handleClick}
      progress={progress}
      {...rest}
    />
  );
};

export default PromiseIconButton;
