import React from 'react';
import {useTranslate} from 'react-admin';

import ImageList from '../media/ImageList';

const ImageListField = ({label, record, source}) => {
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const images = (record && record[source]) ? record[source] : [];
  return (
    <ImageList images={images} title={labelText}/>
  );
}

export default ImageListField;
