import React from 'react';
import {connect} from 'react-redux';
import {showNotification} from 'react-admin';

import {ErrorCode} from '../../constants';
import {navigateTo} from '../../routes';

class PessimisticMutationMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      fieldErrors: null
    }
  }

  render() {
    const {
      Component, Form, routePath, showNotification, ...rest
    } = this.props;
    return (
      <Component
        mutationMode="pessimistic"
        onSuccess={this.onSuccess}
        onFailure={this.onFailure}
        {...rest}
      >
        <Form getInputProps={this.getInputProps}/>
      </Component>
    );
  }

  getInputProps = (source) => {
    const {isError, fieldErrors} = this.state;
    if (isError && fieldErrors && fieldErrors[source]) {
      const helperText = Array.isArray(fieldErrors[source])
        ? fieldErrors[source][0]
        : fieldErrors[source];
      return {
        error: true,
        helperText: helperText
      }
    }
    return {};
  }

  onSuccess = ({data}) => {
    this.setState({
      isError: false,
      fieldErrors: null
    });
    navigateTo(this.props.routePath, {id: data.id});
  }

  onFailure = (e) => {
    console.log(e);
    const message = e.message;
    const fieldErrors = (message.error_code === ErrorCode.INVALID_DATA)
      ? message.error_data
      : null;
    this.setState({
      isError: true,
      fieldErrors: fieldErrors
    });
    this.props.showNotification(message.error_description, 'warning');
  }
}

export default connect(null, {showNotification})(PessimisticMutationMode);
