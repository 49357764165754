import React from 'react';
import {useTranslate} from 'react-admin';

const VehicleTitle = ({record}) => {
  const typeName = useTranslate()('telemedic.vehicles.show.label');
  return (
    <span>{
      (record && record.car_license_plate)
      ? `${typeName} ${record.car_license_plate}`
      : typeName
    }</span>
  );
};

export default VehicleTitle;
