import { getEnvVariable } from './utils/env';

export const API_ROOT = getEnvVariable(
  'REACT_APP_API_ROOT',
  'http://localhost:5000/api',
);
export const WS_ROOT = getEnvVariable(
  'REACT_APP_WS_ROOT',
  'ws://localhost:4000/',
);
export const WS_SECRET_KEY = getEnvVariable(
  'REACT_APP_WS_SECRET_KEY',
  'test-key',
);
