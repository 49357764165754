import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './CheckupsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService'
import {getAuthToken} from '../../storages/auth';
import {formatDisplayDateTime, formatDisplayInterval} from '../../utils/formatters';

const PERIOD_TYPE_REST = 0;
const PERIOD_TYPE_WORKING = 1;
const PERIOD_TYPE_STRINGS = {};
PERIOD_TYPE_STRINGS[PERIOD_TYPE_REST] = 'telemedic.statistics.workingAndRestTime.periodRest';
PERIOD_TYPE_STRINGS[PERIOD_TYPE_WORKING] = 'telemedic.statistics.workingAndRestTime.periodWorking';
const OVERWORKING_THRESHOLD = 12 * 60 * 60;
const UNDERRESTING_THRESHOLD = 12 * 60 * 60;

class WorkingAndRestTimeDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statisticsData: null,
      exportProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  getRowStyle = (periodType, period) => {
    const { isColored } = this.props;
    let rowStyle;
    if (isColored && periodType === PERIOD_TYPE_WORKING && period > OVERWORKING_THRESHOLD) {
      rowStyle = styles.overworkRow;
    } else if (isColored && periodType === PERIOD_TYPE_REST && period > 0 && period < UNDERRESTING_THRESHOLD) {
      rowStyle = styles.underrestRow;
    }
    return rowStyle;
  }

  renderTable(data) {
    const { translate } = this.props;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('telemedic.statistics.workingAndRestTime.columnFullName')}</TableCell>
              <TableCell>{translate('telemedic.statistics.workingAndRestTime.columnPeriod')}</TableCell>
              <TableCell>{translate('telemedic.statistics.workingAndRestTime.columnInterval')}</TableCell>
              <TableCell>{translate('telemedic.statistics.workingAndRestTime.columnTotal')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.users.map(this.renderUserRows)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderUserRows = (user, index) => {
    const { translate } = this.props;
    const periods = user.periods;
    const periodsCount = periods.length;
    const rows = [];
    const formatPeriod = (periodType, dateTimeFrom, dateTimeTo) => {
      const periodTypeText = translate(PERIOD_TYPE_STRINGS[periodType]);
      const fromText = formatDisplayDateTime(dateTimeFrom);
      const toText = formatDisplayDateTime(dateTimeTo);
      return `${periodTypeText}: ${fromText} - ${toText}`;
    }
    if (periodsCount > 0) {
      const period = periods[0];
      const firstRowStyle = this.getRowStyle(period.period_type, period.interval);
      rows.push(
        <TableRow key={user.id}>
          <TableCell rowSpan={periodsCount}>
            {user.last_name} {user.first_name} {user.middle_name}
          </TableCell>
          <TableCell className={firstRowStyle}>
            {formatPeriod(period.period_type, period.datetime_from, period.datetime_to)}
          </TableCell>
          <TableCell className={firstRowStyle}>
            {formatDisplayInterval(period.interval)}
          </TableCell>
          <TableCell rowSpan={periodsCount}>
            {translate('telemedic.statistics.workingAndRestTime.periodWorking')}:{' '}
            {formatDisplayInterval(user.working_time)}
            <br />
            {translate('telemedic.statistics.workingAndRestTime.periodRest')}:{' '}
            {formatDisplayInterval(user.rest_time)}
          </TableCell>
        </TableRow>
      );
    }
    for (let i = 1; i < periodsCount; i++) {
      const period = periods[i];
      rows.push(
        <TableRow key={user.id + '_' + i} className={this.getRowStyle(period.period_type, period.interval)}>
          <TableCell>
            {formatPeriod(period.period_type, period.datetime_from, period.datetime_to)}
          </TableCell>
          <TableCell>{formatDisplayInterval(period.interval)}</TableCell>
        </TableRow>
      );
    }
    return rows;
  }

  render() {
    const {statisticsData, onBackClick, translate} = this.props;
    const exportProgress = this.state.exportProgress;

    const pageTitle = translate('telemedic.statistics.workingAndRestTime.title');

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon/>}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData}/>
        {this.renderTable(statisticsData)}
      </Card>
    );
  }

  onExport = () => {
    this.setState({exportProgress: true});
    const { isColored, params } = this.props;
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId
    } = params;
    const promise = this.service.exportWorkingAndRestTime(
      dateFrom, dateTo, checkupType, organizationId, terminalId, isColored
    );
    promise.then(() => {
      this.setState({exportProgress: false});
    }).catch((e) => {
      console.log(e);
      this.setState({exportProgress: false});
      this.props.showNotification('telemedic.errors.submitError');
    });
  }
}

export default connect(null, {showNotification})(withTranslate(WorkingAndRestTimeDisplay));
