import React, { useState } from 'react';
import { Title } from 'react-admin';

import StatisticsForm from './StatisticsForm';
import TechInspectionsDisplay from './TechInspectionsDisplay';
import TechInspectionsExtendedDisplay from './TechInspectionsExtendedDisplay';

import {
  REPORT_TYPE_INSPECTIONS,
  REPORT_TYPE_INSPECTIONS_EXTENDED,
  REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS,
} from './constants';

const StatisticsTechPage = () => {
  const [formParams, setFormParams] = useState(null);
  const [statisticsData, setStatisticsData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  };

  const dataCallback = (statisticsData) => {
    setStatisticsData(statisticsData);
  };

  const onBackClick = () => {
    setStatisticsData(null);
  };

  const reportType = statisticsData ? formParams.reportType : null;
  return (
    <div>
      <Title title="telemedic.statistics.label" />
      {
        !statisticsData && (
          <StatisticsForm
            params={formParams}
            paramsCallback={paramsCallback}
            dataCallback={dataCallback}
          />
        )
      }
      {
        reportType === REPORT_TYPE_INSPECTIONS && (
          <TechInspectionsDisplay
            label="telemedic.statistics.checkups.title"
            params={formParams}
            statisticsData={statisticsData}
            onBackClick={onBackClick}
          />
        )
      }
      {
        reportType === REPORT_TYPE_INSPECTIONS_EXTENDED && (
          <TechInspectionsExtendedDisplay
            params={formParams}
            statisticsData={statisticsData}
            onBackClick={onBackClick}
          />
        )
      }
      {
        reportType === REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS && (
          <TechInspectionsDisplay
            label="telemedic.statistics.checkups.titleWithoutRepeats"
            params={formParams}
            statisticsData={statisticsData}
            onBackClick={onBackClick}
          />
        )
      }
    </div>
  );
};

export default StatisticsTechPage;
