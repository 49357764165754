import { useCallback, useState } from 'react';

import { ErrorCode } from '../constants';

const formatErrors = (errors) => {
  if (Array.isArray(errors)) return errors.join(', ');
  return errors;
};

const useFormResponseHandler = (options) => {
  const { defaultError } = options;
  const [success, setSuccess] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const handleSuccess = useCallback(() => {
    setSuccess(true);
    setGlobalError(null);
    setFieldErrors({});
  }, []);
  const handleError = useCallback((e) => {
    setSuccess(false);
    setGlobalError(e.errorDescription || defaultError);
    if (e.errorCode === ErrorCode.INVALID_DATA) {
      setFieldErrors(e.errorData || {});
    } else {
      setFieldErrors({});
    }
  }, [defaultError]);
  const getFieldProps = useCallback((fieldName) => {
    if (fieldErrors[fieldName]) {
      return {
        error: true,
        helperText: formatErrors(fieldErrors[fieldName]),
      };
    }
    return {};
  }, [fieldErrors]);
  return [handleSuccess, handleError, success, globalError, getFieldProps];
};

export default useFormResponseHandler;
