import React from 'react';
import {useTranslate} from 'react-admin';

const TechInspectionTitle = ({record}) => {
  const typeName = useTranslate()('telemedic.techInspections.show.label');
  return (
    <span>{
      (record && record.vehicle && record.vehicle.car_license_plate)
      ? `${typeName} ${record.vehicle.car_license_plate}`
      : typeName
    }</span>
  );
};

export default TechInspectionTitle;
