import React from 'react';
import { Layout, Authenticated } from 'react-admin';

import AppBar from './AppBar';
import Menu from './Menu';
import IdleLogoutTracker from '../components/idleLogoutTracker';

const CustomLayout = (props) => (
  <>
    <Layout
      {...props}
      appBar={AppBar}
      menu={Menu}
    />
    <Authenticated>
      <IdleLogoutTracker />
    </Authenticated>
  </>
);

export default CustomLayout;
