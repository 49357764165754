import React from 'react';
import {
  BooleanInput, NumberInput, SelectInput, TextInput, required
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {Role, MailingPeriodUnit} from '../../constants';
import {getRole} from '../../storages/auth';
import {enumChoices} from '../../utils/enums';
import {formatMailingPeriodUnit} from '../../utils/formatters';

const PeriodicMailingForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const workers = useTelemedicServiceJsonValue(() => {
    return service.getWorkerValues();
  }, null, []);
  const showWorkerInput = getRole() === Role.ADMIN;
  const initialValues = {active: true};
  return(
    <RestrictedSimpleForm initialValues={initialValues} {...rest}>
      <BooleanInput
        label="telemedic.periodicMailings.form.active"
        source="active"
        {...getInputProps('active')}
      />
      <TextInput
        label="telemedic.periodicMailings.form.email"
        source="email"
        validate={required()}
        {...getInputProps('email')}
      />
      <NumberInput
        label="telemedic.periodicMailings.form.period"
        source="period"
        {...getInputProps('period')}
      />
      <SelectInput
        label="telemedic.periodicMailings.form.periodUnit"
        source="period_unit"
        choices={enumChoices(MailingPeriodUnit, formatMailingPeriodUnit)}
        {...getInputProps('period_unit')}
      />
      <TextInput
        label="telemedic.periodicMailings.form.comment"
        source="comment"
        {...getInputProps('comment')}
      />
      {showWorkerInput &&
        <AutocompleteInput
          name="worker_id"
          label="telemedic.periodicMailings.form.worker"
          choices={workers}
          choiceLabelField="name"
          choiceValueField="id"
          {...getInputProps('worker_id')}
          dropdownAboveInput
        />
      }
    </RestrictedSimpleForm>
  );
}

export default PeriodicMailingForm;
