import React from 'react';
import PropTypes from 'prop-types';
import {Resource} from 'react-admin';

import {Action} from '../../permissions';

const RestrictedResource = ({
  permissions,
  section,
  list,
  show,
  create,
  edit,
  ...rest
}) => {
  if (permissions) {
    return (
      <Resource
        list={permissions.check(section, Action.READ) ? list : null}
        show={permissions.check(section, Action.READ) ? show : null}
        create={permissions.check(section, Action.CREATE) ? create : null}
        edit={permissions.check(section, Action.UPDATE) ? edit : null}
        {...rest}/>
    );
  }
  return null;
}

RestrictedResource.propTypes = {
  permissions: PropTypes.object,
  section: PropTypes.string
};

export default RestrictedResource;
