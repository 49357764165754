import React from 'react';
import {Title, withTranslate} from 'react-admin';

import Card from '@material-ui/core/Card';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import {Form, Field} from 'react-final-form';

import FormTextarea from '../../components/FormTextarea';
import FormTextField from '../../components/FormTextField';
import ProgressButton from '../../components/ProgressButton';
import TelemedicService from '../../services/TelemedicService'
import styles from './FeedbackForm.module.css';
import {getAuthToken} from '../../storages/auth';

class _FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      messageSent: false,
      errorText: ''
    }
    this.telemedicService = new TelemedicService(getAuthToken());
  }

  render(props) {
    const translate = this.props.translate;
    const {messageSent, errorText} = this.state;
    return (
      <Card className={styles.card}>
        <Title title="telemedic.feedback.label"/>
        <Typography variant="h4">
          {translate('telemedic.feedback.label')}
        </Typography>
        {messageSent &&
          <Typography variant="h5" className={styles.success}>
            {translate('telemedic.feedback.success')}
          </Typography>}
        <Form
          onSubmit={this.submitFeedback}
          validate={this.validate}
          render={this.renderForm}
        />
        {!!errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
      </Card>
    );
  }

  renderForm = ({handleSubmit}) => {
    const translate = this.props.translate;
    const progress = this.state.progress;
    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.controlWrapper}>
          <Field
            name="name"
            type="text"
            label={translate("telemedic.feedback.name")}
            component={FormTextField}
            fullWidth/>
        </div>
        <div className={styles.controlWrapper}>
          <Field
            name="phone"
            type="text"
            label={translate("telemedic.feedback.phone")}
            component={FormTextField}
            fullWidth/>
        </div>
        <div className={styles.controlWrapper}>
          <Field
            name="email"
            type="text"
            label={translate("telemedic.feedback.email")}
            component={FormTextField}
            fullWidth/>
        </div>
        <div className={styles.controlWrapper}>
          <Field
            name="message"
            type="text"
            label={translate("telemedic.feedback.message")}
            component={FormTextarea}
            rows={5}
            fullWidth/>
        </div>
        <div className={styles.controlWrapper}>
          <ProgressButton
            className={styles.fullWidth}
            type="submit"
            variant="contained"
            color="primary"
            label="telemedic.actions.send"
            progress={progress}/>
        </div>
      </form>
    );
  }

  validate = (data) => {
    const errors = {}
    const requiredFields = ['name', 'phone', 'email', 'message'];
    for (let i = 0, length = requiredFields.length; i < length; ++i) {
      const fieldName = requiredFields[i];
      if (!data[fieldName]) {
        errors[fieldName] = this.props.translate('telemedic.validation.required');
      }
    }
    return errors;
  }

  submitFeedback = (values, form) => {
    this.setState({progress: true});
    const {name, phone, email, message} = values;
    this.telemedicService.sendFeedback(name, phone, email, message).then(response => {
      this.setState({
        progress: false,
        messageSent: true,
        errorText: ''
      });
      form.reset();
      Object.keys(values).forEach(field => form.resetFieldState(field));
    }).catch(e => {
      console.log(e);
      const errorText = e.description
        ? e.description
        : this.props.translate('telemedic.errors.submitError');
      this.setState({
        progress: false,
        errorText: errorText
      });
    })
  }
}

export const FeedbackForm =  withTranslate(_FeedbackForm);
