import React from 'react';
import { useTranslate } from 'react-admin';

const OrganizationTitle = ({ record }) => {
  const typeName = useTranslate()('telemedic.organizations.show.label');
  return (
    <span>{(record && record.name) ? `${typeName} "${record.name}"` : typeName}</span>
  );
};

export default OrganizationTitle;
