import * as React from "react"

function Thermometer(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 30 30"
      {...props}
    >
      <path d="M28.061 1.671a3.969 3.969 0 00-5.612 0l-16.78 16.78-.014-.015v.03l-.044.043.042.042c-.024 3.153-3.278 6.853-4.212 7.85a1.467 1.351 45 00-.474.297 1.467 1.351 45 00.081 1.992 1.467 1.351 45 001.993.082 1.467 1.351 45 00.295-.473c.995-.931 4.698-4.189 7.852-4.213l.035.036.037-.037h.043l-.02-.022L28.06 7.284a3.969 3.969 0 000-5.613zm-.57.569a3.165 2.905 45 01.184 4.292 3.165 2.905 45 01-.193.173l.018.018L10.662 23.56 8.98 21.878 25.638 5.22l-.001-.002a.794.794 0 00.005-.004.794.794 0 000-1.123.794.794 0 00-1.123 0 .794.794 0 00-.057.066L7.86 20.758l-1.688-1.687L23.01 2.233l.018.017a3.165 2.905 45 01.172-.194 3.165 2.905 45 014.291.184zm-3.35 5.223l-.375.375.935.935.375-.374zM22.642 8.96l-.374.374.936.936.374-.374zm-1.496 1.497l-.374.374.935.936.374-.375zm-1.497 1.497l-.374.374.935.935.375-.374zm-1.497 1.496l-.374.374.936.936.374-.374zm-1.496 1.497l-.375.374.936.936.374-.375zm-1.497 1.497l-.374.374.935.935.374-.374zm-1.497 1.496l-.374.375.935.935.375-.374zm-1.496 1.497l-.375.374.936.936.374-.374z" />
    </svg>
  )
}

export default Thermometer
