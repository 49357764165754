import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import styles from './FormatChipField.module.css';

const FormatChipField = ({clickable, formatter, record}) => {
  const value = formatter(record);
  const label = (value && typeof value !== 'string' && !React.isValidElement(value))
    ? JSON.stringify(value)
    : value;
  return (
    <Chip className={styles.chip} clickable={clickable} label={label}/>
  );
}

FormatChipField.propTypes = {
  clickable: PropTypes.bool,
  formatter: PropTypes.func,
  record: PropTypes.object
};

export default FormatChipField;
