import russianMessages from 'ra-language-russian';

// missing translation
russianMessages.ra.navigation.skip_nav = 'Перейти к содержимому';

export default {
  ra: russianMessages.ra,
  telemedic: {
    login: {
      fields: {
        login: 'Логин',
        password: 'Пароль',
      },
      restoreTokenAccess: 'Восстановить доступ по токену',
      tokenSuccessfullyAttached: 'Токен успешно прикреплён к учётной записи',
      resetPassword: 'Забыли пароль?',
      awaitingResponse: 'Логин успешно проведён. Устанавливается связь с сервером.',
      awaitingLogoutResponse: 'Выход из ГосУслуг успешно проведён. Выполняется выход из системы.',
      esiaGeneralError: 'Во время авторизации произошла ошибка.'
    },
    passwordReset: {
      passwordReset: 'Сброс пароля',
      login: 'Логин',
      hint: 'Если в Вашей учётной записи указан телефон, на него будет отправлено СМС с кодом для сброса пароля. Код действует 10 минут.',
      getCode: 'Получить код',
      enterCode: 'Введите код, полученный по СМС',
      code: 'Код',
      resetPassword: 'Сбросить пароль',
      backToLogin: 'Ввести другой логин',
      getCodeTimerText: 'Вы можете запросить новый код через',
      getCodeAgain: 'Получить новый код',
      newPassword: 'Введите новый пароль',
      password: 'Пароль',
      confirm: 'Пароль (ещё раз)',
      setPassword: 'Сохранить пароль',
      passwordChanged: 'Пароль успешно изменён',
    },
    passwordChange: {
      oldPassword: 'Старый пароль',
      newPassword: 'Новый пароль',
      confirm: 'Новый пароль (ещё раз)',
      passwordSuccessfullyChanged: 'Пароль успешно изменён',
    },
    appBar: {
      backup: 'Бэкап',
      backupDateError: 'ошибка',
    },
    menu: {
      main: 'Основное меню',
      bills: 'Счета',
      checkups: 'Медосмотры',
      structure: 'Структура',
      support: 'Техническая поддержка',
      serverMonitoring: 'Мониторинг сервера',
      terminalMonitoring: 'Мониторинг терминалов',
      analytics: 'Аналитика и контроль',
      waybill: 'Путевой лист',
      techInspections: 'Техосмотры',
      test: 'Инструктажи',
      exit: 'Выход',
    },
    dashboard: {
      label: 'Главная',
      title: 'ONE Telemedic',
      greeting: 'Здравствуйте, {userName}!',
      role: 'Ваша роль в системе: {role}',
      organizations: 'Доступно организаций: {organizations}',
      documents: 'Документы',
    },
    activeUsers: {
      label: 'Активные пользователи',
      list: {
        id: 'ID',
        login: 'Логин',
        ip: 'IP',
        node: 'Node',
        noUsers: 'Нет активных пользователей',
      },
    },
    alcoholSmsReport: {
      label: 'Отчёт по СМС',
      list: {
        inn: 'ИНН',
        search: 'Поиск',
        organizationName: 'Название компании',
        manager: 'Ответственный (наш менеджер)',
        senderName: 'ФИО кому отправляем',
        senderTelephone: 'Телефон куда шлем СМС',
        dealer: 'Дилер',
        noResults: 'Организации не найдены',
        prompt: 'Выполняется поиск организаций',
      },
    },
    checkOrgInn: {
      label: 'Поиск организаций',
      list: {
        inn: 'ИНН',
        search: 'Поиск',
        name: 'Название',
        dealer: 'Дилер',
        noResults: 'Организации не найдены',
        prompt: 'Введите ИНН для поиска',
      },
    },
    checkupsLive: {
      label: 'Новые медосмотры',
      list: {
        userName: 'Имя сотрудника',
        terminal: 'Терминал',
        awaitingOnly: 'только ожидающие осмотра',
        reset: 'Сброс',
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        result: 'Результат',
        employee: 'Сотрудник',
        organization: 'Организация',
        type: 'Тип',
        datetimeStart: 'Начало (по МСК)',
        datetimeEnd: 'Окончание (по МСК)',
        datetimeReceived: 'Получен (по МСК)',
        resultAuto: 'Результат авто',
        resultMedic: 'Результат мед. работника',
        noResults: 'Нет осмотров, удовлетворяющих критериям',
        noCheckups: 'Нет осмотров за последние 24 часа',
        listUpdateError: 'Не удалось обновить список',
        isObserved: 'В процессе',
      },
    },
    checkupJournals: {
      label: 'Журнал медосмотров',
      form: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        organization: 'Организация',
        journalFormat: 'Формат журнала',
        getDOCX: 'DOCX',
        getPDF: 'PDF',
        getXLSX: 'XLSX',
      },
      filesAreReady: 'Файлы готовы для скачки',
      signatureHint: 'Если флаг установлен, то документ будет подписан ЭЦП и электронная подпись доступна для скачивания вместе с Журналом',
      downloadJournal: 'Скачать журнал',
      downloadSignature: 'Скачать подпись',
      signJournal: 'подписать журнал ЭЦП',
      requiresECP: 'Для подписи необходимо прикрепить токен к учётной записи',
      signaturePDFOnly: 'Документ подписывается ЭЦП только в формате PDF'
    },
    signedCheckupJournals: {
      label: 'Подписанные журналы',
      list: {
        date: 'Дата',
        notFormedOnly: 'только несформированные',
        signSelected: 'Сформировать выбранные журналы',
        tokenNotAttached: 'Для подписи необходимо прикрепить токен к учётной записи',
        deviceNotAvailable: 'Для подписи необходимо подключить токен',
        organization: 'Организация',
        journal: 'Журнал',
        signature: 'Эл. подпись',
        empty: 'Нет осмотров за выбранный день',
        filteredEmpty: 'Нет несформированных журналов за выбранный день',
        totalJournals: 'Всего журналов',
        signedJournals: 'Подписано',
        withErrors: 'С ошибками',
        journalsSignFinished: 'Подпись журналов завершена',
        publicKeyError: 'Не удалось получить открытый ключ',
      },
    },
    checkups: {
      label: 'История медосмотров',
      list: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        result: 'Результат',
        employee: 'Сотрудник',
        organization: 'Организация',
        type: 'Тип',
        datetimeStart: 'Начало (по МСК)',
        datetimeEnd: 'Окончание (по МСК)',
        resultAuto: 'Результат автоматический',
        resultMedic: 'Результат мед. работника',
        dealer: 'Дилер',
        tab_num: 'Табельный номер',
        filterSearch: 'Поиск',
      },
      show: {
        label: 'Осмотр',
        personnelNumber: 'Табельный номер',
        sex: 'Пол',
        name: 'ФИО',
        sendGisEpdSignedError: 'Не удалось подписать медосмотр с помощью ЭЦП',
        signature: 'Подпись',
        birthday: 'Дата рождения',
        organization: 'Организация',
        responsible: 'Ответственный',
        responsiblePhonePrefix: 'тел.',
        checkupTime: 'Время осмотра (по МСК)',
        bloodPressure: 'Давление',
        pulse: 'Пульс',
        alcohol: 'Алкоголь',
        temperature: 'Температура',
        pulseoximeter: 'Пульсоксиметр',
        weight: 'Вес',
        graphTest: 'Графический тест',
        schulte: 'Тест Шульте',
        complaints: 'Анамнез',
        testNotPerformed: 'Не проводился',
        resultAuto: 'Результат (автоматический)',
        resultMedic: 'Результат (мед. работника)',
        sendAisErp: 'Отправка в АИС ЕРП',
        medic: 'Мед. работник',
        nonAdmissionReasons: 'Причины недопуска',
        comment: 'Комментарий',
        resultDateTime: 'Время результата (по МСК)',
        checkupType: 'Тип осмотра',
        terminal: 'Терминал',
        normal: 'норма',
        belowNorm: 'ниже нормы',
        aboveNorm: 'выше нормы',
        feelingGood: 'Самочувствие хорошее',
        healthComplaints: 'Жалобы на недомогания и боли отсутствуют',
        feelRested: 'Чувствую себя отдохнувшим и готов к работе',
        checkupPollStart: 'Начало опроса',
        checkupPollEnd: 'Окончание опроса',
        pressStart: 'Начало измерения давления',
        pressEnd: 'Окончание измерения давления',
        tempStart: 'Начало измерения температуры',
        tempEnd: 'Окончание измерения температуры',
        alcStart: 'Начало измерения алкоголя',
        alcEnd: 'Окончание измерения алкоголя',
        userConf: 'Согласие сотрудника',
        recentCheckups: 'Последние осмотры пользователя',
        noRecentCheckups: 'Больше осмотров нет',
        recentCheckupsError: 'Не удалось загрузить последние осмотры',
        userPhoto: 'Фото сотрудника',
        addToProfile: 'Добавить в профиль',
        checkupPhoto: 'Фото осмотра',
        checkupVideo: 'Запись осмотра',
        flightsCertificateFront: 'Лицевая сторона справки профосмотра для полетов',
        flightsCertificateBack: 'Задняя сторона справки профосмотра для полетов',
        medCertificate: 'Мед. справка',
        mouth: 'Рот',
        eyes: 'Глаза',
        elbow: 'Локоть',
      },
      form: {
        label: 'Осмотр',
        organization: 'Организация',
        checkupTime: 'Время осмотра (по МСК)',
        user: 'Сотрудник',
        arterialPressure: 'Артериальное давление',
        venousPressure: 'Венозное давление',
        pulse: 'Пульс',
        alcohol: 'Алкоголь',
        temperature: 'Температура',
        pulseoximeter: 'Пульсоксиметр',
        weight: 'Вес',
        graphTest: 'Графический тест',
        schulte: 'Тест Шульте',
        complaints: 'Анамнез',
        testNotPerformed: 'Не проводился',
        checkupType: 'Тип осмотра',
        terminal: 'Терминал',
        feelingGood: 'Самочувствие хорошее',
        healthComplaints: 'Жалобы на недомогания и боли отсутствуют',
        feelRested: 'Чувствую себя отдохнувшим и готов к работе',
        dateTimeStart: 'Начало осмотра',
        dateTimeEnd: 'Конец осмотра',
      },
      toolbar: {
        doctorVerdict: 'Дубликат заключения врача',
        checkupReport: 'Заключение медосмотра',
        nonAdmissionPaper: 'Справка при недопуске',
        sobrietyProtocol: 'Протокол трезвости',
        QRCode: 'QR-код осмотра',
        taxiWaybill: 'Путевой лист с медосмотром',
      },
    },
    checkupDialogs: {
      resultAuto: 'Автоматический результат:',
      confirmAdmission: 'Вы уверены, что хотите допустить сотрудника?',
      warningNotAdmitted: 'Внимание! Сотрудник не был допущен автоматически. Вы уверены, что хотите его допустить?',
      nonAdmissionReason: 'Выберите причину недопуска',
      warningAdmitted: 'Внимание! Сотрудник был допущен автоматически.',
      comment: 'Комментарий:',
      noReasonSelected: 'Нужно выбрать причину недопуска или указать её в комментарии',
      tokenErrorText: 'Не удалось получить доступ к сертификату',
      requestErrorText: 'Ошибка при обработке запроса',
    },
    checkupsImport: {
      label: 'Загрузка медосмотров',
      form: {
        importFile: 'Файл для импорта',
        importSuccess: 'Импорт завершён успешно',
        importError: 'Импорт завершён с ошибками',
      },
    },
    dealers: {
      label: 'Дилеры',
      list: {
        name: 'Название',
        responsible: 'Ответственный',
      },
      show: {
        label: 'Дилер',
        name: 'Название',
        responsible: 'Ответственный',
      },
      form: {
        name: 'Название',
        responsible: 'Ответственный',
      },
    },
    dealerBalance: {
      label: 'Баланс дилеров',
      list: {
        name: 'Название',
        balance: 'Баланс',
      },
      show: {
        label: 'Баланс дилера',
        name: 'Название',
        balance: 'Баланс',
        recentPayments: 'Последние платежи',
        noPayments: 'Нет платежей',
      },
    },
    dealerPayments: {
      label: 'Платежы дилеров',
      list: {
        dateFrom: 'Начало пероида',
        dateTo: 'Конец периода',
        user: 'Пользователь',
        dealer: 'Дилер',
        amount: 'Сумма',
        datetime: 'Время',
        login: 'Логин',
        userName: 'ФИО',
      },
      show: {
        label: 'Платёж дилера',
        dealer: 'Дилер',
        amount: 'Сумма',
        datetime: 'Время',
        login: 'Логин',
        userName: 'ФИО',
      },
      form: {
        dealer: 'Дилер',
        amount: 'Сумма',
      },
    },
    dealersResponsible: {
      label: 'Ответственные',
      list: {
        name: 'Название',
        comment: 'Описание',
      },
      show: {
        label: 'Ответственный',
        name: 'Название',
        comment: 'Описание',
      },
      form: {
        name: 'Название',
        comment: 'Описание',
      },
    },
    diskSpace: {
      label: 'Дисковое пространство',
      show: {
        total: 'Всего',
        available: 'Свободно',
        noInfo: 'Информация отсутствует',
      },
    },
    inspectionPoints: {
      label: 'Пункты осмотра',
      list: {
        name: 'Название',
      },
      show: {
        label: 'Пункт осмотра',
        name: 'Название',
        organizations: 'Организации',
      },
      form: {
        name: 'Название',
        organizations: 'Организации',
      },
    },
    kisArtWorkerOrgsDialog: {
      title: 'КИС АРТ ID в организациях',
      organization: 'Организация',
      kisArtId: 'КИС АРТ ID',
      noKisArtIds: 'Нет записей КИС АРТ ID',
      addKisArtId: 'Добавить КИС АРТ ID',
      organizationNotUnique: 'Организация уже есть в списке',
    },
    logs: {
      label: 'Логи и ошибки',
    },
    medOrganizations: {
      label: 'Мед. организации',
      list: {
        inn: 'ИНН',
        name: 'Название',
        phone: 'Телефон',
        director: 'Директор',
        partner: 'Партнёр',
      },
      show: {
        license: 'Лицензия',
        licenseSerial: 'Серия лицензии',
        licenseNumber: 'Номер лицензии',
        licenseDateFrom: 'Лицензия работает с',
        licenseDateTo: 'Лицензия работает до',
        unlimitedLicense: 'Бессрочная',
        label: 'Мед. организация',
        inn: 'ИНН',
        kpp: 'КПП',
        name: 'Название',
        shortName: 'Короткое название',
        phone: 'Телефон',
        address: 'Адрес',
        director: 'Директор',
        partner: 'Партнёр',
      },
      form: {
        license: 'Лицензия',
        licenseSerial: 'Серия лицензии',
        licenseNumber: 'Номер лицензии',
        licenseDateFrom: 'Лицензия работает с',
        licenseDateTo: 'Лицензия работает до',
        deleteLicense: 'Удалить лицензию',
        inn: 'ИНН',
        kpp: 'КПП',
        name: 'Название',
        shortName: 'Короткое название',
        phone: 'Телефон',
        address: 'Адрес',
        director: 'Директор',
        partner: 'Партнёр',
      },
    },
    orgBalance: {
      label: 'Баланс организаций',
      list: {
        name: 'Название',
        balance: 'Баланс',
      },
      show: {
        label: 'Баланс организации',
        name: 'Название',
        balance: 'Баланс',
        recentPayments: 'Последние платежи',
        noPayments: 'Нет платежей',
      },
    },
    orgPayments: {
      label: 'Платежи организаций',
      list: {
        dateFrom: 'Начало пероида',
        dateTo: 'Конец периода',
        user: 'Пользователь',
        organization: 'Оранизация',
        amount: 'Сумма',
        datetime: 'Время',
        login: 'Логин',
        userName: 'ФИО',
      },
      show: {
        label: 'Платёж организации',
        organization: 'Оранизация',
        amount: 'Сумма',
        datetime: 'Время',
        login: 'Логин',
        userName: 'ФИО',
      },
      form: {
        organization: 'Оранизация',
        amount: 'Сумма',
      },
    },
    organizations: {
      label: 'Организации',
      list: {
        inn: 'ИНН',
        name: 'Название',
        region: 'Регион',
        contract: 'Договор',
        dealer: 'Дилер',
        partner: 'Партнёр',
      },
      show: {
        kisArtClientId: 'КИС АРТ client ID',
        kisArtClientSecret: 'КИС АРТ client secret',
        isKisArtRelated: 'API для КИС АРТ',
        operatorKisArtId: 'КИС АРТ ID оператора',
        medicKisArtId: 'КИС АРТ ID мед. работника',
        mechanicKisArtId: 'КИС АРТ ID механика',
        eplGisEpdAPI: 'API для ЭПЛ ГИС ЭПД',
        aisErpApi: 'Отправка медосмотров в АИС ЕРП',
        label: 'Организация',
        inn: 'ИНН',
        legalData: 'Юридические данные о клиенте',
        orgData: 'Организационные данные о клиенте',
        generalSettings: 'Общие настройки',
        affiliateNumber: 'Номер филиала',
        blockSettings: 'Блокировки',
        financialData: 'Финансовые данные',
        checkupPayment: 'Оплата ПО (медосмотры)',
        servicePayment: 'Оплата УСЛУГИ (медосмотры)',
        equipmentRent: 'Аренда оборудования (нерабочее)',
        softwarePayment: 'Оплата ПО (ЭПЛ ГИС ЭПД) (нерабочее)',
        kpp: 'КПП',
        ogrn: 'ОГРН',
        name: 'Название',
        address: 'Адрес',
        organization_type: 'Тип организации',
        full_name: 'Полное наименование организации',
        org_phone: 'Телефон организации',
        org_email: 'Почта организации',
        legal_region_code: 'Код региона',
        legal_city: 'Город',
        legal_settlement: 'Населенный пункт',
        legal_index: 'Индекс',
        legal_neighborhood: 'Район',
        legal_street: 'Улица',
        legal_house: 'Дом',
        legal_building: 'Корпус',
        legal_apartment: 'Квартира',
        actual_region_code: 'Код региона',
        actual_city: 'Город',
        actual_settlement: 'Населенный пункт',
        actual_index: 'Индекс',
        actual_neighborhood: 'Район',
        actual_street: 'Улица',
        actual_house: 'Дом',
        actual_building: 'Корпус',
        actual_apartment: 'Квартира',
        contract: 'Договор',
        useOrganizationBilling: 'Использовать биллинг к организации',
        paidPreInspections: 'Оплата пред… осмотров',
        paidPreInspectionsForDealer: 'Оплата пред… осмотров для дилеров (нерабочее)',
        shouldNotCountRepeatedCheckups: 'Повторные осмотры не считать',
        shouldNotCountRepeatedCheckupsForDealer: 'Повторные осмотры не считать для дилеров (нерабочее)',
        inspectionPackage: 'Пакет осмотров (нерабочее)',
        priceInspectionPackage: 'Стоимость пакета осмотров (нерабочее)',
        overPriceInspectionPackage: 'Стоимость сверх пакета (нерабочее)',
        price: 'Стоимость медосмотра для клиента',
        priceType: 'Тип оплаты',
        priceTypeDealer: 'Тип оплаты для дилера (нерабочий)',
        EquipmentIsRent : 'Оборудование в аренде (нерабочее)',
        chooseTerminals: 'Выбранные терминалы (нерабочий)',
        monthlyEquipmentPay: 'Стоимость в месяц (нерабочее)',
        perCheckup: 'за осмотр',
        monthly: 'ежемесячно',
        techInspectionPrice: 'Стоимость техосмотра для клиента',
        dealerPrice: 'Стоимость медосмотра для дилера',
        techInspectionDealerPrice: 'Стоимость техосмотра для дилера',
        region: 'Регион',
        dealer: 'Дилер',
        dealersResponsible: 'Сопровождение клиента',
        partner: 'Партнёр',
        responsibleName: 'ФИО ответственного',
        responsiblePhone: 'Моб. телефон ответственного',
        responsibleEmail: 'Email ответственного',
        contacts: 'Контакты',
        comments: 'Комментарий',
        useLocalTime: 'Использовать локальное время',
        alcoholSMS: 'Рассылка SMS: Внимание алкоголь!',
        alcoholEmail: 'Уведомление email: Внимание алкоголь!',
        notAdmitNotifyEmail: 'Уведомление email о недопуске',
        temperatureInDegrees: 'Температура в градусах',
        autoinspect: 'Автодопуск',
        repeats: 'С повторами',
        enableFaceId: 'Включить Face ID',
        partnerApiEpl: 'Включить ЭПЛ по API партнера',
        partnerApiEplName: 'API партнера',
        ediwebGlnOrg: 'GLN организации в ЭдиВеб',
        partnerApiOrgId: 'Id организации на платформе партнера',
        partnerApiLogin: 'Логин медика у партнера',
        partnerApiPassword: 'Пароль медика у партнера',
        partnerApiMedicInn: 'ИНН медорганизации у партнера',
        partnerApiMedicKpp: 'КПП медорганизации у партнера',
        procPersData: 'Всегда запрашивать согласие на обработку перс. данных',
        alcoholBlock: 'Блокировка по алкоголю',
        checkupBlock: 'Блокировка по любому недопуску',
        threeCheckupBlock: 'Блокировка при 3х недопусках',
        durationMinutes: 'Указать длительность в минутах',
        limitPreTrip: 'Ограничить ПРЕД осмотры разрешив их в заданном интервале',
        limitPostTrip: 'Ограничить ПОСЛЕ осмотры разрешив их в заданном интервале',
        timeFrom: 'с (мск)',
        timeTo: 'по (мск)',
      },
      form: {
        kisArtClientId: 'КИС АРТ client ID',
        kisArtClientSecret: 'КИС АРТ client secret',
        isKisArtRelated: 'API для КИС АРТ',
        operatorKisArtId: 'КИС АРТ ID оператора',
        medicKisArtId: 'КИС АРТ ID мед. работника',
        mechanicKisArtId: 'КИС АРТ ID механика',
        eplGisEpdAPI: 'API для ЭПЛ ГИС ЭПД',
        aisErpApi: 'Отправка медосмотров в АИС ЕРП',
        inn: 'ИНН',
        affiliate_number: 'Номер филиала',
        kpp: 'КПП',
        ogrn: 'ОГРН',
        name: 'Название',
        address: 'Адрес',
        organization_type: 'Тип организации',
        full_name: 'Полное наименование организации',
        org_phone: 'Телефон организации',
        org_email: 'Почта организации',
        legal_region_code: 'Код региона',
        legal_city: 'Город',
        legal_settlement: 'Населенный пункт',
        legal_index: 'Индекс',
        legal_neighborhood: 'Район',
        legal_street: 'Улица',
        legal_house: 'Дом',
        legal_building: 'Корпус',
        legal_apartment: 'Квартира',
        actual_region_code: 'Код региона',
        actual_city: 'Город',
        actual_settlement: 'Населенный пункт',
        actual_index: 'Индекс',
        actual_neighborhood: 'Район',
        actual_street: 'Улица',
        actual_house: 'Дом',
        actual_building: 'Корпус',
        actual_apartment: 'Квартира',
        contract: 'Договор',
        useOrganizationBilling: 'Использовать биллинг к организации',
        priceType: 'Тип оплаты',
        priceTypeDealer: 'Тип оплаты для дилера (нерабочий)',
        EquipmentIsRent : 'Оборудование в аренде (нерабочее)',
        chooseTerminals: 'Выбрать терминалы (нерабочий)',
        monthlyEquipmentPay: 'Стоимость в месяц (нерабочее)',
        paidPreInspections: 'Оплата пред… осмотров',
        paidPreInspectionsForDealer: 'Оплата пред… осмотров для дилеров (нерабочее)',
        shouldNotCountRepeatedCheckups: 'Повторные осмотры не считать',
        shouldNotCountRepeatedCheckupsForDealer: 'Повторные осмотры не считать для дилеров (нерабочее)',
        inspectionPackage: 'Пакет осмотров (нерабочее)',
        priceInspectionPackage: 'Стоимость пакета осмотров (нерабочее)',
        overPriceInspectionPackage: 'Стоимость сверх пакета (нерабочее)',
        price: 'Ст-ть медосмотра для клиента',
        techInspectionPrice: 'Ст-ть техосмотра для клиента',
        dealerPrice: 'Ст-ть медосмотра для дилера',
        techInspectionDealerPrice: 'Ст-ть техосмотра для дилера',
        region: 'Регион',
        dealer: 'Дилер',
        dealersResponsible: 'Сопровождение клиента',
        responsibleName: 'ФИО ответственного',
        responsiblePhone: 'Моб. телефон ответственного',
        responsibleEmail: 'Email ответственного',
        contacts: 'Контакты',
        comments: 'Комментарий',
        useLocalTime: 'Использовать локальное время',
        alcoholSMS: 'Рассылка SMS: Внимание алкоголь!',
        alcoholEmail: 'Уведомление email: Внимание алкоголь!',
        notAdmitNotifyEmail: 'Уведомление email о недопуске',
        temperatureInDegrees: 'Температура в градусах',
        autoinspect: 'Автодопуск',
        partnerApiEpl: 'Включить ЭПЛ по API партнера',
        partnerApiEplName: 'API партнера',
        ediwebGlnOrg: 'GLN организации в ЭдиВеб',
        partnerApiOrgId: 'Id организации на платформе партнера',
        partnerApiLogin: 'Логин медика у партнера',
        partnerApiPassword: 'Пароль медика у партнера',
        partnerApiMedicInn: 'ИНН медорганизации у партнера',
        partnerApiMedicKpp: 'КПП медорганизации у партнера',
        repeats: 'С повторами',
        enableFaceId: 'Включить Face ID',
        procPersData: 'Всегда запрашивать согласие на обработку перс. данных',
        alcoholBlock: 'Блокировка по алкоголю',
        checkupBlock: 'Блокировка по любому недопуску',
        threeCheckupBlock: 'Блокировка при 3х недопусках',
        durationMinutes: 'Указать длительность в минутах',
        limitPreTrip: 'Ограничить ПРЕД осмотры разрешив их в заданном интервале',
        limitPostTrip: 'Ограничить ПОСЛЕ осмотры разрешив их в заданном интервале',
        timeFrom: 'с (мск)',
        timeTo: 'по (мск)',
        orgDirectorCertificate: 'Сертификат директора организации',
        sendOrgAstral: 'Зарегистрировать в Калуге',
      },
    },
    testQuestionCategory: {
      label: 'Категории вопросов',
      list: {
        category: 'Категория',
      },
      show: {
        category: 'Категория',
        label: 'Категория вопросов',
      },
      form: {
        category: 'Категория',
      },
    },
    testResults: {
      label: 'Результаты тестирования',
      list: {
        employee: 'Сотрудник',
        organization: 'Организация',
        numberOfQuestions: 'Количество вопросов',
        numberOfCorrectAnswers: 'Количество правильных ответов',
        createdAt: 'Дата создания',
      },
      show: {
        employee: 'Сотрудник',
        organization: 'Организация',
        numberOfQuestions: 'Количество вопросов',
        numberOfCorrectAnswers: 'Количество правильных ответов',
        createdAt: 'Дата создания',
        label: 'Результат тестирования',
      },
      form: {
        employee: 'Сотрудник',
        numberOfQuestions: 'Количество вопросов',
        numberOfCorrectAnswers: 'Количество правильных ответов',
        createdAt: 'Дата создания',
        category: 'Категория',
      },
    },
    testResultsLive: {
      label: 'Новые результаты тестирования',
      list: {
        employee: 'Сотрудник',
        organization: 'Организация',
        numberOfQuestions: 'Количество вопросов',
        numberOfCorrectAnswers: 'Количество правильных ответов',
        createdAt: 'Дата создания',
        noTestResults: 'Новых результатов тестирования нет',
      },
    },
    periodicMailings: {
      label: 'Рассылки медосмотров',
      list: {
        active: 'Рассылка включена',
        email: 'Email',
        period: 'Период рассылки',
        last_mailing: 'Последяя отправка',
        worker: 'Пользователь',
      },
      show: {
        label: 'Рассылка медосмотров',
        active: 'Рассылка включена',
        email: 'Email',
        period: 'Период рассылки',
        comment: 'Комментарий',
        last_mailing: 'Последяя отправка',
        worker: 'Пользователь (доступ)',
      },
      form: {
        active: 'Рассылка включена',
        email: 'Email',
        period: 'Период рассылки',
        periodUnit: 'Ед. изм. периода',
        comment: 'Комментарий',
        worker: 'Пользователь (доступ)',
      },
    },
    techInspections: {
      label: 'История техосмотров',
      list: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        vehicle: 'Транспорт',
        carLicensePlate: 'Гос. номер',
        date: 'Дата',
        type: 'Тип осмотра',
        result: 'Статус',
        organization: 'Организация',
      },
      show: {
        label: 'Техосмотр ТС',
        date: 'Дата',
        organization: 'Лицензиат ',
        driver: 'Водитель',
        vehicle: 'Автомобиль',
        inspectionPoint: 'Пункт осмотра',
        carLicensePlate: 'Гос. номер',
        sendGisEpdSignedError: 'Не удалось подписать техосмотр с помощью ЭЦП',
        sendGisEpdSignedFile: 'Производится отправка данных в Калуга Астрал',
        type: 'Тип осмотра',
        mileage: 'Пробег',
        technicalCondition: 'Техническое состояние',
        note: 'Примечание',
        lightning: 'Осветительные приборы исправны',
        brakingSystem: 'Тормозная система исправна',
        steeringWheelPlay: 'Люфт рулевого колеса в норме',
        clutch: 'Сцепление исправно',
        tirePressure: 'Давление в шинах в норме',
        wipers: 'Стеклоочистители исправны',
        soundSignals: 'Звуковые сигналы исправны',
        locks: 'Замки на двери, багажнике, капоте исправны',
        fireExtinguisher: 'Огнетушитель',
        towRope: 'Буксировочный трос',
        jack: 'Домкрат',
        firstAidKit: 'Аптечка',
        warningTriangle: 'Знак аварийной остановки',
        individualProtection: 'Получены маска, перчатки, дез. средства',
        briefingRegime: 'Проведён инструктаж по режиму труда и отдыха',
        briefingChildren: 'Инструктаж правила перевозки детей',
        gasEquipment: 'Газовое оборудование',
        result: 'Статус',
        resultDatetime: 'Время результата',
        mechanic: 'Механик',
        photos: 'Фотографии',
      },
      form: {
        organization: 'Лицензиат ',
        driver: 'Водитель',
        vehicle: 'Автомобиль',
        inspectionPoint: 'Пункт осмотра',
        carLicensePlate: 'Гос. номер',
        type: 'Тип осмотра',
        mileage: 'Пробег',
        technicalCondition: 'Техническое состояние',
        note: 'Примечание',
        lightning: 'Осветительные приборы исправны',
        brakingSystem: 'Тормозная система исправна',
        steeringWheelPlay: 'Люфт рулевого колеса в норме',
        clutch: 'Сцепление исправно',
        tirePressure: 'Давление в шинах в норме',
        wipers: 'Стеклоочистители исправны',
        soundSignals: 'Звуковые сигналы исправны',
        locks: 'Замки на двери, багажнике, капоте исправны',
        fireExtinguisher: 'Огнетушитель',
        towRope: 'Буксировочный трос',
        jack: 'Домкрат',
        firstAidKit: 'Аптечка',
        warningTriangle: 'Знак аварийной остановки',
        individualProtection: 'Получены маска, перчатки, дез. средства',
        briefingRegime: 'Проведён инструктаж по режиму труда и отдыха',
        briefingChildren: 'Инструктаж правила перевозки детей',
        gasEquipment: 'Газовое оборудование',
        photos: 'Фото',
        admission: 'Допуск',
        nonAdmission: 'Недопуск',
        saveButton: 'Сохранить',
      },
    },
    techInspectionJournals: {
      label: 'Журнал технического состояния ТС',
      form: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        organization: 'Организация',
        inspectionPoint: 'Пункт осмотра',
        journalType: 'Журнал',
        departureJournal: 'Выпуск на линию',
        arrivalJournal: 'Возвращение с линии',
        journalFormat: 'Формат журнала',
        getDOCX: 'DOCX',
        getPDF: 'PDF',
      },
    },
    techInspectionsLive: {
      label: 'Новые техосмотры',
      list: {
        userName: 'Имя сотрудника',
        terminal: 'Терминал',
        awaitingOnly: 'только ожидающие осмотра',
        reset: 'Сброс',
        datetime: 'Время осмотра',
        organization: 'Организация',
        vehicle: 'Гос. номер',
        driver: 'Водитель',
        type: 'Тип',
        result: 'Результат',
        employee: 'Сотрудник',
        noResults: 'Нет осмотров, удовлетворяющих критериям',
        noTechInspections: 'Нет осмотров за последние 24 часа',
        listUpdateError: 'Не удалось обновить список',
      },
    },
    techInspectionDialogs: {
      requestErrorText: 'Ошибка при обработке запроса',
      sendGisEpdSignedError: 'Не удалось подписать техосмотр с помощью ЭЦП'
    },
    users: {
      label: 'Сотрудники',
      list: {
        personnelNumber: 'Табельный номер',
        name: 'Фамилия, имя или отчество',
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
        birthday: 'Дата рождения',
        organization: 'Организация',
        isFired: 'Уволен',
        isNotIncludedInWorkRestReport: 'Не формировать отчет «График труда и отдыха» для данного сотрудника',
        showFired: 'Показать уволенных',
        showEsiaRegistered: 'Показать привязаных к ГосУслугам',
        showEsiaAllowed: 'Показать имеющих доступ к ГосУслугам',
      },
      show: {
        photo: 'Фото',
        medCertificate: 'Справка',
        label: 'Сотрудник',
        personnelNumber: 'Табельный номер',
        cardId: 'ID карты',
        position: 'Должность',
        name: 'ФИО',
        sex: 'Пол',
        esiaId: 'ID из ГосУслуг',
        isAllowedToAuthWithEsia: 'Разрешена авторизацию через ГосУслуги?',
        organization: 'Организация',
        dateHired: 'Дата приема на работу',
        phone: 'Номер телефона',
        drivingLicenseSerial: 'Серийный номер вод. удостоверения',
        drivingLicenseNumber: 'Номер вод. удостоверения',
        drivingLicenseDate: 'Дата выдачи вод. удостоверения',
        passport: 'Паспорт (серия и номер)',
        passportDate: 'Дата выдачи паспорта',
        passportBy: 'Кем выдан паспорт',
        snils: 'СНИЛС',
        licenseCardNumber: 'Номер лиц. карточки',
        licenseCardDateStart: 'Дата выдачи лиц. карточки',
        licenseCardDateEnd: 'Дата окончания лиц. карточки',
        birthday: 'Дата рождения',
        arterialPressure: 'Пределы артериального давления',
        venousPressure: 'Пределы венозного давления',
        pulse: 'Пределы пульса',
        maxTemperature: 'Максимальная температура',
        chronicDiseases: 'Хронические заболевания',
        diagnosis: 'Диагноз диспансерного наблюдения',
        healthReport: 'Отчёт о состоянии здоровья',
        faceRecognitionId: 'ID в базе распознавания лиц',
        isFired: 'Уволен',
        isNotIncludedInWorkRestReport: 'Не формировать отчет «График труда и отдыха» для данного сотрудника',
        testQuestionCategories: 'Категории вопросов для тестирования',
      },
      form: {
        photo: 'Фото',
        cardId: 'ID карты',
        deletePhoto: 'Удалить фото',
        medCertificate: 'Справка',
        deleteMedCertificate: 'Удалить справку',
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
        sex: 'Пол',
        password: 'Пароль',
        isAllowedToAuthWithEsia: 'Разрешить авторизацию через ГосУслуги?',
        esiaId: 'ID из ГосУслуг',
        organization: 'Организация',
        personnelNumber: 'Табельный номер',
        position: 'Должность',
        dateHired: 'Дата приема на работу',
        dateFired: 'Дата увольнения',
        phone: 'Номер телефона',
        drivingLicenseSerial: 'Серийный номер вод. удостоверения',
        drivingLicenseNumber: 'Номер вод. удостоверения',
        drivingLicenseDate: 'Дата выдачи вод. удостоверения',
        passport: 'Паспорт (серия и номер)',
        passportDate: 'Дата выдачи паспорта',
        passportBy: 'Кем выдан паспорт',
        snils: 'СНИЛС',
        licenseCardNumber: 'Номер лиц. карточки',
        licenseCardDateStart: 'Дата выдачи лиц. карточки',
        licenseCardDateEnd: 'Дата окончания лиц. карточки',
        birthday: 'Дата рождения',
        minArterialPressure: 'Мин. арт. давление',
        maxArterialPressure: 'Макс. арт. давление',
        minVenousPressure: 'Мин. вен. давление',
        maxVenousPressure: 'Макс. вен. давление',
        minPulse: 'Мин. пульс',
        maxPulse: 'Макс. пульс',
        maxTemperature: 'Макс. температура',
        chronicDiseases: 'Хронические заболевания',
        diagnosis: 'Диагноз дисп. наблюдения',
        removeAuthPhoto: 'Удалить фото из базы распознавания лиц',
        isFired: 'Уволен',
        testQuestionCategories: 'Категории вопросов для тестирования',
        isNotIncludedInWorkRestReport: 'Не формировать отчет «График труда и отдыха» для данного сотрудника',
      },
    },
    usersImport: {
      label: 'Загрузка пользователей',
      form: {
        organization: 'Организация',
        importFile: 'Файл для импорта',
        importSuccess: 'Импорт завершён успешно',
        importError: 'Импорт завершён с ошибками',
      },
    },
    vehicles: {
      label: 'Транспортные средства',
      list: {
        name: 'Марка или модель',
        manufacturer: 'Марка',
        model: 'Модель',
        carLicensePlate: 'Гос. номер',
        year: 'Год',
        color: 'Цвет',
        organization: 'Организация',
      },
      show: {
        label: 'Транспортное средство',
        manufacturer: 'Марка',
        model: 'Модель',
        year: 'Год',
        color: 'Цвет',
        carLicensePlate: 'Гос. номер',
        vin: 'VIN',
        licenseCard: '№ рег. карточки',
        numberTrail: '№ прицепа',
        osago: '№ ОСАГО',
        endOsago: 'Дата окончания ОСАГО',
        diagnosticCard: '№ Техосмотра',
        endDiagnosticCard: 'Дата окончания Техосмотра',
        permissionNumber: '№ Разрешения',
        organization: 'Лицензиат ',
      },
      form: {
        manufacturer: 'Марка',
        model: 'Модель',
        year: 'Год',
        color: 'Цвет',
        carLicensePlate: 'Гос. номер',
        vin: 'VIN',
        licenseCard: '№ рег. карточки',
        numberTrail: '№ прицепа',
        osago: '№ ОСАГО',
        endOsago: 'Дата окончания ОСАГО',
        diagnosticCard: '№ Техосмотра',
        endDiagnosticCard: 'Дата окончания Техосмотра',
        permissionNumber: '№ Разрешения',
        organization: 'Лицензиат ',
      },
    },
    vehiclesImport: {
      label: 'Загрузка ТС',
      form: {
        organization: 'Организация',
        importFile: 'Файл для импорта',
        importSuccess: 'Импорт завершён успешно',
        importError: 'Импорт завершён с ошибками',
      },
    },
    waybills: {
      label: 'Путевые листы',
      list: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        datetime: 'Время',
        vehicle: 'ТС',
        inspectionPoint: 'Пункт осмотра',
        medic: 'Мед. работник',
        checkupResult: 'Рез-т медосмотра',
        mechanic: 'Механик',
        techInspectionResult: 'Рез-т техосмотра',
        sentAt: 'Отправлен в КИС АРТ',
      },
      show: {
        label: 'Путевой лист',
        organization: 'Организация',
        driver: 'Водитель',
        vehicle: 'Транспортное средство',
        inspectionPoint: 'Пункт осмотра',
        datetime: 'Время проведения осмотра',
        type: 'Тип осмотра',
        mileage: 'Пробег',
        checkup: 'Осмотр',
        checkupDatetime: 'Время проведения медосмотра',
        checkupResultDatetime: 'Время результата медосмотра',
        medic: 'Мед. работник',
        checkupResult: 'Результат медосмотра',
        techInspection: 'Техосмотр',
        techInspectionDatetime: 'Время проведения техосмотра',
        mechanic: 'Механик',
        techInspectionResult: 'Результат техосмотра',
        techInspectionResultDatetime: 'Время результата техосмотра',
        driverPhoto: 'Фото водителя',
        vehiclePhotos: 'Фотографии ТС',
        kisArtSend: 'Отправить в КИС АРТ',
        kisArtSendSuccess: 'Путевой лист будет отправлен в КИС АРТ в ближайшее вермя',
        kisArtSendError: 'Путевой лист не отправлен',
        waybillKisArt: 'Бланк КИС АРТ',
        waybillCommon: 'Бланк «Такси общий»',
        waybillTaxi: 'Путевой лист такси',
        waybillFreightVehicle: 'Путевой лист грузовое ТС',
      },
    },
    ewaybills: {
      label: 'ЭПЛ',
      list: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        datetime: 'Время',
        organization: 'Организация',
        vehicle: 'ТС',
        inspectionPoint: 'Пункт осмотра',
        medic: 'Мед. работник',
        checkupResult: 'Рез-т медосмотра',
        mechanic: 'Механик',
        techInspectionResult: 'Рез-т техосмотра',
        waybillTerm: 'Срок путевого листа',
        waybillTermDate: 'Дата путевого листа',
        whoCreated: 'Кто создаёт путевой лист',
        transportType: 'Вид перевозки',
        commercialTransportationType: 'Вид коммерческой перевозки',
        infoAboutTypeTransportation: 'Сведения о виде сообщения',
        fio: 'Должностное лицо',
        jobTitle: 'Должность',
        postshiftCheckup: 'Обязательность проведения послесменного медосмотра',
        sentAt: 'Отправлено',
        error: 'Из ЭДО пришли ошибки',
      },
      form: {
        label: 'Путевой лист',
        organization: 'Организация',
        inn: 'ИНН',
        kpp: 'КПП',
        ogrn: 'ОГРН',
        driver: 'Водитель',
        vehicle: 'Транспортное средство',
        inspectionPoint: 'Место отправления (Пункт осмотра)',
        inspectionAddress: 'Адрес пункта',
        address: 'Адрес',
        index: 'Индекс',
        regionCode: 'Код региона',
        city: 'Город',
        street: 'Улица',
        home: 'Дом',
        waybillTerm: 'Срок путевого листа',
        waybillTermDate: 'Дата путевого листа',
        waybillTermDateBegin: 'Дата начала путевого листа',
        waybillTermDateEnd: 'Дата окончания путевого листа',
        whoCreated: 'Кто создаёт путевой лист',
        infoAboutPers : 'Сведения о лице кто подписывает',
        transportType: 'Вид перевозки',
        commercialTransportationType: 'Вид коммерческой перевозки',
        infoAboutTypeTransportation: 'Сведения о виде сообщения',
        fio: 'ФИО',
        worker: 'Сотрудник',
        vehicleType: 'Тип ТС',
        vehicleMark: 'Марка ТС',
        vehicleModel: 'Модель ТС',
        vehicleRegNumber: 'Рег номер ТС',
        drivingLicenseNumber: 'Номер ВУ',
        drivingLicenseSerial: 'Серия ВУ',
        drivingLicenseDate: 'Дата выдачи ВУ',
        jobTitle: 'Должность',
        postshiftCheckup: 'Обязательность проведения послесменного медосмотра',
        datetime: 'Время проведения осмотра',
        type: 'Тип осмотра',
        mileage: 'Пробег',
        checkup: 'Осмотр',
        checkupDatetime: 'Время проведения медосмотра',
        checkupResultDatetime: 'Время результата медосмотра',
        medic: 'Мед. работник',
        checkupResult: 'Результат медосмотра',
        techInspection: 'Техосмотр',
        techInspectionDatetime: 'Время проведения техосмотра',
        mechanic: 'Механик',
        techInspectionResult: 'Результат техосмотра',
        techInspectionResultDatetime: 'Время результата техосмотра',
        driverPhoto: 'Фото водителя',
        vehiclePhotos: 'Фотографии ТС',
        enablePostTrip: 'Послерейсовые осмотры',
        kisArtSend: 'Отправить в КИС АРТ',
        kisArtSendSuccess: 'Путевой лист будет отправлен в КИС АРТ в ближайшее вермя',
        kisArtSendError: 'Путевой лист не отправлен',
        waybillKisArt: 'Бланк КИС АРТ',
        waybillCommon: 'Бланк «Такси общий»',
        waybillTaxi: 'Путевой лист такси',
      },
      show: {
        sendGisEpd: 'Отправить в ГИС ЭПД',
        sendGisEpdBegin: 'Начинаю отправку',
        sendGisEpdSuccess: 'Успешно отправлен на проверку',
        sendGisEpdError: 'Ваша организация не поддерживает ЭПЛ или не найден токен',
        sendGisEpdSignedError: 'Ошбика подписания ЭПЛ с помощью ЭЦП',
        QRCode: 'QR-код',
        QRWarning: 'Данный QR-код требуется для инспекторов ГАИ',
        createTechInspection: 'Создать послерейсовый техосмотр',
        techInspectionSuccessfullyCreated: 'Техосмотр успешно создан',
        label: 'Путевой лист',
        organization: 'Организация',
        driver: 'Водитель',
        vehicle: 'Транспортное средство',
        inspectionPoint: 'Пункт осмотра',
        datetime: 'Время проведения осмотра',
        type: 'Тип осмотра',
        mileage: 'Пробег',
        checkup: 'Осмотр',
        checkupDatetime: 'Время проведения медосмотра',
        checkupResultDatetime: 'Время результата медосмотра',
        medic: 'Мед. работник',
        checkupResult: 'Результат медосмотра',
        techInspection: 'Техосмотр',
        waybillTerm: 'Срок путевого листа',
        waybillTermDate: 'Дата путевого листа',
        whoCreated: 'Кто создаёт путевой лист',
        transportType: 'Вид перевозки',
        commercialTransportationType: 'Вид коммерческой перевозки',
        infoAboutTypeTransportation: 'Сведения о виде сообщения',
        fio: 'ФИО должностного лица',
        jobTitle: 'Должность',
        postshiftCheckup: 'Обязательность проведения послесменного медосмотра',
        techInspectionDatetime: 'Время проведения техосмотра',
        mechanic: 'Механик',
        techInspectionResult: 'Результат техосмотра',
        techInspectionResultDatetime: 'Время результата техосмотра',
        driverPhoto: 'Фото водителя',
        vehiclePhotos: 'Фотографии ТС',
        kisArtSend: 'Отправить в КИС АРТ',
        kisArtSendSuccess: 'Путевой лист будет отправлен в КИС АРТ в ближайшее вермя',
        kisArtSendError: 'Путевой лист не отправлен',
        waybillKisArt: 'Бланк КИС АРТ',
        waybillCommon: 'Бланк «Такси общий»',
        waybillTaxi: 'Путевой лист такси',
        error: 'Ошибки',
      },
    },
    waybillsMovement: {
      label: 'Журнал учета движения путевых листов',
      form: {
        periodFrom: 'Начало периода',
        periodTo: 'Конец периода',
        organization: 'Организация',
        journalFormat: 'Формат журнала',
        getXLSX: 'XLSX',
        getPDF: 'PDF',
      },
    },
    workers: {
      label: 'Персонал',
      list: {
        role: 'Должность',
        login: 'Логин',
        anyName: 'Фамилия, имя или отчество',
        organization: 'Организация',
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
        organizationsCount: 'Кол-во организаций',
        createdAt: 'Время создания',
        lastAuth: 'Последний вход',
        isFired: 'Уволен',
        showFired: 'Показать уволенных',
        showEsiaRegistered: 'Показать привязаных к ГосУслугам',
        showEsiaAllowed: 'Показать имеющих доступ к ГосУслугам',
      },
      show: {
        photo: 'Фото',
        license: 'Лицензия',
        label: 'Персонал',
        role: 'Должность',
        login: 'Логин',
        name: 'ФИО',
        isAllowedToAuthWithEsia: 'Разрешена авторизацию через ГосУслуги?',
        esiaId: 'ID из ГосУслуг',
        medOrganizations: 'Мед. организации',
        organizations: 'Организации',
        inspectionPoints: 'Пункты осмотра',
        snils: 'СНИЛС',
        phone: 'Номер телефона',
        email: 'Email',
        serial: 'Серийный номер ЭЦП',
        issuer: 'Кем выдано ЭЦП',
        ecpDateFrom: 'Дата получения ключа ЭЦП',
        ecpDateTo: 'Срок действия ключа ЭЦП',
        ecp: 'Открытый ключ ЭЦП',
        createdAt: 'Время создания',
        lastAuth: 'Последний вход',
        signature: 'Подпись',
        isFired: 'Уволен',
      },
      form: {
        photo: 'Фото',
        deletePhoto: 'Удалить фото',
        license: 'Лицензия',
        deleteLicense: 'Удалить лицензию',
        role: 'Роль',
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
        login: 'Логин',
        password: 'Пароль',
        isAllowedToAuthWithEsia: 'Разрешить авторизацию через ГосУслуги?',
        esiaId: 'ID из ГосУслуг',
        medOrganizations: 'Мед. организации',
        organizations: 'Организации',
        inspectionPoints: 'Пункты осмотра',
        snils: 'СНИЛС',
        phone: 'Номер телефона',
        email: 'Email',
        serial: 'Серийный номер ЭЦП',
        issuer: 'Кем выдано ЭЦП',
        ecpDateFrom: 'Дата получения ключа ЭЦП',
        ecpDateTo: 'Срок действия ключа ЭЦП',
        ecp: 'Открытый ключ ЭЦП',
        signature: 'Подпись',
        deleteSignature: 'Удалить подпись',
        isFired: 'Уволен',
      },
      banDialog: {
        title: 'Блокировка пользователя',
        prompt: 'Введите причину блокировки пользователя',

      },
      revokeBanDialog: {
        title: 'Разблокировка пользователя',
        prompt: 'Вы уверены, что хотите разблокировать пользователя?',
      },
    },
    terminals: {
      label: 'Терминалы',
      list: {
        name: 'Название',
        message: 'Сообщение',
        withMessage: 'есть',
        withoutMessage: 'нет',
        organization: 'Организация',
        teamViewerLogin: ' Номер AnyDesk/OpenVPN',
        buildDate: 'Дата сборки',
        deadline: 'Deadline',
        type: 'Тип',
        version: 'Версия',
        timezone: 'Часовой пояс',
        isOnline: 'Онлайн',
        lastActivityTime: 'Последний отклик',
        organizationsCount: 'Кол-во организаций',
        id: 'ID',
        dealer: 'Дилер',
      },
      show: {
        label: 'Терминал',
        info: 'Информация',
        devices: 'Устройства',
        misc: 'Прочее',
        map: 'Карта',
        type: 'Тип терминала',
        model: 'Модель терминала',
        buildDate: 'Дата сборки',
        deadline: 'Deadline',
        verificationDate: 'Дата поверки',
        lastActivityTime: 'Последний отклик',
        teamViewerLogin: ' Номер AnyDesk/OpenVPN',
        teamViewerPassword: ' Пароль AnyDesk/OpenVPN',
        termAdminPassword: 'Пароль администратора терминала',
        aisErpApi: 'Отправка медосмотров в АИС ЕРП',
        addDataToAisErpApi: 'Дополнить данные для АИС ЕРП',
        registerAisErpIntegration: 'Зарегистрировать терминал в АИС ЕРП',
        terminalAisErpId: 'ID терминала в АИС ЕРП',
        aisErpErrors: 'Ошибки из АИС ЕРП',
        arshinCertNumber: 'Номер свидетельства о поверке МИ в Аршин',
        breathalyzerModel: 'Модель алкотестера',
        breathalyzerSerial: 'Серийный номер алкотестера',
        pyrometerModel: 'Модель пирометра',
        pyrometerSerial: 'Серийный номер пирометра',
        tonometerModel: 'Модель тонометра',
        tonometerSerial: 'Серийный номер тонометра',
        pcModel: 'Модель ПК/моноблока',
        cameraModel: 'Модель камеры',
        printerModel: 'Модель принтера',
        decree866: 'ПП866',
        ambientTemperature: 'Температура окр. среды',
        illuminance: 'Освещенность',
        humidity: 'Влажность',
        controlHash: 'Контрольный хэш',
        currentHash: 'Текущий хэш',
        tamperSensor: 'Датчик вскрытия',
        tamperDetected: 'Открыт',
        tamperNotDetected: 'Закрыт',
        coverEnerg: 'Энергонезависимое состояние корпуса',
        coverEnergClose: 'Корпус закрыт',
        coverEnergOpen: 'Корпус открыт',
        coverEnergLostPower: 'Питание отсутствует',
        coverEnergLostPowerOpen: 'Был вскрыт в момент отсутствия питания',
        name: 'Имя терминала',
        region: 'Регион',
        timezone: 'Часовой пояс',
        organizations: 'Организации',
        inspectionPoint: 'Пункт осмотра',
        comments: 'Комментарии',
        message: 'Сообщение',
        medicPhone: 'Телефон врача',
        autoinspect: 'Автодопуск',
        restart: 'Перезапустить терминал',
      },
      form: {
        label: 'Терминал',
        info: 'Информация',
        devices: 'Устройства',
        misc: 'Прочее',
        map: 'Карта',
        type: 'Тип терминала',
        model: 'Модель терминала',
        buildDate: 'Дата сборки',
        verificationDate: 'Дата поверки',
        deadline: 'Deadline',
        teamViewerLogin: ' Номер AnyDesk/OpenVPN',
        teamViewerPassword: ' Пароль AnyDesk/OpenVPN',
        termAdminPassword: 'Пароль администратора терминала',
        aisErpApi: 'Отправка медосмотров в АИС ЕРП',
        addDataToAisErpApi: 'Дополнить данные для АИС ЕРП',
        arshinCertNumber: 'Номер свидетельства о поверке МИ в Аршин',
        registerAisErpIntegration: 'Зарегистрировать терминал в АИС ЕРП',
        breathalyzerModel: 'Модель алкотестера',
        breathalyzerSerial: 'Серийный номер алкотестера',
        pyrometerModel: 'Модель пирометра',
        pyrometerSerial: 'Серийный номер пирометра',
        tonometerModel: 'Модель тонометра',
        tonometerSerial: 'Серийный номер тонометра',
        pcModel: 'Модель ПК/моноблока',
        cameraModel: 'Модель камеры',
        controlHash: 'Контрольный хэш',
        printerModel: 'Модель принтера',
        decree866: 'ПП866',
        name: 'Имя терминала',
        region: 'Регион',
        timezone: 'Часовой пояс',
        organizations: 'Организации',
        inspectionPoint: 'Пункт осмотра',
        latitude: 'Широта',
        longitude: 'Долгота',
        comments: 'Комментарии',
        message: 'Сообщение',
        medicPhone: 'Телефон врача',
        autoinspect: 'Автодопуск',
        clearCache: 'Почистить хэш и датчик вскрытия',
      },
    },
    terminalsMap: {
      label: 'География ONE',
      terminal: 'Терминал',
      terminalId: 'Номер терминала',
      coordinates: 'Координаты',
    },
    statistics: {
      label: 'Статистика',
      form: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        checkupType: 'Тип осмотра',
        checkupTypeAll: 'все',
        reportType: 'Отчёт',
        reportTypeCheckups: 'Журнал осмотров',
        reportTypeInspectionsExtended: 'Расширенный журнал осмотров',
        reportTypeCheckupsWithoutRepeats: 'Журнал без повторных осмотров (20 минут)',
        reportTypeCheckupsWithoutRepeats2v: 'Журнал без повторных осмотров (30 минут)',
        reportTypeRiskGroup: 'Группа риска',
        reportTypeAgeRiskGroup: 'Группа риска (55 лет)',
        reportTypeWorkingAndRestTime: 'Работа/отдых',
        reportTypeWorkingAndRestTimeColored: 'Работа/отдых цвет 12x12',
        organization: 'Организация',
        terminal: 'Терминал',
        pressureAndPulse: 'давление и пульс',
        alcohol: 'пары этанола',
        temperature: 'температура',
        riskGroupParams: 'параметры отчёта "Группа риска"'
      },
      display: {
        statsPeriod: 'Период',
        periodFrom: 'с',
        periodTo: 'по',
        organization: 'Организация',
      },
      checkups: {
        title: 'Журнал осмотров',
        titleWithoutRepeats: 'Журнал без повторных осмотров',
        columnCheckupDate: 'Дата осмотра',
        columnCheckupType: 'Тип осмотра',
        columnPersonnelNumber: 'Таб. номер',
        columnFullName: 'ФИО',
        columnBirthday: 'Дата рождения',
        columnBloodPressure: 'Давление',
        columnPulse: 'Пульс',
        columnAlcohol: 'Алкоголь',
        columnTemperature: 'Температура',
        columnComplaints: 'Жалобы',
        columnVisualInspect: 'Визуальный осмотр',
        columnMedic: 'Медицинский работник',
        columnApproval: 'Допуск',
        columnOrganization: 'Организация',
        columnSignature: 'Подпись водителя',
        total: 'Всего',
        admissions: 'Допущено',
        nonAdmissions: 'Не допущено',
        awaiting: 'Ожидающих',
        totalPreTrip: 'Предрейсовых',
        totalPostTrip: 'Послерейсовых',
      },
      checkupsExtended: {
        title: 'Расширенный журнал осмотров',
        columnTerminalName: 'Наименование оборудования(комплекса)',
        columnCheckupYear: 'Год',
        columnCheckupMonth: 'Месяц',
        columnCheckupDay: 'День',
        columnCheckupTime: 'Время проведения осмотра',
        columnPersonnelNumber: 'Таб. номер',
        columnFullName: 'ФИО',
        columnPosition: 'Должность',
        columnSex: 'Пол',
        columnBirthday: 'Дата рождения',
        columnComplaints: 'Жалобы',
        columnTemperature: 'Температура',
        columnBloodPressureUpper: 'Давление (верхнее)',
        columnBloodPressureLower: 'Давление (нижнее)',
        columnPulse: 'Пульс',
        columnAlcohol: 'Показания алкотестера',
        columnUrinalysis: 'Исследования мочи(наркотики, психотропы)',
        columnResult: 'Результат осмотра',
        columnMedic: 'ФИО мед. работника',
        sexFemale: 'ж',
        sexMale: 'м',
        noComplaints: 'Жалоб нет',
        total: 'Всего',
        admissions: 'Допущено',
        nonAdmissions: 'Не допущено',
        awaiting: 'Ожидающих',
        totalPreTrip: 'Предрейсовых',
        totalPostTrip: 'Послерейсовых',
      },
      riskGroup: {
        title: 'Группа риска',
        columnIndex: '№ п/п',
        columnFullName: 'ФИО водителей, отстраненных от работы',
        columnReason: 'Причина недопуска к управлению автомобиля',
        columnStatistics: 'Количество недопусков/пройденных медосмотров',
        columnDisapprovals: 'Недопуски (в %) от пройденных мед.осмотров',
        columnCheckupDate: 'Дата проведения мед.осм.',
        columnBloodPressure: 'А/Д (мм.рт.ст.)',
        columnPulse: 'Пульс (уд/мин)',
        columnTemperature: 'Температура',
        columnAlcohol: 'Содер.алког.в выд.воздухе (промилле)',
      },
      ageRiskGroup: {
        title: 'Группа риска (55 лет)',
        columnIndex: '№ п/п',
        columnFullName: 'ФИО',
        columnOrganization: 'Организация',
        columnBirthday: 'Дата рождения',
        columnAge: 'Возраст',
      },
      workingAndRestTime: {
        title: 'Работа/отдых',
        columnFullName: 'ФИО',
        columnPeriod: 'Период',
        columnInterval: 'Время',
        periodRest: 'Отдых',
        periodWorking: 'Работа',
        columnTotal: 'Общее вермя',
      },
    },
    statisticsTech: {
      label: 'Статистика тех. осмотров',
      labelExtended: 'Расширенный журнал тех. осмотров',
      form: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        checkupType: 'Тип осмотра',
        checkupTypeAll: 'все',
        reportType: 'Отчёт',
        reportTypeInspections: 'Журнал осмотров',
        reportTypeInspectionsExtended: 'Расширенный журнал осмотров',
        organization: 'Организация',
      },
      display: {
        statsPeriod: 'Период',
        periodFrom: 'с',
        periodTo: 'по',
        organization: 'Организация',
      },
      techInspections: {
        title: 'Журнал тех. осмотров',
        titleWithoutRepeats: 'Журнал без повторных тех. осмотров',
        columnInspectionDate: 'Дата тех. осмотра',
        columnInspectionType: 'Тип тех. осмотра',
        columnPersonnelNumber: 'Таб. номер',
        columnFullName: 'ФИО',
        columnBirthday: 'Дата рождения',
        columnMileage: 'Пробег',
        columnLightning: 'Осветительные приборы исправны',
        columnBrakingSystem: 'Тормозная система исправна',
        columnSteeringWheelPlay: 'Люфт рулевого колеса в норме',
        columnClutch: 'Сцепление исправно',
        columnTirePressure: 'Давление в шинах в норме',
        columnWipers: 'Стеклоочистители исправны',
        columnSoundSignals: 'Звуковые сигналы исправны',
        columnLocks: 'Замки на двери, багажнике, капоте исправны',
        columnFireExtinguisher: 'Огнетушитель',
        columnTowRope: 'Буксировочный трос',
        columnJack: 'Домкрат',
        columnFirstAidKit: 'Аптечка',
        columnWarningTriangle: 'Знак аварийной остановки',
        columnIndividualProtection: 'Получены маска, перчатки, дез. средства',
        columnBriefingRegime: 'Проведён инструктаж по режиму труда и отдыха',
        columnBriefingChildren: 'Инструктаж правила перевозки детей',
        columnGasEquipment: 'Газовое оборудование',
        columnApproval: 'Допуск',
        columnOrganization: 'Организация',
        columnVehicle: 'Автомобиль',
        columnNote: 'Примечание',
        columnMechanic: 'Механик',
        columnSignature: 'Подпись водителя',
        total: 'Всего',
        admissions: 'Допущено',
        nonAdmissions: 'Не допущено',
        awaiting: 'Ожидающих',
        totalPreTrip: 'Предрейсовых',
        totalPostTrip: 'Послерейсовых',
      },
    },
    usage: {
      label: 'Использование',
      form: {
        reportType: 'Статистика',
        reportTypeByOrg: 'по организациям',
        reportTypeByTerm: 'по терминалам',
        reportTypeByDealers: 'по дилерам',
        organizations: 'Организации',
        terminals: 'Терминалы',
        dealers: 'Дилеры',
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        checkupTypes: 'Типы осмотра',
        selectWithContract: 'с договором',
        selectAll: 'все',
        selectNone: 'ничего',
      },
      display: {
        usageByOrgs: 'Статистика по организациям',
        usageByTerms: 'Статистика по терминалам',
        usageByDealers: 'Статистика по дилерам',
        columnOrganization: 'Организация',
        columnContract: 'Договор',
        columnRegion: 'Регион',
        columnPrice: 'Цена',
        columnDealer: 'Дилер',
        columnResponsible: 'Ответственный',
        columnCheckupsAveragePerDay: 'Среднее количество осмотров',
        columnCheckups: 'Итоги в осмотрах',
        columnCheckupsWithoutRepeats: 'Без повторов',
        columnTotalPrice: 'Итоги в рублях',
        columnTotalPriceDistinct: 'Итоги в рублях без повторов',
        columnTerminal: 'Терминал',
        total: 'Всего',
        date: 'Дата',
        checkupsCount: 'Осмотров',
        techInspectionsCount: 'Тех. осмотров',
      },
    },
    billingSum: {
      label: 'Суммы для счетов',
      form: {
        billingType: 'Тип оплаты',
        billingTypePerCheckup: 'поштучно',
        billingTypePerMonth: 'за месяц',
        reportType: 'Статистика',
        reportTypeByOrg: 'по организациям',
        reportTypeByTerm: 'по терминалам',
        reportTypeByDealers: 'по дилерам',
        organizations: 'Организации',
        terminals: 'Терминалы',
        dealers: 'Дилеры',
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        checkupTypes: 'Типы осмотра',
        selectWithContract: 'с договором',
        selectAll: 'все',
        selectNone: 'ничего',
      },
      display: {
        billingSumByOrgs: 'Статистика по организациям',
        billingSumByTerms: 'Статистика по терминалам',
        billingSumByDealers: 'Статистика по дилерам',
        columnOrganization: 'Организация',
        columnContract: 'Договор',
        columnRegion: 'Регион',
        columnPrice: 'Цена',
        columnDealer: 'Дилер',
        columnResponsible: 'Ответственный',
        columnCheckups: 'Итоги в осмотрах',
        columnTotalPrice: 'Итоги в рублях',
        columnTerminal: 'Терминал',
        total: 'Всего',
        date: 'Дата',
        checkupsCount: 'Осмотров',
      },
    },
    healthStatus: {
      label: 'Состояние здоровья',
      form: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        organization: 'Организация',
        user: 'Сотрудник',
        numberOfLastCheckups: 'Количество осмотров',
        reportType: 'Тип отчёта',
        dateIntervalType: 'по временному периоду',
        numberOfLastCheckupsType: 'по количеству последних осмотров',
      },
      display: {
        subtitle: 'Отчёт за период с {from} по {to}',
        name: 'ФИО',
        birthday: 'Дата рождения',
        organization: 'Организация',
        personnelNumber: 'Табельный номер',
        arterialPressure: 'Артериальное давление',
        venousPressure: 'Венозное давление',
        pulse: 'Пульс',
        checkup: 'Осмотр',
      },
    },
    medicEfficiency: {
      label: 'Скорость работы мед. работников',
      form: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        dealer: 'Дилер',
      },
      display: {
        period: 'Отчёт за период с {dateFrom} по {dateTo}',
        dealer: 'Дилер',
        medic: 'Медицинский работник',
        timeReceived: 'Время поступления (по МСК)',
        decisionTime: 'Время принятия решения (по МСК)',
        '3minutes': 'Более 3 минут',
        '5minutes': 'Более 5 минут',
      },
    },
    medicWorkload: {
      label: 'Нагрузка на врача',
      form: {
        dateFrom: 'Начало периода',
        dateTo: 'Конец периода',
        medOrganization: 'Мед. организация',
        medic: 'Мед. работник',
      },
      display: {
        medOrganization: 'Медицинская организация',
        medOrganizations: 'Медицинские организации',
        medic: 'Медицинский работник',
        ecp: 'ЭЦП',
        validUntil: 'Действует до',
        checkups: 'Осмотры',
      },
    },
    control: {
      label: 'Контроль',
      medicEcp: {
        label: 'Контроль ЭЦП',
        columnName: 'ФИО',
        columnLogin: 'Логин',
        columnEcpDateFrom: 'Дата получения ЭЦП',
        columnEcpDateTo: 'Срок действия ЭЦП',
        columnLastActivityDate: 'Дата последней активности',
        columnPhone: 'Номер телефона',
        columnOrgNames: 'Мед. организации',
      },
      terminalVerification: {
        label: 'Поверка оборудования',
        columnName: 'Название терминала',
        columnBuildDate: 'Дата сборки',
        columnVerificationDate: 'Дата поверки',
        columnPlannedVerificationDate: 'Дата плановой проверки',
        columnPlannedVerificationDaysLeft: 'Дней осталось',
        columnLastActivityTime: 'Дата последней активности',
        columnDealer: 'Дилер',
        columnTonometerModel: 'Тонометр',
        columnTonometerSerial: 'Серийный',
        columnBreathalyzerModel: 'Алкотестер',
        columnBreathalyzerSerial: 'Серийный',
        columnPyrometerModel: 'Градусник',
        columnPyrometerSerial: 'Серийный',
      },
      smsReport: {
        label: 'Отправленные СМС',
        organizationName: 'Организация',
        dealerName: 'Дилер',
        smsCount: 'Отправлено СМС'
      },
      terminalStatus: {
        label: 'Статус терминалов',
        columnName: 'Название терминала',
        columnAttention: 'Внимание',
        columnDeadline: 'Дата поверки',
        columnTampering: 'Вскрытие',
        columnHash: 'Hash',
        columnTemperature: 'Температура',
        columnHumidity: 'Влажность',
        columnIlluminance: 'Свет',
        columnGPS: 'GPS',
        OK: 'OK',
        attention: 'Внимание!'
      },
    },
    receivedCheckups: {
      label: 'Частота осмотров',
      form: {
        dateTimeFrom: 'Начало статистики',
        dateTimeTo: 'Конец статистики',
        period: 'Период',
        period1m: '1 минута',
        period10m: '10 минут',
        period30m: '30 минут',
        period1h: '1 час',
        organization: 'Организация',
      },
      display: {
        columnTime: 'Время',
        columnMin: 'Мин. частота',
        columnAvg: 'Средняя частота',
        columnMean: 'Медиана',
        columnMax: 'Макс. частота',
        tooltipMin: 'Мин.',
        tooltipAvg: 'Средн.',
        tooltipMean: 'Мед.',
        tooltipMax: 'Макс.',
      },
    },
    usefulLinks: {
      label: 'Полезные ресурсы',
    },

    feedback: {
      label: 'Обратная связь',
      name: 'ФИО',
      phone: 'Телефон',
      email: 'Почта',
      message: 'Сообщение',
      success: 'Ваше сообщение отправлено',
    },
    largeCheckList: {
      filterByName: 'Фильтр по названию',
      selectedOnly: 'только выбранные',
      valueEditor: 'Редактор выбранных значений',
      value: 'Список значений',
    },
    validation: {
      required: 'Это поле обязательно',
      passwordMismatch: 'Пароли не совпадают',
    },
    actions: {
      add: 'Добавить',
      admit: 'Допустить',
      notAdmit: 'Не допустить',
      repeat: 'Повторить',
      attachToken: 'Прикрепить токен',
      ban: 'Заблокировать',
      cancel: 'Отмена',
      changePassword: 'Изменить пароль',
      close: 'Закрыть',
      confirm: 'Подтвердить',
      copy: 'Копировать',
      download: 'Загрузить',
      goBack: 'Вернуться',
      export: 'Экспорт',
      fill: 'Заполнить',
      login: 'Вход',
      loginByToken: 'Вход по токену',
      loginByESIA: 'Вход через ГосУслуги',
      logout: 'Выход',
      print: 'Печать',
      regOrgKaluga: 'Регистрация в Калуга Астрал',
      replace: 'Заменить',
      replaceToken: 'Заменить токен',
      attachEsiaToProfile: 'Привязать профиль из ГосУслуг',
      unlinkEsiaFromProfile: 'Открепить профиль из ГосУслуг',
      revokeBan: 'Разблокировать',
      save: 'Сохранить',
      select: 'Выбрать…',
      send: 'Отправить',
      clearMedicEcp: 'Очистить ЭЦП',
      topUpBalance: 'Пополнить баланс',
      uploadPhoto: 'Загрузить фото',
    },
    dialogs: {
      soundNotification: {
        title: 'Звуковые уведомления',
        content: 'Разрешить звуковые уведомления?\nЧтобы убрать это сообщение, вы можете разрешить воспроизведение звука в настройках сайта',
      },
      ok: 'OK',
      cancel: 'Отмена',
    },
    common: {
      kisArtId: 'КИС АРТ ID',
      noData: 'Нет данных',
      roleMedic: 'Мед. работник',
      roleDispatcher: 'Диспетчер',
      roleOrgDirector: 'Директор организации',
      roleAdmin: 'Администратор',
      roleMedOrgDirector: 'Директор мед. организации',
      roleSupport: 'Поддержка',
      rolePartner: 'Партнёр',
      roleMechanic: 'Механик',
      roleUser: 'Пользователь',
      sexMale: 'Мужской',
      sexFemale: 'Женский',
      changelog: 'Список изменений',
      emptyValue: 'ПУСТОЕ ЗНАЧЕНИЕ',
    },
    errors: {
      fetchError: 'Ошибка при получении данных',
      formatError: 'Недопустимый формат',
      noData: 'Нет данных для указанных параметров',
      submitError: 'Ошибка при выполнении запроса',
      unknownError: 'Ошибка при выполнении действия',
      decimalError: 'Значение должно быть десятичным числом, пример формата: 13.50, 5.00 или 5 (точка разделяет десятичное значение)'
    },
  },
};
