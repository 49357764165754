import React from 'react';

import Paper from '@material-ui/core/Paper';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import images from '../../images';
import styles from './Image.module.css';

const QrCode = ({src, title}) => {
  return (
    <Paper className={styles.container}>
      <Zoom>
        <img className={styles.image} src={src || images.noqr} alt=""/>
      </Zoom>
      {!!title &&
        <div className={styles.title}>{title}</div>
      }
    </Paper>
  );
}

export default QrCode;
