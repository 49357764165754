import React from 'react';
import { Admin } from 'react-admin';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import Layout from './layout/Layout';
import ProfileMenu from './layout/ProfileMenu';
import customRoutes from './routes';

import RestrictedResource from './components/admin/RestrictedResource';
import { CheckupShow, CheckupList, CheckupCreate } from './pages/checkups';
import {
  DealerList, DealerShow, DealerCreate, DealerEdit,
} from './pages/dealers';
import { DealerBalanceList, DealerBalanceShow } from './pages/dealerBalance';
import {
  DealerPaymentList, DealerPaymentShow, DealerPaymentCreate,
} from './pages/dealerPayments';
import {
  DealersResponsibleList, DealersResponsibleShow, DealersResponsibleCreate, DealersResponsibleEdit,
} from './pages/dealersResponsible';
import {
  InspectionPointList, InspectionPointShow, InspectionPointCreate, InspectionPointEdit,
} from './pages/inspectionPoints';
import { LoginPage } from './pages/auth';
import {
  MedOrganizationList, MedOrganizationShow, MedOrganizationCreate, MedOrganizationEdit,
} from './pages/medOrganizations';
import { OrgPaymentList, OrgPaymentShow, OrgPaymentCreate } from './pages/orgPayments';
import {
  OrganizationList, OrganizationShow, OrganizationCreate, OrganizationEdit,
} from './pages/organizations';
import { OrgBalanceList, OrgBalanceShow } from './pages/orgBalance';
import {
  PeriodicMailingList, PeriodicMailingShow, PeriodicMailingCreate, PeriodicMailingEdit,
} from './pages/periodicMailings';
import {
  TechInspectionList, TechInspectionShow, TechInspectionCreate, TechInspectionEdit,
} from './pages/techInspections';
import {
  TerminalList, TerminalShow, TerminalCreate, TerminalEdit,
} from './pages/terminals';
import {
  TestQuestionCategoryList, TestQuestionCategoryShow, TestQuestionCategoryCreate, TestQuestionCategoryEdit,
} from './pages/testQuestionCategories';
import {
  TestResultList, TestResultShow, TestResultCreate, TestResultEdit,
} from './pages/testResults';
import {
  UserList, UserShow, UserCreate, UserEdit,
} from './pages/users';
import {
  VehicleList, VehicleShow, VehicleCreate, VehicleEdit,
} from './pages/vehicles';
import { WaybillList, WaybillShow, } from './pages/waybills';
import { EWaybillList, EWaybillShow, EWaybillCreate, EWaybillEdit, } from './pages/ewaybills';
import {
  WorkerList, WorkerShow, WorkerCreate, WorkerEdit,
} from './pages/workers';
import { Section } from './permissions';
import { WebsocketProvider } from './providers/webSocketProvider';

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <WebsocketProvider>
      <Admin
        authProvider={authProvider}
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
        logoutButton={ProfileMenu}
      >
        {(permissions) => [
          <RestrictedResource
            name="checkups"
            list={CheckupList}
            show={CheckupShow}
            create={CheckupCreate}
            permissions={permissions}
            section={Section.CHECKUPS}
          />,
          <RestrictedResource
            name="dealer_balance"
            list={DealerBalanceList}
            show={DealerBalanceShow}
            permissions={permissions}
            section={Section.DEALER_BALANCE}
          />,
          <RestrictedResource
            name="dealer_payments"
            list={DealerPaymentList}
            show={DealerPaymentShow}
            create={DealerPaymentCreate}
            permissions={permissions}
            section={Section.DEALER_PAYMENTS}
          />,
          <RestrictedResource
            name="dealers"
            list={DealerList}
            show={DealerShow}
            create={DealerCreate}
            edit={DealerEdit}
            permissions={permissions}
            section={Section.DEALERS}
          />,
          <RestrictedResource
            name="dealers_responsible"
            list={DealersResponsibleList}
            show={DealersResponsibleShow}
            create={DealersResponsibleCreate}
            edit={DealersResponsibleEdit}
            permissions={permissions}
            section={Section.DEALERS_RESPONSIBLE}
          />,
          <RestrictedResource
            name="inspection_points"
            list={InspectionPointList}
            show={InspectionPointShow}
            create={InspectionPointCreate}
            edit={InspectionPointEdit}
            permissions={permissions}
            section={Section.INSPECTION_POINTS}
          />,
          <RestrictedResource
            name="med_organizations"
            list={MedOrganizationList}
            show={MedOrganizationShow}
            create={MedOrganizationCreate}
            edit={MedOrganizationEdit}
            permissions={permissions}
            section={Section.MED_ORGANIZATIONS}
          />,
          <RestrictedResource
            name="organizations"
            list={OrganizationList}
            show={OrganizationShow}
            create={OrganizationCreate}
            edit={OrganizationEdit}
            permissions={permissions}
            section={Section.ORGANIZATIONS}
          />,
          <RestrictedResource
            name="test_question_categories"
            list={TestQuestionCategoryList}
            show={TestQuestionCategoryShow}
            create={TestQuestionCategoryCreate}
            edit={TestQuestionCategoryEdit}
            permissions={permissions}
            section={Section.TEST_QUESTION_CATEGORIES}
          />,
          <RestrictedResource
            name="test_results"
            list={TestResultList}
            show={TestResultShow}
            create={TestResultCreate}
            edit={TestResultEdit}
            permissions={permissions}
            section={Section.TEST_RESULTS}
          />,
          <RestrictedResource
            name="org_balance"
            list={OrgBalanceList}
            show={OrgBalanceShow}
            permissions={permissions}
            section={Section.ORG_BALANCE}
          />,
          <RestrictedResource
            name="org_payments"
            list={OrgPaymentList}
            show={OrgPaymentShow}
            create={OrgPaymentCreate}
            permissions={permissions}
            section={Section.ORG_PAYMENTS}
          />,
          <RestrictedResource
            name="periodic_mailings"
            list={PeriodicMailingList}
            show={PeriodicMailingShow}
            create={PeriodicMailingCreate}
            edit={PeriodicMailingEdit}
            permissions={permissions}
            section={Section.PERIODIC_MAILINGS}
          />,
          <RestrictedResource
            name="tech_inspections"
            list={TechInspectionList}
            show={TechInspectionShow}
            create={TechInspectionCreate}
            edit={TechInspectionEdit}
            permissions={permissions}
            section={Section.TECH_INSPECTIONS}
          />,
          <RestrictedResource
            name="terminals"
            list={TerminalList}
            show={TerminalShow}
            create={TerminalCreate}
            edit={TerminalEdit}
            permissions={permissions}
            section={Section.TERMINALS}
          />,
          <RestrictedResource
            name="users"
            list={UserList}
            show={UserShow}
            create={UserCreate}
            edit={UserEdit}
            permissions={permissions}
            section={Section.USERS}
          />,
          <RestrictedResource
            name="vehicles"
            list={VehicleList}
            show={VehicleShow}
            create={VehicleCreate}
            edit={VehicleEdit}
            permissions={permissions}
            section={Section.VEHICLES}
          />,
          <RestrictedResource
            name="waybills"
            list={WaybillList}
            show={WaybillShow}
            permissions={permissions}
            section={Section.WAYBILLS}
          />,
          <RestrictedResource
            name="ewaybills"
            list={EWaybillList}
            show={EWaybillShow}
            create={EWaybillCreate}
            edit={EWaybillEdit}
            permissions={permissions}
            section={Section.EWAYBILLS}
          />,
          <RestrictedResource
            name="workers"
            list={WorkerList}
            show={WorkerShow}
            create={WorkerCreate}
            edit={WorkerEdit}
            permissions={permissions}
            section={Section.WORKERS}
          />,
        ]}
      </Admin>
    </WebsocketProvider>
  </MuiPickersUtilsProvider>
);

export default App;
