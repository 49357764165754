import {useCallback, useEffect, useMemo, useState} from 'react';

const useAsyncValue = (promiseFactory, promiseArg, initialValue, errorCallback) => {
  const memoisedPromiseFactory = useCallback(promiseFactory, []);
  const memoisedPromiseArg = useMemo(() => promiseArg, [promiseArg]);
  const safeErrorCallback = errorCallback ? errorCallback : () => {};
  const memoisedErrorCallback = useCallback(safeErrorCallback, []);
  const [value, setValue] = useState(undefined);
  const fetchValue = useCallback(() => {
    if (typeof(memoisedPromiseArg) === 'undefined') {
      return;
    }
    memoisedPromiseFactory(memoisedPromiseArg).then((promiseResult) => {
      setValue(promiseResult);
    }).catch((error) => {
      console.log(error);
      memoisedErrorCallback(error);
    });
  }, [memoisedPromiseFactory, memoisedPromiseArg, memoisedErrorCallback]);
  useEffect(() => {
    setValue(undefined);
    fetchValue();
  }, [fetchValue]);
  return [
    (typeof(value) !== 'undefined') ? value : initialValue,
    fetchValue
  ];
}

export default useAsyncValue;
