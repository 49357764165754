import React, { useCallback } from 'react';

import { formatDateAsDateTime } from '../../utils/formatters';

import DateInput from './DateInput';

const TZDateInput = ({ isPeriodEnd, ...rest }) => {
  const valueFormatter = useCallback(
    (v) => formatDateAsDateTime(v, isPeriodEnd), [isPeriodEnd],
  );
  return (
    <DateInput valueFormatter={valueFormatter} {...rest} />
  );
};

export default TZDateInput;
