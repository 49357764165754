import * as React from "react"

function Robot(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 40 40"
      {...props}
    >
      <path d="M19.842 2.694a1.323 1.323 0 00-1.321 1.324 1.323 1.323 0 00.651 1.137v2.937a4.63 3.969 0 00-3.826 3.907 4.63 3.969 0 00.013.22H8.73v2.646a5.292 5.953 0 00-.006 0 5.292 5.953 0 00-5.285 5.958A5.292 5.953 0 008.73 26.77v2.646h22.49V26.77a5.292 5.953 0 00.002 0 5.292 5.953 0 005.29-5.953H35.19a3.969 4.63 0 01-3.968 4.63 3.969 4.63 0 01-.001 0v-9.26a3.969 4.63 0 013.969 4.622l-3.97.008 5.292-.01a5.292 5.953 0 00-5.291-5.943V12.22h-6.624a4.63 3.969 0 00.01-.223h-1.324a3.307 2.646 0 01-.014.223h-6.587a3.307 2.646 0 01-.013-.22 3.307 2.646 0 013.303-2.649 3.307 2.646 0 013.311 2.642l1.323-.002a4.63 3.969 0 00-4.092-3.93V5.156a1.323 1.323 0 00.653-1.14h-.653l.653-.001a1.323 1.323 0 00-1.325-1.321zM8.726 16.188a3.969 4.63 0 01.005 0v9.26a3.969 4.63 0 01-3.968-4.627 3.969 4.63 0 013.963-4.633zm5.956 0a1.984 1.984 0 011.987 1.98l-1.985.004h1.985a1.984 1.984 0 01-1.984 1.985 1.984 1.984 0 01-1.985-1.983 1.984 1.984 0 011.982-1.986zm10.548 0a1.984 1.984 0 011.986 1.98l-1.984.004h1.984a1.984 1.984 0 01-1.983 1.985 1.984 1.984 0 01-1.985-1.983 1.984 1.984 0 011.982-1.986zm-10.807 7.408h11.306v2.646H14.423zM17.992 29.417h3.969v5.292h-3.969z" />
      <path d="M8.084 39.18c0-1.72.552-3.277 1.508-4.257.672-.688 1.621-1.15 2.678-1.303.28-.04 3.204-.066 7.507-.067 6.616 0 7.086.008 7.652.124 1.673.346 2.903 1.2 3.569 2.48.383.735.536 1.431.585 2.656l.04 1.02H8.084l.001-.652z" />
    </svg>
  )
}

export default Robot
