import React from 'react';
import {withTranslate} from 'react-admin';

import ProgressDialog from './ProgressDialog';

class _PromiseProgressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProgress: true,
      isError: false,
      errorText: ''
    }
  }

  componentDidMount() {
    this._executeTask();
  }

  render() {
    const {isProgress, isError, errorText} = this.state;
    return (
      <ProgressDialog
        open={true}
        isProgress={isProgress}
        isError={isError}
        errorText={errorText}
        onRetry={this._executeTask}
        onCancel={this.props.onCancel}/>
    );
  }

  _executeTask = () => {
    const {translate, onDone} = this.props;
    this.setState({
      isProgress: true,
      isError: false,
      errorText: ''
    });
    this.props.promiseFactory().then(result => {
      onDone();
    }).catch((e) => {
      if (e.errorText) {
        this.setState({
          isProgress: false,
          isError: true,
          errorText: e.errorText
        });
      }
      else if (e.errorTextId) {
        this.setState({
          isProgress: false,
          isError: true,
          errorText: translate(e.errorTextId)
        });
      }
      else {
        this.setState({
          isProgress: false,
          isError: true,
          errorText: this.props.translate('telemedic.errors.unknownError')
        });
      }
    });
  }
}

export default withTranslate(_PromiseProgressDialog);
