import React from 'react';
import { Create } from 'react-admin';

import TestQuestionCategoryForm from './TestQuestionCategoryForm';
import TestQuestionCategoryTitle from './TestQuestionCategoryTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import { RoutePath } from '../../routes';

const TestQuestionCategoryCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={TestQuestionCategoryForm}
    routePath={RoutePath.TEST_QUESTION_CATEGORIES_SHOW}
    title={<TestQuestionCategoryTitle />}
    {...props}
  />
);

export default TestQuestionCategoryCreate;
