import React from 'react';
import { useTranslate } from 'react-admin';

const WorkerTitle = ({ record }) => {
  const typeName = useTranslate()('telemedic.workers.show.label');
  return (
    <span>
      {
        (record && record.first_name)
          ? `${record.last_name} ${record.first_name} ${record.middle_name}`
          : typeName
      }
    </span>
  );
};

export default WorkerTitle;
