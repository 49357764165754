import React from 'react';
import PropTypes from 'prop-types';
import {withTranslate} from 'react-admin';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class _DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    }
  }

  render() {
    const {
      className, id, label, choices, translate, ...rest
    } = this.props;
    const labelText = label ? translate(label) : '';
    const anchorEl = this.state.anchorEl;
    return (
      <div className={className}>
        <Button
          aria-controls={id}
          aria-haspopup="true"
          endIcon={<ArrowDropDownIcon/>}
          onClick={this.handleClick}
          {...rest}
        >
          {labelText}
        </Button>
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={this.handleClose}
        >
          {choices.map((choice, index) => (
            <MenuItem
              key={index}
              data-index={index}
              onClick={this.handleSelect}
            >
              {choice.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleSelect = (event) => {
    const index = parseInt(event.currentTarget.getAttribute('data-index'));
    this.props.choices[index].onSelect();
    this.setAnchorEl(null);
  }

  handleClose = () => {
    this.setAnchorEl(null);
  };

  setAnchorEl(anchorEl) {
    this.setState({anchorEl: anchorEl});
  }
}

const DropdownButton = withTranslate(_DropdownButton);

DropdownButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  choices: PropTypes.array
};


export default DropdownButton;
