import React from 'react';
import {
  Datagrid, TextField,
} from 'react-admin';

import RestrictedList from '../../components/admin/RestrictedList';

const TestQuestionCategoryList = (props) => (
  <RestrictedList
    title="telemedic.testQuestionCategory.label"
    sort={{ field: 'category', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <TextField
        label="telemedic.testQuestionCategory.list.category"
        source="category"
        sortable
      />
    </Datagrid>
  </RestrictedList>
);

export default TestQuestionCategoryList;
