import React, { useCallback, useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import useInterval from '../../hooks/useInterval';
import useTelemedicService from '../../hooks/useTelemedicService';
import styles from './DiskSpacePage.module.css';

const useStyles = makeStyles(() => ({
  safeSpace: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'green',
    },
  },
  warningSpace: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'gold',
    },
  },
  criticalSpace: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'red',
    },
  },
}));

const renderDiskSpace = (diskSpace, translate, classes) => {
  let style = classes.safeSpace;
  if (diskSpace.used_space_percentage > 60 && diskSpace.used_space_percentage <= 80) {
    style = classes.warningSpace;
  } else if (diskSpace.used_space_percentage > 80) {
    style = classes.criticalSpace;
  }
  return (
    <div className={styles.diskSpace}>
      <Typography variant="h6" gutterBottom>
        {diskSpace.name}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={diskSpace.used_space_percentage}
        className={style}
      />
      {`${translate('telemedic.diskSpace.show.total')}: ${diskSpace.total_space} `}
      {`${translate('telemedic.diskSpace.show.available')}: ${diskSpace.available_space} `}
      {`(${diskSpace.available_space_percentage}%)`}
    </div>
  );
};

const DiskSpacePage = (props) => {
  const service = useTelemedicService();
  const [diskSpaces, setDiskSpaces] = useState();
  const loadDiskSpaces = useCallback(() => {
    service.getDiskSpace().then((response) => {
      setDiskSpaces(response.json.disk_spaces);
    }).catch((e) => {
      console.log(e);
    });
  }, [service]);
  useInterval(loadDiskSpaces, 60000, true);
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div>
      <Title title="telemedic.diskSpace.label" />
      <Paper className={styles.paper}>
        {
          diskSpaces && diskSpaces.length > 0
            ? diskSpaces.map((diskSpace) => renderDiskSpace(diskSpace, translate, classes))
            : translate('telemedic.diskSpace.show.noInfo')
        }
      </Paper>
    </div>
  );
};

export default DiskSpacePage;
