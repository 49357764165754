import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextInput, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Form from '../../../components/Form';
import ProgressButton from '../../../components/ProgressButton';
import styles from '../LoginStyles.module.css';

const LoginForm = ({ onSubmit, onCancel, submissionProgress }) => {
  const translate = useTranslate();
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.login) {
      errors.login = 'telemedic.validation.required';
    }
    return errors;
  }, []);
  return (
    <Form
      className={styles.form}
      onSubmit={onSubmit}
      validate={validate}
    >
      <Typography variant="h6">
        {translate('telemedic.passwordReset.passwordReset')}
      </Typography>
      <TextInput
        className={styles.largeInput}
        fullWidth
        helperText={false}
        label="telemedic.passwordReset.login"
        margin="normal"
        name="login"
        variant="outlined"
      />
      <Typography>
        {translate('telemedic.passwordReset.hint')}
      </Typography>
      <ProgressButton
        className={styles.control}
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        label="telemedic.passwordReset.getCode"
        progress={submissionProgress}
      />
      <Button
        className={styles.control}
        fullWidth
        color="primary"
        onClick={onCancel}
        type="button"
      >
        {translate('telemedic.actions.goBack')}
      </Button>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submissionProgress: PropTypes.bool.isRequired,
};

export default LoginForm;
