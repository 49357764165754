import React, { useState } from 'react';
import {
  useTranslate,
  Title,
} from 'react-admin';

import TableRowEmpty from '../../components/tables/TableRowEmpty';


import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useTelemedicService from '../../hooks/useTelemedicService';


const AlcoholSmsReport = (props) => {
  const [organizations, setOrganizations] = useState(null);
  let notnull = null;
  const service = useTelemedicService();
  if (organizations === null) {
    service.getAlcoholList().then((response) => {
      setOrganizations(response.json.data);
    }).catch((error) => {
      console.log(error);
    });
  }
  if (organizations !== null && notnull === null) {
    console.log(organizations);
  }
  
  const translate = useTranslate();
  return(
    <div>
      <Title title="telemedic.alcoholSmsReport.label" />
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('telemedic.alcoholSmsReport.list.organizationName')}</TableCell>
                <TableCell>{translate('telemedic.alcoholSmsReport.list.dealer')}</TableCell>
                <TableCell>{translate('telemedic.alcoholSmsReport.list.senderName')}</TableCell>
                <TableCell>{translate('telemedic.alcoholSmsReport.list.senderTelephone')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations && organizations.map((organization) => {
                const { id, attributes } = organization;
                return (
                  <TableRow key={id}>
                    <TableCell>{attributes.name}</TableCell>
                    <TableCell>{attributes.dealer ? attributes.dealer.name : ''}</TableCell>
                    <TableCell>{attributes.responsible_name ? attributes.responsible_name : ''}</TableCell>
                    <TableCell>{attributes.responsible_phone ? attributes.responsible_phone : ''}</TableCell>
                  </TableRow>
                );
              })}
              {organizations == null && (
                <TableRowEmpty colSpan={3} textId="telemedic.alcoholSmsReport.list.prompt" />
              )}
              {organizations && !organizations.length && (
                <TableRowEmpty colSpan={3} textId="telemedic.alcoholSmsReport.list.noResults" />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default AlcoholSmsReport;