import React from 'react';
import {Filter, Datagrid, BooleanField, TextField} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatField from '../../components/fields/FormatField';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {Role} from '../../constants';
import {getRole} from '../../storages/auth';
import {
  formatDisplayDateTime, formatMailingPeriod, formatPersonName
} from '../../utils/formatters';

export const PeriodicMailingList = (props) => {
  const periodFormatter = ({period, period_unit}) => {
    return formatMailingPeriod(period, period_unit);
  };
  const showWorkerColumn = [Role.ADMIN, Role.MANAGER].indexOf(getRole()) >= 0;
  return (
    <RestrictedList
      title="telemedic.periodicMailings.label"
      filters={showWorkerColumn ? <PeriodicMailingFilter/> : null}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <BooleanField
          label="telemedic.periodicMailings.list.active"
          source="active"
          sortable={false}/>
        <TextField
          label="telemedic.periodicMailings.list.email"
          source="email"
          sortable={false}/>
        <FormatField
          label="telemedic.periodicMailings.list.period"
          formatter={periodFormatter}
          sortable={false}/>
        <FormatValueField
          label="telemedic.periodicMailings.list.last_mailing"
          formatter={formatDisplayDateTime}
          source="last_mailing"
          sortable={true}/>
        {showWorkerColumn &&
          <FormatValueField
            label="telemedic.periodicMailings.list.worker"
            formatter={formatPersonName}
            source="worker"
            sortable={false}/>
        }
      </Datagrid>
    </RestrictedList>
  );
}

const PeriodicMailingFilter = (props) => {
  const service = useTelemedicService();
  const workers = useTelemedicServiceJsonValue(() => {
    return service.getWorkerValues();
  }, null, []);
  return (
    <Filter key="periodic_mailing_filter" {...props}>
      <AutocompleteInput
        name="worker_id"
        label="telemedic.periodicMailings.list.worker"
        choices={workers}
        choiceLabelField="name"
        choiceValueField="id"
        helperText={false}
        alwaysOn resettable/>
    </Filter>
  );
}
