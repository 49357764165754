import React from 'react';
import PropTypes from 'prop-types';

const ListField = ({record, source, children}) => {
  const child = React.Children.only(children);
  const items = record ? record[source] : null;
  return (
    <div>
      {!!items && items.map((item, index) => (
        React.cloneElement(child, {
          key: index,
          record: item
        })
      ))}
    </div>
  );
}

ListField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};


ListField.defaultProps = {
  addLabel: true
};

export default ListField;
