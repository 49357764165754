import React from 'react';
import PropTypes from 'prop-types';
import { useInput, useTranslate } from 'react-admin';

import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperTextSpan from '../FormHelperTextSpan';
import createMultiAutocomplete from '../../hocs/createMultiAutocomplete';

const FilledInputMultiAutocomplete = createMultiAutocomplete(FilledInput);

const MultiAutocompleteInput = (props) => {
  const {
    choices,
    choiceLabelField,
    choiceValueField,
    className,
    dropdownAboveInput,
    fullWidth,
    helperText,
    label,
    margin = 'dense',
    required,
    suggestionRows,
    variant = 'filled'
  } = props;
  const {
    meta: { touched, error },
    input,
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  return (
    <FormControl
      className={className}
      error={!!(touched && error)}
      fullWidth={fullWidth}
      margin={margin}
    >
      <InputLabel
        error={!!(touched && error)}
        required={required || isRequired}
        variant={variant}
      >
        {labelText}
      </InputLabel>
      <FilledInputMultiAutocomplete
        choices={choices}
        choiceLabelField={choiceLabelField}
        choiceValueField={choiceValueField}
        dropdownAboveInput={dropdownAboveInput}
        error={!!(touched && error)}
        fullWidth={fullWidth}
        margin={margin}
        required={required}
        resettable={!(required || isRequired)}
        suggestionRows={suggestionRows}
        {...input}
      />
      <FormHelperText
        error={!!(touched && error)}
        variant={variant}
      >
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}
        />
      </FormHelperText>
    </FormControl>
  );
}

MultiAutocompleteInput.propTypes = {
  choices: PropTypes.array,
  choiceLabelField: PropTypes.string,
  choiceValueField: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  label: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string
};

export default MultiAutocompleteInput;
