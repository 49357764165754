import React from 'react';
import {useTranslate} from 'react-admin';

const UserTitle = ({record}) => {
  const typeName = useTranslate()('telemedic.users.show.label');
  return (
    <span>{
      (record && record.first_name)
      ? `${typeName} ${record.last_name} ${record.first_name} ${record.middle_name}`
      : typeName
    }</span>
  );
};

export default UserTitle;
