import React from 'react';
import {TextInput, required} from 'react-admin';

import DateInput from '../../components/inputs/DateInput';
import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DeleteableFileInput from '../../components/inputs/DeleteableFileInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import TelemedicDataSource from '../../components/TelemedicDataSource';

const MedOrganizationForm = ({getInputProps, ...rest}) => {
  return (
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.common.kisArtId"
        source="kis_art_id"
        {...getInputProps('kis_art_id')}/>
      <TextInput
        label="telemedic.medOrganizations.form.inn"
        source="inn"
        validate={required()}
        {...getInputProps('inn')}/>
      <TextInput
        label="telemedic.medOrganizations.form.kpp"
        source="kpp"
        {...getInputProps('kpp')}/>
      <TextInput
        label="telemedic.medOrganizations.form.name"
        source="name"
        validate={required()}
        {...getInputProps('name')}/>
      <TextInput
        label="telemedic.medOrganizations.form.shortName"
        source="short_name"
        {...getInputProps('short_name')}/>
      <TextInput
        label="telemedic.medOrganizations.form.phone"
        source="phone"
        {...getInputProps('phone')}/>
      <TextInput
        label="telemedic.medOrganizations.form.address"
        source="address"
        {...getInputProps('address')}/>
      {/* visible to admin, med org director and partner */}
      <TelemedicDataSource
        dataPromise={directorsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        validate={required()}
      >
        <AutocompleteInput
          label="telemedic.medOrganizations.form.director"
          choiceLabelField="name"
          choiceValueField="id"
          source="director_id"
          dropdownAboveInput
          fullWidth
          {...getInputProps('director_id')}/>
      </TelemedicDataSource>
      <TextInput
        label="telemedic.medOrganizations.form.licenseSerial"
        source="license_serial"
        {...getInputProps('license_serial')}
      />
      <TextInput
        label="telemedic.medOrganizations.form.licenseNumber"
        source="license_number"
        {...getInputProps('license_number')}/>
      <DateInput
        source="license_date_from"
        label="telemedic.medOrganizations.form.licenseDateFrom"
      />
      <DateInput
        source="license_date_to"
        label="telemedic.medOrganizations.form.licenseDateTo"
      />
      <DeleteableFileInput
        accept="image/*"
        deleteCheckboxLabel="telemedic.medOrganizations.form.deleteLicense"
        fileInputLabel="telemedic.medOrganizations.form.license"
        source="license"
        {...getInputProps('license')}/>
    </RestrictedSimpleForm>
  );
}

const directorsDataPromise = (service) => {
  return service.getMedOrganizationFormDirectorValues();
}

export default MedOrganizationForm;
