import React from 'react';
import {SimpleShowLayout, TextField} from 'react-admin';

import DealerPaymentTitle from './DealerPaymentTitle';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import {formatDisplayDateTime, formatPersonName} from '../../utils/formatters';

export const DealerPaymentShow = (props) => {
  return (
    <RestrictedShow title={<DealerPaymentTitle/>} {...props}>
      <SimpleShowLayout>
        <TextField
          label="telemedic.dealerPayments.show.dealer"
          source="dealer.name"/>
        <TextField
          label="telemedic.dealerPayments.show.amount"
          source="amount"/>
        <FormatValueField
          label="telemedic.dealerPayments.show.datetime"
          source="datetime"
          formatter={formatDisplayDateTime}/>
        <TextField
          label="telemedic.dealerPayments.show.login"
          source="worker.login"/>
        <FormatValueField
          label="telemedic.dealerPayments.show.userName"
          source="worker"
          sortable={false}
          formatter={formatPersonName}/>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
