import React from 'react';
import {TextInput, required} from 'react-admin';
import Grid from '@material-ui/core/Grid';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import TelemedicDataSource from '../../components/TelemedicDataSource';

const VehicleForm = ({getInputProps, ...rest}) => {
  return (
    <RestrictedSimpleForm {...rest}>
      <Grid item xs="auto" container justifyContent="space-between" spacing={2} style={{ width: '500px' }}>
        <Grid item xs={6}>
          <TextInput
            label="telemedic.common.kisArtId"
            source="kis_art_id"
            {...getInputProps('kis_art_id')}/>
          <TextInput
            label="telemedic.vehicles.form.manufacturer"
            source="manufacturer"
            validate={required()}
            {...getInputProps('manufacturer')}/>
          <TextInput
            label="telemedic.vehicles.form.model"
            source="model"
            validate={required()}
            {...getInputProps('model')}/>
          <TextInput
            label="telemedic.vehicles.form.year"
            source="year"
            validate={required()}
            {...getInputProps('year')}/>
          <TextInput
            label="telemedic.vehicles.form.color"
            source="color"
            validate={required()}
            {...getInputProps('color')}/>
          <TextInput
            label="telemedic.vehicles.form.carLicensePlate"
            source="car_license_plate"
            validate={required()}
            {...getInputProps('car_license_plate')}/>
          <TextInput
            label="telemedic.vehicles.form.vin"
            source="vin"
            validate={required()}
            {...getInputProps('vin')}/>
          <TextInput
            label="telemedic.vehicles.form.numberTrail"
            source="number_trail"
            {...getInputProps('number_trail')}/>
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="telemedic.vehicles.form.licenseCard"
            source="license_card"
            validate={required()}
            {...getInputProps('license_card')}/>
          <TextInput
            label="telemedic.vehicles.form.osago"
            source="osago"
            validate={required()}
            {...getInputProps('osago')}/>
          <DateInput
            label="telemedic.vehicles.form.endOsago"
            source="end_osago"
            validate={required()}
            {...getInputProps('end_osago')}/>
          <TextInput
            label="telemedic.vehicles.form.diagnosticCard"
            source="diagnostic_card"
            validate={required()}
            {...getInputProps('diagnostic_card')}/>
          <DateInput
            label="telemedic.vehicles.form.endDiagnosticCard"
            source="end_diagnostic_card"
            validate={required()}
            {...getInputProps('end_diagnostic_card')}/>
          <TextInput
            label="telemedic.vehicles.form.permissionNumber"
            source="permission_number"
            validate={required()}
            {...getInputProps('permission_number')}/>
          <TelemedicDataSource
            dataPromise={organizationsDataPromise}
            dataDefault={[]}
            dataAttrName="choices"
            validate={required()}
          >
            <AutocompleteInput
              label="telemedic.vehicles.form.organization"
              choiceLabelField="name"
              choiceValueField="id"
              source="organization_id"
              dropdownAboveInput
              fullWidth
              {...getInputProps('organization_id')}/>
          </TelemedicDataSource>
        </Grid>
      </Grid>
      
      
    </RestrictedSimpleForm>
  );
}

const organizationsDataPromise = (service) => {
  return service.getVehicleFormOrganizationValues();
}

export default VehicleForm;
