import React from 'react';
import {useTranslate} from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const progressStyle = {
  position: 'absolute',
};

const ProgressButton = ({
  className,
  fullWidth,
  type,
  variant,
  color,
  icon,
  label,
  onClick,
  progress,
  disabled
}) => {
  const translate = useTranslate();
  const buttonStyle = progress ? {color: 'transparent'} : null;
  return (
    <Button
      className={className}
      fullWidth={fullWidth}
      type={type}
      variant={variant}
      color={color}
      disabled={progress || disabled}
      startIcon={icon}
      onClick={onClick}
      style={buttonStyle}
    >
      {translate(label)}
      {progress && <CircularProgress size={25} style={progressStyle}/>}
    </Button>
  );
};

export default ProgressButton;
