import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const floatLeftStyle = {
  float: 'left',
}

const floatRightStyle = {
  float: 'right',
}

const ToolBar = ({title, onBackClick, children}) => {
  return (
    <div>
      <Typography variant="h4" gutterBottom style={floatLeftStyle}>
        {!!onBackClick && <IconButton
          variant="contained"
          color="primary"
          onClick={onBackClick}
        >
          <ArrowBackIcon/>
        </IconButton>}
        {title}
      </Typography>
      <div style={floatRightStyle}>
        {children}
      </div>
    </div>
  );
};

export default ToolBar;
