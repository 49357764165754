import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate, RadioButtonGroupInput } from 'react-admin';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import TextInput from '../../components/inputs/TextInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import styles from './HealthStatusForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import { formatApiDate } from '../../utils/formatters';

export const ReportType = {
  DATE: 'date',
  CHECKUP_NUMBER: 'number',
};

class HealthStatusForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      users: [],
      selectedOrganiztionId: null,
      selectedUserId: null,
      submitProgress: false,
      selectedReportType: ReportType.DATE,
    };
    this.service = new TelemedicService(getAuthToken());

    this.usersCache = {};

    this.dateRequiredFields = [
      'dateFrom', 'dateTo', 'organizationId', 'userId',
    ];

    this.checkupNumberRequiredFields = [
      'organizationId', 'userId', 'numberOfLastCheckups',
    ];

    this.reportTypeChoices = [
      { id: ReportType.DATE, name: 'telemedic.healthStatus.form.dateIntervalType' },
      { id: ReportType.CHECKUP_NUMBER, name: 'telemedic.healthStatus.form.numberOfLastCheckupsType' },
    ];
  }

  componentDidMount() {
    const { userId } = this.props;
    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    if (userId) {
      this.service.getUser(userId).then((response) => {
        const { data } = response.json;
        const selectedOrganiztionId = data.attributes.organization_id;
        this.setState({
          selectedOrganiztionId,
          selectedUserId: data.id,
        });
        this.loadOrgUsers(selectedOrganiztionId);
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onChange = (data) => {
    const { selectedReportType, selectedOrganiztionId } = this.state;
    if (selectedOrganiztionId !== data.organizationId) {
      this.setState({
        selectedOrganiztionId: data.organizationId,
      });
      this.loadOrgUsers(data.organizationId);
    }
    if (data.reportType && selectedReportType !== data.reportType) {
      this.setState({ selectedReportType: data.reportType });
    }
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = values.reportType === ReportType.DATE
      ? this.dateRequiredFields
      : this.checkupNumberRequiredFields;
    requiredFields.forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  };

  onSubmit = (data) => {
    const { paramsCallback, dataCallback, showNotification } = this.props;
    paramsCallback(data);
    this.setState({ submitProgress: true });
    const {
      userId, dateFrom, dateTo, numberOfLastCheckups, reportType
    } = data;
    this.service.getHealthStatus(userId, dateFrom, dateTo, numberOfLastCheckups, reportType).then((response) => {
      this.setState({ submitProgress: false });
      if (response.json.checkups.length > 0) {
        dataCallback(response.json);
      } else {
        showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({ submitProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  };

  loadOrgUsers(organizationId) {
    const cachedUsers = this.usersCache[organizationId];
    if (cachedUsers) {
      this.setState({ users: cachedUsers });
    } else {
      this.service.getUserValues(organizationId).then((response) => {
        const users = response.json.data;
        this.usersCache[organizationId] = users;
        this.setState({ users });
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  render() {
    const {
      organizations, users, selectedOrganiztionId, selectedUserId,
      submitProgress, selectedReportType,
    } = this.state;
    const { params } = this.props;
    const periodEnd = moment();
    const periodStart = moment().subtract(60, 'days');
    const formInitialValues = (params) || {
      dateFrom: formatApiDate(periodStart),
      dateTo: formatApiDate(periodEnd),
      organizationId: selectedOrganiztionId,
      userId: selectedUserId,
      reportType: selectedReportType,
    };
    return (
      <Paper>
        <Form
          initialValues={formInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            {
              selectedReportType === ReportType.CHECKUP_NUMBER && (
                <TextInput
                  className={styles.formControl}
                  source="numberOfLastCheckups"
                  label="telemedic.healthStatus.form.numberOfLastCheckups"
                  required
                />
              )
            }
            {
              selectedReportType === ReportType.DATE && (
                <DateInput
                  className={styles.formControl}
                  source="dateFrom"
                  label="telemedic.healthStatus.form.dateFrom"
                  required
                />
              )
            }
            {
              selectedReportType === ReportType.DATE && (
                <DateInput
                  className={styles.formControl}
                  source="dateTo"
                  label="telemedic.healthStatus.form.dateTo"
                  required
                />
              )
            }
            <AutocompleteInput
              className={styles.formControl}
              source="organizationId"
              label="telemedic.healthStatus.form.organization"
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              required
            />
            <AutocompleteInput
              className={styles.formControl}
              source="userId"
              label="telemedic.healthStatus.form.user"
              choices={users}
              choiceLabelField="name"
              choiceValueField="id"
              required
            />
            <RadioButtonGroupInput
              className={styles.formControl}
              source="reportType"
              label="telemedic.healthStatus.form.reportType"
              choices={this.reportTypeChoices}
            />
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(HealthStatusForm));
