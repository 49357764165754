import React from 'react';
import {Edit} from 'react-admin';

import DealerForm from './DealerForm';
import DealerTitle from './DealerTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const DealerEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={DealerForm}
    routePath={RoutePath.DEALER_SHOW}
    title={<DealerTitle/>}
    {...props}/>
);
