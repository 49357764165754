import React from 'react';
import { connect } from 'react-redux';
import {
  SelectInput, showNotification, withTranslate, RadioButtonGroupInput,
} from 'react-admin';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';

import DateTimeInput from '../../components/inputs/DateTimeInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import ProgressButton from '../../components/ProgressButton';
import TelemedicService from '../../services/TelemedicService';

import styles from './ReceivedCheckupsForm.module.css';

import { getAuthToken, getRole } from '../../storages/auth';
import { ContractStatus, ReportType, Role } from '../../constants';
import { ValueTypes } from '../../utils/values';

const PERIOD_1M = '1m';
const PERIOD_10M = '10m';
const PERIOD_30M = '30m';
const PERIOD_1H = '1h';

class ReceivedCheckupsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      terminals: [],
      dealers: [],
      submitProgress: false,
      selectedReportType: ReportType.REPORT_TYPE_ORGS,
    };
    this.service = new TelemedicService(getAuthToken());

    const { params, translate } = this.props;
    this.periodChoices = [
      { id: PERIOD_1M, name: translate('telemedic.receivedCheckups.form.period1m') },
      { id: PERIOD_10M, name: translate('telemedic.receivedCheckups.form.period10m') },
      { id: PERIOD_30M, name: translate('telemedic.receivedCheckups.form.period30m') },
      { id: PERIOD_1H, name: translate('telemedic.receivedCheckups.form.period1h') },
    ];

    const now = moment();
    const yesterday = moment().subtract(1, 'days');
    this.fornInitialValues = (params) || {
      dateTimeFrom: yesterday.format('YYYY-MM-DD hh:mm:ss'),
      dateTimeTo: now.format('YYYY-MM-DD hh:mm:ss'),
      period: PERIOD_1M,
      reportType: ReportType.REPORT_TYPE_ORGS,
      organizations: [],
      terminals: [],
      dealers: [],
    };

    this.reportTypeChoices = [
      { id: ReportType.REPORT_TYPE_ORGS, name: translate('telemedic.usage.form.reportTypeByOrg') },
      { id: ReportType.REPORT_TYPE_TERMS, name: translate('telemedic.usage.form.reportTypeByTerm') },
    ];
    if (getRole() === Role.ADMIN) {
      this.reportTypeChoices.push(
        { id: ReportType.REPORT_TYPE_DEALERS, name: translate('telemedic.usage.form.reportTypeByDealers') },
      );
    }

    this.requiredFields = ['dateTimeFrom', 'dateTimeTo', 'period'];

    this.defaultChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];

    this.organizationsChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectWithContract'),
        filter: (list) => list.filter((i) => i.contract_status === ContractStatus.CONCLUDED),
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];
  }

  componentDidMount() {
    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    this.service.getTerminalValues().then((response) => {
      this.setState({ terminals: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
    this.service.getDealerValues().then((response) => {
      this.setState({ dealers: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  validate = (values) => {
    const errors = {};
    this.requiredFields.forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    });
    return errors;
  };

  onChange = (data) => {
    const { selectedReportType } = this.state;
    if (data.reportType && selectedReportType !== data.reportType) {
      this.setState({ selectedReportType: data.reportType });
    }
  }

  onSubmit = (data) => {
    const { paramsCallback, dataCallback, showNotification } = this.props;
    paramsCallback(data);
    this.setState({ submitProgress: true });
    const {
      dateTimeFrom, dateTimeTo, period, organizations, terminals, dealers, reportType,
    } = data;
    let promise;
    if (reportType === ReportType.REPORT_TYPE_ORGS) {
      promise = this.service.getReceivedCheckupsReportByOrganisations(dateTimeFrom, dateTimeTo, period, organizations);
    } else if (reportType === ReportType.REPORT_TYPE_TERMS) {
      promise = this.service.getReceivedCheckupsReportByTerminals(dateTimeFrom, dateTimeTo, period, terminals);
    } else if (reportType === ReportType.REPORT_TYPE_DEALERS) {
      promise = this.service.getReceivedCheckupsReportByDealers(dateTimeFrom, dateTimeTo, period, dealers);
    } else {
      throw new Error('Invalid usage report type');
    }
    promise.then((response) => {
      this.setState({ submitProgress: false });
      if (!response.json.is_empty) {
        dataCallback(response.json);
      } else {
        showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({ submitProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  };

  render() {
    const {
      organizations, terminals, dealers, submitProgress, selectedReportType,
    } = this.state;
    return (
      <Paper>
        <Form
          initialValues={this.fornInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateTimeInput
              className={styles.formControl}
              source="dateTimeFrom"
              label="telemedic.receivedCheckups.form.dateTimeFrom"
              required
            />
            <DateTimeInput
              className={styles.formControl}
              source="dateTimeTo"
              label="telemedic.receivedCheckups.form.dateTimeTo"
              required
            />
            <SelectInput
              className={styles.formControl}
              source="period"
              label="telemedic.receivedCheckups.form.period"
              choices={this.periodChoices}
              required
            />
            <RadioButtonGroupInput
              className={styles.formControl}
              source="reportType"
              label="telemedic.usage.form.reportType"
              choices={this.reportTypeChoices}
            />
            <div className={selectedReportType === ReportType.REPORT_TYPE_ORGS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="organizations"
                label="telemedic.usage.form.organizations"
                choices={organizations}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.organizationsChoiceMenu}
                fixedWidth
              />
            </div>
            <div className={selectedReportType === ReportType.REPORT_TYPE_TERMS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="terminals"
                label="telemedic.usage.form.terminals"
                choices={terminals}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.defaultChoiceMenu}
                fixedWidth
              />
            </div>
            <div className={selectedReportType === ReportType.REPORT_TYPE_DEALERS ? null : styles.hidden}>
              <LargeCheckListInput
                className={styles.formControl}
                source="dealers"
                label="telemedic.usage.form.dealers"
                choices={dealers}
                choiceLabelField="name"
                choiceValueField="id"
                valueType={ValueTypes.INTEGER}
                choiceMenu={this.defaultChoiceMenu}
                fixedWidth
              />
            </div>
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(ReceivedCheckupsForm));
