import React from 'react';
import {
  SimpleShowLayout, TextField,
} from 'react-admin';

import TestResultTitle from './TestResultTitle';
import FieldLink from '../../components/FieldLink';
import RestrictedShow from '../../components/admin/RestrictedShow';
import FormatValueField from '../../components/fields/FormatValueField';
import { formatDisplayDate, formatPersonName } from '../../utils/formatters';
import { RoutePath, getUrl } from '../../routes';

const getTestQuestionCategoryUrl = (record) => (
  record ? getUrl(RoutePath.USERS, { id: record.id }) : ''
);

const TestResultShow = (props) => (
  <RestrictedShow title={<TestResultTitle />} {...props}>
    <SimpleShowLayout>
      <FieldLink
        getUrl={getTestQuestionCategoryUrl}
        label="telemedic.testResults.show.employee"
      >
        <FormatValueField
          source="user"
          formatter={formatPersonName}
        />
      </FieldLink>
      <TextField
        label="telemedic.testResults.show.organization"
        source="user.organization.name"
      />
      <TextField
        label="telemedic.testResults.show.numberOfQuestions"
        source="number_of_questions"
      />
      <TextField
        label="telemedic.testResults.show.numberOfCorrectAnswers"
        source="number_of_correct_answers"
      />
      <FormatValueField
        label="telemedic.testResults.show.createdAt"
        source="created_at"
        formatter={formatDisplayDate}
      />
    </SimpleShowLayout>
  </RestrictedShow>
);

export default TestResultShow;
