import React, { useCallback } from 'react';
import { usePermissions, useTranslate, useNotify } from 'react-admin';

import OrganizationTitle from './OrganizationTitle';
import Button from '@material-ui/core/Button';
import styles from './OrganizationShow.module.css';

import { createTelemedicService } from '../../services/TelemedicService';


const OrganizationTitleWithControls = ({ record }) => {
  const notify = useNotify();
  const regOrgInKaluga = useCallback(async () => {
    const org_id = record.id;
    try {
        const telemedicService = createTelemedicService();
        await telemedicService.regOrgInKaluga(org_id);
        notify('Регистрация успешна!', 'success');
    } catch (error) {
      notify('Ошибка при регистрации: ' + error.errorDescription, 'error');
    }
  }, [notify, record]);
  const translate = useTranslate();
  const { loaded } = usePermissions();
  const alreadyRegister = record.orgastral;
  const epdEnabled = record.epl_gis_epd_api;
  const showReviewButtons = loaded
    && epdEnabled === true && alreadyRegister === false;
    return (
    <>
      <OrganizationTitle record={record} />
      {showReviewButtons && (
        <>
          <Button
            className={styles.regAstralButton}
            variant="contained"
            color="contained"
            onClick={regOrgInKaluga}
          >
            {translate('telemedic.actions.regOrgKaluga')}
          </Button>
        </>
      )}
    </>
  );
};

export default OrganizationTitleWithControls;
