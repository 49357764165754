import React, {useEffect, useState} from 'react';
import {Title, useTranslate} from 'react-admin';
import {YMaps, Map, Placemark} from 'react-yandex-maps';

import TelemedicService from '../../services/TelemedicService'
import styles from './TerminalsMap.module.css';
import {getAuthToken} from '../../storages/auth';

const TerminalsMap = (props) => {
  const translate = useTranslate();
  const [terminalsRequested, setTerminalsRequested] = useState(false);
  const [terminals, setTerminals] = useState([]);
  useEffect(() => {
    if (!terminalsRequested) {
      setTerminalsRequested(true);
      const service = new TelemedicService(getAuthToken());
      service.getTerminalCoordinates().then((response) => {
        setTerminals(response.json.data);
      }).catch((e) => {
        console.log(e);
      });
    }
  }, [terminalsRequested, setTerminalsRequested, setTerminals]);
  return (
    <div>
      <Title title="telemedic.terminalsMap.label"/>
      <YMaps>
        <Map
          className={styles.map}
          defaultState={{
            center: [55.7519, 37.6213],
            zoom: 10,
            controls: ['zoomControl', 'fullscreenControl', 'searchControl']
          }}
          modules={['control.ZoomControl', 'control.FullscreenControl', 'control.SearchControl']}
        >
          {terminals.map(({id, name, latitude, longitude}, index) => (
            <Placemark
              modules={['geoObject.addon.balloon']}
              defaultGeometry={[latitude, longitude]}
              properties={{
                balloonContentBody: `
                  ${translate('telemedic.terminalsMap.terminal')}: <b>${name}</b><br>
                  ${translate('telemedic.terminalsMap.terminalId')}: ${id}<br>
                  <sup>${translate('telemedic.terminalsMap.coordinates')}: ${latitude}, ${longitude}</sup>
                `,
              }}/>
          ))}
        </Map>
      </YMaps>
    </div>
  );
}

export default TerminalsMap;
