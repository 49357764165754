import React from 'react';
import {SimpleShowLayout, BooleanField, TextField} from 'react-admin';

import FormatField from '../../components/fields/FormatField';
import FormatValueField from '../../components/fields/FormatValueField';
import PeriodicMailingTitle from './PeriodicMailingTitle';
import RestrictedShow from '../../components/admin/RestrictedShow';
import {Role} from '../../constants';
import {getRole} from '../../storages/auth';
import {
  formatDisplayDateTime, formatMailingPeriod, formatPersonName
} from '../../utils/formatters';

export const PeriodicMailingShow = (props) => {
  const periodFormatter = ({period, period_unit}) => {
    return formatMailingPeriod(period, period_unit);
  };
  const showWorkerColumn = [Role.ADMIN, Role.MANAGER].indexOf(getRole()) >= 0;
  return (
    <RestrictedShow title={<PeriodicMailingTitle/>} {...props}>
      <SimpleShowLayout>
        <BooleanField label="telemedic.periodicMailings.show.active" source="active"/>
        <TextField label="telemedic.periodicMailings.show.email" source="email"/>
        <FormatField
          formatter={periodFormatter}
          label="telemedic.periodicMailings.show.period"
        />
        <TextField label="telemedic.periodicMailings.show.comment" source="comment"/>
        <FormatValueField
          formatter={formatDisplayDateTime}
          label="telemedic.periodicMailings.show.last_mailing"
          source="last_mailing"
        />
        {showWorkerColumn &&
          <FormatValueField
            label="telemedic.periodicMailings.show.worker"
            formatter={formatPersonName}
            source="worker"
            sortable={false}/>
        }
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
