import React from 'react';
import { useTranslate } from 'react-admin';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const EMPTY_VALUES = ['', null, 'None', undefined];
const BOOL_VALUES = ['True', 'False'];

const Changelog = ({
  changelog, fieldsLabels
}) => {
  const translate = useTranslate();
  const formatFieldValue = (fieldValue) => {
    if (EMPTY_VALUES.includes(fieldValue)) {
      return translate('telemedic.common.emptyValue');
    }
    if (BOOL_VALUES.includes(fieldValue)) {
      return fieldValue === 'True' ? '✓' : '✗';
    }
    return fieldValue;
  };
  changelog = changelog.filter((change) => change.changed_fields.filter((ch) => ch.field in fieldsLabels).length > 0)
  const formatChanges = (changes) => changes.changed_fields
    .filter((change) => change.field in fieldsLabels)
    .map(
      (change) => `${translate(fieldsLabels[change.field])} [${formatFieldValue(change.old_value)} => ${formatFieldValue(change.new_value)}]`
    ).join(', ');
  const formatDate = (date) => Intl.DateTimeFormat(
    'ru-RU',
    { dateStyle: 'full', timeStyle: 'short' },
  ).format(Date.parse(date));

  if (changelog instanceof Promise || (changelog instanceof Array && changelog.length === 0) || !changelog) {
    return (
      <div />
    );
  }
  return (
    <List>
      {translate('telemedic.common.changelog')}
      {
        changelog.map((changes, index) => (
          <ListItem disablePadding>
            <ListItemText
              primary={`${formatDate(changes.datetime)}: ${changes.worker_name} сделал изменения:`}
              secondary={formatChanges(changes)}
            />
          </ListItem>
        ))
      }
    </List>
  );
};

export default Changelog;
