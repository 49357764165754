import moment from 'moment';

import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Title, showNotification, useTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import Form from '../../components/Form';
import FormDiv from '../../components/FormDiv';
import ProgressButton from '../../components/ProgressButton';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import TZDateInput from '../../components/inputs/TZDateInput';
import ToolBar from '../../components/ToolBar';
import styles from './TerminalVerificationDisplay.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';

import { RoutePath, getUrl } from '../../routes';

const _SmsReportDisplay = ({ showNotification }) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [smsReport, setSmsReport] = useState([]);
  const [exportProgress, setExportProgress] = useState(false);
  const [submissionProgress, setSubmissionProgress] = useState(false);
  const [smsReportError, setSmsReportError] = useState(false);
  const [dateRange, setDateRange] = useState({ dateTimeFrom: null, dateTimeTo: null });
  const handleExport = useCallback(() => {
    setExportProgress(true);
    service.exportSmsReport(dateRange).then(() => {
      setExportProgress(false);
    }).catch((e) => {
      console.log(e);
      setExportProgress(false);
      showNotification('telemedic.errors.submitError');
    });
  }, [service, setExportProgress, showNotification, dateRange]);
  const submit = (formData) => {
    service.getSmsReport(formData).then((result) => {
      setSmsReport(result.json.data);
      setSmsReportError(false);
    }).catch((e) => {
      console.log(e);
      setSubmissionProgress(false);
      setSmsReportError(true);
    });
  };
  const initialValues = useMemo(() => {
    const monthAgo = moment().subtract(1, 'months');
    const dateTimeFrom = monthAgo.clone().startOf('month').toISOString();
    const dateTimeTo = monthAgo.clone().endOf('month').toISOString();
    submit({ dateTimeFrom, dateTimeTo });
    return { dateTimeFrom, dateTimeTo };
  }, []);
  const pageTitle = translate('telemedic.control.smsReport.label');
  return (
    <Card className={styles.card}>
      <Title title="telemedic.control.label" />
      <ToolBar
        title={pageTitle}
      >
        <Form
          initialValues={initialValues}
          onSubmit={submit}
          className={styles.form}
          onChange={(formData) => setDateRange(formData)}
        >
          <FormDiv className={styles.container}>
            <TZDateInput
              className={styles.formControl}
              label="telemedic.checkupJournals.form.periodFrom"
              required
              source="dateTimeFrom"

            />
            <TZDateInput
              className={styles.formControl}
              isPeriodEnd
              label="telemedic.checkupJournals.form.periodTo"
              required
              source="dateTimeTo"
            />
          </FormDiv>
          <ProgressButton
            color="primary"
            label="telemedic.actions.send"
            progress={submissionProgress}
            type="submit"
            variant="contained"
          />
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={handleExport}
            progress={exportProgress}
          />
        </Form>
      </ToolBar>
      <SmsReportTable data={smsReport} error={smsReportError} />
    </Card>
  );
};

const SmsTableRow = ({ item }) => (
  <TableRow>
    <TableCell component="th" scope="row">{item.organization_id}</TableCell>
    <TableCell>
      <Link href={getUrl(RoutePath.ORGANIZATION_SHOW, {id: item.id})}>
        {item.organization_name}
      </Link>
    </TableCell>
    <TableCell>
      <Link href={getUrl(RoutePath.DEALER_SHOW, {id: item.dealer_id})}>
        {item.dealer_name}
      </Link>
    </TableCell>
    <TableCell>{item.sms_sent}</TableCell>
  </TableRow>
);

const SmsReportTable = ({ data, error }) => {
  const translate = useTranslate();
  return (
    <TableContainer className={styles.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.control.smsReport.organizationName')}</TableCell>
            <TableCell>{translate('telemedic.control.smsReport.dealerName')}</TableCell>
            <TableCell>{translate('telemedic.control.smsReport.smsCount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={4}
            data={data}
            error={error}
            emptyTextId="telemedic.common.noData"
            errorTextId="telemedic.errors.fetchError"
            placeholderRows={5}
            Row={SmsTableRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SmsReportDisplay = connect(null, {showNotification})(_SmsReportDisplay);
