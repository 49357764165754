import React from 'react';
import {Create} from 'react-admin';

import TechInspectionForm from './TechInspectionForm';
import TechInspectionTitle from './TechInspectionTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const TechInspectionCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={TechInspectionForm}
    routePath={RoutePath.TECH_INSPECTION_SHOW}
    title={<TechInspectionTitle/>}
    {...props}/>
);
