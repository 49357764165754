import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import {
  ResponsiveContainer, LineChart, XAxis, YAxis, Line, Tooltip,
} from 'recharts';

import ProgressButton from '../../components/ProgressButton';
import TelemedicService from '../../services/TelemedicService';
import ToolBar from '../../components/ToolBar';

import styles from './ReceivedCheckupsDisplay.module.css';

import { getAuthToken } from '../../storages/auth';
import { ReportType } from '../../constants';
import { formatDisplayDateTime } from '../../utils/formatters';

class _ReceivedCheckupsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onExport = () => {
    this.setState({ exportProgress: true });
    const { params, showNotification } = this.props;
    const {
      dateTimeFrom, dateTimeTo, period, organizations, terminals, dealers, reportType,
    } = params;
    let promise;
    const exportParams = [dateTimeFrom, dateTimeTo, period];
    if (reportType === ReportType.REPORT_TYPE_ORGS) {
      promise = this.service.exportReceivedCheckupsReportByOrganizations(...exportParams, organizations);
    } else if (reportType === ReportType.REPORT_TYPE_TERMS) {
      promise = this.service.exportReceivedCheckupsReportByTerminals(...exportParams, terminals);
    } else if (reportType === ReportType.REPORT_TYPE_DEALERS) {
      promise = this.service.exportReceivedCheckupsReportByDealers(...exportParams, dealers);
    } else {
      throw new Error('Invalid usage stats type');
    }
    promise.then(
      () => {
        this.setState({ exportProgress: false });
      },
    ).catch(
      (error) => {
        this.setState({ exportProgress: false });
        showNotification('telemedic.errors.submitError');
      },
    );
  }

  renderChart(stats) {
    return (
      <div className={styles.chartWrapper}>
        <ResponsiveContainer>
          <LineChart data={stats}>
            <XAxis dataKey="datetime" tickFormatter={formatDisplayDateTime} />
            <YAxis domain={[0, 'dataMax']} />
            <Tooltip content={this.renderTooltip} />
            <Line type="monotone" dataKey="max" stroke="#f44336" dot={false} />
            <Line type="monotone" dataKey="avg" stroke="#ffc107" dot={false} />
            <Line type="monotone" dataKey="mean" stroke="#8bc34a" dot={false} />
            <Line type="monotone" dataKey="min" stroke="#3f51b5" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderTable(stats) {
    if (!stats.length) {
      return (<div />);
    }
    const { translate } = this.props;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('telemedic.receivedCheckups.display.columnTime')}</TableCell>
              <TableCell>{translate('telemedic.receivedCheckups.display.columnMin')}</TableCell>
              <TableCell>{translate('telemedic.receivedCheckups.display.columnMean')}</TableCell>
              <TableCell>{translate('telemedic.receivedCheckups.display.columnAvg')}</TableCell>
              <TableCell>{translate('telemedic.receivedCheckups.display.columnMax')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((timeStats) => this.renderTableRow(timeStats))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderTableRow(timeStats) {
    return (
      <TableRow key={timeStats.datetime}>
        <TableCell>{formatDisplayDateTime(timeStats.datetime)}</TableCell>
        <TableCell>{timeStats.min}</TableCell>
        <TableCell>{timeStats.mean}</TableCell>
        <TableCell>{timeStats.avg.toFixed(1)}</TableCell>
        <TableCell>{timeStats.max}</TableCell>
      </TableRow>
    );
  }

  renderTooltip = ({ payload }) => {
    if (!payload || !payload.length) {
      return (<div />);
    }
    const payloadData = payload[0].payload;
    const datetime = formatDisplayDateTime(payloadData.datetime);
    const { translate } = this.props;
    return (
      <div className={styles.tooltip}>
        {datetime}
        <br />
        {`${translate('telemedic.receivedCheckups.display.tooltipMax')}: ${payloadData.max}`}
        <br />
        {`${translate('telemedic.receivedCheckups.display.tooltipAvg')}: ${payloadData.avg.toFixed(1)}`}
        <br />
        {`${translate('telemedic.receivedCheckups.display.tooltipMean')}: ${payloadData.mean}`}
        <br />
        {`${translate('telemedic.receivedCheckups.display.tooltipMin')}: ${payloadData.min}`}
        <br />
      </div>
    );
  }

  render() {
    const { translate, statisticsData, onBackClick } = this.props;
    const { exportProgress } = this.state;

    const pageTitle = translate('telemedic.receivedCheckups.label');

    const organizationTitle = statisticsData.organization ? statisticsData.organization.name : '';
    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        {
          !!organizationTitle && (
            <div className={styles.subtitle}>
              <Typography component="div" variant="body1">
                {organizationTitle}
              </Typography>
            </div>
          )
        }
        {this.renderChart(statisticsData.data)}
        {this.renderTable(statisticsData.data)}
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(_ReceivedCheckupsDisplay));
