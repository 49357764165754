import React from 'react';
import {Filter, Datagrid, TextField} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TZDateInput from '../../components/inputs/TZDateInput';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {formatDisplayDateTime, formatPersonName} from '../../utils/formatters';

export const OrgPaymentList = props => (
    <RestrictedList
      title="telemedic.orgPayments.label"
      filters={<OrgPaymentFilter/>}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <TextField
          label="telemedic.orgPayments.list.organization"
          source="organization.name"
          sortable={true}
          sortBy="organization"/>
        <FormatValueField
          label="telemedic.orgPayments.list.datetime"
          source="datetime"
          sortable={true}
          formatter={formatDisplayDateTime}/>
        <TextField
          label="telemedic.orgPayments.list.amount"
          source="amount"
          sortable={true}/>
        <TextField
          label="telemedic.orgPayments.list.login"
          source="worker.login"
          sortable={false}/>
        <FormatValueField
          label="telemedic.orgPayments.list.userName"
          source="worker"
          sortable={false}
          formatter={formatPersonName}/>
      </Datagrid>
    </RestrictedList>
);

const OrgPaymentFilter = (props) => {
  const service = useTelemedicService();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getOrganizationValues();
  }, null, []);
  const workers = useTelemedicServiceJsonValue(() => {
    return service.getWorkerValues();
  }, null, []);
  return (
    <Filter key="org_payment_filter" {...props}>
      <TZDateInput
        label="telemedic.orgPayments.list.dateFrom"
        source="datetime_from"
        alwaysOn minWidth resettable/>
      <TZDateInput
        label="telemedic.orgPayments.list.dateTo"
        source="datetime_to"
        alwaysOn isPeriodEnd minWidth resettable/>
      <AutocompleteInput
        label="telemedic.orgPayments.list.organization"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        alwaysOn resettable/>
      <AutocompleteInput
        label="telemedic.orgPayments.list.user"
        choices={workers}
        choiceLabelField="name"
        choiceValueField="id"
        source="worker_id"
        alwaysOn resettable/>
    </Filter>
  );
}
