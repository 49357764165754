import React, { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';

import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

import ProgressButton from '../../components/ProgressButton';
import useTelemedicService from '../../hooks/useTelemedicService';

const ToggleBanButton = (props) => {
  const { record } = props;
  if (record) {
    return record.banned
      ? <RevokeBanButton {...props} />
      : <BanButton {...props} />;
  }
  return null;
};

const BanButton = ({ onDone, record }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [banReason, setBanReason] = useState('');
  const [progress, setProgess] = useState(false);
  const [error, setError] = useState('');

  const telemedicService = useTelemedicService();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleBanReasonChange = useCallback((e) => {
    setBanReason(e.target.value);
  }, [setBanReason]);

  const handleConfirm = useCallback(() => {
    if (!banReason) {
      setError(translate('telemedic.validation.required'));
      return;
    }
    setError('');
    setProgess(true);
    telemedicService.banWorker([record.id], banReason).then(() => {
      setProgess(false);
      setOpen(false);
      if (onDone) onDone();
    }).catch((e) => {
      console.log(e);
      setProgess(false);
      setError(e.errorDescription || translate('telemedic.errors.submitError'));
    });
  }, [banReason, onDone, record, setOpen, setProgess, telemedicService, translate]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div>
      <Button onClick={handleClickOpen} size="small" startIcon={<BlockIcon />}>
        {translate('telemedic.actions.ban')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="ban-dialog-title">
        <DialogTitle id="ban-dialog-title">
          {translate('telemedic.workers.banDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('telemedic.workers.banDialog.prompt')}
          </DialogContentText>
          <TextField
            error={!!error}
            helperText={error}
            id="name"
            margin="dense"
            onChange={handleBanReasonChange}
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <ProgressButton
            color="primary"
            label="telemedic.actions.confirm"
            onClick={handleConfirm}
            progress={progress}
            variant="contained"
          />
          <Button onClick={handleClose} color="primary">
            {translate('telemedic.actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RevokeBanButton = ({ onDone, record }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [progress, setProgess] = useState(false);
  const [error, setError] = useState('');

  const telemedicService = useTelemedicService();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleConfirm = useCallback(() => {
    setError('');
    setProgess(true);
    telemedicService.revokeWorkerBan([record.id]).then(() => {
      setProgess(false);
      setOpen(false);
      if (onDone) onDone();
    }).catch((e) => {
      console.log(e);
      setProgess(false);
      setError(e.errorDescription || translate('telemedic.errors.submitError'));
    });
  }, [onDone, record, setOpen, setProgess, telemedicService, translate]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div>
      <Button onClick={handleClickOpen} size="small" startIcon={<BlockIcon />}>
        {translate('telemedic.actions.revokeBan')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="revoke-ban-dialog-title">
        <DialogTitle id="revoke-ban-dialog-title">
          {translate('telemedic.workers.revokeBanDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('telemedic.workers.revokeBanDialog.prompt')}
          </DialogContentText>
          <FormHelperText error>{error}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <ProgressButton
            color="primary"
            label="telemedic.actions.confirm"
            onClick={handleConfirm}
            progress={progress}
            variant="contained"
          />
          <Button onClick={handleClose} color="primary">
            {translate('telemedic.actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToggleBanButton;
