import React from 'react';
import {NumberInput, required} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {parseQueryParams} from '../../utils/urls';

const OrgPaymentForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getOrgPaymentFormOrganizationValues();
  }, null, []);
  const queryParams = parseQueryParams(window.location.href.split('?')[1]);
  const initialValues = {
    organization_id: queryParams.organizationId
  };
  return(
    <RestrictedSimpleForm initialValues={initialValues} {...rest}>
      <AutocompleteInput
        label="telemedic.orgPayments.form.organization"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        {...getInputProps('organization_id')}/>
      <NumberInput
        label="telemedic.orgPayments.form.amount"
        source="amount"
        validate={required()}
        {...getInputProps('amount')}/>
    </RestrictedSimpleForm>
  );
}

export default OrgPaymentForm;
