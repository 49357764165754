import {useEffect, useState} from 'react';

import {createTelemedicService} from '../services/TelemedicService';

const useTelemedicService = () => {
  const [service] = useState(createTelemedicService());
  useEffect(() => {
    return () => {
      service.abort();
    }
  }, [service]);
  return service;
}

export default useTelemedicService;
