import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { RadioButtonGroupInput, Title, useTranslate } from 'react-admin';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import TZDateInput from '../../components/inputs/TZDateInput';
import styles from './WaybillsMovementPage.module.css';
import useAsyncValue from '../../hooks/useAsyncValue';
import useTelemedicService from '../../hooks/useTelemedicService';

const XLSX = 'xlsx';
const PDF = 'pdf';
const JOURNAL_FORMATS = [
  { id: XLSX, name: 'telemedic.waybillsMovement.form.getXLSX' },
  { id: PDF, name: 'telemedic.waybillsMovement.form.getPDF' },
];
const REQUIRED_FIELDS = ['periodFrom', 'periodTo', 'organizationId', 'journalFormat'];

const WaybillsMovementPage = () => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [organizations] = useAsyncValue(
    () => service.getWaybillsMovementFormOrganizationsData(), null, [],
  );
  const initialValues = useMemo(() => {
    const monthAgo = moment().subtract(1, 'months');
    const periodFrom = monthAgo.clone().startOf('month');
    const periodTo = monthAgo.clone().endOf('month');
    const journalFormat = XLSX;
    return { periodFrom, periodTo, journalFormat };
  }, []);
  const validate = useCallback((formData) => {
    const errors = {};
    REQUIRED_FIELDS.forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = translate('telemedic.validation.required');
      }
    });
    return errors;
  }, [translate]);
  const [submissionProgress, setSubmissionProgress] = useState(false);
  const submit = useCallback((formData) => {
    let journalPromise;
    if (formData.journalFormat === XLSX) {
      journalPromise = service.getWaybillsMovementJournal(formData);
    } else if (formData.journalFormat === PDF) {
      journalPromise = service.getWaybillsMovementJournalPdf(formData);
    }
    setSubmissionProgress(true);
    journalPromise.then(
      () => setSubmissionProgress(false),
    ).catch(
      (e) => {
        console.log(e);
        setSubmissionProgress(false);
      },
    );
  }, [service]);
  return (
    <div>
      <Title title="telemedic.waybillsMovement.label" />
      <Paper>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          <FormContent>
            <TZDateInput
              className={styles.formControl}
              label="telemedic.waybillsMovement.form.periodFrom"
              required
              source="periodFrom"
            />
            <TZDateInput
              className={styles.formControl}
              isPeriodEnd
              label="telemedic.waybillsMovement.form.periodTo"
              required
              source="periodTo"
            />
            <AutocompleteInput
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              className={styles.formControl}
              label="telemedic.waybillsMovement.form.organization"
              required
              source="organizationId"
            />
            <RadioButtonGroupInput
              className={styles.formControl}
              source="journalFormat"
              label="telemedic.waybillsMovement.form.journalFormat"
              required
              choices={JOURNAL_FORMATS}
            />
          </FormContent>
          <FormFooter>
            <ProgressButton
              color="primary"
              label="telemedic.actions.send"
              progress={submissionProgress}
              type="submit"
              variant="contained"
            />
          </FormFooter>
        </Form>
      </Paper>
    </div>
  );
};

export default WaybillsMovementPage;
