import React from 'react';
import {
  DeleteButton, SaveButton, SimpleForm, Toolbar, usePermissions
} from 'react-admin';

import Spacer from '../Spacer';
import {Action} from '../../permissions';

const RestrictedSimpleForm = ({toolbar, hideButtons, ...rest}) => {
// sourcery skip: simplify-ternary
  const formWithToolbar = <SimpleForm toolbar={toolbar ? toolbar : <RestrictedToolbar/>} {...rest}/>
  const formWithoutToolbar = <SimpleForm {...rest} toolbar={null}/>

  return (hideButtons ? formWithoutToolbar : formWithToolbar);
}

const RestrictedToolbar = (props) => {
  const {loaded, permissions} = usePermissions();
  const section = props.resource ? props.resource.toUpperCase() : '';
  const canDelete = loaded && permissions.check(section, Action.DELETE);
  return (
    <Toolbar {...props} >
      <SaveButton/>
      <Spacer fill/>
      {canDelete &&
        <DeleteButton mutationMode="pessimistic"/>
      }
    </Toolbar>
  );
}

export default RestrictedSimpleForm;
