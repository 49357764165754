import React from 'react';
import { connect } from 'react-redux';
import { CheckboxGroupInput, showNotification, withTranslate } from 'react-admin';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import DateInput from '../../components/inputs/DateInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import ProgressButton from '../../components/ProgressButton';
import styles from './UsageForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import { CheckupType, ContractStatus } from '../../constants';
import { formatApiDate, formatInspectionType } from '../../utils/formatters';
import { ValueTypes } from '../../utils/values';

class UsageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      submitProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());

    const { params, translate } = this.props;
    this.techInspectionTypeChoices = Object.values(CheckupType).map((techInspectionType) => (
      { id: techInspectionType, name: formatInspectionType(techInspectionType).toLowerCase() }
    ));

    const previousMonth = moment().subtract(1, 'months');
    this.formInitialValues = (params) || {
      dateFrom: formatApiDate(previousMonth.startOf('month')),
      dateTo: formatApiDate(previousMonth.endOf('month')),
      organizations: [],
      terminals: [],
      dealers: [],
    };

    this.requiredFields = ['dateFrom', 'dateTo'];

    this.defaultChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];

    this.organizationsChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectWithContract'),
        filter: (list) => list.filter((i) => i['contract_status'] === ContractStatus.CONCLUDED),
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];
  }

  componentDidMount() {
    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.service.abort();
  }

  validate = (values) => {
    return this.requiredFields.reduce((errors, fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
      return errors;
    }, {});
  }

  onSubmit = (data) => {
    const { paramsCallback, showNotification, dataCallback } = this.props;
    paramsCallback(data);
    this.setState({ submitProgress: true });
    const {
      organizations, dateFrom, dateTo, inspectionTypes,
    } = data;
    this.service.getUsageTechByOrganizations(organizations, dateFrom, dateTo, inspectionTypes)
      .then((response) => {
        this.setState({ submitProgress: false });
        if (!response.json.is_empty) {
          dataCallback(response.json);
        } else {
          showNotification('telemedic.errors.noData');
        }
      }).catch((e) => {
        console.log(e);
        this.setState({ submitProgress: false });
        showNotification('telemedic.errors.submitError');
      });
  }

  render() {
    const { organizations, submitProgress } = this.state;

    return (
      <Paper>
        <Form
          initialValues={this.formInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateInput
              className={styles.formControl}
              source="dateFrom"
              label="telemedic.usage.form.dateFrom"
            />
            <DateInput
              className={styles.formControl}
              source="dateTo"
              label="telemedic.usage.form.dateTo"
            />
            <CheckboxGroupInput
              className={styles.formControl}
              source="techInspectionTypes"
              label="telemedic.usage.form.checkupTypes"
              choices={this.techInspectionTypeChoices}
              row={false}
            />
            <LargeCheckListInput
              className={styles.formControl}
              source="organizations"
              label="telemedic.usage.form.organizations"
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              valueType={ValueTypes.INTEGER}
              choiceMenu={this.organizationsChoiceMenu}
              fixedWidth
            />
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(UsageForm));
