import React, {useCallback, useState} from 'react';
import {Show, SimpleShowLayout, TextField, TopToolbar, useTranslate} from 'react-admin';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DealerBalanceTitle from './DealerBalanceTitle';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {Section, Action} from '../../permissions';
import {RoutePath, navigateTo} from '../../routes';
import {formatDisplayDateTime, formatPersonName} from '../../utils/formatters';

export const DealerBalanceShow = (props) => {
  const permissions = props.permissions;
  const showPayments = permissions && permissions.check(Section.DEALER_PAYMENTS, Action.READ);
  return (
    <Show
      actions={<BalanceActions permissions={permissions}/>}
      title={<DealerBalanceTitle/>}
      {...props}
    >
      <SimpleShowLayout>
        <TextField label="telemedic.dealerBalance.show.name" source="name"/>
        <TextField label="telemedic.dealerBalance.show.balance" source="balance"/>
        {showPayments && <RecentPayments/>}
      </SimpleShowLayout>
    </Show>
  );
}

const BalanceActions = ({basePath, data, permissions, resource}) => {
  const translate = useTranslate();
  const handleTopUpClick = useCallback(() => {
    navigateTo(RoutePath.DEALER_PAYMENT_CREATE, null, {
      dealerId: data.id
    });
  }, [data]);
  const canTopUpBalance = permissions && permissions.check(Section.DEALER_PAYMENTS, Action.CREATE);
  if (!canTopUpBalance) {
    return null;
  }
  return (
    <TopToolbar>
      <Button color="primary" onClick={handleTopUpClick} variant="contained">
        {translate('telemedic.actions.topUpBalance')}
      </Button>
    </TopToolbar>
  );
}

const RecentPayments = ({record}) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [error, setError] = useState(null);
  const dealerId = record ? record.id : undefined;
  const payments = useTelemedicServiceJsonValue((dealerId) => {
    return service.getDealerPayments({dealerId: dealerId, limit: 10, sort: '-id'});
  }, dealerId, null, (error) => {
    console.log(error);
    const errorDescription = error.errorDescription
      ? error.errorDescription
      : translate('telemedic.errors.fetchError')
    setError(errorDescription);
  });
  return (
    <React.Fragment>
      <Typography variant="h6">
        {translate('telemedic.dealerBalance.show.recentPayments')}
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>
                {translate('telemedic.dealerPayments.list.dealer')}
              </TableCell>
              <TableCell>
                {translate('telemedic.dealerPayments.list.datetime')}
              </TableCell>
              <TableCell>
                {translate('telemedic.dealerPayments.list.amount')}
              </TableCell>
              <TableCell>
                {translate('telemedic.dealerPayments.list.login')}
              </TableCell>
              <TableCell>
                {translate('telemedic.dealerPayments.list.userName')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableArrayAdapter
              columns={6}
              data={payments}
              error={!!error}
              emptyTextId="telemedic.dealerBalance.show.noPayments"
              errorText={error}
              placeholderRows={5}
              Row={RecentPaymentsTableRow}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

const RecentPaymentsTableRow = ({item}) => {
  const attributes = item.attributes;
  const handleClick = useCallback(() => {
    navigateTo(RoutePath.DEALER_PAYMENT_SHOW, {id: item.id});
  }, [item.id]);
  return (
    <TableRow onClick={handleClick} clickable hover>
      <TableCell>{item.id}</TableCell>
      <TableCell>{attributes.dealer.name}</TableCell>
      <TableCell>{formatDisplayDateTime(attributes.datetime)}</TableCell>
      <TableCell>{attributes.amount}</TableCell>
      <TableCell>{attributes.worker.login}</TableCell>
      <TableCell>{formatPersonName(attributes.worker)}</TableCell>
    </TableRow>
  );
}
