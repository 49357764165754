import React from 'react';
import {TextInput, required} from 'react-admin';

import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {ValueTypes} from '../../utils/values';

const InspectionPointForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getTechInspectionFormOrganizationValues();
  }, null, []);
  return(
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.inspectionPoints.form.name"
        source="name"
        validate={required()}
        {...getInputProps('name')}/>
      <LargeCheckListInput
        label="telemedic.inspectionPoints.form.organizations"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('organization_ids')}/>
    </RestrictedSimpleForm>
  );
}

export default InspectionPointForm;
