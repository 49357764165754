import React from 'react';

import styles from './FilterFormContent.module.css';

const FilterFormContent = ({children}) => {
  return (
    <div className={styles.formContent}>
      {children}
    </div>
  );
}

export default FilterFormContent;
