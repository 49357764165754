import React from 'react';
import {
  TextInput, required,
} from 'react-admin';

import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';

const TestQuestionCategoryForm = ({ getInputProps, ...rest }) => (
  <RestrictedSimpleForm {...rest}>
    <TextInput
      label="telemedic.testQuestionCategory.form.category"
      source="category"
      validate={required()}
      {...getInputProps('category')}
    />
  </RestrictedSimpleForm>
);

export default TestQuestionCategoryForm;
