import React, {useState} from 'react';
import {Title} from 'react-admin';

import UsageForm from './UsageForm';
import UsageDisplay from './UsageDisplay';

const UsageCheckupPage = () => {
  const [formParams, setFormParams] = useState(null);
  const [usageData, setUsageData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  }

  const dataCallback = (usageData) => {
    setUsageData(usageData);
  }

  const onBackClick = () => {
    setUsageData(null);
  }

  return (
    <div>
      <Title title="telemedic.usage.label"/>
      {!usageData && <UsageForm
        params={formParams}
        paramsCallback={paramsCallback}
        dataCallback={dataCallback}/>}
      {!!usageData && <UsageDisplay
        params={formParams}
        usageData={usageData}
        onBackClick={onBackClick}/>}
    </div>
  );
}

export default UsageCheckupPage;
