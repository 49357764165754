import React, {useState} from 'react';
import {Title} from 'react-admin';

import MedicEfficiencyForm from './MedicEfficiencyForm';
import MedicEfficiencyDisplay from './MedicEfficiencyDisplay';

const MedicEfficiencyPage = (props) => {
  const [formParams, setFormParams] = useState(null);
  const [medicEfficiencyData, setMedicEfficiencyData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  }

  const dataCallback = (medicEfficiencyData) => {
    setMedicEfficiencyData(medicEfficiencyData);
  }

  const onBackClick = () => {
    setMedicEfficiencyData(null);
  }

  return (
    <div>
      <Title title="telemedic.medicEfficiency.label"/>
      {!medicEfficiencyData && <MedicEfficiencyForm
        params={formParams}
        paramsCallback={paramsCallback}
        dataCallback={dataCallback}/>}
      {!!medicEfficiencyData && <MedicEfficiencyDisplay
        params={formParams}
        medicEfficiencyData={medicEfficiencyData}
        onBackClick={onBackClick}
        {...props}/>}
    </div>
  );
}

export default MedicEfficiencyPage;
