import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import ToolBar from '../../components/ToolBar';

import TelemedicService from '../../services/TelemedicService'
import {getAuthToken} from '../../storages/auth';

import styles from './MedicEfficiencyDisplay.module.css';
import {formatDisplayMoscowDateTime} from '../../utils/formatters';
import {replace} from '../../utils/text';

class _MedicEfficiencyDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      exportProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {params, medicEfficiencyData, onBackClick, translate} = this.props;
    const exportProgress = this.state.exportProgress;

    const pageTitle = translate('telemedic.medicEfficiency.label');

    const period = replace(translate('telemedic.medicEfficiency.display.period'), {
      '{dateFrom}': params.dateFrom,
      '{dateTo}': params.dateTo
    });

    const dealerName = medicEfficiencyData.dealer ? medicEfficiencyData.dealer.name : null;

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}>
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon/>}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <div className={styles.userInfo}>
          <Typography component="div" variant="body1">{period}</Typography>
          {!!dealerName &&
            <Typography component="div" variant="body1">
              {translate('telemedic.medicEfficiency.display.dealer')}: {dealerName}
            </Typography>
          }
        </div>
        {this.renderEfficiencyTable(medicEfficiencyData.checkups)}
      </Card>
    );
  }

  renderEfficiencyTable(checkups) {
    if (!checkups.length) {
      return (<div></div>);
    }
    const translate = this.props.translate;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.dealer')}</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.medic')}</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.timeReceived')}</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.decisionTime')}</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.3minutes')}</TableCell>
              <TableCell>{translate('telemedic.medicEfficiency.display.5minutes')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkups.map((checkup, index) => (
              <TableRow key={index}>
                <TableCell>{checkup.id}</TableCell>
                <TableCell>{checkup.dealer_name}</TableCell>
                <TableCell>{checkup.medic_name}</TableCell>
                <TableCell>{formatDisplayMoscowDateTime(checkup.created_at)}</TableCell>
                <TableCell>{formatDisplayMoscowDateTime(checkup.result_time)}</TableCell>
                <TableCell>
                  {checkup.decision_time > 180 && checkup.decision_time <= 300 ? '+' : ''}
                </TableCell>
                <TableCell>
                  {checkup.decision_time > 300 ? '+' : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  onExport = () => {
    this.setState({exportProgress: true});
    const {dateFrom, dateTo, dealerId} = this.props.params;
    let promise = this.service.exportMedicEfficiencyReport(dateFrom, dateTo, dealerId);
    promise.then(
      () => {
        this.setState({exportProgress: false});
      }
    ).catch(
      error => {
        this.setState({exportProgress: false});
        this.props.showNotification('telemedic.errors.submitError');
      }
    );
  }
}

export default connect(null, {showNotification})(withTranslate(_MedicEfficiencyDisplay));
