import React, {useState} from 'react';
import {Title} from 'react-admin';

import MedicWorkloadForm from './MedicWorkloadForm';
import MedicWorkloadDisplay from './MedicWorkloadDisplay';

const MedicWorkloadPage = (props) => {
  const [formParams, setFormParams] = useState(null);
  const [medicWorkloadData, setMedicWorkloadData] = useState(null);

  const paramsCallback = (formParams) => {
    setFormParams(formParams);
  }

  const dataCallback = (medicWorkloadData) => {
    setMedicWorkloadData(medicWorkloadData);
  }

  const onBackClick = () => {
    setMedicWorkloadData(null);
  }

  const medicId = props.match.params.medicId;

  return (
    <div>
      <Title title="telemedic.medicWorkload.label"/>
      {!medicWorkloadData && <MedicWorkloadForm
        medicId={medicId}
        params={formParams}
        paramsCallback={paramsCallback}
        dataCallback={dataCallback}/>}
      {!!medicWorkloadData && <MedicWorkloadDisplay
        params={formParams}
        medicWorkloadData={medicWorkloadData}
        onBackClick={onBackClick}
        {...props}/>}
    </div>
  );
}

export default MedicWorkloadPage;
