import React, { useCallback, useMemo, useState } from 'react';
import { TextInput, usePermissions, useTranslate } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import Form from '../../components/Form';
import Info from '../../components/Info';
import PromiseIconButton from '../../components/PromiseIconButton';
import Spacer from '../../components/Spacer';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import TableRow from '../../components/tables/TableRow';
import useAsyncValue from '../../hooks/useAsyncValue';
import styles from './KisArtWorkerOrgsDialog.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import { Action, Section } from '../../permissions';

const KisArtOrganizationRow = ({
  canDelete, canUpdate, item, onUpdate, service
}) => {
  const { id, attributes } = item;
  const kisArtId = attributes['kis_art_id'];
  const organizationId = attributes['organization_id'];
  const workerId = attributes['worker_id'];
  const [newKisArtId, setNewKisArtId] = useState(kisArtId);
  const handleChange = useCallback((e) => {
    setNewKisArtId(e.target.value);
  }, []);
  const [isEditing, setEditing] = useState(false);
  const handleEdit = useCallback(() => {
    setEditing(true);
  }, []);
  const handleCancel = useCallback(() => {
    setEditing(false);
    setNewKisArtId(kisArtId);
  }, [kisArtId]);
  const saveButtonProps = useMemo(() => ({
    icon: <CheckIcon />,
    promiseFactory: () => service.updateKisArtWorker(id, {
      organizationId,
      workerId,
      kisArtId: newKisArtId,
    }),
    onSuccess: () => {
      setEditing(false);
      onUpdate();
    },
    onError: (e) => {
      console.log(e);
      setEditing(false);
      onUpdate();
    },
  }), [id, newKisArtId, onUpdate, organizationId, workerId, service]);
  const deleteButtonProps = useMemo(() => ({
    icon: <DeleteIcon />,
    promiseFactory: () => service.deleteKisArtWorker(id),
    onSuccess: () => onUpdate(),
    onError: (e) => console.log(e),
  }), [id, onUpdate, service]);
  return (
    <TableRow hover>
      <TableCell>
        {attributes.organization.name}
      </TableCell>
      <TableCell>
        <Input
          disabled={!isEditing}
          margin="dense"
          onChange={handleChange}
          value={newKisArtId}
        />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <PromiseIconButton size="small" {...saveButtonProps}/>
        ) : (
          <IconButton disabled={!canUpdate} onClick={handleEdit} size="small">
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <IconButton onClick={handleCancel} size="small">
            <ClearIcon />
          </IconButton>
        ) : (
          <PromiseIconButton
            disabled={!canDelete}
            size="small"
            {...deleteButtonProps}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

const NewKisArtIdForm = ({
  onUpdate, service, workerId
}) => {
  const translate = useTranslate();
  const [organizations] = useAsyncValue(
    () => service.getKisArtWorkerFormOrganizationsData(),
    null,
    [],
    (e) => console.log(e),
  );
  const [error, setError] = useState(false);
  const handleSubmit = useCallback((data, form) => {
    console.log(data);
    setError(false);
    const submissionData = {
      workerId,
      ...data,
    };
    service.createKisArtWorker(submissionData).then(() => {
      form.reset();
      onUpdate();
    }).catch((e) => {
      console.log(e);
      setError(true);
    });
  }, [onUpdate, service, workerId]);
  return (
    <>
      <Form className={styles.horizontalForm} onSubmit={handleSubmit}>
        <AutocompleteInput
          name="organizationId"
          label="telemedic.kisArtWorkerOrgsDialog.organization"
          choices={organizations}
          choiceLabelField="name"
          choiceValueField="id"
          dropdownAboveInput
          required
          suggestionRows={4}
        />
        <Spacer />
        <TextInput
          name="kisArtId"
          label="telemedic.kisArtWorkerOrgsDialog.kisArtId"
          required
        />
        <Spacer />
        <div className={styles.controlPadding}>
          <IconButton type="submit">
            <AddIcon />
          </IconButton>
        </div>
      </Form>
      {error && (
        <Info level="error">
          {translate('telemedic.kisArtWorkerOrgsDialog.organizationNotUnique')}
        </Info>
      )}
    </>
  );
};

const KisArtWorkerOrgsDialog = ({
  open, onClose, workerId
}) => {
  const translate = useTranslate();

  const { loaded, permissions } = usePermissions();
  const canCreate = loaded && permissions.check(Section.KIS_ART_WORKERS, Action.CREATE);
  const canUpdate = loaded && permissions.check(Section.KIS_ART_WORKERS, Action.UPDATE);
  const canDelete = loaded && permissions.check(Section.KIS_ART_WORKERS, Action.DELETE);

  const [kisArtWorkersError, setKisArtWorkersError] = useState();
  const service = useTelemedicService();
  const [kisArtWorkers, refreshKisArtWorkers] = useAsyncValue(
    (argWorkerId) => service.getKisArtWorkersData({ workerId: argWorkerId }),
    workerId,
    [],
    (e) => {
      console.log(e);
      setKisArtWorkersError(e.errorDescription || translate('telemedic.errors.fetchError'))
    },
  );
  const handleUpdate = useCallback(() => {
    refreshKisArtWorkers();
  }, [refreshKisArtWorkers]);
  const rowProps = useMemo(() => ({
    canDelete,
    canUpdate,
    onUpdate: () => refreshKisArtWorkers(),
    service,
  }), [canDelete, canUpdate, refreshKisArtWorkers, service]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {translate('telemedic.kisArtWorkerOrgsDialog.title')}
      </DialogTitle>
      <DialogContent>
        <TableContainer className={styles.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('telemedic.kisArtWorkerOrgsDialog.organization')}</TableCell>
                <TableCell>{translate('telemedic.kisArtWorkerOrgsDialog.kisArtId')}</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableArrayAdapter
                columns={4}
                data={kisArtWorkers}
                emptyTextId="telemedic.kisArtWorkerOrgsDialog.noKisArtIds"
                error={!!kisArtWorkersError}
                errorText={kisArtWorkersError}
                placeholderRows={1}
                Row={KisArtOrganizationRow}
                rowProps={rowProps}
              />
            </TableBody>
          </Table>
        </TableContainer>
        {canCreate && (
          <>
            <hr />
            <Typography variant="h6">
              {translate('telemedic.kisArtWorkerOrgsDialog.addKisArtId')}
            </Typography>
            <NewKisArtIdForm
              onUpdate={handleUpdate}
              service={service}
              workerId={workerId}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {translate('telemedic.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KisArtWorkerOrgsDialog;
