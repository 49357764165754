import React from 'react';
import {Create} from 'react-admin';

import DealerPaymentForm from './DealerPaymentForm';
import DealerPaymentTitle from './DealerPaymentTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const DealerPaymentCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={DealerPaymentForm}
    routePath={RoutePath.DEALER_PAYMENT_SHOW}
    title={<DealerPaymentTitle/>}
    {...props}/>
);
