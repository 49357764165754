import React, { useCallback, useMemo, useState } from 'react';
import {
  BooleanInput, Notification, TextInput, Title, withTranslate,
} from 'react-admin';
import { isEqual } from 'lodash';

import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';

import moment from 'moment';
import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import MultiAutocompleteInput from '../../components/inputs/MultiAutocompleteInput';
import FilterFormContent from '../../components/FilterFormContent';
import Form from '../../components/Form';
import IndexedList from '../../utils/IndexedList';
import ProgressIconButton from '../../components/ProgressIconButton';
import Spacer from '../../components/Spacer';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import TableRow from '../../components/tables/TableRow';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import withCheckupActions from './withCheckupActions';
import withSoundNotification from '../../hocs/withSoundNotification';
import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';

import { CheckupResult } from '../../constants';
import { RoutePath, navigateTo } from '../../routes';
import { Section, Action } from '../../permissions';
import {
  formatApiDateTime, formatCheckupResult, formatInspectionType, formatDisplayMoscowDateTime, formatDisplayDateTime
} from '../../utils/formatters';
import { parseQueryParams, serializeQueryParams } from '../../utils/urls';

import styles from './CheckupLivePage.module.css';
import sounds from '../../sounds';
import { WebsocketContext } from '../../providers/webSocketProvider';

const DEFAULT_FILTERS = {
  userName: '',
  organizationId: '',
  terminalNames: [],
  awaiting: false,
};
const PAGE_SIZE = 50;
const FIRST_PAGE = 1;
const CHECKUP_LOAD_SCROLL_MARGIN = 500;

const isAnyFilterSet = (filters) => Object.values(filters).some((value) => !!value);

class CheckupLivePage extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = parseQueryParams(window.location.href.split('?')[1]);
    this.urlFilters = queryParams && queryParams.filters ? JSON.parse(queryParams.filters) : DEFAULT_FILTERS;
    this.service = new TelemedicService(getAuthToken());

    this.state = {
      filters: this.urlFilters,
      indexedCheckups: new IndexedList([]),
      listError: null,
      updateError: null,
      startDate: formatApiDateTime(moment().subtract(1, 'days')),
      syncDate: null,
      checkups: [],
      currentPage: FIRST_PAGE,
      isLoadingNextPage: false,
      hasLoadedAllCheckups: false,
      observedCheckupsIds: this.getObservedCheckupIds(),
    };

    this.notificationSound = new Audio(sounds.notification);
    this.disconnectedSound = new Audio(sounds.disconnected);

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.bottomOfList = React.createRef();
  }

  getObservedCheckupIds() {
    const observedCheckupsIdsRaw = localStorage.getItem('observedCheckupIds') || '';
    return new Set(observedCheckupsIdsRaw.split(',').map(x => parseInt(x, 10)));
  }

  componentDidMount() {
    const { startDate } = this.state;

    window.addEventListener('scroll', this.onScroll);

    this.getPaginatedCheckups(startDate, FIRST_PAGE)
      .then(() => {
        const intervalCallback = () => {
          const { syncDate } = this.state;
          if (syncDate) { // Костыль, чтобы исключить дублирование, так как componentDidMount делается дважды.
            this.updateCheckups(startDate, syncDate);
          }
        };
        this.updateInterval = setInterval(intervalCallback, 10000);
      });

    window.addEventListener('observedCheckupsUpdate',(e) => {
      this.setState({ observedCheckupsIds: this.getObservedCheckupIds() });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      indexedCheckups, filters, hasLoadedAllCheckups, startDate, currentPage, isLoadingNextPage, observedCheckupsIds,
    } = this.state;
    let { checkups } = this.state;

    const { top, bottom } = this.bottomOfList.current.getBoundingClientRect();
    const verticalHeight = (window.innerHeight || document.documentElement.clientHeight);

    const isBottomOfListVisible = (top > 0 || bottom > 0) && top < verticalHeight;
    if (isBottomOfListVisible && !hasLoadedAllCheckups && !isLoadingNextPage) {
      this.getPaginatedCheckups(startDate, currentPage + 1);
    }
    const didCheckupsUpdate = !isEqual(prevState.indexedCheckups, indexedCheckups);
    if (didCheckupsUpdate) {
      checkups = indexedCheckups.getList().sort((c1, c2) => c1.id < c2.id);
      if (filters.awaiting) {
        checkups = checkups.filter((checkup) => checkup.attributes.result_medic === CheckupResult.AWAITING);
      }
      this.setState({ checkups });
    }
    if (!isEqual(prevState.observedCheckupsIds, observedCheckupsIds) || didCheckupsUpdate) {
      checkups = checkups.map((checkup) => {
        checkup.isObserved = observedCheckupsIds.has(checkup.id);
        return checkup;
      });
      this.setState({ checkups });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    clearInterval(this.updateInterval);
  }

  handleFiltersChange(newFilters) {
    const { filters, startDate } = this.state;
    const updatedFilters = isAnyFilterSet(newFilters) ? newFilters : DEFAULT_FILTERS;
    if (isEqual(updatedFilters, filters)) {
      return;
    }
    this.setState({ filters: updatedFilters });
    const url = window.location.href.split('?')[0];
    const query = serializeQueryParams({ filters: JSON.stringify(newFilters) });
    window.location.href = `${url}?${query}`;
    this.getPaginatedCheckups(startDate, FIRST_PAGE);
  }

  onScroll(event) {
    const {
      startDate, currentPage, isLoadingNextPage, hasLoadedAllCheckups,
    } = this.state;
    const { scrollingElement } = event.target;
    const scrollingLeft = scrollingElement.scrollHeight - scrollingElement.scrollTop - scrollingElement.clientHeight;
    if (scrollingLeft <= CHECKUP_LOAD_SCROLL_MARGIN && !isLoadingNextPage && !hasLoadedAllCheckups) {
      const nextPage = currentPage + 1;
      this.getPaginatedCheckups(startDate, nextPage);
    }
  }

  getPaginatedCheckups(dateFrom, page) {
    const { translate } = this.props;
    const { filters, indexedCheckups } = this.state;

    this.setState({ isLoadingNextPage: true, hasLoadedAllCheckups: false });

    return this.service.getCheckups({
      dateCreatedFrom: dateFrom,
      userName: filters.userName,
      organizationId: filters.organizationId,
      terminalNames: filters.terminalNames,
      status: filters.awaiting ? CheckupResult.AWAITING : null,
      sort: '-created_at',
      page,
      pageSize: PAGE_SIZE,
    }).then((data) => {
      if (page === FIRST_PAGE) {
        this.setState({
          indexedCheckups: new IndexedList(data.json.data),
        });
      } else {
        const [updatedCheckups] = indexedCheckups.getUpdated(data.json.data);
        this.setState({ indexedCheckups: updatedCheckups });
      }
      const hasLoadedAllCheckups = data.json.data.length < PAGE_SIZE;
      this.setState({
        syncDate: data.json.sync_dt,
        currentPage: page,
        hasLoadedAllCheckups,
        isLoadingNextPage: false,
      });
    }).catch((e) => {
      console.log(e);
      this.setState({ indexedCheckups: new IndexedList([]) });
      this.setState({ listError: e.errorDescription || translate('telemedic.errors.fetchError') });
    });
  }

  updateCheckups(dateFrom, syncDate) {
    const { playSound, translate } = this.props;
    const { filters, updateError, indexedCheckups } = this.state;
    this.service.getCheckupsUpdates({
      createdFrom: dateFrom,
      updatedFrom: syncDate,
      userName: filters.userName,
      organizationId: filters.organizationId,
      terminalNames: filters.terminalNames,
    }).then((data) => {
      const [updatedCheckups, newCheckups] = indexedCheckups.getUpdated(data.json.data);
      this.setState({
        indexedCheckups: updatedCheckups,
        updateError: null,
        syncDate: data.json.sync_dt,
      });
      if (newCheckups.some((checkup) => checkup.attributes.result_medic === CheckupResult.AWAITING)) {
        playSound(this.notificationSound);
      }
    }).catch((e) => {
      console.log(e);
      if (!updateError) {
        playSound(this.disconnectedSound);
        const error = translate('telemedic.checkupsLive.list.listUpdateError');
        const errorDescription = e.errorDescription || translate('telemedic.errors.fetchError');
        this.setState({ updateError: `${error}. ${errorDescription}` });
      }
    });
  }

  render() {
    const {
      filters, listError, updateError, checkups, isLoadingNextPage,
    } = this.state;
    const { checkupActions, permissions, translate } = this.props;
    const isFilterSet = (filters !== DEFAULT_FILTERS);
    const userHasReviewPermission = permissions.check(Section.CHECKUPS, Action.REVIEW);

    return (
      <div>
        <Title title="telemedic.checkupsLive.label" />
        <CheckupFilters
          filters={filters}
          initial={this.urlFilters}
          isFilterSet={isFilterSet}
          onChange={this.handleFiltersChange}
          onReset={(form) => form.reset(DEFAULT_FILTERS)}
          service={this.service}
          translate={translate}
        />
        <Paper>
          <CheckupTable
            checkupActions={checkupActions}
            checkups={checkups}
            error={listError}
            isFilterSet={isFilterSet}
            userHasReviewPermission={userHasReviewPermission}
            translate={translate}
          />
        </Paper>
        {
          isLoadingNextPage && <LinearProgress />
        }
        <div ref={this.bottomOfList} />
        <Notification
          message={updateError}
          open={!!updateError}
          type="error"
        />
      </div>
    );
  }
}

const getRowClassName = ({ attributes }) => {
  if (attributes.result_medic === CheckupResult.AWAITING) {
    return attributes.result_auto === CheckupResult.ADMITTED
      ? styles.autoResultAdmittedBg
      : styles.autoResultNotAdmittedBg;
  }
  return null;
};

const CheckupRow = ({
  item, checkupActions, translate, userHasReviewPermission,
}) => {
  const [nonAdmissionProgress, setNonAdmissionProgress] = useState(false);
  const [nonAdmissionError, setNonAdmissionError] = useState(null);
  const { id, attributes, isObserved } = item;
  const handleRowClick = useCallback(() => {
    navigateTo(RoutePath.CHECKUP_SHOW, { id });
  }, [id]);
  const handleNotAdmitClick = useCallback((event) => {
    event.stopPropagation();
    setNonAdmissionProgress(true);
    setNonAdmissionError(null);
    checkupActions.notAdmitUserAuto(id).then(() => {
      attributes.result_medic = CheckupResult.NOT_ADMITTED;
      setNonAdmissionProgress(false);
    }).catch((e) => {
      setNonAdmissionProgress(false);
      if (e.errorText) {
        setNonAdmissionError(e.errorText);
      } else {
        setNonAdmissionError(translate(e.errorTextId || 'telemedic.errors.unknownError'));
      }
    });
  }, [attributes, checkupActions, id, translate]);
  return (
    <TableRow
      className={getRowClassName(item)}
      clickable
      key={id}
      hover
      onClick={handleRowClick}
    >
      <TableCell>{id}</TableCell>
      <TableCell>
        {`${attributes.user.last_name} ${attributes.user.first_name} ${attributes.user.middle_name}`}
      </TableCell>
      <TableCell>{attributes.user.organization.name}</TableCell>
      <TableCell>{formatInspectionType(attributes.type)}</TableCell>
      <TableCell>{formatDisplayDateTime(attributes.datetime_start)}</TableCell>
      <TableCell>{formatDisplayDateTime(attributes.datetime_end)}</TableCell>
      <TableCell>{formatDisplayMoscowDateTime(attributes.created_at)}</TableCell>
      <TableCell>{formatCheckupResult(attributes.result_auto)}</TableCell>
      <TableCell>{formatCheckupResult(attributes.result_medic)}</TableCell>
      <TableCell>{isObserved && <VisibilityIcon />}</TableCell>
      <TableCell>
        {userHasReviewPermission
          && attributes.result_auto === CheckupResult.NOT_ADMITTED
          && attributes.result_medic === CheckupResult.AWAITING
          && (
            <div className={styles.rowControls}>
              <ProgressIconButton
                icon={<BlockIcon />}
                color="primary"
                onClick={handleNotAdmitClick}
                progress={nonAdmissionProgress}
              />
              {!!nonAdmissionError && (
                <Tooltip disableFocusListener disableTouchListener title={nonAdmissionError}>
                  <span><PriorityHigh color="error" /></span>
                </Tooltip>
              )}
            </div>
          )}
      </TableCell>
    </TableRow>
  );
};

const CheckupFilters = ({
  initial, isFilterSet, onChange, onReset, service, translate,
}) => {
  const organizations = useTelemedicServiceJsonValue(() => service.getOrganizationValues(), null, []);
  const terminals = useTelemedicServiceJsonValue(() => service.getTerminalValues(), null, []);
  return (
    <Toolbar variant="regular" disableGutters>
      <Form
        initialValues={initial}
        onSubmit={onChange}
        onReset={onReset}
        submitOnChange
      >
        <FilterFormContent>
          <TextInput
            name="userName"
            label={translate('telemedic.checkupsLive.list.userName')}
            margin="dense"
            size="small"
            variant="filled"
            helperText={false}
            resettable
          />
          <Spacer />
          <AutocompleteInput
            name="organizationId"
            label="telemedic.checkupsLive.list.organization"
            choices={organizations}
            choiceLabelField="name"
            choiceValueField="id"
            helperText={false}
            resettable
          />
          <Spacer />
          <MultiAutocompleteInput
            name="terminalNames"
            label="telemedic.checkupsLive.list.terminal"
            choices={terminals}
            choiceLabelField="name"
            choiceValueField="name"
            helperText={false}
            suggestionLimit={10}
            resettable
          />
          <Spacer />
          <BooleanInput
            name="awaiting"
            label={translate('telemedic.checkupsLive.list.awaitingOnly')}
            helperText={false}
          />
          <Spacer fill />
          {isFilterSet && (
            <Button
              type="reset"
              color="primary"
            >
              {translate('telemedic.checkupsLive.list.reset')}
            </Button>
          )}
        </FilterFormContent>
      </Form>
    </Toolbar>
  );
};

const CheckupTable = ({
  checkupActions, checkups, error, isFilterSet, userHasReviewPermission, translate,
}) => {
  const rowProps = useMemo(() => ({
    checkupActions, translate, userHasReviewPermission,
  }), [checkupActions, translate, userHasReviewPermission]);
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.employee')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.organization')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.type')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.datetimeStart')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.datetimeEnd')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.datetimeReceived')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.resultAuto')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.resultMedic')}</TableCell>
            <TableCell>{translate('telemedic.checkupsLive.list.isObserved')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={10}
            data={checkups}
            emptyTextId={isFilterSet
              ? 'telemedic.checkupsLive.list.noResults'
              : 'telemedic.checkupsLive.list.noCheckups'}
            error={!!error}
            errorText={error}
            placeholderRows={5}
            Row={CheckupRow}
            rowProps={rowProps}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CheckupLivePage.contextType = WebsocketContext;

export default withSoundNotification(withTranslate(withCheckupActions(CheckupLivePage)));
