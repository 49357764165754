import React, {useCallback} from 'react';
import {useInput, useTranslate} from 'react-admin';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperTextSpan from '../FormHelperTextSpan';
import styles from './FileInput.module.css';

const FileInput = (props) => {
  const {
    accept,
    className,
    helperText,
    label,
    margin = 'dense',
    required,
    variant = 'filled'
  } = props;
  const {
    meta: {touched, error},
    input: {onChange, value, ...inputRest},
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const handleChange = useCallback(
    (event) => onChange(event.target.files[0]),
    [onChange]
  );
  return (
    <FormControl className={className} margin={margin}>
      <div className={styles.inputBackground}>
        <InputLabel
          error={!!(touched && error)}
          required={required || isRequired}
          shrink={true}
          variant={variant}
        >
          {labelText}
        </InputLabel>
        <input type="file" accept={accept} onChange={handleChange} {...inputRest}/>
      </div>
      <FormHelperText
        error={!!(touched && error)}
        variant={variant}
      >
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}/>
      </FormHelperText>
    </FormControl>
  );
}

export default FileInput;
