import React from 'react';
import {Create} from 'react-admin';

import DealerForm from './DealerForm';
import DealerTitle from './DealerTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const DealerCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={DealerForm}
    routePath={RoutePath.DEALER_SHOW}
    title={<DealerTitle/>}
    {...props}/>
);
