import React, {forwardRef} from 'react';
import {useTranslate, useLogout} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/ExitToApp';
import UsbIcon from '@material-ui/icons/Usb';

import {RoutePath, navigateTo} from '../routes';
import {isTokenAttached} from '../storages/auth';

const ProfileMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  const logout = useLogout();
  const handleAttachToken = () => navigateTo(RoutePath.ATTACH_TOKEN);
  const handleLogout = () => logout();
  return (
    <React.Fragment>
      <MenuItem onClick={handleAttachToken}>
        <UsbIcon />
        {
          isTokenAttached()
          ? translate('telemedic.actions.replaceToken')
          : translate('telemedic.actions.attachToken')
        }
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ExitIcon /> {translate('telemedic.actions.logout')}
      </MenuItem>
    </React.Fragment>
  );
});

export default ProfileMenu;
