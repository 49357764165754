import React from 'react';
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box';

import images from '../../images';
import styles from './LoginBox.module.css';

const LoginBox = ({ children }) => (
  <div className={styles.container}>
    <Box className={styles.box}>
      <img
        className={styles.logo}
        src={images.logo}
        alt=""
      />
    </Box>
    <div className={styles.spacer} />
    <Box className={styles.box}>
      {children}
    </Box>
  </div>
);

LoginBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginBox;
