import React from 'react';
import {Edit} from 'react-admin';

import InspectionPointForm from './InspectionPointForm';
import InspectionPointTitle from './InspectionPointTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const InspectionPointEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={InspectionPointForm}
    routePath={RoutePath.INSPECTION_POINT_SHOW}
    title={<InspectionPointTitle/>}
    {...props}/>
);
