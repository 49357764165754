import React from 'react';
import PromiseProgressDialog from '../../components/PromiseProgressDialog';

import moment from 'moment';
import { TechInspectionType } from '../../constants';
import { createTelemedicService } from '../../services/TelemedicService';
import { isTokenAttached } from '../../storages/auth';
import { getRutokenServiceInstance } from '../../services/RutokenService';

export function withTechInspectionActions(WrappedComponent) {

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        epl_gis_epd_api: false,
        showAdmissionDialog: false,
        showNonAdmissionDialog: false,
        signing: false
      }
      this.telemedicService = createTelemedicService();
      this.rutokenService = getRutokenServiceInstance();
      this.techInspectionId = null;
      this.callbacks = null;
      this.deviceId = null;
      this.certificateId = null;
      this.date = moment(new Date(2022, 8, 5)).format('YYYY-MM-DD');
      this.organizationId = null;
      this.driverId = null
      this.encodedJournal = null;
      this.epl_gis_epd_api = false;
      this.techInspectionType = 0;
      this.dontReload = false;
    }

    componentWillUnmount() {
      this.telemedicService.abort();
    }

    render() {
      const {showAdmissionDialog, showNonAdmissionDialog} = this.state;
      return (
        <React.Fragment>
          <WrappedComponent
            techInspectionActions={{
              admitVehicle: this.admitVehicle,
              notAdmitVehicle: this.notAdmitVehicle,
              setFlagforEpl: this.setFlagforEpl,
              signCheckupIfNeeded: this.signCheckupIfNeeded
            }}
            {...this.props}/>
          {showAdmissionDialog &&
            <PromiseProgressDialog
              promiseFactory={this._admitVehicle}
              onCancel={this._onCancel}
              onDone={this._onDone}/>
          }
          {showNonAdmissionDialog &&
            <PromiseProgressDialog
              promiseFactory={this._notAdmitVehicle}
              onCancel={this._onCancel}
              onDone={this._onDone}/>
          }
        </React.Fragment>
      );
    }

    admitVehicle = (techInspectionId, driverId, organizationId, callbacks) => {
      this.setState({
        showAdmissionDialog: true,
        techInspectionId: techInspectionId
      });
      this.techInspectionId = techInspectionId;
      this.driverId = driverId;
      this.organizationId = organizationId;
      this.callbacks = callbacks;
    }

    _admitVehicle = () => {
      return this.signCheckupIfNeeded().then(() => this._handleFetchPromise(
        this.telemedicService.admitVehicle(this.techInspectionId),
      ))
    }

    notAdmitVehicle = (techInspectionId, callbacks) => {
      this.setState({
        showNonAdmissionDialog: true,
        techInspectionId: techInspectionId
      });
      this.techInspectionId = techInspectionId;
      this.callbacks = callbacks;
    }

    _notAdmitVehicle = () => {
      return this._handleFetchPromise(
        this.telemedicService.notAdmitVehicle(this.techInspectionId)
      );
    }

    signCheckupIfNeeded = () => {
        this.setState({
          signing: true
        });
        const epl_gis_epd_api = this.epl_gis_epd_api;
        let certificateKey;
        console.log('Join in signCheckupIfNeeded');
        console.log('epl_gis_epd_api: ' + epl_gis_epd_api)
        if (epl_gis_epd_api) {
          console.log('Join in epl_gis_epd_api');
          return this._withDeviceAndCertificateId(({deviceId, certificateId}) => {
            console.log('Join in _withDeviceAndCertificateId');
            return this.rutokenService.getCertificatePublicKey(deviceId, certificateId)
              .then((certificatePublicKey) => {
                certificateKey = certificatePublicKey;
                console.log('Join in getCertificatePublicKey');
                return this.signJournals(deviceId, certificateId, certificatePublicKey);})
              .then(() => this.secondSignJournals(deviceId, certificateId, certificateKey))
          }).then(() => {if (!this.dontReload) setTimeout(() => window.location.reload(), 4000)})
          .catch((error) => {
            this.dontReload = true;
            this.telemedicService.revertVehicleAdmit(this.techInspectionId)
            console.log(error)
            return Promise.reject({
              errorTextId: 'telemedic.techInspections.show.sendGisEpdSignedError',
              error,
            });});
        }
        this.setState({
          signing: false
        });
      return Promise.resolve();
    }

    _withDeviceAndCertificateId = (promiseFactory) => {
      if (isTokenAttached()) {
        return this.rutokenService.getDeviceAndCertificateId()
          .catch((error) => {
            this.telemedicService.revertVehicleAdmit(this.techInspectionId)
            console.log(error);
            return Promise.reject({
              errorTextId: 'telemedic.checkupDialogs.tokenErrorText',
              error,
            });
          })
          .then((data) => promiseFactory(data));
      }
      return promiseFactory({deviceId: null, certificateId: null});
    }

    signJournals = (deviceId, certificateId, certificatePublicKey) => {
      if (this.techInspectionType === TechInspectionType.POST_TRIP || this.techInspectionType === TechInspectionType.POST_SHIFT || this.techInspectionType === TechInspectionType.POST_WATCH) {
        return this.telemedicService.getEncodedPostTechInspectionXml(this.techInspectionId, certificatePublicKey).then((response) => {
          this.encodedJournal = response.json.data;
          return this.rutokenService.sign(deviceId, certificateId, this.encodedJournal);
        }).then((signature) => {
          return this.telemedicService.uploadSignedPostTechInspectionXml(this.techInspectionId, this.encodedJournal, signature);
        });
      }
      return this.telemedicService.getEncodedTechInspectionXml(this.techInspectionId, certificatePublicKey).then((response) => {
        this.encodedJournal = response.json.data;
        return this.rutokenService.sign(deviceId, certificateId, this.encodedJournal);
      }).then((signature) => {
        return this.telemedicService.uploadSignedTechInspectionXml(this.techInspectionId, this.encodedJournal, signature);
      });
    }

    secondSignJournals = (deviceId, certificateId, certificatePublicKey) => {
      console.log('Join in secondSignJournals');
      if (this.techInspectionType === TechInspectionType.POST_TRIP || this.techInspectionType === TechInspectionType.POST_SHIFT || this.techInspectionType === TechInspectionType.POST_WATCH) {
        return this.telemedicService.getEncodedPostTechInspectionXmlOdometr(this.techInspectionId, certificatePublicKey).then((response) => {
          this.encodedJournal = response.json.data;
          return this.rutokenService.sign(deviceId, certificateId, this.encodedJournal);
        }).then((signature) => {
          return this.telemedicService.uploadSignedPostTechInspectionXmlOdometr(this.techInspectionId, this.encodedJournal, signature);
        });
      }
      return this.telemedicService.getEncodedTechInspectionXmlOdometr(this.techInspectionId, certificatePublicKey).then((response) => {
        this.encodedJournal = response.json.data;
        return this.rutokenService.sign(deviceId, certificateId, this.encodedJournal);
      }).then((signature) => {
        return this.telemedicService.uploadSignedTechInspectionXmlOdometr(this.techInspectionId, this.encodedJournal, signature);
      });
    }

    setFlagforEpl = (epl_gis_epd_api, organizationId, techInspectionType) => {
      this.techInspectionType = techInspectionType
      this.organizationId = organizationId;
      this.epl_gis_epd_api = epl_gis_epd_api;
    }

    _handleFetchPromise = (promise) => {
      return promise.catch((e) => {
        console.log(e);
        const result = {};
        if (e.errorDescription) {
          result.errorText = e.errorDescription;
        }
        else {
          result.errorTextId = 'telemedic.techInspectionDialogs.requestErrorText';
          result.error = e;
        }
        this.telemedicService.revertVehicleAdmit(this.techInspectionId)
        return Promise.reject(result);
      });
    }

    _onCancel = () => {
      const {signing} = this.state;
      this.setState({
        showAdmissionDialog: false,
        showNonAdmissionDialog: false
      });
      if (this.callbacks && this.callbacks.onCancel) {
        this.callbacks.onCancel();
      }
      this.callbacks = null;
      if (signing) {
        this.telemedicService.revertVehicleAdmit(this.techInspectionId)
      }
    }

    _onDone = () => {
      this.setState({
        showAdmissionDialog: false,
        showNonAdmissionDialog: false
      });
      if (this.callbacks && this.callbacks.onDone) {
        this.callbacks.onDone();
      }
      this.callbacks = null;
    }
  };
}

export default withTechInspectionActions;
