import React from 'react';
import {Edit} from 'react-admin';

import UserForm from './UserForm';
import UserTitle from './UserTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const UserEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={UserForm}
    routePath={RoutePath.USER_SHOW}
    title={<UserTitle/>}
    {...props}/>
);
