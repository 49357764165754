import React from 'react';
import { connect } from 'react-redux';
import { showNotification, withTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './CheckupsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import {
  formatCheckupResult, formatInspectionType, formatInspectionsTypeGenitivePlural,
  formatDisplayDate, formatDisplayDateTime, formatDisplayTime,
} from '../../utils/formatters';
import { REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS, REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V } from './constants';

class CheckupsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportProgress: false,
    };
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  onExport = () => {
    this.setState({ exportProgress: true });
    const { showNotification, params } = this.props;
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId, reportType,
    } = params;
    const withoutRepeats = reportType === REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS;
    const withoutRepeats2v = reportType === REPORT_TYPE_INSPECTIONS_WITHOUT_REPEATS_2V;
    const promise = this.service.exportCheckupsStatistics(
      dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats, withoutRepeats2v,
    );
    promise.then(() => {
      this.setState({ exportProgress: false });
    }).catch((e) => {
      console.log(e);
      this.setState({ exportProgress: false });
      showNotification('telemedic.errors.submitError');
    });
  }

  formatTotal(total) {
    const { translate } = this.props;
    const totalStringParts = [
      `${translate('telemedic.statistics.checkups.total')}: ${total.checkups}. `,
      `${translate('telemedic.statistics.checkups.admissions')}: ${total.admissions}. `,
      `${translate('telemedic.statistics.checkups.nonAdmissions')}: ${total.non_admissions}. `,
      `${translate('telemedic.statistics.checkups.awaiting')}: ${total.awaiting}. `
    ];
    const typesStringParts = [];
    for (let [checkupType, count] of Object.entries(total.checkups_by_type)) {
      typesStringParts.push(
        `${formatInspectionsTypeGenitivePlural(checkupType)}: ${count}. `
      );
    }
    totalStringParts.push(...typesStringParts.sort());
    return totalStringParts.join('');
  }

  renderTable(data) {
    const { translate } = this.props;
    const formatPeriod = (dateFrom, dateTo) => `${formatDisplayDateTime(dateFrom)}-${formatDisplayTime(dateTo)}`;
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnCheckupDate')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnCheckupType')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnPersonnelNumber')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnFullName')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnBirthday')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnBloodPressure')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnPulse')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnAlcohol')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnTemperature')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnComplaints')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnVisualInspect')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnMedic')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnApproval')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnOrganization')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkups.columnSignature')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.checkups.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{formatPeriod(row.datetime_start, row.datetime_end)}</TableCell>
                <TableCell>{formatInspectionType(row.checkup_type)}</TableCell>
                <TableCell>{row.personnel_number}</TableCell>
                <TableCell>{`${row.last_name} ${row.first_name} ${row.middle_name}`}</TableCell>
                <TableCell>{formatDisplayDate(row.birthday)}</TableCell>
                <TableCell>{`${row.arterial_pressure}/${row.venous_pressure}`}</TableCell>
                <TableCell>{row.pulse}</TableCell>
                <TableCell>{row.alcohol}</TableCell>
                <TableCell>{row.temperature}</TableCell>
                <TableCell>{row.complaints}</TableCell>
                <TableCell>{row.visual_inspect}</TableCell>
                <TableCell>
                  {`${row.medic_last_name} ${row.medic_first_name} ${row.medic_middle_name}`}
                  <br />
                  {row.digital_signature}
                </TableCell>
                <TableCell>{formatCheckupResult(row.result_medic)}</TableCell>
                <TableCell>{row.organization_name}</TableCell>
                <TableCell />
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={16}>{this.formatTotal(data.total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const {
      label, statisticsData, onBackClick, translate,
    } = this.props;
    const { exportProgress } = this.state;

    const pageTitle = translate(label);

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon />}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData} />
        {this.renderTable(statisticsData)}
      </Card>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(CheckupsDisplay));
