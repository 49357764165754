import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SaveIcon from '@material-ui/icons/Save';

import ProgressButton from '../../components/ProgressButton';
import StatisticsHeader from './StatisticsHeader';
import ToolBar from '../../components/ToolBar';
import styles from './CheckupsDisplay.module.css';

import TelemedicService from '../../services/TelemedicService'
import {getAuthToken} from '../../storages/auth';
import {
  formatCheckupResult, formatInspectionsTypeGenitivePlural, formatDisplayDate,
  formatDisplayYear, formatDisplayMonthName, formatDisplayDay,
  formatDisplayTime
} from '../../utils/formatters';

class CheckupsDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statisticsData: null,
      exportProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {statisticsData, onBackClick, translate} = this.props;
    const exportProgress = this.state.exportProgress;

    const pageTitle = translate('telemedic.statistics.checkupsExtended.title');

    return (
      <Card className={styles.card}>
        <ToolBar
          title={pageTitle}
          onBackClick={onBackClick}
        >
          <ProgressButton
            className={styles.floatRight}
            variant="contained"
            color="primary"
            icon={<SaveIcon/>}
            label="telemedic.actions.export"
            onClick={this.onExport}
            progress={exportProgress}
          />
        </ToolBar>
        <StatisticsHeader statisticsData={statisticsData}/>
        {this.renderTable(statisticsData)}
      </Card>
    );
  }

  renderTable(data) {
    const translate = this.props.translate;
    const formatSex = (sex) => {
      if (sex === 1) return translate('telemedic.statistics.checkupsExtended.sexFemale');
      return translate('telemedic.statistics.checkupsExtended.sexMale');
    };
    const noComplaints = translate('telemedic.statistics.checkupsExtended.noComplaints');
    return (
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnTerminalName')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnCheckupYear')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnCheckupMonth')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnCheckupDay')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnCheckupTime')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnPersonnelNumber')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnFullName')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnPosition')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnSex')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnBirthday')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnComplaints')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnTemperature')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnBloodPressureUpper')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnBloodPressureLower')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnPulse')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnAlcohol')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnUrinalysis')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnResult')}</TableCell>
              <TableCell>{translate('telemedic.statistics.checkupsExtended.columnMedic')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.checkups.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{row.terminal_name}</TableCell>
                <TableCell>{formatDisplayYear(row.datetime_start)}</TableCell>
                <TableCell>{formatDisplayMonthName(row.datetime_start)}</TableCell>
                <TableCell>{formatDisplayDay(row.datetime_start)}</TableCell>
                <TableCell>{formatDisplayTime(row.datetime_start)}</TableCell>
                <TableCell>{row.personnel_number}</TableCell>
                <TableCell>{row.last_name} {row.first_name} {row.middle_name}</TableCell>
                <TableCell>{row.position}</TableCell>
                <TableCell>{formatSex(row.sex)}</TableCell>
                <TableCell>{formatDisplayDate(row.birthday)}</TableCell>
                <TableCell>{row.complaints || noComplaints}</TableCell>
                <TableCell>{row.temperature}</TableCell>
                <TableCell>{row.arterial_pressure}</TableCell>
                <TableCell>{row.venous_pressure}</TableCell>
                <TableCell>{row.pulse}</TableCell>
                <TableCell>{row.alcohol}</TableCell>
                <TableCell></TableCell>
                <TableCell>{formatCheckupResult(row.result_medic)}</TableCell>
                <TableCell>
                  {row.medic_last_name} {row.medic_first_name} {row.medic_middle_name}<br/>
                  {row.digital_signature}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={19}>{this.formatTotal(data.total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  formatTotal(total) {
    const translate = this.props.translate;
    const totalStringParts = [
      `${translate('telemedic.statistics.checkups.total')}: ${total.checkups}. `,
      `${translate('telemedic.statistics.checkups.admissions')}: ${total.admissions}. `,
      `${translate('telemedic.statistics.checkups.nonAdmissions')}: ${total.non_admissions}. `
    ];
    const typesStringParts = [];
    for (let [checkupType, count] of Object.entries(total.checkups_by_type)) {
      typesStringParts.push(
        `${formatInspectionsTypeGenitivePlural(checkupType)}: ${count}. `
      );
    }
    totalStringParts.push(...typesStringParts.sort());
    return totalStringParts.join('');
  }

  onExport = () => {
    this.setState({exportProgress: true});
    const {
      dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats
    } = this.props.params;
    const promise = this.service.exportCheckupsExtendedStatistics(
      dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats
    );
    promise.then(() => {
      this.setState({exportProgress: false});
    }).catch((e) => {
      console.log(e);
      this.setState({exportProgress: false});
      this.props.showNotification('telemedic.errors.submitError');
    });
  }
}

export default connect(null, {showNotification})(withTranslate(CheckupsDisplay));
