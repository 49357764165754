import React, {Children, isValidElement, cloneElement} from 'react';
import Grid from '@material-ui/core/Grid';


const GridShowMediator = ({
  basePath,
  children,
  record,
  resource,
  version,
  ...rest
}) => (
  <Grid {...rest}>
    {Children.map(children, field =>
        field && isValidElement(field) ? (
            typeof field.type === 'string'? (
              field
            ) : (
              cloneElement(field, {
                record,
                resource,
                basePath,
              })
            )
        ) : null
    )}
  </Grid>
);

export default GridShowMediator;
