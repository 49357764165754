import React, {useState} from 'react';
import {
  NumberInput, TextInput, BooleanInput, RadioButtonGroupInput
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import { CheckupType } from '../../constants';
import { formatInspectionType } from '../../utils/formatters';

const CheckupForm = ({getInputProps, ...rest}) => {
  const service = useTelemedicService();
  const [organizationId, setOrganizationId] = useState();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getUserFormOrganizationValues();
  }, null, []);
  const users = useTelemedicServiceJsonValue((orgId) => {
    return orgId ? service.getUserValues(orgId) : [];
  }, organizationId, []);
  const checkupTypes = Object.values(CheckupType).map((checkupType) => (
    { id: checkupType, name: formatInspectionType(checkupType).toLowerCase() }
  ));
  return (
    <RestrictedSimpleForm {...rest}>
      <AutocompleteInput
        label="telemedic.checkups.form.organization"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        onChange={(orgId) => setOrganizationId(orgId)}
        required
        {...getInputProps('organization_ids')}/>
      <AutocompleteInput
        label="telemedic.checkups.form.user"
        choices={users}
        choiceLabelField="name"
        choiceValueField="id"
        source="user_id"
        required
        {...getInputProps('user_id')}/>
      <RadioButtonGroupInput
        source="type"
        label="telemedic.checkups.form.checkupType"
        choices={checkupTypes}
        required
      />
      <NumberInput
        label="telemedic.checkups.form.arterialPressure"
        source="arterial_pressure"
        {...getInputProps('arterial_pressure')}/>
      <NumberInput
        label="telemedic.checkups.form.venousPressure"
        source="venous_pressure"
        {...getInputProps('venous_pressure')}/>
      <NumberInput
        label="telemedic.checkups.form.pulse"
        source="pulse"
        {...getInputProps('pulse')}/>
      <TextInput
        label="telemedic.checkups.form.alcohol"
        source="alcohol"
        {...getInputProps('alcohol')}/>
      <NumberInput
        label="telemedic.checkups.form.temperature"
        source="temperature"
        {...getInputProps('temperature')}/>
      <NumberInput
        label="telemedic.checkups.form.weight"
        source="weight"
        {...getInputProps('weight')}/>
      <TextInput
        label="telemedic.checkups.form.graphTest"
        source="graph_test"
        {...getInputProps('graph_test')}/>
      <TextInput
        label="telemedic.checkups.form.pulseoximeter"
        source="pulseoximeter"
        {...getInputProps('pulseoximeter')}/>
      <BooleanInput
        label="telemedic.checkups.form.feelingGood"
        source="feeling_good" />
      <BooleanInput
        label="telemedic.checkups.form.healthComplaints"
        source="health_complaints" />
      <BooleanInput
        label="telemedic.checkups.form.feelRested"
        source="feel_rested" />
    </RestrictedSimpleForm>
  );
}

export default CheckupForm;
