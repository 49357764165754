import React from 'react';
import PropTypes from 'prop-types';

import styles from './Spacer.module.css';

const Spacer = ({fill}) => {
  return (
    <div className={fill ? styles.fillSpacer : styles.spacer}> </div>
  );
}

Spacer.propTypes = {
  fill: PropTypes.bool
};

export default Spacer;
