import React, { useCallback } from 'react';
import {
  Notification, Title, withTranslate,
} from 'react-admin';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';

import moment from 'moment';
import IndexedList from '../../utils/IndexedList';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import TableRow from '../../components/tables/TableRow';
import withSoundNotification from '../../hocs/withSoundNotification';
import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';

import { CheckupResult } from '../../constants';
import { RoutePath, navigateTo } from '../../routes';
import {
  formatApiDateTime, formatDisplayMoscowDateTime,
} from '../../utils/formatters';

import sounds from '../../sounds';

const PAGE_SIZE = 50;
const FIRST_PAGE = 1;
const TEST_RESULT_LOAD_SCROLL_MARGIN = 500;

class TestResultLivePage extends React.Component {
  constructor(props) {
    super(props);

    this.service = new TelemedicService(getAuthToken());

    this.state = {
      indexedTestResults: new IndexedList([]),
      listError: null,
      updateError: null,
      startDate: formatApiDateTime(moment().subtract(1, 'days')),
      syncDate: null,
      testResults: null,
      currentPage: FIRST_PAGE,
      isLoadingNextPage: false,
      hasLoadedAllTestResults: false,
    };

    this.notificationSound = new Audio(sounds.notification);
    this.disconnectedSound = new Audio(sounds.disconnected);

    this.onScroll = this.onScroll.bind(this);

    this.bottomOfList = React.createRef();
  }

  componentDidMount() {
    const { startDate } = this.state;

    window.addEventListener('scroll', this.onScroll);

    this.getPaginatedTestResults(startDate, FIRST_PAGE)
      .then(() => {
        const intervalCallback = () => {
          const { syncDate } = this.state;
          this.updateTestResults(startDate, syncDate || startDate);
        };
        this.updateInterval = setInterval(intervalCallback, 10000);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      indexedTestResults, hasLoadedAllTestResults, startDate, currentPage, isLoadingNextPage
    } = this.state;

    const { top, bottom } = this.bottomOfList.current.getBoundingClientRect();
    const verticalHeight = (window.innerHeight || document.documentElement.clientHeight);

    const isBottomOfListVisible = (top > 0 || bottom > 0) && top < verticalHeight;
    if (isBottomOfListVisible && !hasLoadedAllTestResults && !isLoadingNextPage) {
      this.getPaginatedTestResults(startDate, currentPage + 1)
    }
    if (prevState.indexedTestResults === indexedTestResults) {
      return;
    }
    const testResults = indexedTestResults.getList().sort((c1, c2) => c1.id < c2.id);
    this.setState({ testResults });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    clearInterval(this.updateInterval);
  }

  onScroll(event) {
    const {
      startDate, currentPage, isLoadingNextPage, hasLoadedAllTestResults,
    } = this.state;
    const { scrollingElement } = event.target;
    const scrollingLeft = scrollingElement.scrollHeight - scrollingElement.scrollTop - scrollingElement.clientHeight;
    if (scrollingLeft <= TEST_RESULT_LOAD_SCROLL_MARGIN && !isLoadingNextPage && !hasLoadedAllTestResults) {
      const nextPage = currentPage + 1;
      this.getPaginatedTestResults(startDate, nextPage);
    }
  }

  getPaginatedTestResults(dateFrom, page) {
    const { translate } = this.props;
    const { indexedTestResults } = this.state;

    this.setState({ isLoadingNextPage: true, hasLoadedAllTestResults: false });

    return this.service.getTestResults({
      dateCreatedFrom: dateFrom,
      sort: '-created_at',
      page,
      pageSize: PAGE_SIZE,
    }).then((data) => {
      if (page === FIRST_PAGE) {
        this.setState({ indexedTestResults: new IndexedList(data.json.data) });
      } else {
        const [updatedTestResults] = indexedTestResults.getUpdated(data.json.data);
        this.setState({ indexedTestResults: updatedTestResults });
      }
      const hasLoadedAllTestResults = data.json.data.length < PAGE_SIZE;
      this.setState({
        syncDate: data.json.sync_dt,
        currentPage: page,
        hasLoadedAllTestResults,
        isLoadingNextPage: false,
      });
    }).catch((e) => {
      console.log(e);
      this.setState({ indexedTestResults: null });
      this.setState({ listError: e.errorDescription || translate('telemedic.errors.fetchError') });
    });
  }

  updateTestResults(dateFrom, syncDate) {
    const { playSound, translate } = this.props;
    const { updateError, indexedTestResults } = this.state;
    this.service.getTestResultsUpdates({
      createdFrom: dateFrom,
    }).then((data) => {
      const [updatedTestResults, newTestResults] = indexedTestResults.getUpdated(data.json.data);
      this.setState({
        indexedTestResults: updatedTestResults,
        updateError: null,
        syncDate: data.json.sync_dt,
      });
      if (newTestResults.some((checkup) => checkup.attributes.result_medic === CheckupResult.AWAITING)) {
        playSound(this.notificationSound);
      }
    }).catch((e) => {
      console.log(e);
      if (!updateError) {
        playSound(this.disconnectedSound);
        const error = translate('telemedic.testResultsLive.list.listUpdateError');
        const errorDescription = e.errorDescription || translate('telemedic.errors.fetchError');
        this.setState({ updateError: `${error}. ${errorDescription}` });
      }
    });
  }

  render() {
    const {
      listError, updateError, testResults, isLoadingNextPage,
    } = this.state;
    const { translate } = this.props

    return (
      <div>
        <Title title="telemedic.testResultsLive.label" />
        <Paper>
          <TestResultTable
            testResults={testResults}
            error={listError}
            translate={translate}
          />
        </Paper>
        {
          isLoadingNextPage && <LinearProgress />
        }
        <div ref={this.bottomOfList} />
        <Notification
          message={updateError}
          open={!!updateError}
          type="error"
        />
      </div>
    );
  }
}

const TestResultRow = ({ item }) => {
  const { id, attributes } = item;
  const handleRowClick = useCallback(() => {
    navigateTo(RoutePath.TEST_RESULTS_SHOW, { id });
  }, [id]);
  return (
    <TableRow
      clickable
      key={id}
      hover
      onClick={handleRowClick}
    >
      <TableCell>{id}</TableCell>
      <TableCell>
        {`${attributes.user.last_name} ${attributes.user.first_name} ${attributes.user.middle_name}`}
      </TableCell>
      <TableCell>{attributes.user.organization.name}</TableCell>
      <TableCell>{attributes.number_of_questions}</TableCell>
      <TableCell>{attributes.number_of_correct_answers}</TableCell>
      <TableCell>{formatDisplayMoscowDateTime(attributes.created_at)}</TableCell>
    </TableRow>
  );
};

const TestResultTable = ({
  testResults, error, translate,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.testResultsLive.list.employee')}</TableCell>
            <TableCell>{translate('telemedic.testResultsLive.list.organization')}</TableCell>
            <TableCell>{translate('telemedic.testResultsLive.list.numberOfQuestions')}</TableCell>
            <TableCell>{translate('telemedic.testResultsLive.list.numberOfCorrectAnswers')}</TableCell>
            <TableCell>{translate('telemedic.testResultsLive.list.createdAt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={10}
            data={testResults}
            emptyTextId="telemedic.testResultsLive.list.noTestResults"
            error={!!error}
            errorText={error}
            placeholderRows={5}
            Row={TestResultRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withSoundNotification(withTranslate(TestResultLivePage));
