import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {Title, showNotification, useTranslate} from 'react-admin';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import SaveIcon from '@material-ui/icons/Save';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ProgressButton from '../../components/ProgressButton';
import TableArrayAdapter from '../../components/tables/TableArrayAdapter';
import ToolBar from '../../components/ToolBar';
import styles from './MedicEcpDisplay.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import {RoutePath, getUrl} from '../../routes';
import {formatPersonName} from '../../utils/formatters';

const _MedicEcpDisplay = ({showNotification}) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const [exportProgress, setExportProgress] = useState(false);
  const handleExport = useCallback(() => {
    setExportProgress(true);
    service.exportMedicEcpReport().then(() => {
      setExportProgress(false);
    }).catch((e) => {
      console.log(e);
      setExportProgress(false);
      showNotification('telemedic.errors.submitError');
    });
  }, [service, setExportProgress, showNotification]);
  const pageTitle = translate('telemedic.control.medicEcp.label');
  return (
    <Card className={styles.card}>
      <Title title="telemedic.control.label"/>
      <ToolBar title={pageTitle}>
        <ProgressButton
          className={styles.floatRight}
          variant="contained"
          color="primary"
          icon={<SaveIcon/>}
          label="telemedic.actions.export"
          onClick={handleExport}
          progress={exportProgress}
        />
      </ToolBar>
      <MedicsTable/>
    </Card>
  );
}

const MedicsTable =() => {
  const translate = useTranslate();
  const [medicsError, setMedicsError] = useState(false);
  const service = useTelemedicService();
  const medics = useTelemedicServiceJsonValue(() => {
    return service.getMedicEcpReport();
  }, null, null, (e) => {
    console.log(e);
    setMedicsError(true);
  });
  return (
    <TableContainer className={styles.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnName')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnLogin')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnEcpDateFrom')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnEcpDateTo')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnLastActivityDate')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnPhone')}</TableCell>
            <TableCell>{translate('telemedic.control.medicEcp.columnOrgNames')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableArrayAdapter
            columns={8}
            data={medics}
            error={medicsError}
            emptyTextId="telemedic.common.noData"
            errorTextId="telemedic.errors.fetchError"
            placeholderRows={5}
            Row={MedicsTableRow}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const MedicsTableRow =({item}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">{item.id}</TableCell>
      <TableCell>
        <Link href={getUrl(RoutePath.WORKER_SHOW, {id: item.id})}>
          {formatPersonName(item)}
        </Link>
      </TableCell>
      <TableCell>{item.login}</TableCell>
      <TableCell>{item.ecp_date_from}</TableCell>
      <TableCell>{item.ecp_date_to}</TableCell>
      <TableCell>{item.last_activity_date}</TableCell>
      <TableCell>{item.phone}</TableCell>
      <TableCell>{item.org_names}</TableCell>
    </TableRow>
  );
}

export const MedicEcpDisplay = connect(null, {showNotification})(_MedicEcpDisplay);
