import React from 'react';
import {connect} from 'react-redux';
import {showNotification, withTranslate} from 'react-admin';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import FormFooter from '../../components/FormFooter';
import ProgressButton from '../../components/ProgressButton';
import styles from './MedicWorkloadForm.module.css';

import TelemedicService from '../../services/TelemedicService';
import {getAuthToken} from '../../storages/auth';
import {formatApiDate} from '../../utils/formatters';

class MedicWorkloadForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      medOrganizations: [],
      medics: [],
      selectedMedOrganizationId: null,
      submitProgress: false,
    }
    this.service = new TelemedicService(getAuthToken());

    this.medicsCache = {};

    const params = this.props.params;
    const periodEnd = moment();
    const periodStart = moment().subtract(7, 'days');
    this.formInitialValues = (params) ? params : {
      dateFrom: formatApiDate(periodStart),
      dateTo: formatApiDate(periodEnd),
      medOrganizationId: null,
      medicId: null,
    };

    this.requiredFields = [
      'dateFrom', 'dateTo', 'medOrganizationId', 'medicId'
    ];
  }

  componentDidMount() {
    this.service.getMedicWorkloadMedOrganizations().then((response) => {
      this.setState({medOrganizations: response.json.data});
    }).catch((e) => {
      console.log(e);
    });
  }

  loadOrgMedics(medOrganizationId) {
    const cachedMedics = this.medicsCache[medOrganizationId];
    if (cachedMedics) {
      this.setState({medics: cachedMedics});
    }
    else {
      this.service.getMedicWorkloadMedics(medOrganizationId).then((response) => {
        const medics = response.json.data;
        this.medicsCache[medOrganizationId] = medics;
        this.setState({medics: medics});
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  componentWillUnmount() {
    this.service.abort();
  }

  render() {
    const {medOrganizations, medics, submitProgress} = this.state;
    return (
      <Paper>
        <Form
          initialValues={this.formInitialValues}
          validate={this.validate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        >
          <FormContent>
            <DateInput
              className={styles.formControl}
              source="dateFrom"
              label="telemedic.medicWorkload.form.dateFrom"
              required/>
            <DateInput
              className={styles.formControl}
              source="dateTo"
              label="telemedic.medicWorkload.form.dateTo"
              required/>
            <AutocompleteInput
              className={styles.formControl}
              source="medOrganizationId"
              label="telemedic.medicWorkload.form.medOrganization"
              choices={medOrganizations}
              choiceLabelField="name"
              choiceValueField="id"
              required/>
            <AutocompleteInput
              className={styles.formControl}
              source="medicId"
              label="telemedic.medicWorkload.form.medic"
              choices={medics}
              choiceLabelField="name"
              choiceValueField="id"
              required/>
          </FormContent>
          <FormFooter>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              label="telemedic.actions.send"
              progress={submitProgress}
            />
          </FormFooter>
        </Form>
      </Paper>
    );
  }

  validate = (values) => {
    const errors = {};
    for (const fieldName of this.requiredFields) {
      if (!values[fieldName]) {
        errors[fieldName] = 'telemedic.validation.required';
      }
    }
    return errors;
  };

  onChange = (data) => {
    const selectedMedOrganizationId = this.state.selectedMedOrganizationId;
    if (selectedMedOrganizationId !== data.medOrganizationId) {
      this.setState({
        selectedMedOrganizationId: data.medOrganizationId,
      });
      this.loadOrgMedics(data.medOrganizationId);
    }
  }

  onSubmit = (data) => {
    this.props.paramsCallback(data);
    this.setState({submitProgress: true});
    const {medicId, dateFrom, dateTo} = data;
    const promise = this.service.getMedicWorkloadReport(medicId, dateFrom, dateTo);
    promise.then((response) => {
      this.setState({submitProgress: false});
      if (response.json.stats.length > 0) {
        this.props.dataCallback(response.json);
      }
      else {
        this.props.showNotification('telemedic.errors.noData');
      }
    }).catch((e) => {
      console.log(e);
      this.setState({submitProgress: false});
      this.props.showNotification('telemedic.errors.submitError');
    });
  };
}

export default connect(null, {showNotification})(withTranslate(MedicWorkloadForm));
