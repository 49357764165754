export const ValueTypes = {
  INTEGER: 'integer',
  STRING: 'string'
}

const PARSERS = {};
PARSERS[ValueTypes.INTEGER] = v => Math.round(v);
PARSERS[ValueTypes.STRING] = v => String(v);

export const castValue = (value, valueType) => {
  if (typeof(value) === valueType) {
    return value;
  }
  return PARSERS[valueType](value);
}
