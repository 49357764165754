import React, { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';

import CodeForm from './CodeForm';
import Info from '../../../components/Info';
import LoginBox from '../LoginBox';
import LoginForm from './LoginForm';
import PasswordForm from './PasswordForm';
import useTelemedicService from '../../../hooks/useTelemedicService';

import styles from '../LoginStyles.module.css';

const PasswordResetPage = () => {
  const translate = useTranslate();
  const service = useTelemedicService();

  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(null);

  const [lastLogin, setLastLogin] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenExpiresAt, setTokenExpiresAt] = useState(null);
  const sendCode = useCallback((login) => {
    setProgress(true);
    service.sendPasswordResetCode(login).then((response) => {
      setProgress(false);
      setError(null);
      setToken(response.json.token);
      setTokenExpiresAt(new Date(response.json.expires_at));
    }).catch((e) => {
      setProgress(false);
      setError(e.errorDescription || translate('telemedic.errors.submitError'));
    });
  }, [service, translate]);

  const submitLogin = useCallback(({ login }) => {
    setLastLogin(login);
    sendCode(login);
  }, [sendCode]);
  const cancel = useCallback(() => window.history.back(), []);

  const [lastCode, setLastCode] = useState(null);
  const [codeAccepted, setCodeAccepted] = useState(false);
  const checkCode = useCallback(({ code }) => {
    setProgress(true);
    service.checkPasswordResetCode(token, code).then(() => {
      setProgress(false);
      setError(null);
      setLastCode(code);
      setCodeAccepted(true);
    }).catch((e) => {
      setProgress(false);
      setError(e.errorDescription || translate('telemedic.errors.submitError'));
      setLastCode(null);
      setCodeAccepted(false);
    });
  }, [token, service, translate]);
  const resendCode = useCallback(() => sendCode(lastLogin), [lastLogin, sendCode]);
  const backToLogin = useCallback(() => {
    setLastLogin(null);
    setToken(null);
    setTokenExpiresAt(null);
  }, []);

  const [passwordChanged, setPasswordChanged] = useState();
  const setPassword = useCallback(({ password, confirm }) => {
    setProgress(true);
    service.resetPassword(token, lastCode, password, confirm).then(() => {
      setProgress(false);
      setError(null);
      setPasswordChanged(true);
    }).catch((e) => {
      setProgress(false);
      setError(e.errorDescription || translate('telemedic.errors.submitError'));
      setPasswordChanged(false);
    });
  }, [lastCode, service, token, translate]);

  return (
    <LoginBox>
      {!token && (
        <LoginForm
          onSubmit={submitLogin}
          onCancel={cancel}
          submissionProgress={progress}
        />
      )}
      {!!token && !codeAccepted && (
        <CodeForm
          onSubmit={checkCode}
          backToLogin={backToLogin}
          onCancel={cancel}
          onCodeRequested={resendCode}
          submissionProgress={progress}
          tokenExpiresAt={tokenExpiresAt}
        />
      )}
      {!!token && codeAccepted && (
        <PasswordForm
          onSubmit={setPassword}
          onCancel={cancel}
          submissionProgress={progress}
        />
      )}
      {!!error && (
        <Info align="center" className={styles.control} level="error">
          {error}
        </Info>
      )}
      {passwordChanged && (
        <Info align="center" className={styles.control} level="info">
          {translate('telemedic.passwordReset.passwordChanged')}
        </Info>
      )}
    </LoginBox>
  );
};

export default PasswordResetPage;
