import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Form from '../../../components/Form';
import ProgressButton from '../../../components/ProgressButton';
import styles from '../LoginStyles.module.css';
import useInterval from '../../../hooks/useInterval';
import { formatTimeFromSeconds } from '../../../utils/formatters';

const CodeForm = ({
  onSubmit, onCancel, backToLogin, onCodeRequested, submissionProgress, tokenExpiresAt,
}) => {
  const translate = useTranslate();
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.code) {
      errors.code = 'telemedic.validation.required';
    }
    return errors;
  }, []);
  const [timerText, setTimerText] = useState(null);
  const intervalCallback = useCallback(() => {
    const seconds = (tokenExpiresAt - new Date()) / 1000;
    if (seconds > 0) {
      setTimerText(`${translate('telemedic.passwordReset.getCodeTimerText')} ${formatTimeFromSeconds(seconds)}`);
    } else {
      setTimerText(null);
    }
  }, [tokenExpiresAt, translate]);
  useInterval(intervalCallback, 1000, true);
  const isTokenExpired = !timerText;
  return (
    <Form
      className={styles.form}
      onSubmit={onSubmit}
      validate={validate}
    >
      <Typography variant="h6">
        {translate('telemedic.passwordReset.enterCode')}
      </Typography>
      <TextInput
        className={styles.largeInput}
        fullWidth
        helperText={false}
        label="telemedic.passwordReset.code"
        margin="normal"
        name="code"
        variant="outlined"
      />
      <ProgressButton
        className={styles.control}
        color="primary"
        fullWidth
        disabled={isTokenExpired}
        label="telemedic.passwordReset.resetPassword"
        progress={submissionProgress}
        type="submit"
        variant="contained"
      />
      <Button
        className={styles.control}
        color="primary"
        disabled={isTokenExpired}
        fullWidth
        onClick={backToLogin}
        type="button"
        variant="contained"
      >
        {translate('telemedic.passwordReset.backToLogin')}
      </Button>
      <Button
        className={styles.control}
        color="primary"
        disabled={isTokenExpired}
        fullWidth
        onClick={onCancel}
        type="button"
      >
        {translate('telemedic.actions.goBack')}
      </Button>
      {!isTokenExpired && (
        <Typography>{timerText}</Typography>
      )}
      {isTokenExpired && (
        <Button
          color="primary"
          disabled={!isTokenExpired}
          fullWidth
          onClick={onCodeRequested}
          type="button"
          variant="contained"
        >
          {translate('telemedic.passwordReset.getCodeAgain')}
        </Button>
      )}
    </Form>
  );
};

CodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backToLogin: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCodeRequested: PropTypes.func.isRequired,
  submissionProgress: PropTypes.bool.isRequired,
  tokenExpiresAt: PropTypes.instanceOf(Date),
};

CodeForm.defaultProps = {
  tokenExpiresAt: null,
};

export default CodeForm;
