import React from 'react';
import {Filter, Datagrid, TextField, TextInput} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import {formatApiDateTime} from '../../utils/formatters';
import {
  formatDisplayDateTime, formatTechInspectionResult, formatTechInspectionType
} from '../../utils/formatters';

export const TechInspectionList = props => {
  const formatVehicleName = ({manufacturer, model}) => {
    return `${manufacturer} ${model}`;
  }
  return(
    <RestrictedList
      title="telemedic.techInspections.label"
      filters={<TechInspectionFilter/>}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <FormatValueField
          label="telemedic.techInspections.list.vehicle"
          source="vehicle"
          sortable={true}
          formatter={formatVehicleName}/>
        <TextField
          label="telemedic.techInspections.list.carLicensePlate"
          source="vehicle.car_license_plate"
          sortable={true}
          sortBy="car_license_plate"/>
        <FormatValueField
          label="telemedic.techInspections.list.date"
          source="date"
          sortable={true}
          formatter={formatDisplayDateTime}/>
        <FormatValueField
          label="telemedic.techInspections.list.type"
          source="type_of_inspection"
          sortable={true}
          formatter={formatTechInspectionType}/>
        <FormatValueField
          label="telemedic.techInspections.list.result"
          source="result"
          sortable={true}
          formatter={formatTechInspectionResult}/>
        <TextField
          label="telemedic.techInspections.list.organization"
          source="organization.name"
          sortable={true}
          sortBy="organization"/>
      </Datagrid>
    </RestrictedList>
  );
}

const TechInspectionFilter = (props) => (
    <Filter key="tech_inspection_filter" {...props}>
      <DateInput
        label="telemedic.techInspections.list.dateFrom"
        source="date_from"
        valueFormatter={formatApiDateTime}
        alwaysOn minWidth resettable/>
      <DateInput
        label="telemedic.techInspections.list.dateTo"
        source="date_to"
        valueFormatter={formatApiDateTime}
        alwaysOn minWidth resettable/>
      <TextInput
        label="telemedic.techInspections.list.carLicensePlate"
        source="car_license_plate"
        alwaysOn resettable/>
      <TelemedicDataSource
        dataPromise={organizationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        alwaysOn resettable>
        <AutocompleteInput
          label="telemedic.techInspections.list.organization"
          choiceLabelField="name"
          choiceValueField="id"
          source="organization_id"/>
      </TelemedicDataSource>
    </Filter>
);

const organizationsDataPromise = (service) => {
  return service.getOrganizationValues();
}
