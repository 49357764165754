import React from 'react';
import {SimpleShowLayout, TextField} from 'react-admin';

import DealerTitle from './DealerTitle';
import RestrictedShow from '../../components/admin/RestrictedShow';

export const DealerShow = (props) => {
  return (
    <RestrictedShow title={<DealerTitle/>} {...props}>
      <SimpleShowLayout>
        <TextField label="telemedic.dealers.show.name" source="name"/>
        <TextField label="telemedic.dealers.show.responsible" source="responsible"/>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
