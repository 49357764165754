import * as React from "react"

function BloodPressure(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontFeatureSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        d="M32.442.577a6.148 6.148 0 00-6.147 6.148A6.148 6.148 0 0032 12.855v13.85l.01.053s.1.582-.056 1.106a1.257 1.257 0 01-.434.666c-.226.173-.57.313-1.164.313H19.378s-.57-.008-1.125-.285c-.554-.277-1.067-.719-1.067-1.907v-1.919h6.038V5.788H2.907v18.944h3.018v8.232s-.006.786.359 1.598 1.224 1.698 2.664 1.698h15.095v1.094h2.891a6.19 3.847 0 005.48 2.063 6.19 3.847 0 006.19-3.847 6.19 3.847 0 00-6.19-3.846 6.19 3.847 0 00-5.692 2.336h-2.679v1.095H8.948c-1.03 0-1.405-.487-1.657-1.047-.253-.56-.26-1.145-.26-1.145v-8.231h9.051v1.92c0 1.555.86 2.485 1.677 2.894.818.409 1.619.401 1.619.401h10.978c.778 0 1.395-.202 1.838-.543.443-.34.691-.8.818-1.223a3.539 3.539 0 00.092-1.58V12.833a6.148 6.148 0 005.485-6.11A6.148 6.148 0 0032.442.578zm-.027 1.373a4.803 4.803 0 014.802 4.803 4.803 4.803 0 01-4.802 4.802 4.803 4.803 0 01-4.804-4.802 4.803 4.803 0 014.804-4.803zm-.456 1.098v4.116h1.186V3.048zM11.694 6.894H22.12v16.734H11.694c-.548 0-.898-.182-1.197-.482-.3-.299-.527-.737-.681-1.2-.308-.924-.314-1.884-.314-1.884V9.086c0-1.188.51-1.631 1.065-1.909a2.885 2.885 0 011.127-.283z"
        color="#000"
        fontWeight={400}
        fontFamily="sans-serif"
        overflow="visible"
      />
    </svg>
  )
}

export default BloodPressure
