import React from 'react';
import PropTypes from 'prop-types';

import TableRowEmpty from './TableRowEmpty';
import TableRowPlaceholder from './TableRowPlaceholder';

const TableArrayAdapter = ({
  columns,
  data,
  emptyText,
  emptyTextId,
  error,
  errorText,
  errorTextId,
  placeholderRows,
  Row,
  rowProps
}) => {
  if (error) {
    return (
      <TableRowEmpty color="error" colSpan={columns} text={errorText} textId={errorTextId}/>
    );
  }
  if (!data && placeholderRows) {
    return (
      <React.Fragment>
        {[...Array(placeholderRows)].map((el, i) => (
          <TableRowPlaceholder key={i} columns={columns}/>
        ))}
      </React.Fragment>
    );
  }
  if (data && !data.length) {
    return (
      <TableRowEmpty colSpan={columns} text={emptyText} textId={emptyTextId}/>
    );
  }
  return (
    <React.Fragment>
      {data.map((item, index) => (
        <Row key={index} item={item} {...rowProps}/>
      ))}
    </React.Fragment>
  );
}

TableArrayAdapter.propTypes = {
  columns: PropTypes.number,
  data: PropTypes.array,
  emptyText: PropTypes.string,
  emptyTextId: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  errorTextId: PropTypes.string,
  placeholderRows: PropTypes.number,
  Row: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  rowProps: PropTypes.object
};

export default TableArrayAdapter;
