import React from 'react';
import { withTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from '@material-ui/core/FormHelperText';

import ProgressButton from '../../components/ProgressButton';

import { CheckupResult } from '../../constants';

class _AdmissionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitProgress: false,
      errorText: '',
    };
  }

  submitAdmission = () => {
    this.setState({ submitProgress: true });
    const { admitUser, onDone, translate } = this.props;
    admitUser().then(() => {
      this.setState({
        submitProgress: false,
        errorText: '',
      });
      onDone();
    }).catch((e) => {
      if (e.errorText) {
        this.setState({
          submitProgress: false,
          errorText: e.errorText,
        });
      } else if (e.errorTextId) {
        this.setState({
          submitProgress: false,
          errorText: translate(e.errorTextId),
        });
      } else {
        this.setState({
          submitProgress: false,
          errorText: translate('telemedic.errors.unknownError'),
        });
      }
    });
  }

  render() {
    const { resultAuto, onCancel, translate } = this.props;
    const { submitProgress, errorText } = this.state;
    let confirmationText;
    let confirmationTextColor;
    if (resultAuto === CheckupResult.ADMITTED) {
      confirmationText = translate('telemedic.checkupDialogs.confirmAdmission');
      confirmationTextColor = 'info';
    } else {
      confirmationText = translate('telemedic.checkupDialogs.warningNotAdmitted');
      confirmationTextColor = 'error';
    }
    return (
      <Dialog open>
        <DialogContent>
          <DialogContentText color={confirmationTextColor}>
            {confirmationText}
          </DialogContentText>
          <FormHelperText error>{errorText}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <ProgressButton
            type="submit"
            variant="contained"
            color="primary"
            label="telemedic.actions.admit"
            progress={submitProgress}
            onClick={this.submitAdmission}
          />
          <Button onClick={onCancel}>
            {translate('telemedic.dialogs.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslate(_AdmissionDialog);
