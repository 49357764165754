import React from 'react';
import {SimpleShowLayout, TextField} from 'react-admin';

import RestrictedShow from '../../components/admin/RestrictedShow';
import VehicleTitle from './VehicleTitle';

export const VehicleShow = (props) => {
  return (
    <RestrictedShow title={<VehicleTitle/>} {...props}>
      <SimpleShowLayout>
        <TextField label="telemedic.common.kisArtId" source="kis_art_id"/>
        <TextField label="telemedic.vehicles.show.manufacturer" source="manufacturer"/>
        <TextField label="telemedic.vehicles.show.model" source="model"/>
        <TextField label="telemedic.vehicles.show.year" source="year"/>
        <TextField label="telemedic.vehicles.show.color" source="color"/>
        <TextField label="telemedic.vehicles.show.carLicensePlate" source="car_license_plate"/>
        <TextField label="telemedic.vehicles.show.vin" source="vin"/>
        <TextField label="telemedic.vehicles.show.numberTrail" source="number_trail"/>
        <TextField label="telemedic.vehicles.show.licenseCard" source="license_card"/>
        <TextField label="telemedic.vehicles.show.osago" source="osago"/>
        <TextField label="telemedic.vehicles.show.endOsago" source="end_osago"/>
        <TextField label="telemedic.vehicles.show.diagnosticCard" source="diagnostic_card"/>
        <TextField label="telemedic.vehicles.show.endDiagnosticCard" source="end_diagnostic_card"/>
        <TextField label="telemedic.vehicles.show.permissionNumber" source="permission_number"/>
        <TextField label="telemedic.vehicles.show.organization" source="organization.name"/>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
