import useAsyncValue from './useAsyncValue';

const useTelemedicServiceJsonValue = (promiseFactory, promiseArg, initialValue, errorCallback) => {
  const wrappedPromiseFactory = (promiseArg) => {
    return promiseFactory(promiseArg).then((result) => {
      return result.json.data;
    });
  }
  const [value] = useAsyncValue(wrappedPromiseFactory, promiseArg, initialValue, errorCallback);
  return value;
}

export default useTelemedicServiceJsonValue;
