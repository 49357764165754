import React from 'react';
import {Filter, Datagrid, TextField, TextInput} from 'react-admin';

import RestrictedList from '../../components/admin/RestrictedList';

export const OrgBalanceList = (props) => (
    <RestrictedList
      title="telemedic.orgBalance.label"
      filters={<OrgBalanceFilter/>}
      sort={{field: 'name', order: 'ASC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <TextField
          label="telemedic.orgBalance.list.name"
          source="name"
          sortable={true}/>
        <TextField
          label="telemedic.orgBalance.list.balance"
          source="balance"
          sortable={true}/>
      </Datagrid>
    </RestrictedList>
);

const OrgBalanceFilter = (props) => (
    <Filter key="org_balance_filter" {...props}>
      <TextInput
        label="telemedic.orgBalance.list.name"
        source="name"
        alwaysOn resettable/>
    </Filter>
);
