import React from 'react';
import {Create} from 'react-admin';

import VehicleForm from './VehicleForm';
import VehicleTitle from './VehicleTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const VehicleCreate = (props) => (
  <PessimisticMutationMode
    Component={Create}
    Form={VehicleForm}
    routePath={RoutePath.VEHICLE_SHOW}
    title={<VehicleTitle/>}
    {...props}/>
);
