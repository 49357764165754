import React, {useCallback} from 'react';
import {
  BulkDeleteButton, ListContextProvider, ListView, Pagination,
  useListController, usePermissions
} from 'react-admin';

import userSettings from '../../storages/userSettings';
import {Action} from '../../permissions';

const RestrictedList = (props) => {
  const resource = props.resource;
  const pageSize = userSettings.resourcePageSize.get(resource, 25);
  const controllerProps = useListController({
    ...props,
    perPage: pageSize
  });
  const {setPerPage} = controllerProps;
  const setResourcePerPage = useCallback((perPage) => {
    userSettings.resourcePageSize.set(resource, perPage);
    setPerPage(perPage);
  }, [resource, setPerPage]);
  const customControllerProps = {
    ...controllerProps,
    setPerPage: setResourcePerPage
  }
  return (
    <ListContextProvider value={customControllerProps}>
      <ListView
        {...props}
        {...customControllerProps}
        bulkActionButtons={<RestrictedBulkActionButtons/>}
        pagination={<CustomPagination/>}/>
    </ListContextProvider>
  );
};

const RestrictedBulkActionButtons = ({resource, ...rest}) => {
  const {loaded, permissions} = usePermissions();
  const section = resource ? resource.toUpperCase() : '';
  const canDelete = loaded && permissions.check(section, Action.DELETE);
  if (!canDelete) {
    return null;
  }
  return (
    <BulkDeleteButton resource={resource} undoable={false} {...rest}/>
  );
}

const CustomPagination = (props) => {
  return (
    <Pagination rowsPerPageOptions={[25, 50, 100]} {...props}/>
  );
}

export default RestrictedList;
