import React from 'react';
import PropTypes from 'prop-types';

import {Placeholder} from 'react-admin';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TableRowPlaceholder = ({columns}) => {
  return (
    <TableRow>
      {[...Array(columns)].map((el, i) => (
        <TableCell key={i}><Placeholder/></TableCell>
      ))}
    </TableRow>
  );
}

TableRowPlaceholder.propTypes = {
  columns: PropTypes.number
};

export default TableRowPlaceholder;
