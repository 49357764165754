import React from 'react';
import Typography from '@material-ui/core/Typography';

const FormatField = ({className, formatter, record = {}, source = undefined}) => {
  const value = formatter(record, source);
  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {value && typeof value !== 'string' && !React.isValidElement(value) ? JSON.stringify(value) : value}
    </Typography>
  );
};

FormatField.displayName = 'FormatField';
FormatField.defaultProps = {
    addLabel: true,
};

export default FormatField;
