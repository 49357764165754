import React from 'react';
import {
  TextInput, required,
} from 'react-admin';

import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import DateInput from '../../components/inputs/DateInput';

const TestResultForm = ({ getInputProps, ...rest }) => (
  <RestrictedSimpleForm {...rest}>
    <TextInput
      label="telemedic.testResults.form.employee"
      source="user_id"
      validate={required()}
      {...getInputProps('user_id')}
    />
    <TextInput
      label="telemedic.testResults.form.numberOfQuestions"
      source="number_of_questions"
      validate={required()}
      {...getInputProps('number_of_questions')}
    />
    <TextInput
      label="telemedic.testResults.form.numberOfCorrectAnswers"
      source="number_of_correct_answers"
      validate={required()}
      {...getInputProps('number_of_correct_answers')}
    />
    <DateInput
      label="telemedic.testResults.form.createdAt"
      source="created_at"
      validate={required()}
      {...getInputProps('created_at')}
    />
  </RestrictedSimpleForm>
);

export default TestResultForm;
